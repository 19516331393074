import { FunctionComponent, memo } from "react";

export type BenefitListGridType = {
  className?: string;
};

const BenefitListGrid: FunctionComponent<BenefitListGridType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[1.187rem] pl-[4.25rem] pr-[1.25rem] box-border max-w-full text-left text-[1.25rem] text-indigo-100 font-poppins lg:pl-[2.125rem] lg:box-border ${className}`}
      >
        <div className="w-[69.563rem] flex flex-row items-end justify-center gap-[7.937rem] max-w-full lg:gap-[3.938rem] mq450:gap-[1rem] mq750:gap-[2rem] mq1050:flex-wrap">
          <div className="w-[29.375rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.625rem] box-border min-w-[29.375rem] max-w-full mq750:min-w-full mq1050:flex-1">
            <div className="self-stretch flex flex-col items-end justify-start gap-[1.5rem] max-w-full">
              <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[4.062rem] pl-[1.25rem] pr-[4.875rem]">
                <div className="w-[6.375rem] flex flex-col items-end justify-start gap-[0.812rem]">
                  <img
                    className="w-[6.375rem] h-[6.375rem] relative object-cover"
                    loading="lazy"
                    alt=""
                    src="/web-designv2-10a-3@2x.png"
                  />
                  <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.875rem] pr-[0.812rem]">
                    <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600" />
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-end pt-[0rem] pb-[3.75rem] pl-[0rem] pr-[0.125rem] box-border max-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[0.375rem] max-w-full">
                  <h3 className="m-0 relative text-inherit tracking-[0.04em] leading-[3.313rem] font-semibold font-inherit mq450:text-[1rem] mq450:leading-[2.625rem]">
                    Free of Cost cPanel License :
                  </h3>
                  <div className="self-stretch h-[4.563rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0">
                    Manage your websites and web applications through a single,
                    easy to use dashboard through our cPanel license. Cherry on
                    the top, this service will be free of cost for you.
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[2.812rem] pl-[1.25rem] pr-[4.875rem]">
                <div className="w-[9.125rem] flex flex-col items-end justify-start gap-[0.812rem]">
                  <img
                    className="w-[9.125rem] h-[9.125rem] relative object-cover"
                    loading="lazy"
                    alt=""
                    src="/fp0124-rocketa-1@2x.png"
                  />
                  <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[2.25rem] pr-[2.187rem]">
                    <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600" />
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1.5rem] gap-[0.375rem]">
                <h3 className="m-0 relative text-inherit tracking-[0.04em] leading-[3.313rem] font-semibold font-inherit mq450:text-[1rem] mq450:leading-[2.625rem]">
                  SSL Certificate :
                </h3>
                <div className="self-stretch h-[4.563rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0">
                  You can avail different certificates on the similar IP
                  address. This will empower you to avail SSL license without
                  the need to purchase a separate IP.
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[0.437rem] pl-[1.25rem] pr-[4.937rem]">
                <div className="w-[8.188rem] flex flex-col items-start justify-start gap-[1.125rem]">
                  <img
                    className="w-[8.188rem] h-[8.188rem] relative object-cover"
                    loading="lazy"
                    alt=""
                    src="/complain-to-customer-servicea-2@2x.png"
                  />
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[1.75rem]">
                    <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600" />
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[0.375rem] max-w-full">
                <h3 className="m-0 relative text-inherit tracking-[0.04em] leading-[3.313rem] font-semibold font-inherit mq450:text-[1rem] mq450:leading-[2.625rem]">
                  One-Click Installation :
                </h3>
                <div className="self-stretch flex flex-col items-start justify-start gap-[0.937rem] max-w-full text-[0.938rem] text-slategray-100">
                  <div className="self-stretch h-[7.125rem] relative tracking-[0.04em] leading-[1.25rem] flex items-center shrink-0">
                    Hostlasting offers a script library named Softaculous that
                    is capable of installing more than 300 web applications such
                    as Drupal, Wordpress, and others in a single click. This
                    offers you ease in use and saves a lot of your valuable
                    time.
                  </div>
                  <div className="w-[25.5rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                    <div className="h-[10.875rem] w-[10.875rem] relative bg-[url('/public/ebe24492895e499ab1d02eeb589abda4a-1@2x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                        alt=""
                        src="/ebe24492895e499ab1d02eeb589abda4a-1@2x.png"
                      />
                      <div className="absolute top-[9.938rem] left-[3.063rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-end pt-[0rem] pb-[1.312rem] pl-[0rem] pr-[0.125rem] box-border max-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[0.375rem] max-w-full">
                  <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                    <h3 className="m-0 relative text-inherit tracking-[0.04em] leading-[3.313rem] font-semibold font-inherit mq450:text-[1rem] mq450:leading-[2.625rem]">
                      Ease in Upgrading :
                    </h3>
                  </div>
                  <div className="self-stretch h-[6rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0">
                    You can easily upgrade the services as the need arises with
                    time. Our software helps you in upgrading the plans from
                    Business to Pro. We also provide necessary assistance and
                    free cPanel hosting.
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[0.75rem] pl-[1.25rem] pr-[4.937rem]">
                <div className="h-[9.313rem] w-[9.313rem] relative bg-[url('/public/web-designv1-2a-1@2x.png')] bg-cover bg-no-repeat bg-[top]">
                  <img
                    className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                    alt=""
                    src="/web-designv1-2a-1@2x.png"
                  />
                  <div className="absolute top-[8.938rem] left-[2.313rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[0.375rem]">
                <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                  <h3 className="m-0 relative text-inherit tracking-[0.04em] leading-[3.313rem] font-semibold font-inherit mq450:text-[1rem] mq450:leading-[2.625rem]">
                    Website Migrations for Free :
                  </h3>
                </div>
                <div className="self-stretch h-[4.688rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0">
                  Our expert will help in migrating your website to our hosting
                  platform without any cost. This will provide you all the
                  advantages of our plans.
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[3.125rem] min-w-[20.938rem] max-w-full mq750:gap-[1.563rem]">
            <div className="w-[29.25rem] flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1.875rem] box-border gap-[0.375rem] max-w-full">
              <h3 className="m-0 relative text-inherit tracking-[0.04em] leading-[3.313rem] font-semibold font-inherit mq450:text-[1rem] mq450:leading-[2.625rem]">
                Fast-Loading Websites:
              </h3>
              <div className="self-stretch h-[6rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0">
                Hostlasting’s finely curated Linux web hosting plans
                with LiteSpeed technology ensure the fast loading of your
                website pages. We deploy state-of-the-art hardware to provide
                improved capabilities, efficiency, and reliability.
              </div>
            </div>
            <div className="w-[29.25rem] flex flex-col items-start justify-start gap-[1.687rem] max-w-full">
              <div className="w-[21.125rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                <div className="h-[8.688rem] w-[8.5rem] relative bg-[url('/public/77f63307e597425693d30bc8fd5d92d0a-1@2x.png')] bg-cover bg-no-repeat bg-[top]">
                  <img
                    className="absolute top-[0rem] left-[0rem] w-[8.5rem] h-[8.5rem] object-cover hidden"
                    alt=""
                    src="/77f63307e597425693d30bc8fd5d92d0a-1@2x.png"
                  />
                  <div className="absolute top-[8.313rem] left-[1.875rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[0.375rem] max-w-full">
                <div className="flex flex-row items-start justify-start py-[0rem] px-[0.062rem] box-border max-w-full">
                  <h3 className="m-0 relative text-inherit tracking-[0.04em] leading-[3.313rem] font-semibold font-inherit mq450:text-[1rem] mq450:leading-[2.625rem]">
                    Enjoy Hosting with 24x LiteSpeed Feature:
                  </h3>
                </div>
                <div className="self-stretch h-[11.875rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0">
                  We offer you an extraordinary feature to increase the page
                  load speed of your web applications and websites by nearly 24
                  times. Superfast websites have 2x the chances to rank on the
                  first page on the various search engines while comparing with
                  other normal websites. The highly dedicated Apache server
                  along with the LiteSpeed web server will give you A++ speed
                  when it comes to website load time as well as it handles
                  maximum visitors at a time.
                </div>
              </div>
            </div>
            <div className="w-[29.25rem] flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.25rem] box-border gap-[2.062rem] max-w-full mq750:gap-[1rem]">
              <div className="w-[20.063rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                <div className="w-[6.563rem] flex flex-col items-start justify-start gap-[0.687rem]">
                  <img
                    className="w-[6.563rem] h-[6.563rem] relative object-cover"
                    loading="lazy"
                    alt=""
                    src="/ssl-certificate-1-sidea-2@2x.png"
                  />
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[0.937rem]">
                    <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600" />
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[0.375rem]">
                <h3 className="m-0 relative text-inherit tracking-[0.04em] leading-[3.313rem] font-semibold font-inherit mq450:text-[1rem] mq450:leading-[2.625rem]">
                  Around the Clock Support :
                </h3>
                <div className="self-stretch h-[6rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0">
                  Hostlasting believes in serving the customers with its utmost
                  ability and dedication. We will address your queries and solve
                  your issues rapidly as our experts are at your disposal around
                  the clock. This will minimize downtime and disruption in your
                  business.
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[2.25rem] max-w-full mq750:gap-[1.125rem]">
              <div className="w-[18.813rem] flex flex-row items-start justify-start py-[0rem] px-[4.687rem] box-border mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                <div className="h-[9.438rem] flex-1 relative bg-[url('/public/2-weba-2@2x.png')] bg-cover bg-no-repeat bg-[top]">
                  <img
                    className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                    alt=""
                    src="/2-weba-2@2x.png"
                  />
                  <div className="absolute top-[8.625rem] left-[3rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[0.375rem] max-w-full">
                <div className="w-[29.25rem] flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1.687rem] box-border gap-[0.375rem] max-w-full">
                  <h3 className="m-0 relative text-inherit tracking-[0.04em] leading-[3.313rem] font-semibold font-inherit mq450:text-[1rem] mq450:leading-[2.625rem]">
                    Improved Security:
                  </h3>
                  <div className="self-stretch h-[6rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0">
                    We ensure your data and websites are secure from any data
                    breaches and cyber-attacks. We employ advanced security
                    software to protect CPGuard, emails, passwords, Hotlink,
                    Leech, and other essential data.
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[1.937rem] mq750:gap-[0.938rem]">
                  <div className="flex flex-row items-start justify-start py-[0rem] px-[4.562rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                    <div className="flex flex-col items-end justify-start gap-[0.312rem]">
                      <img
                        className="w-[9.688rem] h-[9.688rem] relative object-cover"
                        loading="lazy"
                        alt=""
                        src="/cloud-storage-3a-2@2x.png"
                      />
                      <div className="w-[8.438rem] flex flex-row items-start justify-end py-[0rem] px-[1.875rem] box-border">
                        <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600" />
                      </div>
                    </div>
                  </div>
                  <h3 className="m-0 relative text-inherit tracking-[0.04em] leading-[3.313rem] font-semibold font-inherit mq450:text-[1rem] mq450:leading-[2.625rem]">
                    Compatible with Leading Scripting Languages :
                  </h3>
                </div>
                <div className="w-[29.25rem] flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[1.062rem] box-border max-w-full text-[0.938rem] text-slategray-100">
                  <div className="h-[6rem] flex-1 relative tracking-[0.04em] leading-[1.25rem] flex items-center max-w-full">
                    Hostlasting gives you an ultimate freedom to develop your
                    website in the scripting language and framework of your
                    choice. These include MySQL, PHP, and others.
                  </div>
                </div>
                <div className="w-[27rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                  <div className="h-[9.625rem] w-[9.625rem] relative bg-[url('/public/86e2e2fcf66c41a4a2f792b940aabe0ca-3@2x.png')] bg-cover bg-no-repeat bg-[top]">
                    <img
                      className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                      alt=""
                      src="/86e2e2fcf66c41a4a2f792b940aabe0ca-3@2x.png"
                    />
                    <div className="absolute top-[9rem] left-[2.438rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default BenefitListGrid;

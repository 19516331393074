import { FunctionComponent, memo } from "react";

export type FaqItemsList1Type = {
  className?: string;
  sharedHostingIsATypeOfWeb?: string;
  hostlastingsLinuxSharedWe?: string;
};

const FaqItemsList1: FunctionComponent<FaqItemsList1Type> = memo(
  ({
    className = "",
    sharedHostingIsATypeOfWeb,
    hostlastingsLinuxSharedWe,
  }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[0.562rem] pl-[0.062rem] pr-[0.125rem] box-border max-w-full text-left text-[0.875rem] text-slategray-100 font-poppins ${className}`}
      >
        <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[1.812rem] px-[4.562rem] box-border max-w-full mq1050:pl-[2.25rem] mq1050:pr-[2.25rem] mq1050:box-border">
          <div className="h-[10rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
          <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
            <p className="m-0">{sharedHostingIsATypeOfWeb}</p>
            <p className="m-0">{hostlastingsLinuxSharedWe}</p>
          </div>
        </div>
      </div>
    );
  }
);

export default FaqItemsList1;

import { FunctionComponent, memo } from "react";
import LocationsList from "./LocationsList";
import FrameComponent51 from "./FrameComponent51";
import GroupComponent24 from "./GroupComponent24";
import GroupComponent14 from "./GroupComponent14";

export type PlansType = {
  className?: string;
};

const Plans: FunctionComponent<PlansType> = memo(({ className = "" }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[1.687rem] box-border max-w-full text-center text-[3rem] text-darkslategray-100 font-poppins ${className}`}
    >
      <div className="flex-1 bg-ghostwhite-100 flex flex-col items-end justify-start pt-[11.312rem] pb-[14.187rem] pl-[11.25rem] pr-[11.375rem] box-border gap-[9.687rem] max-w-full mq800:gap-[4.813rem] mq800:pt-[3.125rem] mq800:pb-[3.875rem] mq800:pl-[5.625rem] mq800:pr-[5.688rem] mq800:box-border mq450:gap-[2.438rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq1350:pt-[4.813rem] mq1350:pb-[6rem] mq1350:box-border">
        <div className="w-[89.438rem] h-[145.125rem] relative bg-ghostwhite-100 hidden max-w-full" />
        <div className="w-[50.125rem] flex flex-row items-start justify-end py-[0rem] px-[0.187rem] box-border max-w-full">
          <div className="flex-1 flex flex-col items-end justify-start gap-[8.187rem] max-w-full mq800:gap-[4.063rem] mq450:gap-[2.063rem]">
            <div className="flex flex-col items-start justify-start gap-[1.375rem] max-w-full">
              <div className="flex flex-row items-start justify-start relative">
                <div className="h-[22.875rem] w-[25.313rem] !m-[0] absolute bottom-[-12.812rem] left-[-24.375rem] flex flex-row items-start justify-start z-[1]">
                  <div className="h-full w-full absolute !m-[0] top-[0rem] right-[0rem] bottom-[0rem] left-[0rem]">
                    <img
                      className="absolute top-[3.875rem] left-[0rem] w-[25.313rem] h-[19rem] object-cover"
                      alt=""
                      src="/hostlastingslide2-12@2x.png"
                    />
                    <img
                      className="absolute top-[0rem] left-[0.938rem] w-[20.188rem] h-[20.188rem] object-contain z-[1]"
                      loading="lazy"
                      alt=""
                      src="/domain-1@2x.png"
                    />
                  </div>
                </div>
                <h2 className="m-0 relative text-inherit leading-[1.25rem] font-semibold font-inherit z-[2] mq800:text-[2.375rem] mq800:leading-[1rem] mq450:text-[1.813rem] mq450:leading-[0.75rem]">
                  Check out
                </h2>
              </div>
              <h2 className="m-0 relative text-[2rem] leading-[1.25rem] font-medium font-inherit inline-block min-w-[3.375rem] z-[2] mq800:text-[1.625rem] mq800:leading-[1rem] mq450:text-[1.188rem] mq450:leading-[0.75rem]">
                our
              </h2>
              <h2 className="m-0 relative text-inherit leading-[1.25rem] font-semibold font-inherit text-indigo-100 z-[2] mq800:text-[2.375rem] mq800:leading-[1rem] mq450:text-[1.813rem] mq450:leading-[0.75rem]">
                CLOUD VPS HOSTING
              </h2>
              <h2 className="m-0 relative text-[2rem] leading-[1.25rem] font-medium font-inherit inline-block min-w-[5.5rem] z-[2] mq800:text-[1.625rem] mq800:leading-[1rem] mq450:text-[1.188rem] mq450:leading-[0.75rem]">
                Plans
              </h2>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start max-w-full text-[0.75rem] text-slategray-100">
              <div className="w-[33.25rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
                <LocationsList
                  uS1="/us-1@2x.png"
                  unitedStates="United States"
                />
                <LocationsList uS1="/finland-1@2x.png" unitedStates="Finland" />
                <LocationsList uS1="/germany-1@2x.png" unitedStates="Germany" />
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[4.687rem] max-w-full text-left text-[1.5rem] text-white mq800:gap-[1.188rem] mq1150:gap-[2.313rem]">
          <div className="self-stretch flex flex-col items-end justify-start gap-[0.812rem] max-w-full">
            <div className="self-stretch flex flex-col items-start justify-start gap-[4rem] max-w-full mq800:gap-[1rem] mq1150:gap-[2rem]">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.437rem] box-border max-w-full">
                <div className="w-[31rem] rounded-tl-none rounded-tr-41xl rounded-br-41xl rounded-bl-none [background:linear-gradient(270deg,_#4b0082,_#fff)] flex flex-row items-start justify-center pt-[0.75rem] pb-[0.687rem] pl-[1.312rem] pr-[1.25rem] box-border whitespace-nowrap max-w-full z-[1]">
                  <div className="h-[4.563rem] w-[31rem] relative rounded-tl-none rounded-tr-41xl rounded-br-41xl rounded-bl-none [background:linear-gradient(270deg,_#4b0082,_#fff)] hidden max-w-full" />
                  <h2 className="m-0 relative text-inherit leading-[3.125rem] font-semibold font-inherit z-[2]">
                    Shared vCPU(x86)
                  </h2>
                </div>
              </div>
              <div className="self-stretch rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[3.5rem] box-border gap-[1.937rem] max-w-full z-[1] text-center text-[0.875rem] mq800:gap-[0.938rem] mq800:pb-[2.25rem] mq800:box-border">
                <div className="self-stretch h-[39.25rem] relative rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] hidden" />
                <FrameComponent51 />
                <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.937rem] pr-[0.75rem] box-border max-w-full text-[0.813rem] text-slategray-100">
                  <div className="flex-1 flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
                    <GroupComponent24
                      cX11="CX11"
                      intel="1 Intel"
                      gB="2 GB"
                      gB1="20 GB"
                      iPv4v6="IPv4/v6"
                      mo="$5/mo"
                    />
                    <GroupComponent14
                      cPX11="CPX11"
                      aMD="2 AMD"
                      gB="2 GB"
                      gB1="40 GB"
                      mo="$6/mo"
                    />
                    <GroupComponent24
                      propOverflowX="unset"
                      cX11="CX21"
                      intel="2 Intel"
                      gB="4 GB"
                      gB1="40 GB"
                      iPv4v6="IPv4/v6"
                      mo="$7/mo"
                    />
                    <GroupComponent14
                      propPadding="1.562rem 0.875rem 1.562rem 2.187rem"
                      cPX11="CPX21"
                      aMD="3 AMD"
                      gB="4 GB"
                      gB1="80 GB"
                      mo="$10/mo"
                    />
                    <GroupComponent24
                      propOverflowX="auto"
                      cX11="CX31"
                      intel="2 Intel"
                      gB="8 GB"
                      gB1="80 GB"
                      iPv4v6="Ipv4"
                      mo="$13/mo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[64.75rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
              <div className="w-[31rem] rounded-tl-none rounded-tr-41xl rounded-br-41xl rounded-bl-none [background:linear-gradient(270deg,_#4b0082,_#fff)] flex flex-row items-start justify-center pt-[0.75rem] px-[1.25rem] pb-[0.687rem] box-border whitespace-nowrap max-w-full z-[1]">
                <div className="h-[4.563rem] w-[31rem] relative rounded-tl-none rounded-tr-41xl rounded-br-41xl rounded-bl-none [background:linear-gradient(270deg,_#4b0082,_#fff)] hidden max-w-full" />
                <h2 className="m-0 relative text-inherit leading-[3.125rem] font-semibold font-inherit z-[2]">
                  Dedicated vCPU
                </h2>
              </div>
            </div>
          </div>
          <div className="self-stretch rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[2.875rem] box-border gap-[1.937rem] max-w-full z-[1] mq800:gap-[0.938rem] mq450:pb-[1.875rem] mq450:box-border">
            <div className="self-stretch h-[26.75rem] relative rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] hidden" />
            <FrameComponent51 />
            <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.937rem] pr-[0.75rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
                <GroupComponent14
                  propPadding="1.562rem 0.875rem 1.562rem 1.687rem"
                  cPX11="CCX12"
                  aMD="2 Intel"
                  gB="8 GB"
                  gB1="80 GB"
                  mo="$30/mo"
                />
                <GroupComponent14
                  propPadding="1.562rem 0.875rem 1.562rem 1.875rem"
                  cPX11="CCX22"
                  aMD="4 AMD"
                  gB="16 GB"
                  gB1="160 GB"
                  mo="$50/mo"
                />
                <GroupComponent14
                  propPadding="1.562rem 0.875rem 1.562rem 1.75rem"
                  cPX11="CCX32"
                  aMD="8 AMD"
                  gB="32 GB"
                  gB1="240 GB"
                  mo="$100/mo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Plans;

import { FunctionComponent, memo } from "react";

export type FrameComponent14Type = {
  className?: string;
};

const FrameComponent14: FunctionComponent<FrameComponent14Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch bg-ghostwhite-100 flex flex-col items-start justify-start pt-[5rem] px-[11.375rem] pb-[4.75rem] box-border max-w-full text-center text-[0.875rem] text-slategray-100 font-poppins mq800:pt-[3.25rem] mq800:px-[5.688rem] mq800:pb-[3.063rem] mq800:box-border mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border ${className}`}
      >
        <div className="w-[89.438rem] h-[37.125rem] relative bg-ghostwhite-100 hidden max-w-full" />
        <div className="self-stretch flex flex-row items-start justify-center text-left text-darkslategray-100">
          <h2 className="relative leading-[2.875rem] font-semibold z-[1]">
            Ready to purchase the best VPS web hosting service?
          </h2>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[2.25rem] box-border max-w-full">
          <div className="flex-1 relative leading-[1.25rem] inline-block max-w-full z-[2]">
            <p className="m-0">
              Hostlasting’s VPS hosting solutions are equipped with the best
              tools and security features. Moreover, our affordable plans,
              best-in-class server infrastructure, and excellent support will
              provide the maximum uptime and efficiency.
            </p>
            <p className="m-0">
              Hostlasting – Enterprise-grade VPS hosting solutions to empower
              your businesses
            </p>
            <p className="m-0">
              If you are planning to grow the online presence of your business
              and website at cheaper rate as compared to shared hosting, then
              choosing the best VPS web hosting services by Hostlasting would
              help you in achieving your goals. As you choose VPS hosting
              solutions over shared hosting to avail better efficiency,
              security, and flexibility, our VPS hosting services would ensure
              that you gain the appropriate and high-quality solutions. Our
              servers located in Europe have its own operating system and offer
              adaptability for rebooting.
            </p>
            <p className="m-0">
              With our multi-layered firewall configuration, our services offer
              better security and safety to your valuable data as compared to
              other service providers. In addition, you will avail a control
              panel that is easy to navigate and operate. Our team of experts is
              available around the clock to solve your queries.
            </p>
          </div>
        </div>
        <div className="w-[48.063rem] flex flex-row items-start justify-start py-[0rem] px-[0.312rem] box-border max-w-full text-[0.75rem]">
          <div className="flex-1 flex flex-col items-end justify-start gap-[1.625rem] max-w-full">
            <div className="w-[28.75rem] h-[0.125rem] relative rounded-8xs bg-indigo-100 max-w-full z-[1]" />
            <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0rem] pr-[2.25rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
                <div className="flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.5rem] box-border max-w-full text-[0.875rem]">
                  <div className="relative leading-[1.25rem] font-semibold z-[1]">
                    Following are some of the major advantages of availing our
                    services:
                  </div>
                </div>
                <div className="w-[39.313rem] flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
                  <div className="self-stretch relative leading-[1.25rem] z-[1]">
                    <ul className="m-0 font-inherit text-inherit pl-[1.333rem]">
                      <li>
                        Ease in installation of the popular operating system
                        (OS) to make the virtual server perform at its best.
                      </li>
                    </ul>
                  </div>
                  <div className="w-[23rem] relative leading-[1.25rem] inline-block max-w-full z-[1]">
                    <ul className="m-0 font-inherit text-inherit pl-[1.333rem]">
                      <li>
                        High-quality server infrastructure at budget-friendly
                        rates.
                      </li>
                    </ul>
                  </div>
                  <div className="w-[27.938rem] relative leading-[1.25rem] inline-block max-w-full z-[1]">
                    <ul className="m-0 font-inherit text-inherit pl-[1.333rem]">
                      <li>
                        Reliable, secure, and fast service for helping your
                        business grow rapidly.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="self-stretch relative leading-[1.25rem] z-[1]">
                  <ul className="m-0 font-inherit text-inherit pl-[1.333rem]">
                    <li>
                      The best SSD VPS hosting powered by the cloud-based
                      environment to offer full flexibility for management of
                      projects.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent14;

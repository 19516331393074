import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type FaqKVMType = {
  className?: string;
  hostlastingProvidesThreeT?: string;
  thesePlansIncludeVPSL2VPS?: string;
  andMalwareProtectionTheCh?: string;
  theCostOfEachPlanVariesWi?: string;

  /** Style props */
  propPadding?: CSSProperties["padding"];
};

const FaqKVM: FunctionComponent<FaqKVMType> = memo(
  ({
    className = "",
    propPadding,
    hostlastingProvidesThreeT,
    thesePlansIncludeVPSL2VPS,
    andMalwareProtectionTheCh,
    theCostOfEachPlanVariesWi,
  }) => {
    const answerContentStyle: CSSProperties = useMemo(() => {
      return {
        padding: propPadding,
      };
    }, [propPadding]);

    return (
      <div
        className={`self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full text-left text-[0.875rem] text-slategray-100 font-poppins ${className}`}
      >
        <div
          className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[2rem] px-[4.312rem] box-border max-w-full mq1050:pl-[2.125rem] mq1050:pr-[2.125rem] mq1050:box-border"
          style={answerContentStyle}
        >
          <div className="h-[8.25rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
          <div className="relative tracking-[0.04em] leading-[1.063rem] z-[1]">
            <p className="m-0">{hostlastingProvidesThreeT}</p>
            <p className="m-0">{thesePlansIncludeVPSL2VPS}</p>
            <p className="m-0">{andMalwareProtectionTheCh}</p>
            <p className="m-0">{theCostOfEachPlanVariesWi}</p>
          </div>
        </div>
      </div>
    );
  }
);

export default FaqKVM;

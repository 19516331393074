import { FunctionComponent, memo } from "react";
import OSQuestion from "./OSQuestion";

export type DedicatedCPUPlansType = {
  className?: string;
};

const DedicatedCPUPlans: FunctionComponent<DedicatedCPUPlansType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[0.75rem] pl-[1.25rem] pr-[1.437rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins ${className}`}
      >
        <div className="w-[66.75rem] flex flex-col items-start justify-start gap-[2.562rem] max-w-full mq800:gap-[1.25rem]">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[2.062rem] pr-[1.25rem]">
            <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
              FREQUENTLY ASKED QUESTIONS
            </h2>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[0.125rem] max-w-full text-[0.875rem] text-slategray-100">
            <OSQuestion
              whichHypervisorIsUsedInTh="1.Which hypervisor is used in the Standard VPS hosting?"
              compatAnswer="/vector-335.svg"
            />
            <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.562rem] box-border max-w-full">
              <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[1.25rem] px-[4.625rem] pb-[2.437rem] box-border max-w-full mq1150:pl-[2.313rem] mq1150:pr-[2.313rem] mq1150:box-border">
                <div className="h-[9rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                  <p className="m-0">
                    Hostlasting’s best VPS web hosting service utilizes stable
                    and reliable OpenVZ hypervisor. This hypervisor is a
                    Linux-based platform and operates only on the Linux-based
                    operating systems such as Ubuntu, Fedora, Centos, Debian,
                    and others.
                  </p>
                  <p className="m-0">
                    Moreover, it only supports the Linux distributions. This
                    hypervisor provides ease in scaling up and perfect ratio
                    between performance and cost. Our Tier III datacenter
                    servers are located in Lithuania, Europe.
                  </p>
                  <p className="m-0">
                    In case you need Windows 2008 R2 or Windows 2012 R2, please
                    look out for our KVM Windows VPS offerings.
                  </p>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[0.562rem] pl-[0.187rem] pr-[0rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
                <OSQuestion
                  propGap="4.062rem"
                  propPadding="0.5rem 3.812rem 0.562rem 4.687rem"
                  whichHypervisorIsUsedInTh="2.What are benefits of availing the VPS hosting from Hostlasting? How much storage and transfer data will I get?"
                  compatAnswer="/vector-335.svg"
                />
                <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[2.812rem] px-[4.625rem] box-border max-w-full mq1150:pl-[2.313rem] mq1150:pr-[2.313rem] mq1150:box-border">
                  <div className="h-[12rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                    <p className="m-0">
                      With our state-of-the-art infrastructure, Hostlasting
                      ensures you get the necessary services within your
                      budgetary constraints. Moreover, we do not compromise on
                      security and performance when it comes to serving our
                      clients. Our different plans are tailored to cater to your
                      requirements at affordable prices.
                    </p>
                    <p className="m-0">
                      Our VPS server provides the RAM capabilities from 2 GB to
                      16 GB. Moreover, the storage capacity for storing your
                      website data is from 20 GB to 160 GB. Moreover, the
                      bandwidth for transferring the data is from 1800 GB to
                      15900 GB. As per the different plans, you will avail the
                      storage and transfer capabilities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.562rem] box-border gap-[0.125rem] max-w-full">
              <OSQuestion
                propGap="1.25rem"
                propPadding="0.5rem 3.812rem 0.562rem 4.687rem"
                whichHypervisorIsUsedInTh="3.Which operating systems (OS) are compatible with Hostlasting’s VPS web hosting services?"
                compatAnswer="/vector-337.svg"
              />
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
                <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[2rem] pl-[4.75rem] pr-[4.687rem] box-border min-h-[15.063rem] max-w-full mq1150:pl-[2.375rem] mq1150:pr-[2.313rem] mq1150:box-border">
                  <div className="h-[15.063rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                    <p className="m-0">
                      Hostlasting’s VPS web hosting services are compatible with
                      four operating systems such as Ubuntu, Fedora, CentOS, and
                      Debian.
                    </p>
                    <p className="m-0">
                      Ubuntu – It is one of the most popular, open-source, and
                      free Linux distribution operating system. It can be used
                      in a cloud-based environment.
                    </p>
                    <p className="m-0">
                      CentOS – It is a Linux operating system used by
                      enterprises and has a large community to support.
                    </p>
                    <p className="m-0">
                      Fedora – This Linux distribution system is developed by
                      Fedora Project and sponsored by Red Hat. It contains the
                      free and open-source license software that can be used for
                      learning and studying.
                    </p>
                    <p className="m-0">{`Debian – It is UNIX-based operating system and popular for network servers & personal computers. It is the oldest operating system based on Linux Kernel.`}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[0.562rem] pl-[0.062rem] pr-[0.125rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
                <OSQuestion
                  propGap="1.25rem"
                  propPadding="0.5rem 3.75rem 0.562rem 4.687rem"
                  whichHypervisorIsUsedInTh="4. Will Hostlasting provide low budget VPS solution?"
                  compatAnswer="/vector-337.svg"
                />
                <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[1.937rem] pb-[1.875rem] pl-[4.687rem] pr-[4.562rem] box-border max-w-full mq1150:pl-[2.313rem] mq1150:pr-[2.25rem] mq1150:box-border">
                  <div className="h-[8.063rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                    Yes, we offer affordable and budgeted VPS hosting services.
                    Our plans offer cheaper services as compared to other VPS
                    web hosting service providers. You will gain cost-efficient
                    and high-quality services with Tier III datacenter located
                    at Europe (Lithuania). Moreover, you will be empowered with
                    necessary security tools, server performance, and other
                    configurations that would provide 99.99% uptime.
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[0.562rem] pl-[0.062rem] pr-[0.125rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
                <OSQuestion
                  propGap="1.25rem"
                  propPadding="0.5rem 3.812rem 0.562rem 4.687rem"
                  whichHypervisorIsUsedInTh="5.Will Hostlasting provide IPv6 support? Do you also offer inclusion of IPv4 addresses?"
                  compatAnswer="/vector-337.svg"
                />
                <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[2.25rem] px-[4.625rem] box-border max-w-full mq1150:pl-[2.313rem] mq1150:pr-[2.313rem] mq1150:box-border">
                  <div className="h-[9.813rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                    <p className="m-0">
                      Yes, we provide a single primary IPv6 address with all of
                      our virtual machine. In addition, you can avail the option
                      to add more addresses as per the requirement.
                    </p>
                    <p className="m-0">&nbsp;</p>
                    <p className="m-0">
                      There is an option to include a single IPv4 address with
                      each of our packages. If you need more IPv4 addresses,
                      they can be purchased as add-ons after deploying the
                      server. You can add maximum of 20 addresses in a single
                      instance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.187rem] pr-[0rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.437rem] max-w-full">
                <OSQuestion
                  propGap="1.25rem"
                  propPadding="0.5rem 3.812rem 0.562rem 4.687rem"
                  whichHypervisorIsUsedInTh="6. What are the services that you can expect with Hostlasting’s standard VPS hosting?"
                  compatAnswer="/vector-337.svg"
                />
                <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[2.5rem] pl-[4.687rem] pr-[4.562rem] box-border min-h-[19.875rem] max-w-full mq1150:pl-[2.313rem] mq1150:pr-[2.25rem] mq1150:box-border">
                  <div className="h-[19.875rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                    <p className="m-0">
                      You can avail following services by choosing Hostlasting’s
                      standard VPS hosting: Secured data – Multi-layered
                      firewall protection ensures your data is safe and secure.
                      In addition, there is the RAID technology-enabled storage
                      platform. Best VPS server infrastructure – We deploy Tier
                      III datacenter located in Lithuania, Europe. It offers
                      high security and hassle-free functionality to ensure
                      99.99% uptime. Multi-software and multi-OS support – The
                      compatibility with various software and operating systems
                      that are based on Linux and containing cPanel/WHM control
                      will help you in improving efficiency and scaling up your
                      business. Backup functionality – By enabling weekly backup
                      functionality, we ensure your valuable data is safe even
                      when some unfortunate events occur. There is also the RAID
                      technology-enabled storage platform. Entire access to the
                      root – As you gain an entire access to the root, you can
                      control various functionalities such as free DNS manager,
                      emergency access console, hostname management, reverse
                      DNS, OS installation, and others. Around the clock support
                      – Our team of experts is available for addressing your
                      queries and assisting you in case of any issues at any
                      time and from anywhere.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default DedicatedCPUPlans;

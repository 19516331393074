import { FunctionComponent, memo } from "react";

export type DataTransfer1Type = {
  className?: string;
};

const DataTransfer1: FunctionComponent<DataTransfer1Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch bg-white flex flex-col items-start justify-start pt-[4.625rem] px-[0rem] pb-[3.187rem] box-border gap-[4.125rem] max-w-full text-left text-[2rem] text-darkslategray-100 font-poppins mq450:gap-[1rem] mq450:pt-[1.938rem] mq450:pb-[1.313rem] mq450:box-border mq800:gap-[2.063rem] mq1125:pt-[3rem] mq1125:pb-[2.063rem] mq1125:box-border ${className}`}
      >
        <div className="self-stretch h-[55.625rem] relative bg-white hidden" />
        <div className="self-stretch flex flex-row items-start justify-end max-w-full">
          <div className="w-[83.25rem] flex flex-row items-end justify-between py-[0rem] pl-[1.25rem] pr-[0rem] box-border max-w-full gap-[1.25rem] mq1325:flex-wrap">
            <img
              className="w-[30.375rem] relative max-h-full object-cover max-w-full z-[1] mq1325:flex-1"
              loading="lazy"
              alt=""
              src="/2-57@2x.png"
            />
            <div className="w-[39.563rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[2.25rem] box-border min-w-[39.563rem] max-w-full mq800:min-w-full mq1325:flex-1">
              <div className="self-stretch flex flex-col items-start justify-start gap-[2.375rem] max-w-full mq800:gap-[1.188rem]">
                <div className="self-stretch h-[1.375rem] relative rounded-tl-xl rounded-tr-none rounded-br-none rounded-bl-xl bg-indigo-100 z-[1]" />
                <div className="w-[30.5rem] flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.25rem] box-border gap-[1.375rem] max-w-full">
                  <h1 className="m-0 self-stretch relative text-inherit tracking-[0.04em] leading-[2.5rem] font-semibold font-inherit z-[1] mq450:text-[1.188rem] mq450:leading-[1.5rem] mq800:text-[1.625rem] mq800:leading-[2rem]">
                    No Limits on Data Transfer
                  </h1>
                  <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                    As compared to other web hosting companies and resellers, we
                    have not put any limitations on the amount of data that is
                    allowed to transfer each month. We offer flexibility to our
                    clients in choosing the hosting location among seven
                    locations. In addition, we offer the best shared web hosting
                    platform in a cost-efficient manner along with easy steps to
                    be followed for hosting the website on the SSD servers.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[79.688rem] flex flex-row items-start justify-between py-[0rem] pl-[0rem] pr-[1.25rem] box-border max-w-full gap-[1.25rem] mq1325:flex-wrap">
          <div className="w-[45.188rem] flex flex-col items-start justify-start pt-[0.125rem] px-[0rem] pb-[0rem] box-border min-w-[45.188rem] max-w-full mq800:min-w-full mq1325:flex-1">
            <div className="self-stretch flex flex-col items-start justify-start gap-[1.375rem] max-w-full">
              <div className="self-stretch flex flex-col items-start justify-start gap-[2.375rem] max-w-full mq800:gap-[1.188rem]">
                <div className="self-stretch h-[1.375rem] relative rounded-tl-none rounded-tr-xl rounded-br-xl rounded-bl-none bg-indigo-100 z-[1]" />
                <div className="self-stretch flex flex-row items-start justify-end py-[0rem] px-[0.812rem] box-border max-w-full">
                  <h1 className="m-0 w-[33rem] relative text-inherit tracking-[0.04em] leading-[2.5rem] font-semibold font-inherit flex items-center shrink-0 max-w-full z-[1] mq450:text-[1.188rem] mq450:leading-[1.5rem] mq800:text-[1.625rem] mq800:leading-[2rem]">
                    Domain and SSL Certificates
                  </h1>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[7.125rem] pr-[1.25rem] box-border max-w-full text-[0.938rem] text-slategray-100 mq450:pl-[1.25rem] mq450:box-border mq800:pl-[3.563rem] mq800:box-border">
                <div className="w-[28.313rem] relative tracking-[0.04em] leading-[1.25rem] flex items-center shrink-0 max-w-full z-[1]">
                  By joining hands with one of the prominent providers, we
                  provide more than 200 TLDs Domain Registration Service at low
                  cost. In addition, we give you many SSL certificates such as
                  Extended Validation, Domain, Organization, secure website for
                  online transactions, and Wildcard Certificates at affordable
                  prices.
                </div>
              </div>
            </div>
          </div>
          <img
            className="w-[27.875rem] relative max-h-full object-cover max-w-full z-[1] mq1325:flex-1"
            loading="lazy"
            alt=""
            src="/1-36528082@2x.png"
          />
        </div>
      </div>
    );
  }
);

export default DataTransfer1;

import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type FrameComponent10Type = {
  className?: string;
  sharedVCPUx86?: string;

  /** Style props */
  propAlignSelf?: CSSProperties["alignSelf"];
  propPadding?: CSSProperties["padding"];
  propWidth?: CSSProperties["width"];
};

const FrameComponent10: FunctionComponent<FrameComponent10Type> = memo(
  ({
    className = "",
    propAlignSelf,
    propPadding,
    propWidth,
    sharedVCPUx86,
  }) => {
    const frameDiv4Style: CSSProperties = useMemo(() => {
      return {
        alignSelf: propAlignSelf,
        padding: propPadding,
        width: propWidth,
      };
    }, [propAlignSelf, propPadding, propWidth]);

    return (
      <div
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[1.55rem] pl-[1.312rem] pr-[1.25rem] box-border max-w-full text-left text-[1.5rem] text-white font-poppins ${className}`}
        style={frameDiv4Style}
      >
        <div className="w-[31rem] rounded-tl-none rounded-tr-41xl rounded-br-41xl rounded-bl-none [background:linear-gradient(270deg,_#4b0082,_#fff)] flex flex-row items-start justify-center pt-[0.75rem] pb-[0.687rem] pl-[1.312rem] pr-[1.25rem] box-border whitespace-nowrap max-w-full">
          <div className="h-[4.563rem] w-[31rem] relative rounded-tl-none rounded-tr-41xl rounded-br-41xl rounded-bl-none [background:linear-gradient(270deg,_#4b0082,_#fff)] hidden max-w-full" />
          <div className="relative leading-[3.125rem] font-semibold z-[1]">
            {sharedVCPUx86}
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent10;

import { FunctionComponent, memo } from "react";

export type SSLImageItemsGridType = {
  className?: string;
};

const SSLImageItemsGrid: FunctionComponent<SSLImageItemsGridType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[8.25rem] box-border max-w-full text-center text-[0.875rem] text-indigo-100 font-poppins mq750:pb-[5.375rem] mq750:box-border ${className}`}
      >
        <div className="flex-1 bg-ghostwhite-100 flex flex-row items-start justify-center pt-[0.875rem] pb-[3.125rem] pl-[11.437rem] pr-[11.125rem] box-border gap-[3.937rem] max-w-full lg:flex-wrap lg:gap-[1.938rem] lg:pl-[5.688rem] lg:pr-[5.563rem] lg:box-border mq750:gap-[1rem] mq750:pl-[2.813rem] mq750:pr-[2.75rem] mq750:box-border mq450:pt-[1.25rem] mq450:px-[1.25rem] mq450:pb-[2rem] mq450:box-border">
          <div className="h-[22rem] w-[89.438rem] relative bg-ghostwhite-100 hidden max-w-full" />
          <div className="flex-1 flex flex-col items-start justify-start pt-[1.312rem] px-[0rem] pb-[0rem] box-border min-w-[21.313rem] max-w-full mq750:min-w-full">
            <div className="self-stretch flex flex-col items-end justify-start max-w-full">
              <div className="w-[31.688rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                <div className="w-[12.438rem] flex flex-col items-end justify-start gap-[0.462rem]">
                  <div className="self-stretch flex flex-row items-start justify-end py-[0rem] px-[2.437rem]">
                    <div className="w-[6.938rem] flex flex-col items-end justify-start gap-[0.812rem]">
                      <img
                        className="w-[6.938rem] h-[6.938rem] relative object-cover z-[1]"
                        loading="lazy"
                        alt=""
                        src="/web-designv2-10a-4@2x.png"
                      />
                      <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[1.062rem] pr-[1rem]">
                        <div className="h-[0.538rem] flex-1 relative [filter:blur(11.4px)] rounded-[50%] bg-gray-1300 z-[1]" />
                      </div>
                    </div>
                  </div>
                  <h3 className="relative tracking-[0.04em] leading-[2.938rem] font-semibold z-[2]">
                    99.99% Uptime Guarantee
                  </h3>
                </div>
              </div>
              <div className="relative text-[0.75rem] leading-[1.25rem] text-slategray-100 z-[1]">
                <p className="m-0">
                  Our Uptime Guarantee scheme ensures that consumers can get
                  excellent performance
                </p>
                <p className="m-0">
                  {" "}
                  parameters from us. Our Web Service Application Mechanisms
                  ensure that the system
                </p>
                <p className="m-0">{` dumps smoothly and effectively, providing better prospects `}</p>
                <p className="m-0">for the organization in the future.</p>
              </div>
            </div>
          </div>
          <div className="w-[30.125rem] flex flex-col items-start justify-start relative gap-[2.375rem] min-w-[30.125rem] max-w-full lg:flex-1 mq750:gap-[1.188rem] mq1050:min-w-full">
            <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.312rem]">
              <img
                className="h-[7.688rem] w-[7.688rem] relative object-cover z-[1]"
                loading="lazy"
                alt=""
                src="/complain-to-customer-servicea-11@2x.png"
              />
            </div>
            <div className="w-[4.844rem] h-[0.538rem] absolute !m-[0] bottom-[5.894rem] left-[12.569rem] [filter:blur(11.4px)] rounded-[50%] bg-gray-1300 z-[1]" />
            <div className="self-stretch flex flex-col items-start justify-start">
              <div className="flex flex-row items-start justify-start py-[0rem] pl-[3.937rem] pr-[4.187rem] mq750:pl-[1.938rem] mq750:pr-[2.063rem] mq750:box-border">
                <h3 className="relative tracking-[0.04em] leading-[2.938rem] font-semibold z-[2]">
                  Around the clock Technical Support via phone
                </h3>
              </div>
              <div className="self-stretch relative text-[0.75rem] leading-[1.25rem] text-slategray-100 z-[1]">
                <p className="m-0">{`We are at your disposal for 365x24x7. We will clarify all of your queries or doubts. `}</p>
                <p className="m-0">
                  Our technical support team will always be there to help you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default SSLImageItemsGrid;

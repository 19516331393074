import { FunctionComponent, memo } from "react";

export type AffordableHostingType = {
  className?: string;
};

const AffordableHosting: FunctionComponent<AffordableHostingType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[5.687rem] box-border max-w-full text-left text-[2.5rem] text-indigo-100 font-poppins mq800:pb-[3.688rem] mq800:box-border ${className}`}
      >
        <div className="flex-1 bg-ghostwhite-100 flex flex-col items-start justify-start pt-[1.375rem] px-[11.437rem] pb-[4.125rem] box-border gap-[6.25rem] max-w-full mq450:gap-[1.563rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq800:gap-[3.125rem] mq800:pt-[1.25rem] mq800:px-[5.688rem] mq800:pb-[2.688rem] mq800:box-border">
          <div className="w-[89.438rem] h-[33.625rem] relative bg-ghostwhite-100 hidden max-w-full" />
          <h1 className="m-0 w-[40.75rem] relative text-inherit tracking-[0.04em] leading-[3.313rem] font-bold font-inherit flex items-center max-w-full z-[1] mq450:text-[1.5rem] mq450:leading-[2rem] mq800:text-[2rem] mq800:leading-[2.625rem]">
            “Know Why Affordable Web Hosting Service at Low Cost?”
          </h1>
          <div className="self-stretch rounded-8xs bg-indigo-100 flex flex-row items-start justify-between pt-[1.625rem] pb-[1.687rem] pl-[5.937rem] pr-[5.875rem] box-border max-w-full gap-[1.25rem] z-[1] text-[0.813rem] text-white mq800:pl-[1.438rem] mq800:pr-[1.438rem] mq800:box-border mq1125:pl-[2.938rem] mq1125:pr-[2.938rem] mq1125:box-border mq1325:flex-wrap">
            <div className="h-[15.25rem] w-[66.563rem] relative rounded-8xs bg-indigo-100 hidden max-w-full" />
            <div className="w-[14.938rem] flex flex-col items-start justify-start">
              <div className="self-stretch flex flex-row items-end justify-start gap-[0.687rem]">
                <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.875rem]">
                  <div className="flex flex-col items-start justify-start gap-[1.125rem]">
                    <img
                      className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[2]"
                      loading="lazy"
                      alt=""
                      src="/materialsymbolscheck.svg"
                    />
                    <img
                      className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[2]"
                      loading="lazy"
                      alt=""
                      src="/materialsymbolscheck.svg"
                    />
                    <img
                      className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[2]"
                      loading="lazy"
                      alt=""
                      src="/materialsymbolscheck.svg"
                    />
                  </div>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.687rem]">
                    <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium shrink-0 z-[2]">
                      20x LiteSpeed Hosting
                    </div>
                  </div>
                  <div className="h-[2.25rem] flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0rem] box-border mt-[-0.688rem]">
                    <div className="mt-[-0.688rem] relative tracking-[0.04em] leading-[2.938rem] font-medium shrink-0 z-[3]">
                      99.9% long-lasting Uptime
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] mt-[-0.688rem]">
                    <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium shrink-0 z-[4]">{`Unlimited Space & Bandwidth`}</div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-end justify-start gap-[0.812rem] mt-[-0.688rem]">
                <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.875rem]">
                  <img
                    className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[2]"
                    loading="lazy"
                    alt=""
                    src="/materialsymbolscheck.svg"
                  />
                </div>
                <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium z-[5]">
                  24x7 precision support
                </div>
              </div>
            </div>
            <div className="flex flex-row items-end justify-start gap-[0.687rem]">
              <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.875rem]">
                <div className="flex flex-col items-start justify-start gap-[1.125rem]">
                  <img
                    className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[2]"
                    loading="lazy"
                    alt=""
                    src="/materialsymbolscheck.svg"
                  />
                  <img
                    className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[2]"
                    loading="lazy"
                    alt=""
                    src="/materialsymbolscheck.svg"
                  />
                  <img
                    className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[2]"
                    loading="lazy"
                    alt=""
                    src="/materialsymbolscheck.svg"
                  />
                  <img
                    className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[2]"
                    alt=""
                    src="/materialsymbolscheck.svg"
                  />
                  <img
                    className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[2]"
                    alt=""
                    src="/materialsymbolscheck.svg"
                  />
                </div>
              </div>
              <div className="flex flex-col items-start justify-start">
                <div className="flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.687rem]">
                  <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium shrink-0 z-[2]">
                    1-click install Softaculous Apps
                  </div>
                </div>
                <div className="h-[2.25rem] flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0rem] box-border mt-[-0.688rem]">
                  <div className="mt-[-0.688rem] relative tracking-[0.04em] leading-[2.938rem] font-medium shrink-0 z-[3]">
                    Free website builder (Sitepad)
                  </div>
                </div>
                <div className="flex flex-row items-start justify-start py-[0rem] px-[0.062rem] mt-[-0.688rem]">
                  <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium shrink-0 z-[4]">
                    Free website migration
                  </div>
                </div>
                <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] mt-[-0.688rem]">
                  <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium shrink-0 z-[5]">
                    cPanel Control Panel
                  </div>
                </div>
                <div className="flex flex-row items-start justify-start py-[0rem] pl-[0.125rem] pr-[0rem] mt-[-0.688rem]">
                  <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium shrink-0 z-[6]">
                    Free Full Website Backup - Weekly
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-end justify-start gap-[0.687rem]">
              <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.875rem]">
                <div className="flex flex-col items-start justify-start gap-[1.125rem]">
                  <img
                    className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[2]"
                    alt=""
                    src="/materialsymbolscheck.svg"
                  />
                  <img
                    className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[2]"
                    alt=""
                    src="/materialsymbolscheck.svg"
                  />
                  <img
                    className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[2]"
                    alt=""
                    src="/materialsymbolscheck.svg"
                  />
                  <img
                    className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[2]"
                    alt=""
                    src="/materialsymbolscheck.svg"
                  />
                </div>
              </div>
              <div className="flex flex-col items-start justify-start">
                <div className="flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.687rem]">
                  <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium shrink-0 z-[2]">
                    Free SSL Certificate
                  </div>
                </div>
                <div className="h-[2.25rem] flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0rem] box-border mt-[-0.688rem]">
                  <div className="mt-[-0.688rem] relative tracking-[0.04em] leading-[2.938rem] font-medium inline-block min-w-[8.063rem] shrink-0 z-[3]">
                    Secure IMAP Email
                  </div>
                </div>
                <div className="flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] mt-[-0.688rem]">
                  <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium shrink-0 z-[4]">
                    Free E-Commerce Tools
                  </div>
                </div>
                <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] mt-[-0.688rem]">
                  <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium inline-block min-w-[6.313rem] shrink-0 z-[5]">
                    Free SEO Tools
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default AffordableHosting;

import { FunctionComponent, memo } from "react";
import OperatingSystemsOptions from "./OperatingSystemsOptions";

export type OperatingSystemsType = {
  className?: string;
};

const OperatingSystems: FunctionComponent<OperatingSystemsType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[3.75rem] box-border max-w-full text-center text-[0.875rem] text-white font-poppins ${className}`}
      >
        <div className="flex-1 [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] flex flex-row items-end justify-between pt-[1.625rem] px-[25.5rem] pb-[5.125rem] box-border max-w-full gap-[1.25rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq800:pl-[12.75rem] mq800:pr-[12.75rem] mq800:box-border mq1350:flex-wrap">
          <div className="h-[17.875rem] w-[89.438rem] relative [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] hidden max-w-full" />
          <OperatingSystemsOptions
            deviconPlaincentos="/deviconplaincentos.svg"
            centOS="CentOS"
          />
          <div className="flex flex-col items-start justify-start gap-[2.937rem] text-left text-[1.5rem] mq450:gap-[1.438rem]">
            <div className="flex flex-row items-start justify-start py-[0rem] pl-[1.562rem] pr-[0rem]">
              <h3 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit z-[1] mq450:text-[1.188rem] mq450:leading-[2.313rem]">
                Operating Systems
              </h3>
            </div>
            <div className="w-[13.313rem] flex flex-row items-end justify-between gap-[1.25rem] text-center text-[0.875rem]">
              <div className="flex flex-col items-start justify-start gap-[0.812rem]">
                <img
                  className="w-[3.125rem] h-[3.125rem] relative overflow-hidden shrink-0 z-[1]"
                  loading="lazy"
                  alt=""
                  src="/simpleiconsubuntu.svg"
                />
                <div className="relative leading-[1.25rem] inline-block min-w-[3.188rem] z-[1]">
                  Ubuntu
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-[0.937rem]">
                <img
                  className="w-[3.125rem] h-[3.125rem] relative overflow-hidden shrink-0 z-[1]"
                  loading="lazy"
                  alt=""
                  src="/mdidebian.svg"
                />
                <div className="relative leading-[1.25rem] inline-block min-w-[3.125rem] z-[1]">
                  Debian
                </div>
              </div>
            </div>
          </div>
          <OperatingSystemsOptions
            propPadding="unset"
            deviconPlaincentos="/heroiconswindowsolid.svg"
            centOS="Your Own via KVM"
          />
        </div>
      </section>
    );
  }
);

export default OperatingSystems;

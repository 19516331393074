import { FunctionComponent, memo } from "react";

export type Locations1Type = {
  className?: string;
  uS1?: string;
  unitedStates?: string;
};

const Locations1: FunctionComponent<Locations1Type> = memo(
  ({ className = "", uS1, unitedStates }) => {
    return (
      <div
        className={`flex flex-col items-start justify-start gap-[0.5rem] text-center text-[0.75rem] text-slategray-100 font-poppins ${className}`}
      >
        <img
          className="w-[6.25rem] h-[6.25rem] relative object-cover z-[1]"
          loading="lazy"
          alt=""
          src={uS1}
        />
        <div className="flex flex-row items-start justify-start py-[0rem] pl-[0.562rem] pr-[0.687rem]">
          <div className="relative leading-[1.25rem] inline-block min-w-[5rem] z-[1]">
            {unitedStates}
          </div>
        </div>
      </div>
    );
  }
);

export default Locations1;

import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type GroupComponent11Type = {
  className?: string;
  cPX11?: string;
  aMD?: string;
  gB?: string;
  gB1?: string;
  mo?: string;

  /** Style props */
  propPadding?: CSSProperties["padding"];
};

const GroupComponent11: FunctionComponent<GroupComponent11Type> = memo(
  ({ className = "", propPadding, cPX11, aMD, gB, gB1, mo }) => {
    const groupDiv1Style: CSSProperties = useMemo(() => {
      return {
        padding: propPadding,
      };
    }, [propPadding]);

    return (
      <div
        className={`self-stretch shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white overflow-x-auto flex flex-row items-end justify-between py-[1.562rem] pl-[2.125rem] pr-[0.875rem] gap-[1.25rem] z-[1] text-center text-[0.813rem] text-slategray-100 font-poppins ${className}`}
        style={groupDiv1Style}
      >
        <div className="h-[5.313rem] w-[65.125rem] relative shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white shrink-0 hidden" />
        <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.437rem] pl-[0rem] pr-[1.625rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.313rem] z-[1]">
            {cPX11}
          </div>
        </div>
        <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.437rem] pl-[0rem] pr-[2.062rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.563rem] z-[1]">
            {aMD}
          </div>
        </div>
        <div className="w-[4.875rem] shrink-0 flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.437rem] box-border">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[1.813rem] z-[1]">
            {gB}
          </div>
        </div>
        <div className="w-[5.188rem] shrink-0 flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.437rem] box-border">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.438rem] z-[1]">
            {gB1}
          </div>
        </div>
        <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.437rem] pl-[0rem] pr-[1.5rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.188rem] z-[1]">
            20 TB
          </div>
        </div>
        <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.437rem] pl-[0rem] pr-[0.937rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[3.188rem] z-[1]">
            IPv4/v6
          </div>
        </div>
        <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.25rem] pl-[0rem] pr-[1.312rem]">
          <div className="flex flex-row items-start justify-start gap-[0.687rem]">
            <img
              className="h-[1.625rem] w-[1.625rem] relative object-cover min-h-[1.625rem] z-[2]"
              alt=""
              src="/germany-2@2x.png"
            />
            <img
              className="h-[1.625rem] w-[1.625rem] relative object-cover min-h-[1.625rem] z-[2]"
              alt=""
              src="/finland-2@2x.png"
            />
            <img
              className="h-[1.625rem] w-[1.625rem] relative object-cover min-h-[1.625rem] z-[2]"
              alt=""
              src="/us-3@2x.png"
            />
          </div>
        </div>
        <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.437rem] text-[0.938rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[3.25rem] z-[1]">
            {mo}
          </div>
        </div>
        <div className="rounded-xl bg-deepskyblue flex flex-row items-start justify-start pt-[0.5rem] px-[1.937rem] pb-[0.437rem] z-[1] text-[0.875rem] text-white">
          <div className="h-[2.188rem] w-[6.375rem] relative rounded-xl bg-deepskyblue hidden" />
          <a href="#" className="[text-decoration:none] leading-[1.25rem] font-medium text-white inline-block min-w-[2.5rem] z-[1]">
            Order
          </a>
        </div>
      </div>
    );
  }
);

export default GroupComponent11;

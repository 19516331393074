import { FunctionComponent, memo } from "react";
import ReviewOne from "./ReviewOne";

export type TestimonialsType = {
  className?: string;
};

const Testimonials: FunctionComponent<TestimonialsType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[4.312rem] box-border max-w-full text-center text-[0.75rem] text-darkslategray-100 font-dm-sans mq800:pb-[2.813rem] mq800:box-border ${className}`}
      >
        <div className="w-[66.563rem] flex flex-col items-start justify-start gap-[0.75rem] max-w-full">
          <div className="self-stretch flex flex-row items-end justify-start gap-[4.5rem] max-w-full mq800:gap-[1.125rem] mq1125:flex-wrap mq1125:gap-[2.25rem]">
            <div className="flex-1 flex flex-row items-start justify-start gap-[0.937rem] min-w-[21.313rem] max-w-full mq450:flex-wrap mq450:min-w-full">
              <ReviewOne
                reviews="/ellipse-4@2x.png"
                greatHostingServiceEasyTo={`"Great hosting service! Easy to set up and reliable uptime. Customer support was helpful when I had a question. Highly recommend!"`}
              />
              <ReviewOne
                propPadding="2rem 0.75rem 2.25rem 0.812rem"
                propGap="1.437rem"
                reviews="/ellipse-5@2x.png"
                greatHostingServiceEasyTo={`"Hosting site is excellent! Affordable plans with plenty of features. The interface is user-friendly, even for beginners like me. Very satisfied with my experience."`}
              />
            </div>
            <div className="w-[29.25rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.5rem] box-border min-w-[29.25rem] max-w-full text-left text-[2.5rem] text-indigo-100 font-poppins mq800:min-w-full mq1125:flex-1">
              <h1 className="m-0 self-stretch relative text-inherit tracking-[0.04em] leading-[3.313rem] font-bold font-inherit mq450:text-[1.5rem] mq450:leading-[2rem] mq800:text-[2rem] mq800:leading-[2.625rem]">
                “WHAT OUR CLIENTS SAY ABOUT US”
              </h1>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-[4.5rem] max-w-full mq800:gap-[1.125rem] mq1125:flex-wrap mq1125:gap-[2.25rem]">
            <div className="flex-1 flex flex-row items-start justify-start gap-[0.937rem] min-w-[21.313rem] max-w-full mq450:flex-wrap mq450:min-w-full">
              <ReviewOne
                propPadding="1.75rem 0.75rem 2.062rem 0.812rem"
                propGap="0.625rem"
                reviews="/ellipse-6@2x.png"
                greatHostingServiceEasyTo={`"Impressed with the hosting service! Fast loading times for my website and seamless integration with WordPress. Pricing is competitive too. Thumbs up!"`}
              />
              <div className="flex-1 rounded-3xs bg-ghostwhite-200 flex flex-col items-start justify-start pt-[1.75rem] pb-[0.625rem] pl-[0.812rem] pr-[0.75rem] box-border gap-[1.687rem] min-w-[12rem]">
                <div className="w-[15.938rem] h-[13.063rem] relative rounded-3xs bg-ghostwhite-200 hidden" />
                <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
                  <img
                    className="h-[3.125rem] w-[3.125rem] relative rounded-[50%] object-cover z-[1]"
                    loading="lazy"
                    alt=""
                    src="/ellipse-7@2x.png"
                  />
                </div>
                <div className="self-stretch h-[5.875rem] relative leading-[1.063rem] flex items-center shrink-0 z-[1]">
                  <span>
                    <p className="m-0">
                      "Hosting site exceeded my expectations! Smooth migration
                      process and excellent performance for my e-commerce store.
                      The 24/7 support team is a lifesaver. Definitely worth
                      it!"
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <div className="w-[29.25rem] flex flex-col items-start justify-start pt-[0.5rem] px-[0rem] pb-[0rem] box-border min-w-[29.25rem] max-w-full text-left text-[0.938rem] text-slategray-100 font-poppins mq800:min-w-full mq1125:flex-1">
              <div className="self-stretch h-[5.875rem] relative tracking-[0.04em] leading-[1.25rem] flex items-center shrink-0">
                Check out some of our happy clients and their feedback/ reviews
                on our affordable web hosting service at low cost; it’s been a
                pleasure for us they are happy and on-board on our client base.
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default Testimonials;

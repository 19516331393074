import { FunctionComponent, memo } from "react";

export type FrameComponent27Type = {
  className?: string;
  cyberPanelIsAnAdvancedEas?: string;
  designedAndDevelopedByOpe?: string;
  alternativeControlPanelTo?: string;
  cyberPanelIsEquippedWithD?: string;
  backupsOneClickInstallerP?: string;
  transferYourWebServerFrom?: string;
};

const FrameComponent27: FunctionComponent<FrameComponent27Type> = memo(
  ({
    className = "",
    cyberPanelIsAnAdvancedEas,
    designedAndDevelopedByOpe,
    alternativeControlPanelTo,
    cyberPanelIsEquippedWithD,
    backupsOneClickInstallerP,
    transferYourWebServerFrom,
  }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[0.562rem] pl-[0.25rem] pr-[0rem] box-border max-w-full text-left text-[0.875rem] text-slategray-100 font-poppins ${className}`}
      >
        <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[2.312rem] pb-[2.437rem] pl-[4.687rem] pr-[4.562rem] box-border max-w-full mq1050:pl-[2.313rem] mq1050:pr-[2.25rem] mq1050:box-border">
          <div className="h-[11.75rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
          <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
            <p className="[margin-block-start:0] [margin-block-end:2px]">
              {cyberPanelIsAnAdvancedEas}
            </p>
            <p className="[margin-block-start:0] [margin-block-end:2px]">
              {designedAndDevelopedByOpe}
            </p>
            <p className="[margin-block-start:0] [margin-block-end:2px]">
              {alternativeControlPanelTo}
            </p>
            <p className="[margin-block-start:0] [margin-block-end:2px]">
              {cyberPanelIsEquippedWithD}
            </p>
            <p className="[margin-block-start:0] [margin-block-end:2px]">
              {backupsOneClickInstallerP}
            </p>
            <p className="m-0">{transferYourWebServerFrom}</p>
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent27;

import { FunctionComponent, memo } from "react";
import PlanPricingTableHead from "./PlanPricingTableHead";
import GroupComponent8 from "./GroupComponent8";

export type KvmWindowPlansPricingType = {
  className?: string;
};

const KvmWindowPlansPricing: FunctionComponent<KvmWindowPlansPricingType> =
  memo(({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[5.312rem] box-border max-w-full text-left text-[1.25rem] text-darkslategray-100 font-poppins lg:pb-[3.438rem] lg:box-border mq750:pb-[2.25rem] mq750:box-border ${className}`}
      >
        <div className="w-[66.813rem] flex flex-col items-end justify-start gap-[1.562rem] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[3rem] pr-[2.937rem] box-border max-w-full lg:pl-[1.5rem] lg:pr-[1.438rem] lg:box-border">
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.187rem] max-w-full">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.375rem] pr-[1.25rem]">
                <h2 className="m-0 relative text-inherit tracking-[0.11em] leading-[3.125rem] font-medium font-inherit mq450:text-[1rem] mq450:leading-[2.5rem]">
                  Ready to purchase the best KVM Windows VPS hosting service?
                </h2>
              </div>
              <div className="self-stretch relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
                Hostlasting’s Windows KVM VPS hosting solutions are backed by
                the powerful hardware, huge storage capacity, and advanced
                security measures. Moreover, our well-curated plans, powerful
                server infrastructure, and around the clock support is aimed at
                providing quality services and optimum uptime.
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[0.25rem] pl-[1.312rem] pr-[1.25rem] text-[1.5rem]">
            <div className="flex flex-col items-start justify-start gap-[0.187rem]">
              <div className="flex flex-row items-start justify-start py-[0rem] pl-[1.812rem] pr-[1.875rem]">
                <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">{`Plans & Pricing`}</h2>
              </div>
              <div className="relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
                Choose from our affordable plans.
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.375rem] box-border max-w-full text-center text-[0.813rem] text-slategray-100">
            <div className="flex-1 rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[3.5rem] box-border gap-[1.937rem] max-w-full mq750:gap-[0.938rem] mq750:pb-[2.25rem] mq750:box-border">
              <div className="self-stretch h-[33.313rem] relative rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] hidden" />
              <PlanPricingTableHead />
              <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.937rem] pr-[0.75rem] box-border max-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
                  <GroupComponent8
                    vPSW2="VPS-W2"
                    x260GHzCPU="1 x 2.60GHz CPU"
                    gB="2 GB"
                    gB1="20 GB"
                    tB="2 TB"
                    mo="$12/mo"
                  />
                  <GroupComponent8
                    vPSW2="VPS-W4"
                    x260GHzCPU="1 x 2.60GHz CPU"
                    gB="4 GB"
                    gB1="40 GB"
                    tB="4 TB"
                    mo="$21/mo"
                  />
                  <GroupComponent8
                    vPSW2="VPS-W8"
                    x260GHzCPU="2 x 2.60GHz CPU"
                    gB="8 GB"
                    gB1="80 GB"
                    tB="8 TB"
                    mo="$38/mo"
                  />
                  <GroupComponent8
                    vPSW2="VPS-W16"
                    x260GHzCPU="4 x 2.60GHz CPU"
                    gB="16 GB"
                    gB1="160 GB"
                    tB="16 TB"
                    mo="$68/mo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
            <div className="w-[8.813rem] flex flex-col items-start justify-start">
              <img
                className="w-[8.813rem] h-[8.813rem] relative object-cover"
                loading="lazy"
                alt=""
                src="/2-weba-5@2x.png"
              />
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[2.062rem]">
                <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem] box-border max-w-full">
            <div className="w-[56.375rem] flex flex-col items-start justify-start max-w-full">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
                <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1rem] mq450:leading-[2.313rem]">
                  Know more about the KVM Windows VPS hosting services
                </h2>
              </div>
              <div className="self-stretch relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
                Hostlasting is instrumental in offering the optimum uptime and
                high-performance, customizable, and scalable solutions. Our
                storage, management, and security solutions would cater to your
                business needs and accelerate processes.
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  });

export default KvmWindowPlansPricing;

import { FunctionComponent, memo } from "react";

export type HeroSection3Type = {
  className?: string;
};

const HeroSection3: FunctionComponent<HeroSection3Type> = memo(
  ({ className = "" }) => {
    return (
      <header
        className={`self-stretch bg-indigo-100 overflow-hidden flex flex-row items-start justify-between pt-[2.25rem] pb-[2.625rem] pl-[3.75rem] pr-[5.562rem] box-border top-[0] z-[99] sticky gap-[1.25rem] max-w-full text-left text-[0.938rem] text-black font-poppins mq450:pr-[1.25rem] mq450:box-border mq750:pl-[1.875rem] mq750:pr-[2.75rem] mq750:box-border ${className}`}
      >
        <div className="w-[15.438rem] flex flex-col items-start justify-start pt-[0.156rem] px-[0rem] pb-[0rem] box-border">
          <div className="self-stretch flex flex-row items-start justify-start gap-[1.25rem]">
            <div className="flex-1 flex flex-col items-start justify-start pt-[0.062rem] px-[0rem] pb-[0rem]">
              <img
                className="self-stretch h-[2.125rem] relative max-w-full overflow-hidden shrink-0 object-cover z-[3]"
                loading="lazy"
                alt=""
                src="/hostlasting-logo@2x.png"
              />
            </div>
            <div className="w-[5.688rem] rounded-xl border-white border-[1px] border-solid box-border flex flex-row items-start justify-start py-[0.25rem] pl-[1.25rem] pr-[1.187rem] gap-[0.625rem] z-[3]">
              <div className="h-[2.313rem] w-[5.688rem] relative rounded-xl border-white border-[1px] border-solid box-border hidden" />
              <img
                className="h-[1.563rem] w-[1.625rem] relative object-contain z-[1]"
                alt=""
                src="/us-2@2x.png"
              />
              <div className="flex flex-col items-start justify-start pt-[0.312rem] px-[0rem] pb-[0rem]">
                <img
                  className="w-[0.875rem] h-[0.875rem] relative overflow-hidden shrink-0 z-[1]"
                  alt=""
                  src="/heroiconssolidchevrondown.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <nav className="m-0 w-[41.375rem] flex flex-col items-start justify-start pt-[0.562rem] px-[0rem] pb-[0rem] box-border max-w-full lg:hidden">
          <nav className="m-0 self-stretch h-[1.5rem] relative text-left text-[0.875rem] text-white font-poppins">
            <a className="[text-decoration:none] absolute top-[0rem] left-[0rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[3.438rem] z-[3]">
              SHARED
            </a>
            <a className="[text-decoration:none] absolute top-[0rem] left-[6.063rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[3.813rem] z-[3]">
              RESELLER
            </a>
            <div className="absolute top-[0rem] left-[12.5rem] leading-[1.5rem] font-medium flex items-center min-w-[1.688rem] z-[3]">
              VPS
            </div>
            <a className="[text-decoration:none] absolute top-[0rem] left-[16.813rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[4.25rem] z-[3]">
              ADD-ONS
            </a>
            <a className="[text-decoration:none] absolute top-[0rem] left-[23.688rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[4.813rem] z-[3]">
              DEDICATED
            </a>
            <a className="[text-decoration:none] absolute top-[0rem] left-[31.125rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[4rem] whitespace-nowrap z-[3]">
              About Us
            </a>
            <a className="[text-decoration:none] absolute top-[0rem] left-[37.75rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[3.625rem] z-[3]">
              Contact
            </a>
          </nav>
        </nav>
        <div className="flex flex-row items-start justify-start gap-[1.625rem]">
          <div className="rounded-3xs bg-white flex flex-row items-start justify-start py-[0.562rem] px-[2rem] whitespace-nowrap z-[3]">
            <div className="h-[2.625rem] w-[6.75rem] relative rounded-3xs bg-white hidden" />
            <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[2.75rem] whitespace-nowrap z-[1]">
              Log In
            </a>
          </div>
          <div className="flex flex-col items-start justify-start pt-[0.062rem] px-[0rem] pb-[0rem]">
            <img
              className="w-[2.438rem] h-[2.438rem] relative rounded-3xs object-cover z-[3]"
              loading="lazy"
              alt=""
              src="/24hourssupport-1@2x.png"
            />
          </div>
        </div>
      </header>
    );
  }
);

export default HeroSection3;

import { FunctionComponent, memo } from "react";
import QuestionItem1 from "./QuestionItem1";
import FrameComponent19 from "./FrameComponent19";
import FaqKVM from "./FaqKVM";
import QuestionItem from "./QuestionItem";

export type FAQKVMWindowType = {
  className?: string;
};

const FAQKVMWindow: FunctionComponent<FAQKVMWindowType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[9.25rem] pl-[1.687rem] pr-[1.25rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins lg:pb-[6rem] lg:box-border mq450:pb-[2.5rem] mq450:box-border mq750:pb-[3.875rem] mq750:box-border ${className}`}
      >
        <div className="w-[66.75rem] flex flex-col items-start justify-start gap-[2.312rem] max-w-full mq750:gap-[1.125rem]">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[2.812rem] pr-[1.25rem] mq450:pl-[1.25rem] mq450:box-border">
            <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
              FREQUENTLY ASKED QUESTIONS
            </h2>
          </div>
          <div className="self-stretch flex flex-col items-end justify-start gap-[0.687rem] max-w-full text-[0.875rem] text-black">
            <QuestionItem1
              isWindowsOSLicenseInclude="1.Is Windows OS license included in the plans?"
              yesItIsIncludedWeProvideW="Yes, it is included. We provide Windows 2008, 2012, and 2016 operating system licenses for free with each Windows KVM VPS. As per your suitability, you can avail any of the above operating systems."
            />
            <QuestionItem1
              isWindowsOSLicenseInclude="2.Do I have liberty to use my own Windows OS license?"
              yesItIsIncludedWeProvideW="Unfortunately, you cannot use your own license as we provide a licensed Windows OS version with each KVM Windows VPS hosting service."
            />
            <div className="self-stretch flex flex-row items-start justify-end py-[0rem] px-[0.062rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
                <FrameComponent19 whichVersionsOfOperatingS="3.Which versions of operating systems (OS) do you provide?" />
                <FaqKVM
                  hostlastingProvidesThreeT="Hostlasting provides three types of plans along with technical configuration. "
                  thesePlansIncludeVPSL2VPS="These plans include VPS-L2, VPS-L4 and VPS-L8. All of these plans include cPanel VPS Hosting License, firewall, "
                  andMalwareProtectionTheCh="and malware protection. The charges of the license are same for all the plans. However, "
                  theCostOfEachPlanVariesWi="the cost of each plan varies with the other configuration parameters."
                />
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0rem] pr-[0.125rem] box-border max-w-full text-slategray-100">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
                <FrameComponent19 whichVersionsOfOperatingS="4.What differentiates the managed VPS from the unmanaged VPS" />
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
                  <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[2.5rem] px-[4.312rem] box-border max-w-full mq1050:pl-[2.125rem] mq1050:pr-[2.125rem] mq1050:box-border">
                    <div className="h-[6.063rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                    <div className="relative tracking-[0.04em] leading-[1.063rem] z-[1]">
                      You can install one of the following versions of operating
                      systems: Windows 2008, Windows 2012, and Windows server
                      2016.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <QuestionItem
              whatIfTheBandwidthIAvaile="5.What if the bandwidth I availed in my plan is not enough?"
              inCaseYouRunOutOfBandwidt="In case you run out of bandwidth, we will reduce the server port speed by nearly 10 times until the start of the next month cycle. "
              weWillNotSuspendYourServi="We will not suspend your services. You can always opt for an option to upgrade the plan."
            />
            <QuestionItem
              whatIfTheBandwidthIAvaile="6.Where are your servers located?"
              inCaseYouRunOutOfBandwidt="Our Tier III datacenter servers are located in Lithuania, Europe. Based on continuity and reliability requirements,"
              weWillNotSuspendYourServi="we have selected the aforementioned location."
            />
            <div className="self-stretch flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
              <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-between py-[0.875rem] pl-[4.687rem] pr-[3.812rem] box-border max-w-full gap-[1.25rem] mq1050:pl-[2.313rem] mq1050:pr-[1.875rem] mq1050:box-border">
                <div className="h-[4rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                <div className="relative tracking-[0.04em] leading-[1.125rem] font-medium z-[1]">
                  <p className="m-0">{`7.Does Hostlasting provide IPv6 support? Do you also offer support for IPv4 addresses? `}</p>
                  <p className="m-0">
                    If yes,then how many addresses can be added?
                  </p>
                </div>
                <div className="flex flex-col items-start justify-start pt-[0.875rem] px-[0rem] pb-[0rem]">
                  <img
                    className="w-[0.75rem] h-[0.375rem] relative z-[1]"
                    loading="lazy"
                    alt=""
                    src="/vector-337.svg"
                  />
                </div>
              </div>
              <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[2.75rem] px-[4.625rem] pb-[2.812rem] box-border max-w-full text-slategray-100 mq1050:pl-[2.313rem] mq1050:pr-[2.313rem] mq1050:box-border">
                <div className="h-[9.813rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                  Yes, we offer IPv6 support along with IPv4 support. With all
                  of our virtual machines, you will get a single IPv6 address.
                  However, if the need arises, we offer an option to add more
                  addresses. With each of our plans, we also offer support for a
                  single IPv4 address. In case, you need more IPv4 addresses as
                  per business requirements, you can avail them as add-ons. You
                  can add maximum of 31 addresses per single instance.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default FAQKVMWindow;

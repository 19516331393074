import { FunctionComponent } from "react";
import SSDHeaderHeroSection from "../components/SSDHeaderHeroSection";
import FrameComponent10 from "../components/FrameComponent10";
import FrameComponent8 from "../components/FrameComponent8";
import FrameComponent71 from "../components/FrameComponent71";
import FrameComponent6 from "../components/FrameComponent6";
import FrameComponent5 from "../components/FrameComponent5";
import FrameComponent41 from "../components/FrameComponent41";
import FrameComponent31 from "../components/FrameComponent31";
import FrameComponent21 from "../components/FrameComponent21";
import FrameComponent13 from "../components/FrameComponent13";
import FrameComponent7 from "../components/FrameComponent7";
import FooterComponent from "../components/FooterComponent";

const CloudVPS: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[4rem] leading-[normal] tracking-[normal] text-left text-[1.5rem] text-darkslategray-100 font-poppins mq450:gap-[1rem] mq750:gap-[2rem]">
      <SSDHeaderHeroSection />
      <div className="w-[0.188rem] h-[9.938rem] relative border-white border-r-[3px] border-solid box-border hidden" />
      <img
        className="w-[43.375rem] relative max-h-full object-cover hidden max-w-full"
        alt=""
        src="/11684121-4391-41@2x.png"
      />
      <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[3.437rem] box-border max-w-full text-[1.25rem]">
        <div className="w-[60.188rem] flex flex-col items-start justify-start max-w-full">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.375rem] pr-[1.25rem]">
            <h3 className="relative tracking-[0.11em] leading-[3.125rem] font-medium z-[1] mq450:text-[1rem] mq450:leading-[2.5rem]">
              INCREDIBLE PERFORMANCE STARTING AT $5/ month
            </h3>
          </div>
          <div className="self-stretch relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
            Handle even your most resource-intensive projects with ease. Our
            cloud servers in Germany and Finland are located in our own
            state-of-the-art data centers. We also provide powerful cloud
            servers in the USA at partner data centers. You get best-in-class
            performance with AMD EPYC™ 2nd Gen, Intel® Xeon® Gold processors and
            speedy NVMe SSDs.
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[2.875rem] box-border gap-[2.45rem] max-w-full mq750:gap-[1.25rem] mq1275:pb-[1.25rem] mq1275:box-border">
        <FrameComponent10 sharedVCPUx86="Shared vCPU(x86)" />
        <FrameComponent8 />
        <FrameComponent71 />
        <FrameComponent6 />
        <FrameComponent5 />
      </div>
      <img
        className="w-[4.813rem] h-[4.063rem] relative object-cover hidden"
        alt=""
        src="/11684121-4391-4@2x.png"
      />
      <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[7.375rem]">
        <h2 className="relative leading-[2.875rem] font-semibold mq450:text-[1.188rem] mq450:leading-[2.313rem]">
          Server Locations
        </h2>
      </div>
      <FrameComponent41 />
      <FrameComponent31 />
      <FrameComponent21 />
      <FrameComponent13 />
      <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.437rem] text-indigo-100">
        <h3 className="relative leading-[2.875rem] mq450:text-[1.188rem] mq450:leading-[2.313rem]">
          One click install OS Dockers
        </h3>
      </div>
      <FrameComponent7 />
      <FooterComponent />
    </div>
  );
};

export default CloudVPS;

import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type FrameComponent121Type = {
  className?: string;
  carbonmanageProtection?: string;
  dDosProtection?: string;

  /** Style props */
  propGap?: CSSProperties["gap"];
};

const FrameComponent121: FunctionComponent<FrameComponent121Type> = memo(
  ({ className = "", propGap, carbonmanageProtection, dDosProtection }) => {
    const frameDiv3Style: CSSProperties = useMemo(() => {
      return {
        gap: propGap,
      };
    }, [propGap]);

    return (
      <div
        className={`flex flex-col items-start justify-start gap-[0.437rem] text-center text-[0.813rem] text-lightgray-100 font-poppins ${className}`}
        style={frameDiv3Style}
      >
        <div className="flex flex-row items-start justify-start py-[0rem] px-[2.125rem]">
          <img
            className="h-[2.25rem] w-[2.25rem] relative overflow-hidden shrink-0 z-[1]"
            alt=""
            src={carbonmanageProtection}
          />
        </div>
        <div className="relative leading-[1.25rem] font-medium inline-block min-w-[6.5rem] z-[1]">
          {dDosProtection}
        </div>
      </div>
    );
  }
);

export default FrameComponent121;

import { FunctionComponent, memo } from "react";
import HeaderPanal from "./HeaderPanal";
import FrameComponent16 from "./FrameComponent16";

export type HeaderHeroKVMVPSType = {
  className?: string;
};

const HeaderHeroKVMVPS: FunctionComponent<HeaderHeroKVMVPSType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[3.187rem] box-border max-w-full text-left text-[3rem] text-white font-poppins mq450:pb-[1.313rem] mq450:box-border mq1150:pb-[2.063rem] mq1150:box-border ${className}`}
      >
        <div className="flex-1 [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1rem] box-border gap-[4.75rem] max-w-full mq450:gap-[1.188rem] mq800:gap-[2.375rem]">
          <HeaderPanal />
          <div className="w-[80.125rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <div className="w-[66.25rem] flex flex-col items-start justify-start gap-[7.75rem] max-w-full mq450:gap-[0.938rem] mq800:gap-[1.938rem] mq1150:gap-[3.875rem]">
              <div className="self-stretch flex flex-row items-start justify-start gap-[4.375rem] max-w-full mq800:gap-[1.063rem] mq1150:flex-wrap mq1150:gap-[2.188rem]">
                <div className="h-[25.875rem] w-[25.875rem] relative bg-[url('/public/b2a-1@2x.png')] bg-cover bg-no-repeat bg-[top] min-w-[25.875rem] max-w-full mq800:min-w-full mq1150:flex-1">
                  <img
                    className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                    alt=""
                    src="/b2a-1@2x.png"
                  />
                  <div className="absolute top-[21.875rem] left-[4.5rem] [filter:blur(22.8px)] rounded-[50%] bg-gray-900 w-[16.875rem] h-[0.688rem] z-[1]" />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start pt-[4.562rem] px-[0rem] pb-[0rem] box-border min-w-[23.375rem] max-w-full mq450:min-w-full">
                  <div className="self-stretch flex flex-row items-start justify-start relative max-w-full">
                    <h1 className="!m-[0] absolute top-[3.125rem] left-[-4.875rem] text-inherit leading-[3.125rem] font-semibold font-inherit z-[2] mq450:text-[1.813rem] mq450:leading-[1.875rem] mq800:text-[2.375rem] mq800:leading-[2.5rem]">
                      Unmanaged KVM VPS Hosting
                    </h1>
                    <div className="flex-1 flex flex-col items-start justify-start gap-[4rem] max-w-full text-[1rem] mq450:gap-[1rem] mq800:gap-[2rem]">
                      <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.375rem] pr-[1.25rem]">
                        <div className="relative tracking-[0.11em] leading-[3.125rem] font-semibold z-[2]">
                          Linux KVM VPS Hosting
                        </div>
                      </div>
                      <div className="self-stretch flex flex-col items-start justify-start gap-[2.062rem] max-w-full text-center text-[0.875rem] text-lightgray-100 mq800:gap-[1rem]">
                        <div className="w-[33.125rem] flex flex-row items-start justify-start py-[0rem] px-[1.062rem] box-border max-w-full">
                          <div className="flex-1 flex flex-col items-end justify-start gap-[1.625rem] max-w-full">
                            <div className="flex flex-row items-start justify-end py-[0rem] px-[1.187rem] box-border max-w-full">
                              <div className="relative leading-[1.25rem] font-medium z-[2]">
                                Deal with high-level security and high-speed
                                connectivity.
                              </div>
                            </div>
                            <div className="self-stretch h-[0.438rem] relative rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none [background:linear-gradient(89.99deg,_#642d88,_#fff)] z-[2]" />
                          </div>
                        </div>
                        <div className="self-stretch flex flex-row items-end justify-between gap-[1.25rem] text-[0.813rem] mq800:flex-wrap mq800:justify-center">
                          <div className="flex flex-col items-start justify-start gap-[0.437rem]">
                            <div className="flex flex-row items-start justify-start py-[0rem] pl-[1.437rem] pr-[1.5rem]">
                              <img
                                className="h-[2.25rem] w-[2.25rem] relative overflow-hidden shrink-0 z-[2]"
                                loading="lazy"
                                alt=""
                                src="/mdiipoutline.svg"
                              />
                            </div>
                            <div className="relative leading-[1.25rem] font-medium inline-block min-w-[5.188rem] z-[2]">
                              Dedicated IP
                            </div>
                          </div>
                          <FrameComponent16
                            carbonmanageProtection="/carbonmanageprotection.svg"
                            fullRootAccess="Full Root Access"
                          />
                          <div className="w-[15.563rem] flex flex-row items-end justify-start gap-[2.937rem]">
                            <FrameComponent16
                              propPadding="unset"
                              propGap="0.5rem"
                              carbonmanageProtection="/riusersharedline.svg"
                              fullRootAccess="SSD Disk Drives"
                            />
                            <FrameComponent16
                              propPadding="unset"
                              propGap="0.687rem"
                              carbonmanageProtection="/eosiconsip.svg"
                              fullRootAccess="4 GBPS Network"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-start justify-start py-[0rem] px-[4.375rem] box-border max-w-full text-center text-[1rem] mq800:pl-[2.188rem] mq800:pr-[2.188rem] mq800:box-border">
                <div className="flex flex-row items-start justify-start gap-[0.543rem] max-w-full mq450:flex-wrap">
                  <div className="flex flex-col items-start justify-start pt-[1rem] px-[0rem] pb-[0rem]">
                    <img
                      className="w-[1.5rem] h-[1.5rem] relative overflow-hidden shrink-0 z-[2]"
                      alt=""
                      src="/icroundhome.svg"
                    />
                  </div>
                  <a className="[text-decoration:none] relative leading-[3.5rem] font-medium text-plum inline-block min-w-[3rem] z-[2]">
                    Home
                  </a>
                  <h3 className="m-0 relative text-[1.25rem] leading-[3.5rem] font-medium font-inherit inline-block min-w-[0.688rem] z-[2] mq450:text-[1rem] mq450:leading-[2.813rem] mq450:w-full mq450:h-[0.688rem]">
                    /
                  </h3>
                  <div className="relative leading-[3.5rem] font-medium z-[2]">
                    Cheap KVM Linux VPS Hosting
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default HeaderHeroKVMVPS;

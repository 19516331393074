import { FunctionComponent, memo } from "react";

export type KvmVpsHostingCheapestHostingType = {
  className?: string;
};

const KvmVpsHostingCheapestHosting: FunctionComponent<KvmVpsHostingCheapestHostingType> =
  memo(({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[2.75rem] box-border max-w-full text-left text-[0.875rem] text-darkslategray-100 font-poppins mq1350:pb-[1.25rem] mq1350:box-border ${className}`}
      >
        <div className="flex-1 bg-ghostwhite-100 flex flex-col items-center justify-start pt-[3.812rem] px-[1.25rem] pb-[9.687rem] box-border gap-[6.812rem] max-w-full mq800:gap-[1.688rem] mq800:pt-[1.25rem] mq800:pb-[2.688rem] mq800:box-border mq1150:gap-[3.375rem] mq1350:pt-[1.625rem] mq1350:pb-[4.125rem] mq1350:box-border">
          <div className="w-[89.438rem] h-[151.125rem] relative bg-ghostwhite-100 hidden max-w-full" />
          <div className="w-[66.563rem] flex flex-row items-start justify-center py-[0rem] pl-[0.187rem] pr-[0rem] box-border max-w-full">
            <div className="w-[56.375rem] flex flex-col items-start justify-start max-w-full">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.312rem]">
                <h2 className="relative leading-[2.875rem] font-semibold z-[1]">
                  Know more about the cheapest Linux KVM VPS hosting services
                </h2>
              </div>
              <div className="self-stretch relative leading-[1.25rem] text-slategray-100 text-center z-[2]">
                Hostlasting deploys a top-notch hardware to run the
                virtualization infrastructure and enables its clients with super
                fast SSD storage. In addition to the KVM-based virtualization,
                we offer root access with which you can control and manage your
                entire set of websites and web applications with stability.
              </div>
            </div>
          </div>
          <div className="w-[66.563rem] flex flex-row flex-wrap items-end justify-center gap-[7.937rem] max-w-full text-[1.25rem] text-indigo-100 mq450:gap-[1rem] mq800:gap-[2rem] mq1150:gap-[3.938rem]">
            <div className="flex-1 flex flex-col items-start justify-start gap-[4.062rem] min-w-[19.063rem] max-w-full mq800:gap-[2rem]">
              <div className="w-[27.438rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[1.25rem] box-border max-w-full">
                <div className="h-[12.688rem] w-[12.688rem] relative bg-[url('/public/metaverse-1@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
                  <img
                    className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                    alt=""
                    src="/metaverse-1@2x.png"
                  />
                  <div className="absolute top-[11.938rem] left-[3.875rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem]">
                <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                  <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                    Customization and upgrades:
                  </h3>
                </div>
                <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                  VPS hosting solutions are supported by Linux operating system
                  and easily customizable as per the business requirements.
                  Moreover, these solutions can be upgraded as the need arises
                  in the future.
                </div>
              </div>
              <div className="w-[27.438rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[1.562rem] box-border max-w-full">
                <div className="w-[14.438rem] flex flex-col items-start justify-start">
                  <img
                    className="w-[14.438rem] h-[14.438rem] relative object-cover z-[1]"
                    loading="lazy"
                    alt=""
                    src="/safety-moneya-3@2x.png"
                  />
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[4.75rem] pr-[5rem]">
                    <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[2]" />
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.125rem] pr-[0rem] box-border max-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[2.187rem] max-w-full mq800:gap-[1.063rem]">
                  <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                    <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Cost-saving benefits:
                    </h3>
                  </div>
                  <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                    Hostlasting’s plans are tailored in such a way that clients
                    can fulfill requirements of their businesses at the minimum
                    cost. With KVM virtualization, you can achieve excellent
                    performance and avail quality service without burdening your
                    pocket a lot.
                  </div>
                </div>
              </div>
              <div className="w-[27.5rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[0.875rem] box-border max-w-full">
                <div className="w-[12.875rem] flex flex-col items-start justify-start">
                  <img
                    className="w-[12.875rem] h-[12.875rem] relative object-cover z-[1]"
                    loading="lazy"
                    alt=""
                    src="/cloud-storage-5a-2@2x.png"
                  />
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.937rem] pr-[4.25rem]">
                    <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[2]" />
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[3.312rem] max-w-full mq800:gap-[1.625rem]">
                <div className="self-stretch flex flex-col items-start justify-start gap-[2.187rem] max-w-full mq800:gap-[1.063rem]">
                  <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] box-border max-w-full">
                    <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Server infrastructure at Europe:
                    </h3>
                  </div>
                  <div className="self-stretch h-[7.875rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0 z-[1]">
                    <span>
                      <p className="m-0">
                        Our KVM VPS servers are located at Lithuania, Europe.
                        The deployment at the given location ensures optimum
                        continuity and reliability of our services. Our servers
                        are located and maintained at Tier III datacenters to
                        avoid failure of components and interruption of
                        services.
                      </p>
                    </span>
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[0.625rem] pl-[1.25rem] pr-[1.312rem]">
                  <div className="flex flex-col items-start justify-start gap-[1.687rem]">
                    <img
                      className="w-[9.938rem] h-[9.938rem] relative object-cover z-[1]"
                      loading="lazy"
                      alt=""
                      src="/fp0124-spacewalk-1@2x.png"
                    />
                    <div className="w-[7.938rem] flex flex-row items-start justify-start py-[0rem] px-[1.625rem] box-border">
                      <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[2.187rem] mq800:gap-[1.063rem]">
                  <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                    <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      On-demand assistance:
                    </h3>
                  </div>
                  <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                    Along with solving your issues, we provide assistance
                    related to scalability, data transfer, migration, and
                    various administrative tasks. We ensure agility to your
                    business and provide every possible help that is possible
                    from our side.
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.331rem] box-border min-w-[19rem] max-w-full">
              <div className="self-stretch flex flex-col items-start justify-start gap-[4.687rem] max-w-full mq800:gap-[2.313rem]">
                <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[3.375rem] gap-[1.125rem]">
                  <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                    KVM-based virtualization:
                  </h3>
                  <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                    Kernel-based Virtual Machine (KVM) virtualization offers one
                    of the most efficient and reliable Linux VPS hosting
                    services. It comes with different plans that are suitable
                    for your business needs and budget-friendly.
                  </div>
                </div>
                <div className="w-[20.75rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                  <div className="w-[7.25rem] flex flex-col items-start justify-start gap-[1.437rem]">
                    <img
                      className="w-[7.25rem] h-[7.25rem] relative object-cover z-[1]"
                      loading="lazy"
                      alt=""
                      src="/cloud-storage-3a-3@2x.png"
                    />
                    <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.25rem] pr-[1.312rem]">
                      <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[3.875rem] gap-[2.187rem] mq800:gap-[1.063rem]">
                  <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                    Multi-layered security:
                  </h3>
                  <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                    Hostlasting deployed multi-layer security measures to ensure
                    your valuable data is safe and secure. With high-grade
                    hardware, firewall protection, and coordinated team effort,
                    we ensure cybercriminals do not pose a threat.
                  </div>
                </div>
                <div className="w-[19.938rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[0.875rem] box-border">
                  <div className="flex flex-col items-end justify-start">
                    <img
                      className="w-[9.188rem] h-[9.188rem] relative object-cover z-[1]"
                      loading="lazy"
                      alt=""
                      src="/iconbusinessv3-9-a-3@2x.png"
                    />
                    <div className="w-[8.438rem] flex flex-row items-start justify-end py-[0rem] px-[1.875rem] box-border">
                      <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[2]" />
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1.375rem] gap-[2.187rem] mq800:gap-[1.063rem]">
                  <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                    Top-notch,reliable hardware:
                  </h3>
                  <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                    Equipped with high-end processors, installed at the prime
                    location in Europe, and high-speed network connection, our
                    hardware is capable of providing the reliable, secure, and
                    efficient KVM VPS hosting environment. We ensure 99.99%
                    uptime.
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1.5rem] box-border gap-[2.562rem] max-w-full mq800:gap-[1.25rem]">
                  <div className="w-[20rem] flex flex-row items-start justify-start py-[0rem] px-[3.562rem] box-border mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                    <div className="h-[12.875rem] flex-1 relative bg-[url('/public/www-domain-name-1@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
                      <img
                        className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                        alt=""
                        src="/www-domain-name-1@2x.png"
                      />
                      <div className="absolute top-[12.125rem] left-[4.438rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[2.187rem] max-w-full mq800:gap-[1.063rem]">
                    <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit inline-block max-w-full z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Around the clock guidance and support:
                    </h3>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                      Our team of experts and highly-skilled assistants are
                      available to address your queries and help you with
                      whatever issues arise during our service tenure. We take
                      pride in offering around the clock service to our clients
                      and solve their queries within stipulated time.
                    </div>
                  </div>
                </div>
                <div className="w-[20.688rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                  <div className="w-[7.688rem] flex flex-col items-start justify-start gap-[1.381rem]">
                    <img
                      className="w-[7.688rem] h-[7.688rem] relative object-cover z-[1]"
                      loading="lazy"
                      alt=""
                      src="/complain-to-customer-servicea-11@2x.png"
                    />
                    <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.375rem] pr-[1.437rem]">
                      <div className="h-[0.538rem] flex-1 relative [filter:blur(11.4px)] rounded-[50%] bg-gray-1300 z-[1]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  });

export default KvmVpsHostingCheapestHosting;

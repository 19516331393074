import { FunctionComponent, memo } from "react";

export type FrameComponent41Type = {
  className?: string;
};

const FrameComponent41: FunctionComponent<FrameComponent41Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[5rem] box-border max-w-full text-left text-[1.25rem] text-indigo-100 font-poppins mq750:pb-[3.25rem] mq750:box-border ${className}`}
      >
        <div className="w-[66.563rem] flex flex-col items-start justify-start max-w-full">
          <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
            <h3 className="relative leading-[2.875rem] font-semibold inline-block min-w-[6.813rem] mq450:text-[1rem] mq450:leading-[2.313rem]">
              GERMANY :
            </h3>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[0.312rem] max-w-full mt-[-0.938rem] text-[0.875rem] text-slategray-100">
            <div className="w-[61.875rem] flex flex-row items-start justify-start gap-[4.687rem] max-w-full mq750:gap-[1.188rem] mq1100:flex-wrap mq1100:gap-[2.313rem]">
              <div className="flex-1 flex flex-col items-start justify-start pt-[2.187rem] px-[0rem] pb-[0rem] box-border max-w-full mq750:min-w-full">
                <div className="self-stretch flex flex-col items-start justify-start gap-[6.062rem] max-w-full mq450:gap-[1.5rem] mq750:gap-[3rem]">
                  <div className="w-[33.75rem] flex flex-col items-start justify-start gap-[1.312rem] max-w-full">
                    <div className="self-stretch relative leading-[1.25rem]">
                      Hostlasting’s cloud products in Nuremberg and Falkenstein
                      operate on data center parks located across different
                      parts of Germany. Our data center parks are
                      video-monitored. In addition, we implement high-security
                      measures across these parks to ensure safety and security.
                    </div>
                    <div className="self-stretch relative leading-[1.25rem]">
                      We implement the General Data Protection Regulations
                      (GDPR) of the European Union (EU) across all the data
                      centers. As our data center parks are located in the
                      central part of Europe, they provide fast connection and
                      accessibility to Western, Central, Southern, and Eastern
                      parts of Europe.
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-end text-[1.25rem] text-indigo-100">
                    <h3 className="relative leading-[2.875rem] font-semibold inline-block min-w-[5.875rem] mq450:text-[1rem] mq450:leading-[2.313rem]">
                      FINLAND :
                    </h3>
                  </div>
                </div>
              </div>
              <div className="h-[14.125rem] w-[18.375rem] flex flex-col items-start justify-start gap-[1.062rem] min-w-[18.375rem] mq1100:flex-1">
                <img
                  className="self-stretch flex-1 relative rounded-31xl max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/flaggermany-1@2x.png"
                />
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[2.5rem]">
                  <div className="h-[0.813rem] flex-1 relative [filter:blur(14.5px)] rounded-[50%] bg-gray-1000" />
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.125rem] pr-[0rem] box-border max-w-full">
              <div className="flex-1 flex flex-row items-end justify-between max-w-full gap-[1.25rem] mq1100:flex-wrap">
                <div className="h-[14.125rem] w-[18.375rem] flex flex-col items-start justify-start gap-[1.062rem] min-w-[18.375rem] mq1100:flex-1">
                  <img
                    className="self-stretch flex-1 relative rounded-31xl max-w-full overflow-hidden max-h-full object-cover"
                    alt=""
                    src="/flagfinland-1@2x.png"
                  />
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[2.5rem]">
                    <div className="h-[0.813rem] flex-1 relative [filter:blur(14.5px)] rounded-[50%] bg-gray-1000" />
                  </div>
                </div>
                <div className="w-[33.75rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.437rem] box-border min-w-[33.75rem] max-w-full mq750:min-w-full mq1100:flex-1">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem]">
                    <div className="self-stretch relative leading-[1.25rem]">
                      Hostlasting’s cloud products in Helsinki operate on
                      servers located in data center parks in Tuusula, Finland.
                      It takes only 30 minutes by car to reach this city from
                      central Helsinki. Our data center parks are
                      video-monitored. In addition, we implement high-security
                      measures across these parks to ensure safety and security.
                    </div>
                    <div className="self-stretch relative leading-[1.25rem]">
                      We implement the General Data Protection Regulation (GDPR)
                      of the European Union (EU) across all the data centers. As
                      our data center parks are located in northern Europe, we
                      can provide fast connection and accessibility to Western,
                      Central, and Eastern parts of Europe.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent41;

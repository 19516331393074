import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type OfferType = {
  className?: string;

  /** Style props */
  propMargin?: CSSProperties["margin"];
};

const Offer: FunctionComponent<OfferType> = memo(
  ({ className = "", propMargin }) => {
    const freeLetsEncryptStyle: CSSProperties = useMemo(() => {
      return {
        margin: propMargin,
      };
    }, [propMargin]);

    return (
      <div
        className={`flex-1 flex flex-col items-start justify-start gap-[0.343rem] text-center text-[1.5rem] text-black font-poppins ${className}`}
      >
        <div className="flex flex-row items-start justify-start py-[0rem] pl-[0.125rem] pr-[0.25rem]">
          <div className="relative leading-[2.938rem] font-semibold z-[1] mq450:text-[1.188rem] mq450:leading-[2.375rem]">
            ₹70/mo($1)
          </div>
        </div>
        <div className="self-stretch flex flex-col items-end justify-start text-[0.688rem]">
          <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0rem] pr-[0.125rem]">
            <div className="h-[2.938rem] flex-1 relative shrink-0">
              <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem] z-[1]" />
              <div className="absolute top-[0rem] left-[2.125rem] leading-[2.938rem] font-medium inline-block min-w-[5rem] z-[2]">
                Host 1 Website
              </div>
            </div>
          </div>
          <div className="self-stretch h-[2.938rem] relative shrink-0 mt-[-0.438rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem] z-[1]" />
            <div className="absolute top-[0rem] left-[1.625rem] leading-[2.938rem] font-medium inline-block min-w-[6rem] z-[3]">
              5 GB SSD Storage
            </div>
          </div>
          <div className="self-stretch h-[2.938rem] relative shrink-0 mt-[-0.438rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem] z-[1]" />
            <div className="absolute top-[0rem] left-[1.813rem] leading-[2.938rem] font-medium inline-block min-w-[5.563rem] z-[2]">
              5 GB bandwidth
            </div>
          </div>
          <div className="self-stretch h-[2.938rem] relative shrink-0 mt-[-0.438rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem] z-[1]" />
            <div className="absolute top-[0rem] left-[2.438rem] leading-[2.938rem] font-medium inline-block min-w-[4.375rem] z-[2]">
              3 FTP, MySQL
            </div>
          </div>
        </div>
        <div className="flex flex-row items-start justify-start py-[0rem] pl-[0.562rem] pr-[0.625rem] text-[0.938rem] text-lightgray-200">
          <div
            className="relative leading-[2.938rem] font-semibold z-[1]"
            style={freeLetsEncryptStyle}
          >
            Free Let's Encrypt
          </div>
        </div>
      </div>
    );
  }
);

export default Offer;

import { FunctionComponent, memo } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  InputAdornment,
} from "@mui/material";

export type FrameComponent1Type = {
  className?: string;
};

const FrameComponent1: FunctionComponent<FrameComponent1Type> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[1.125rem] box-border max-w-full text-left text-[0.875rem] text-white font-poppins ${className}`}
      >
        <div className="flex-1 bg-indigo-100 overflow-hidden flex flex-row items-start justify-start pt-[2.25rem] px-[3.75rem] pb-[15.187rem] box-border gap-[1.25rem] max-w-full mq450:pt-[1.25rem] mq450:pb-[6.438rem] mq450:box-border mq1125:pt-[1.438rem] mq1125:pb-[9.875rem] mq1125:box-border mq1325:flex-wrap mq1325:justify-center mq1325:pl-[1.875rem] mq1325:pr-[1.875rem] mq1325:box-border">
          <div className="w-[8.5rem] flex flex-col items-start justify-start pt-[0.218rem] px-[0rem] pb-[0rem] box-border">
            <img
              className="self-stretch h-[2.125rem] relative max-w-full overflow-hidden shrink-0 object-cover z-[1]"
              loading="lazy"
              alt=""
              src="/hostlasting-logo@2x.png"
            />
          </div>
          <div className="w-[10.688rem] flex flex-col items-start justify-start pt-[0.156rem] px-[0rem] pb-[0rem] box-border">
            <FormControl
              className="w-[5.688rem] z-[1]"
              variant="standard"
              sx={{
                borderRadius: "0px 0px 0px 0px",
                width: "91px",
                height: "37px",
                m: 0,
                p: 0,
                "& .MuiInputBase-root": {
                  m: 0,
                  p: 0,
                  minHeight: "37px",
                  justifyContent: "center",
                  display: "inline-flex",
                },
                "& .MuiInputLabel-root": {
                  m: 0,
                  p: 0,
                  minHeight: "37px",
                  display: "inline-flex",
                },
                "& .MuiMenuItem-root": {
                  m: 0,
                  p: 0,
                  height: "37px",
                  display: "inline-flex",
                },
                "& .MuiSelect-select": {
                  m: 0,
                  p: 0,
                  height: "37px",
                  alignItems: "center",
                  display: "inline-flex",
                },
                "& .MuiInput-input": { m: 0, p: 0 },
                "& .MuiInputBase-input": {
                  textAlign: "left",
                  p: "0 !important",
                },
              }}
            >
              <InputLabel />
              <Select disableUnderline displayEmpty />
              <FormHelperText />
            </FormControl>
          </div>
          <div className="w-[4.813rem] flex flex-col items-start justify-start pt-[0.562rem] pb-[0rem] pl-[0rem] pr-[1.25rem] box-border">
            <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[3.438rem] z-[1]">
              SHARED
            </a>
          </div>
          <div className="w-[52.375rem] flex flex-row items-start justify-start gap-[1.625rem] max-w-full text-[0.938rem] mq800:flex-wrap">
            <div className="flex-1 flex flex-col items-start justify-start gap-[3rem] max-w-full mq450:gap-[1.5rem] mq800:min-w-full">
              <div className="self-stretch flex flex-col items-start justify-start gap-[1.625rem] max-w-full">
                <header className="self-stretch flex flex-row items-start justify-start gap-[4.125rem] max-w-full text-left text-[0.875rem] text-white font-poppins mq450:gap-[1rem] mq800:flex-wrap mq800:gap-[2.063rem]">
                  <div className="flex-1 flex flex-col items-start justify-start pt-[0.562rem] px-[0rem] pb-[0rem] box-border max-w-full mq800:min-w-full">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[1.25rem] max-w-full">
                      <div className="w-[35.313rem] flex flex-row items-start justify-start gap-[2.625rem] max-w-full mq800:flex-wrap mq800:gap-[1.313rem]">
                        <div className="flex-1 flex flex-col items-start justify-start gap-[1.312rem] max-w-full">
                          <div className="flex flex-row items-start justify-start gap-[2.625rem] mq450:gap-[1.313rem] mq800:flex-wrap">
                            <div className="w-[15rem] flex flex-col items-start justify-start gap-[0.437rem]">
                              <div className="self-stretch flex flex-row items-start justify-start gap-[2rem]">
                                <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[3.813rem] z-[1]">
                                  RESELLER
                                </a>
                                <div className="flex-1 flex flex-row items-start justify-between gap-[1.25rem]">
                                  <div className="flex flex-col items-start justify-start gap-[0.25rem]">
                                    <div className="flex flex-row items-start justify-start py-[0rem] px-[0.625rem]">
                                      <div className="relative leading-[1.5rem] font-medium inline-block min-w-[1.688rem] z-[1]">
                                        VPS
                                      </div>
                                    </div>
                                    <img
                                      className="w-[2.938rem] h-[0.188rem] relative z-[1]"
                                      alt=""
                                      src="/line-6.svg"
                                    />
                                  </div>
                                  <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[4.25rem] z-[1]">
                                    ADD-ONS
                                  </a>
                                </div>
                              </div>
                              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.875rem] pr-[2.312rem] text-[0.688rem] text-darkslategray-100">
                                <div className="flex-1 flex flex-row items-start justify-start relative">
                                  <div className="h-[25.938rem] w-[33rem] absolute !m-[0] bottom-[-19.562rem] left-[-25.25rem]">
                                    <div className="absolute top-[1.188rem] left-[0rem] w-[33rem] h-[24.75rem] bg-[url('/public/bg-1@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
                                      <img
                                        className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                                        alt=""
                                        src="/bg-1@2x.png"
                                      />
                                      <div className="absolute top-[5.188rem] left-[4.125rem] rounded-[50%] w-[8.813rem] h-[8.813rem] z-[3]" />
                                      <div className="absolute top-[7.938rem] left-[5.188rem] rounded-[50%] w-[13.5rem] h-[13.5rem] z-[5]" />
                                    </div>
                                    <div className="absolute top-[0rem] left-[6.625rem] w-[24.875rem] h-[22.438rem] bg-[url('/public/prettyyoungfashionsensualwomanposingwhitewall-1@2x.png')] bg-cover bg-no-repeat bg-[top] z-[2]">
                                      <img
                                        className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                                        alt=""
                                        src="/prettyyoungfashionsensualwomanposingwhitewall-1@2x.png"
                                      />
                                      <div className="absolute top-[3.313rem] left-[1.25rem] rounded-[50%] w-[16.375rem] h-[16.375rem] z-[4]" />
                                    </div>
                                  </div>
                                  <div className="flex-1 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] rounded-4xs bg-white flex flex-col items-start justify-start pt-[0.562rem] px-[0.437rem] pb-[0.75rem] gap-[0.312rem] z-[3]">
                                    <div className="w-[10.813rem] h-[14.25rem] relative shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] rounded-4xs bg-white hidden" />
                                    <div className="self-stretch rounded-lg bg-lightsteelblue flex flex-row items-start justify-start pt-[0.25rem] px-[0.812rem] pb-[0.312rem] gap-[0.5rem] z-[1]">
                                      <div className="h-[2.063rem] w-[9.938rem] relative rounded-lg bg-lightsteelblue hidden" />
                                      <div className="flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem]">
                                        <img
                                          className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[2]"
                                          alt=""
                                          src="/heroiconsserverstack20solid.svg"
                                        />
                                      </div>
                                      <div className="relative leading-[1.5rem] font-medium inline-block min-w-[3.563rem] whitespace-nowrap z-[2]">
                                        Cloud VPS
                                      </div>
                                    </div>
                                    <div className="flex flex-row items-start justify-start py-[0rem] px-[0.812rem]">
                                      <div className="flex flex-col items-start justify-start gap-[0.312rem]">
                                        <div className="flex flex-row items-start justify-start gap-[0.5rem]">
                                          <div className="flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem]">
                                            <img
                                              className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[1]"
                                              alt=""
                                              src="/heroiconsserverstack20solid.svg"
                                            />
                                          </div>
                                          <div className="relative leading-[1.5rem] font-medium inline-block min-w-[4.75rem] whitespace-nowrap z-[1]">
                                            Standard VPS
                                          </div>
                                        </div>
                                        <div className="flex flex-row items-start justify-start gap-[0.5rem]">
                                          <div className="flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem]">
                                            <img
                                              className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[1]"
                                              alt=""
                                              src="/heroiconsserverstack20solid.svg"
                                            />
                                          </div>
                                          <div className="relative leading-[1.5rem] font-medium inline-block min-w-[3rem] whitespace-nowrap z-[1]">
                                            KVM VPS
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex flex-row items-start justify-start py-[0rem] px-[0.812rem]">
                                      <div className="flex flex-col items-start justify-start gap-[0.312rem]">
                                        <div className="flex flex-row items-end justify-start gap-[0.5rem]">
                                          <img
                                            className="h-[1.125rem] w-[1.125rem] relative overflow-hidden shrink-0 z-[1]"
                                            alt=""
                                            src="/heroiconsserverstack20solid.svg"
                                          />
                                          <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[4.25rem] whitespace-nowrap z-[1]">
                                            Storage VPS
                                          </a>
                                        </div>
                                        <div className="flex flex-row items-start justify-start gap-[0.5rem]">
                                          <div className="flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem]">
                                            <img
                                              className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[1]"
                                              alt=""
                                              src="/mingcutewindowsfill.svg"
                                            />
                                          </div>
                                          <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[4.688rem] whitespace-nowrap z-[1]">
                                            Windows VPS
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex flex-row items-start justify-start py-[0rem] px-[0.625rem]">
                                      <div className="flex flex-col items-start justify-start gap-[0.312rem]">
                                        <div className="flex flex-row items-start justify-start gap-[0.312rem]">
                                          <img
                                            className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem] z-[1]"
                                            alt=""
                                            src="/simpleiconscpanel.svg"
                                          />
                                          <div className="relative leading-[1.5rem] font-medium inline-block min-w-[4rem] whitespace-nowrap z-[1]">
                                            CPanel VPS
                                          </div>
                                        </div>
                                        <div className="flex flex-row items-start justify-start py-[0rem] pl-[0.187rem] pr-[0rem]">
                                          <div className="flex flex-row items-start justify-start gap-[0.5rem]">
                                            <div className="flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem]">
                                              <img
                                                className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 z-[1]"
                                                alt=""
                                                src="/heroiconsserverstack20solid.svg"
                                              />
                                            </div>
                                            <div className="relative leading-[1.5rem] font-medium inline-block min-w-[5.563rem] whitespace-nowrap z-[1]">
                                              Cyberpanel VPS
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[4.813rem] z-[1]">
                              DEDICATED
                            </a>
                            <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[4rem] whitespace-nowrap z-[1]">
                              About Us
                            </a>
                          </div>
                          <div className="self-stretch flex flex-row items-start justify-end py-[0rem] px-[0.437rem] text-[2.5rem]">
                            <h3 className="m-0 relative text-inherit leading-[1.5rem] font-medium font-inherit whitespace-nowrap z-[1]">
                              ROCK SOLID
                            </h3>
                          </div>
                        </div>
                        <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[3.625rem] z-[1]">
                          Contact
                        </a>
                      </div>
                      <div className="self-stretch flex flex-row items-start justify-end text-[3rem]">
                        <h3 className="m-0 relative text-inherit tracking-[0.11em] leading-[1.5rem] font-semibold font-inherit whitespace-nowrap z-[1]">
                          VPS HOSTING
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-3xs bg-white flex flex-row items-start justify-start py-[0.562rem] px-[2rem] whitespace-nowrap z-[1] text-[0.938rem] text-black">
                    <div className="h-[2.625rem] w-[6.75rem] relative rounded-3xs bg-white hidden" />
                    <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[2.75rem] z-[1]">
                      Log In
                    </a>
                  </div>
                </header>
                <div className="self-stretch flex flex-row items-start justify-end py-[0rem] px-[1.687rem] box-border max-w-full">
                  <div className="w-[32.938rem] relative leading-[1.25rem] font-medium flex items-center shrink-0 max-w-full z-[1]">{`Deal with high-level security and high-speed connectivity with our Standard, KVM, Storage, & Windows VPS plans.`}</div>
                </div>
              </div>
              <div className="w-[40.181rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full text-[1rem]">
                <a href="/" className="[text-decoration:none] text-white rounded-3xs bg-gray-300 flex flex-row items-start justify-start py-[1.062rem] pl-[1.875rem] pr-[1.187rem] whitespace-nowrap z-[1]">
                  <div className="h-[3.063rem] w-[12.806rem] relative rounded-3xs bg-gray-300 hidden" />
                  <div className="relative leading-[0.938rem] font-medium z-[1]">
                    Check out the plan
                  </div>
                </a>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start pt-[0.062rem] px-[0rem] pb-[0rem]">
              <img
                className="w-[2.438rem] h-[2.438rem] relative rounded-3xs object-cover z-[1]"
                alt=""
                src="/24hourssupport-1@2x.png"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default FrameComponent1;

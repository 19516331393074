import { FunctionComponent } from "react";
import HeaderHeroBanner from "../components/HeaderHeroBanner";
import PlanDescriptionSSD from "../components/PlanDescriptionSSD";
import HostlastingAd from "../components/HostlastingAd";
import BenefitListGrid from "../components/BenefitListGrid";
import SharedPlanTabs from "../components/SharedPlanTabs";
import FaqUnlimited from "../components/FaqUnlimited";
import FooterComponent from "../components/FooterComponent";

const SSDLinuxHosting: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[3.312rem] leading-[normal] tracking-[normal] text-left text-[1.5rem] text-darkslategray-100 font-poppins mq750:gap-[1.625rem]">
      <HeaderHeroBanner />
      <div className="w-[0.188rem] h-[9.938rem] relative border-white border-r-[3px] border-solid box-border hidden" />
      <PlanDescriptionSSD />
      <HostlastingAd />
      <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[0.937rem]">
        <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
          Benefits of choosing Linux Shared Web Hosting
        </h2>
      </div>
      <BenefitListGrid />
      <SharedPlanTabs />
      <FaqUnlimited />
      <FooterComponent
        hOSTINGMargin="0"
        dOMAINSSSLMargin="0"
        cOMPANYMargin="0"
        wordPressHostingTextDecoration="none"
        aboutUsTextDecoration="none"
        cheapSSDHostingTextDecoration="unset"
        supportTextDecoration="none"
        vPSHostingTextDecoration="unset"
        blogTextDecoration="none"
        dedicatedHostingTextDecoration="unset"
        offersTextDecoration="unset"
        contactUsTextDecoration="none"
        privacyPolicyTextDecoration="none"
        sitemapTextDecoration="unset"
      />
    </div>
  );
};

export default SSDLinuxHosting;

import { FunctionComponent, memo } from "react";

export type SharedPlanTabsType = {
  className?: string;
};

const SharedPlanTabs: FunctionComponent<SharedPlanTabsType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[4.062rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins mq750:pb-[2.625rem] mq750:box-border ${className}`}
      >
        <div className="flex-1 bg-ghostwhite-100 flex flex-col items-center justify-start py-[4.875rem] pl-[1.625rem] pr-[1.25rem] box-border gap-[2.062rem] max-w-full mq750:gap-[1rem] mq750:pt-[3.188rem] mq750:pb-[3.188rem] mq750:box-border">
          <div className="w-[89.438rem] h-[29.875rem] relative bg-ghostwhite-100 hidden max-w-full" />
          <div className="w-[66.688rem] flex flex-row items-start justify-center py-[0rem] pl-[0rem] pr-[0.312rem] box-border max-w-full">
            <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit z-[1] mq450:text-[1.188rem] mq450:leading-[2.313rem]">
              SHARED HOSTING FEATURES
            </h2>
          </div>
          <div className="w-[66.688rem] flex flex-col items-start justify-start gap-[2.687rem] max-w-full text-[0.75rem] text-royalblue-100 mq750:gap-[1.313rem]">
            <div className="self-stretch flex flex-col items-end justify-start max-w-full">
              <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0rem] pr-[0.125rem] box-border max-w-full">
                <form className="m-0 flex-1 flex flex-row flex-wrap items-start justify-center gap-[0.937rem] max-w-full">
                  <div className="rounded-sm bg-indigo-100 border-whitesmoke-500 border-[1px] border-solid flex flex-row items-start justify-start py-[0.937rem] px-[2.937rem] z-[1]">
                    <div className="h-[3.063rem] w-[10.313rem] relative rounded-sm bg-indigo-100 border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                    <img
                      className="h-[0.938rem] w-[4.313rem] relative z-[2]"
                      alt=""
                      src="/features-icons-content.svg"
                    />
                  </div>
                  <div className="rounded-sm bg-white border-whitesmoke-500 border-[1px] border-solid flex flex-row items-start justify-start pt-[0.875rem] pb-[0.812rem] pl-[4.562rem] pr-[4.5rem] z-[1]">
                    <div className="h-[3.063rem] w-[10.313rem] relative rounded-sm bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                    <img
                      className="h-[1.125rem] w-[1.125rem] relative z-[2]"
                      alt=""
                      src="/vector-1.svg"
                    />
                  </div>
                  <div className="rounded-sm bg-white border-whitesmoke-500 border-[1px] border-solid flex flex-row items-start justify-start pt-[0.875rem] px-[4.437rem] pb-[0.812rem] z-[1]">
                    <div className="h-[3.063rem] w-[10.313rem] relative rounded-sm bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                    <img
                      className="h-[1.125rem] w-[1.313rem] relative z-[2]"
                      alt=""
                      src="/vector-2.svg"
                    />
                  </div>
                  <div className="rounded-sm bg-white border-whitesmoke-500 border-[1px] border-solid flex flex-row items-start justify-start py-[0.875rem] px-[4.437rem] z-[1]">
                    <div className="h-[3.063rem] w-[10.313rem] relative rounded-sm bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                    <img
                      className="h-[1.063rem] w-[1.313rem] relative z-[2]"
                      alt=""
                      src="/vector-3.svg"
                    />
                  </div>
                  <div className="rounded-sm bg-white border-whitesmoke-500 border-[1px] border-solid flex flex-row items-start justify-start pt-[0.875rem] pb-[0.812rem] pl-[4.562rem] pr-[4.5rem] z-[1]">
                    <div className="h-[3.063rem] w-[10.313rem] relative rounded-sm bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                    <img
                      className="h-[1.125rem] w-[1.125rem] relative overflow-hidden shrink-0 z-[2]"
                      alt=""
                      src="/uiwloading.svg"
                    />
                  </div>
                  <div className="flex-1 rounded-sm bg-white border-whitesmoke-500 border-[1px] border-solid box-border flex flex-row items-start justify-start pt-[0.875rem] px-[4.375rem] pb-[0.687rem] min-w-[1.25rem] z-[1]">
                    <div className="h-[3.063rem] w-[10.313rem] relative rounded-sm bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                    <img
                      className="h-[1.25rem] w-[1.25rem] relative z-[2]"
                      alt=""
                      src="/vector-4.svg"
                    />
                  </div>
                </form>
              </div>
              <div className="flex flex-row items-end justify-start gap-[3.562rem] max-w-full mq750:gap-[1.75rem] mq1050:flex-wrap">
                <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.062rem]">
                  <div className="relative leading-[2.875rem] font-semibold inline-block min-w-[7.625rem] z-[1]">
                    Free CPanel Hosting
                  </div>
                </div>
                <div className="flex flex-row items-start justify-start gap-[2.875rem] max-w-full text-center text-slategray-100 mq450:gap-[1.438rem] mq1050:flex-wrap">
                  <div className="flex flex-row items-start justify-start gap-[2.5rem] mq450:gap-[1.25rem]">
                    <div className="relative leading-[1rem] font-semibold z-[1]">
                      <p className="m-0">More than 300 Apps</p>
                      <p className="m-0">in a single click</p>
                    </div>
                    <div className="relative leading-[1rem] font-semibold z-[1]">
                      <p className="m-0">Migrate Websites without</p>
                      <p className="m-0">additional cost</p>
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[0.375rem]">
                    <div className="relative leading-[1rem] font-semibold z-[1]">
                      <p className="m-0">Free of cost E-mail</p>
                      <p className="m-0">Hosting</p>
                    </div>
                  </div>
                  <div className="flex flex-row items-start justify-center gap-[1.5rem] max-w-full mq450:flex-wrap">
                    <div className="relative leading-[1rem] font-semibold z-[1]">
                      <p className="m-0 whitespace-pre-wrap">
                        Fast loading Lite speed
                      </p>
                      <p className="m-0">Technology</p>
                    </div>
                    <div className="relative leading-[1rem] font-semibold z-[1]">
                      <p className="m-0">Compatibility with different</p>
                      <p className="m-0">scripting languages</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.375rem] pr-[0.75rem] box-border max-w-full text-center text-[0.938rem] text-dimgray-100">
              <div className="h-[6.5rem] flex-1 relative leading-[1.313rem] flex items-center max-w-full z-[1]">
                <span>
                  <p className="m-0">Free cPanel Hosting</p>
                  <p className="m-0">
                    All of our plans include free of cost cPanel hosting to
                    ensure ease in management of websites and web application.
                    It will provide you convenience in setting up FTP, emails,
                    and databases.
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default SharedPlanTabs;

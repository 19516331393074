import { FunctionComponent } from "react";
import HeaderHeroBannerSection from "../components/HeaderHeroBannerSection";
import FeatureOfBusinessEmail from "../components/FeatureOfBusinessEmail";
import SSLImageTextGrid from "../components/SSLImageTextGrid";
import FooterComponent from "../components/FooterComponent";

const BusinessEmailHosting: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-end justify-start gap-[5rem] leading-[normal] tracking-[normal] mq450:gap-[1.25rem] mq750:gap-[2.5rem]">
      <section className="self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.812rem] box-border max-w-full">
        <div className="flex-1 [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] overflow-hidden flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1rem] box-border max-w-full">
          <HeaderHeroBannerSection />
          <FeatureOfBusinessEmail />
        </div>
      </section>
      <div className="w-[0.188rem] h-[9.938rem] relative border-white border-r-[3px] border-solid box-border hidden" />
      <section className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[0.812rem] pl-[1.312rem] pr-[1.25rem] box-border max-w-full text-left text-[0.875rem] text-darkslategray-100 font-poppins">
        <div className="w-[56.375rem] flex flex-col items-start justify-start max-w-full">
          <div className="w-[53.938rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <h2 className="relative leading-[2.875rem] font-semibold">
              Features of Business Email
            </h2>
          </div>
          <div className="self-stretch relative leading-[1.25rem] text-slategray-100 text-center z-[1]">
            Our Professional Business Plan is endowed with the following set of
            features making it such exclusive service-oriented criterion in work
            performance
          </div>
        </div>
      </section>
      <section className="self-stretch flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[3.312rem] box-border gap-[3.562rem] max-w-full lg:pb-[1.375rem] lg:box-border mq750:gap-[1.75rem] mq1050:pb-[1.25rem] mq1050:box-border">
        <SSLImageTextGrid />
        <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[4.625rem] pr-[4.562rem] box-border max-w-full mq750:pl-[2.313rem] mq750:pr-[2.25rem] mq750:box-border">
          <img
            className="h-[27.375rem] flex-1 relative max-w-full overflow-hidden object-cover"
            loading="lazy"
            alt=""
            src="/business-email-hosting-1@2x.png"
          />
        </div>
      </section>
      <section className="self-stretch bg-darkslategray-100 flex flex-col items-start justify-start pt-[2.812rem] pb-[2.875rem] pl-[11.437rem] pr-[1.25rem] box-border relative gap-[1.062rem] max-w-full text-left text-[1.25rem] text-white font-poppins mq450:pl-[1.25rem] mq450:box-border mq1050:pl-[5.688rem] mq1050:box-border">
        <div className="w-[89.438rem] h-[13.25rem] relative bg-darkslategray-100 hidden max-w-full z-[0]" />
        <div className="relative leading-[1.688rem] font-semibold inline-block max-w-full z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
          <p className="m-0">Cheap Email Hosting</p>
          <p className="m-0">
            Starting at ₹600/Year ($8.24/year) that means ₹1.63/Day only
          </p>
        </div>
        <div className="rounded-3xs bg-white flex flex-row items-start justify-start py-[0.125rem] px-[2.687rem] whitespace-nowrap z-[1] text-[1.125rem] text-black">
          <div className="h-[3.125rem] w-[11.25rem] relative rounded-3xs bg-white hidden" />
          <a href="#" className="[text-decoration:none] relative leading-[2.875rem] font-medium inline-block text-white min-w-[5.875rem] z-[2]">
            Order now
          </a>
        </div>
        <img
          className="w-[18.75rem] h-[18.75rem] absolute !m-[0] right-[11.438rem] bottom-[-5rem] overflow-hidden shrink-0 z-[1]"
          loading="lazy"
          alt=""
          src="/ouiemail.svg"
        />
      </section>
      <FooterComponent
        hOSTINGMargin="unset"
        dOMAINSSSLMargin="unset"
        cOMPANYMargin="unset"
        wordPressHostingTextDecoration="unset"
        aboutUsTextDecoration="unset"
        cheapSSDHostingTextDecoration="unset"
        supportTextDecoration="unset"
        vPSHostingTextDecoration="unset"
        blogTextDecoration="unset"
        dedicatedHostingTextDecoration="unset"
        offersTextDecoration="unset"
        contactUsTextDecoration="unset"
        privacyPolicyTextDecoration="unset"
        sitemapTextDecoration="unset"
      />
    </div>
  );
};

export default BusinessEmailHosting;

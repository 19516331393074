import { FunctionComponent, memo } from "react";

export type HostlastingAdType = {
  className?: string;
};

const HostlastingAd: FunctionComponent<HostlastingAdType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.812rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins ${className}`}
      >
        <div className="flex-1 bg-ghostwhite-100 flex flex-col items-center justify-start pt-[2rem] px-[1.25rem] pb-[5.875rem] box-border gap-[1.125rem] max-w-full mq750:pt-[1.313rem] mq750:pb-[3.813rem] mq750:box-border">
          <div className="w-[89.438rem] h-[29.875rem] relative bg-ghostwhite-100 hidden max-w-full" />
          <div className="w-[65.563rem] flex flex-row items-start justify-center py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
            <img
              className="h-[5.625rem] w-[5.625rem] relative object-cover z-[1]"
              loading="lazy"
              alt=""
              src="/decentralized-financea-2@2x.png"
            />
          </div>
          <div className="w-[65.563rem] flex flex-col items-start justify-start gap-[0.562rem] max-w-full">
            <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
              <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit z-[1] mq450:text-[1.188rem] mq450:leading-[2.313rem]">
                The Hostlasting Advantages
              </h2>
            </div>
            <div className="self-stretch relative text-[0.75rem] leading-[1.313rem] text-dimgray-100 text-center z-[1]">
              <p className="m-0">
                Hostlasting offers the best shared web hosting services for
                startups, new bloggers, and small businesses. The website owners
                who just began their web journeys and have low traffic on their
                websites can opt for our cheapest web hosting services. We take
                pride in providing the best web hosting for blogging.
              </p>
              <p className="m-0">
                Our Linux web hosting plans flexible and includes a free cPanel
                for helping you in management of your websites, databases, and
                web applications with ease. Moreover, we provide free SSL
                certificates with every plan to ensure security of your
                websites. We also make sure your data is encrypted and secure
                from hackers.
              </p>
              <p className="m-0">
                Hostlasting provides unlimited shared web hosting with one-click
                installer to enable you in installing more than 300 applications
                and scripts such as 
                <a
                  className="text-[inherit]"
                  href="https://www.hostlasting.com/joomla-hosting"
                  target="_blank"
                >
                  <span className="[text-decoration:underline]">Joomla</span>
                </a>
                , WordPress, Drupal, Magento, and others. The Softaculous app
                installer comes with each of our plans. In addition, all the
                plans provide you with unlimited disk space, ability to set up
                innumerable email accounts, and rapid data transfer. Our experts
                are available to resolve your queries around the clock. This
                eliminates the need to possess technical knowledge and enables
                you to focus on your core business.
              </p>
              <p className="m-0">
                On top of it, Hostlasting gives a 30-day money-back guarantee
                with each of our plans. So, choose Hostlasting for shared web
                hosting service.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default HostlastingAd;

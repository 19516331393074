import { FunctionComponent, memo } from "react";

export type SSLImageTextGridType = {
  className?: string;
};

const SSLImageTextGrid: FunctionComponent<SSLImageTextGridType> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch bg-ghostwhite-100 flex flex-row flex-wrap items-end justify-center pt-[3.062rem] px-[11.437rem] pb-[5.625rem] box-border gap-[7.937rem] max-w-full text-left text-[1.25rem] text-indigo-100 font-poppins lg:gap-[3.938rem] lg:pt-[1.313rem] lg:px-[5.688rem] lg:pb-[2.375rem] lg:box-border mq450:gap-[1rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq750:gap-[2rem] mq750:pt-[1.25rem] mq750:px-[2.813rem] mq750:pb-[1.563rem] mq750:box-border ${className}`}
      >
        <div className="h-[169.313rem] w-[89.438rem] relative bg-ghostwhite-100 hidden max-w-full" />
        <div className="flex-1 flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[1.687rem] box-border min-w-[19.063rem] max-w-full">
          <div className="self-stretch flex flex-col items-start justify-start gap-[4.5rem] max-w-full mq750:gap-[2.25rem]">
            <div className="w-[22.625rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
              <div className="w-[12.375rem] flex flex-col items-start justify-start">
                <img
                  className="w-[12.375rem] h-[12.375rem] relative object-cover z-[1]"
                  loading="lazy"
                  alt=""
                  src="/fl-69-1@2x.png"
                />
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.812rem] pr-[3.875rem]">
                  <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[2]" />
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[3.562rem] gap-[1.125rem]">
              <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Flexibility in storage facilities
                </h3>
              </div>
              <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                We provide additional storage facilities of 5GB which would
                provide surplus space for business emails and other professional
                communication systems.
              </div>
            </div>
            <div className="w-[22.625rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[4.125rem] box-border max-w-full">
              <div className="w-[9rem] flex flex-col items-start justify-start gap-[1.812rem]">
                <img
                  className="w-[9rem] h-[9rem] relative object-cover z-[1]"
                  loading="lazy"
                  alt=""
                  src="/safety-2@2x.png"
                />
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[2.125rem] pr-[2.187rem]">
                  <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[5.062rem] pl-[0.125rem] pr-[0rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                  <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                    Fully localized
                  </h3>
                </div>
                <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                  The availability of webmail in multiple regional languages
                  ensures that it is accessible to all kinds of users.
                </div>
              </div>
            </div>
            <div className="w-[22.625rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[2rem] box-border max-w-full">
              <div className="w-[7.75rem] flex flex-col items-start justify-start gap-[1.812rem]">
                <img
                  className="w-[7.75rem] h-[7.75rem] relative object-cover z-[1]"
                  loading="lazy"
                  alt=""
                  src="/data-management-9-1@2x.png"
                />
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.5rem] pr-[1.562rem]">
                  <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[2.437rem] gap-[1.125rem]">
              <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  In-house 24/7 support team
                </h3>
              </div>
              <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                We provide a 24/7 support team that would look after the
                advisory functions and thus the core areas of the business can
                be easily looked after.
              </div>
            </div>
            <div className="w-[22.563rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[3.375rem] box-border max-w-full">
              <div className="w-[7.813rem] flex flex-col items-start justify-start gap-[1.812rem]">
                <img
                  className="w-[7.813rem] h-[7.813rem] relative object-cover z-[1]"
                  loading="lazy"
                  alt=""
                  src="/office-works-6-1@2x.png"
                />
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[1.562rem]">
                  <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[2.125rem] gap-[1.125rem]">
              <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Secure data centers
                </h3>
              </div>
              <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                Our data integration mechanisms ensure that the data is
                protected and its privacy is intact.
              </div>
            </div>
            <div className="w-[24.938rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[5.125rem] box-border max-w-full">
              <div className="h-[14.563rem] w-[14.563rem] relative bg-[url('/public/11cc0e2406a74382b4dd3111fb2cfb8aa-2@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
                <img
                  className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                  alt=""
                  src="/11cc0e2406a74382b4dd3111fb2cfb8aa-2@2x.png"
                />
                <div className="absolute top-[13.375rem] left-[3.75rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.125rem] pr-[0rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] box-border max-w-full">
                  <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                    Migration of Older to Newer System
                  </h3>
                </div>
                <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                  The migration of the older system of functioning to the newer
                  system is a relatively easier process when the system
                  functioning mechanism of our company is taken into
                  consideration.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start gap-[3.375rem] min-w-[19rem] max-w-full mq750:gap-[1.688rem]">
          <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1.875rem] gap-[1.125rem]">
            <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
              Robust and Detailed webmail
            </h3>
            <div className="self-stretch h-[4.938rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0 z-[1]">
              We have a robust and detailed webmail structure that can be
              accessed from anywhere without the considerable impact of time
              duration.
            </div>
          </div>
          <div className="w-[20.25rem] h-[14.5rem] flex flex-row items-start justify-start pt-[0rem] px-[2.25rem] pb-[3rem] box-border max-w-full">
            <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[0.625rem]">
              <img
                className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover z-[1]"
                loading="lazy"
                alt=""
                src="/onlinedatastoragebusinessnetworkconceptuploadingdocumentfiledatainformationcloudcomputingtechnologywithcomputerserverracks3drendering-1-1@2x.png"
              />
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.312rem]">
                <div className="h-[0.375rem] w-[4.688rem] relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[5.75rem] gap-[1.125rem]">
            <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
              Spam and virus protection
            </h3>
            <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
              We provide our users with a fully localized and virus-free
              approach and thus our communication system is free from viruses
              and other forms of malware.
            </div>
          </div>
          <div className="w-[19.188rem] h-[13.688rem] flex flex-row items-start justify-start pt-[0rem] px-[2.25rem] pb-[1rem] box-border">
            <div className="self-stretch flex-1 flex flex-col items-end justify-start gap-[1.312rem]">
              <img
                className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover z-[1]"
                loading="lazy"
                alt=""
                src="/3dillustrationcartoonpeopleusingsmartphonesurfinginternetcommunicatesocialmediaonlineapplication-2@2x.png"
              />
              <div className="w-[13.688rem] flex flex-row items-start justify-end py-[0rem] px-[4.5rem] box-border">
                <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[4.437rem] gap-[1.125rem]">
            <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
              Mobile Access
            </h3>
            <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
              We offer Email hosting with IMAP feature; you may receive free
              hand to access your email account through your default app no
              matter what OS of your mobile is iOS, Windows, or Android.
              Experience the excellent secure Email hosting with Hostlasting.
            </div>
          </div>
          <div className="w-[19.188rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[1.562rem] box-border">
            <div className="flex flex-col items-end justify-start gap-[2rem]">
              <img
                className="w-[7.063rem] h-[7.063rem] relative object-cover z-[1]"
                loading="lazy"
                alt=""
                src="/group-312@2x.png"
              />
              <div className="w-[6.063rem] flex flex-row items-start justify-end py-[0rem] px-[0.687rem] box-border">
                <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[5.437rem] gap-[1.125rem]">
            <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
              Virtualized mailboxes
            </h3>
            <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
              The mailbox is virtualized and digitized so that the majority of
              functions about communication can be displayed on the screen.
            </div>
          </div>
          <div className="w-[19.188rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border">
            <div className="flex flex-col items-end justify-start gap-[0.625rem]">
              <img
                className="w-[9.063rem] h-[9.063rem] relative object-cover z-[1]"
                loading="lazy"
                alt=""
                src="/b4d43ddc18614e439b984d15f0a5628da-2@2x.png"
              />
              <div className="w-[8.063rem] flex flex-row items-start justify-end py-[0rem] px-[1.687rem] box-border">
                <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[4.062rem] gap-[1.125rem]">
            <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
              Robust technology
            </h3>
            <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
              <p className="m-0">
                Reliable and efficient means of technology ensures that the
                flexibility in the technical measures is maintained therefore
                promoting organizational efficiency.
              </p>
              <p className="m-0">
                Focus on your kernel business and leave the difficult aspects of
                the business which consume a considerable period to us who would
                integrate your daily communication information and bring about
                efficiency in the mechanism of bringing about uniformity in work
                operations. No hardware or software costs are to be implied in
                the process.
              </p>
            </div>
          </div>
          <div className="w-[21rem] flex flex-row items-start justify-start py-[0rem] px-[4.812rem] box-border max-w-full mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
            <div className="h-[11.375rem] flex-1 relative bg-[url('/public/data-informationv2-5-1@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
              <img
                className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                alt=""
                src="/data-informationv2-5-1@2x.png"
              />
              <div className="absolute top-[10.375rem] left-[2.938rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default SSLImageTextGrid;

import { FunctionComponent, memo } from "react";

export type TryFreePanelDedicatedServerType = {
  className?: string;
};

const TryFreePanelDedicatedServer: FunctionComponent<TryFreePanelDedicatedServerType> =
  memo(({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[2.75rem] box-border max-w-full text-left text-[1.25rem] text-white font-poppins ${className}`}
      >
        <div className="flex-1 bg-darkslategray-100 flex flex-col items-start justify-start pt-[3.562rem] pb-[3.625rem] pl-[31.812rem] pr-[1.25rem] box-border gap-[1.25rem] max-w-full mq800:pl-[7.938rem] mq800:box-border mq1150:pl-[15.875rem] mq1150:box-border">
          <div className="w-[89.438rem] h-[13.25rem] relative bg-darkslategray-100 hidden max-w-full" />
          <div className="relative leading-[1.688rem] font-semibold inline-block max-w-full z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
            MOST POWERFUL WEB HOSTING SOLUTION
          </div>
          <div className="flex flex-row items-start justify-start py-[0rem] px-[4.312rem] box-border max-w-full text-[1.125rem] text-black mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
            <div className="rounded-3xs bg-white flex flex-row items-start justify-start py-[0.125rem] pl-[2.812rem] pr-[2.75rem] whitespace-nowrap z-[1] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
              <div className="h-[3.125rem] w-[15.938rem] relative rounded-3xs bg-white hidden" />
              <div className="relative leading-[2.875rem] font-medium z-[2]">
                TRY NOW FOR FREE
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  });

export default TryFreePanelDedicatedServer;

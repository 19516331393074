import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type BenefitsListType = {
  className?: string;
  payForOnlyWhatYouNeed?: string;
  theBestWayToAchieveCostEf?: string;

  /** Style props */
  propPadding?: CSSProperties["padding"];
  propGap?: CSSProperties["gap"];
};

const BenefitsList: FunctionComponent<BenefitsListType> = memo(
  ({
    className = "",
    propPadding,
    propGap,
    payForOnlyWhatYouNeed,
    theBestWayToAchieveCostEf,
  }) => {
    const benefitsListStyle: CSSProperties = useMemo(() => {
      return {
        padding: propPadding,
        gap: propGap,
      };
    }, [propPadding, propGap]);

    return (
      <div
        className={`w-[24.688rem] shadow-[0px_4px_8.4px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-indigo-100 flex flex-col items-start justify-start pt-[1.312rem] pb-[2.375rem] pl-[1.625rem] pr-[1.562rem] box-border gap-[1.062rem] max-w-full text-center text-[0.75rem] text-white font-poppins ${className}`}
        style={benefitsListStyle}
      >
        <div className="w-[24.688rem] h-[11.25rem] relative shadow-[0px_4px_8.4px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-indigo-100 hidden max-w-full" />
        <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[0rem] pr-[0.062rem]">
          <div className="relative leading-[1.25rem] font-semibold z-[1]">
            {payForOnlyWhatYouNeed}
          </div>
        </div>
        <div className="self-stretch relative text-[0.688rem] leading-[0.875rem] z-[1]">
          <p className="m-0">{theBestWayToAchieveCostEf}</p>
        </div>
      </div>
    );
  }
);

export default BenefitsList;

import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type SharedVCPUContentType = {
  className?: string;
  sharedVCPUx86?: string;

  /** Style props */
  propAlignSelf?: CSSProperties["alignSelf"];
  propWidth?: CSSProperties["width"];
};

const SharedVCPUContent: FunctionComponent<SharedVCPUContentType> = memo(
  ({ className = "", propAlignSelf, propWidth, sharedVCPUx86 }) => {
    const sharedVCPUContentStyle: CSSProperties = useMemo(() => {
      return {
        alignSelf: propAlignSelf,
        width: propWidth,
      };
    }, [propAlignSelf, propWidth]);

    return (
      <div
        className={`self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.437rem] box-border max-w-full text-left text-[1.5rem] text-white font-poppins ${className}`}
        style={sharedVCPUContentStyle}
      >
        <div className="w-[31rem] rounded-tl-none rounded-tr-41xl rounded-br-41xl rounded-bl-none [background:linear-gradient(270deg,_#4b0082,_#fff)] flex flex-row items-start justify-center pt-[0.75rem] pb-[0.687rem] pl-[1.312rem] pr-[1.25rem] box-border whitespace-nowrap max-w-full z-[1]">
          <div className="h-[4.563rem] w-[31rem] relative rounded-tl-none rounded-tr-41xl rounded-br-41xl rounded-bl-none [background:linear-gradient(270deg,_#4b0082,_#fff)] hidden max-w-full" />
          <h2 className="m-0 relative text-inherit leading-[3.125rem] font-semibold font-inherit z-[2]">
            {sharedVCPUx86}
          </h2>
        </div>
      </div>
    );
  }
);

export default SharedVCPUContent;

import { FunctionComponent, memo } from "react";
import FrameComponent12 from "./FrameComponent12";
import ToggleFAQ from "./ToggleFAQ";
import FrameComponent4 from "./FrameComponent4";

export type FQAJoomlaType = {
  className?: string;
};

const FQAJoomla: FunctionComponent<FQAJoomlaType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[3rem] pl-[1.375rem] pr-[1.25rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins lg:pb-[1.938rem] lg:box-border mq750:pb-[1.25rem] mq750:box-border ${className}`}
      >
        <div className="w-[66.688rem] flex flex-col items-start justify-start gap-[3.125rem] max-w-full mq750:gap-[1.563rem]">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
            <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
              FREQUENTLY ASKED QUESTIONS
            </h2>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[0.687rem] max-w-full text-[0.875rem] text-slategray-100">
            <FrameComponent12
              whatIsJoomla="1.What is Joomla?"
              vector335="/vector-335.svg"
              joomlaIsATotallyFreeSimpl="Joomla is a totally free, simple to use, and customizable tool to construct the websites readily. Joomla permits you to create a variety of websites with newest features."
            />
            <FrameComponent12
              whatIsJoomla="2.Which are the Benefits of Joomla Hosting?"
              vector335="/vector-335.svg"
              joomlaIsATotallyFreeSimpl="Joomla is handy and incredibly popular CMS. Its advanced features enable a comprehensive customization, which made it prominent among the other accessible CMS. It can also be used to create any sort of website."
            />
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
                <ToggleFAQ
                  title="3. Can you provide service 24x7?"
                  frameDivAlignSelf="stretch"
                  frameDivPadding="0.5rem 3.812rem 0.562rem 4.687rem"
                  frameDivFlex="unset"
                  frameDivPadding1="1.25rem 0rem 0rem"
                />
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
                  <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[1.312rem] px-[4.625rem] pb-[1.375rem] box-border max-w-full mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                    <div className="h-[3.75rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                    <div className="relative tracking-[0.04em] leading-[1.063rem] z-[1]">
                      Yes, we offer 24x7 support.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FrameComponent12
              whatIsJoomla="4. Does your hosting include control panel?"
              vector335="/vector-337.svg"
              joomlaIsATotallyFreeSimpl="Yes, we provide free cPanel on all cheap Joomla hosting plans, so you are able to control and manage all of the tasks on your own site."
            />
            <FrameComponent4
              doIHaveTheLibertyToUpgrad="5. Do I have the liberty to upgrade my account whenever I need?"
              yesYouHaveTheLibertyToUpg="Yes, you have the liberty to upgrade at any point of time."
            />
            <FrameComponent4
              propGap="0.437rem"
              doIHaveTheLibertyToUpgrad="6. Can you provide money-back warranty with this plan?"
              yesYouHaveTheLibertyToUpg="Yes, we provide 30 days money-back warranty with this strategy. It you are not satisfied with the services provided by us, then you can claim for refund within 30 days of purchase. We will provide 100% refund and it will be transferred to your own account."
            />
            <div className="self-stretch flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
                <ToggleFAQ
                  title="7. What about the security level of the hosting platform for Joomla?"
                  frameDivAlignSelf="unset"
                  frameDivPadding="0.5rem 3.875rem 0.562rem 4.687rem"
                  frameDivFlex="1"
                  frameDivPadding1="0rem 0rem 1.25rem"
                />
              </div>
              <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-end pt-[1.687rem] px-[3.625rem] pb-[1.75rem] box-border max-w-full mq1050:pl-[1.813rem] mq1050:pr-[1.813rem] mq1050:box-border">
                <div className="h-[5.563rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                <div className="relative tracking-[0.04em] leading-[1.063rem] z-[1]">
                  <p className="m-0">{`The security is of the utmost importance for us and for our cheap Joomla hosting platform, `}</p>
                  <p className="m-0">{`we have employed powerful security methods for ensuring protection. This package is protected using Cloud Linux Security. `}</p>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
                <ToggleFAQ
                  title="7. Do you provide us a root access?"
                  frameDivAlignSelf="unset"
                  frameDivPadding="0.5rem 3.875rem 0.562rem 4.687rem"
                  frameDivFlex="1"
                  frameDivPadding1="0rem 0rem 1.25rem"
                />
              </div>
              <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[1.312rem] px-[4.812rem] pb-[1.375rem] box-border max-w-full mq750:pl-[2.375rem] mq750:pr-[2.375rem] mq750:box-border">
                <div className="h-[3.75rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                <div className="relative tracking-[0.04em] leading-[1.063rem] z-[1]">
                  No, the root access will not be available with this plan.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default FQAJoomla;

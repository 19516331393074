import { FunctionComponent, memo } from "react";
import GroupComponent10 from "./GroupComponent10";

export type AdvantageDedicatedServerType = {
  className?: string;
};

const AdvantageDedicatedServer: FunctionComponent<AdvantageDedicatedServerType> =
  memo(({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[3.625rem] box-border max-w-full text-left text-[0.875rem] text-darkslategray-100 font-poppins mq450:pb-[1.563rem] mq450:box-border mq800:pb-[2.375rem] mq800:box-border ${className}`}
      >
        <div className="flex-1 bg-ghostwhite-100 flex flex-col items-center justify-start pt-[3.812rem] px-[1.25rem] pb-[4.062rem] box-border gap-[2rem] max-w-full mq800:gap-[1rem] mq800:pt-[2.5rem] mq800:pb-[2.625rem] mq800:box-border">
          <div className="w-[89.438rem] h-[44.5rem] relative bg-ghostwhite-100 hidden max-w-full" />
          <div className="w-[66.688rem] flex flex-row items-start justify-center max-w-full">
            <h2 className="relative leading-[2.875rem] font-semibold z-[1]">
              Major advantages of renting a Hostlasting Dedicated server in
              India
            </h2>
          </div>
          <div className="w-[66.688rem] flex flex-col items-start justify-start gap-[1.375rem] max-w-full text-[3rem] text-slategray-200">
            <GroupComponent10
              redundancy="1."
              commonlyConstructedWithRe="Commonly constructed with redundancy and backed with data centre system. Uptime service level guarantees are preserved by highly skilled and "
              technicalEngineersExpertT="technical engineers' expert teams."
            />
            <GroupComponent10
              propGap="0.75rem"
              redundancy="2."
              commonlyConstructedWithRe="Hostlasting offers dedicated server from India depending on the calibre of network infrastructure. It gives you an ultimate peace as numerous security layers "
              technicalEngineersExpertT="are used in a Firewall and Mod Security tools are often installed security patches to prevent several malicious attacks and risks."
            />
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
              <div className="flex-1 rounded-mini border-indigo-100 border-[1px] border-solid box-border flex flex-row items-start justify-start py-[1.562rem] px-[1.312rem] gap-[0.75rem] max-w-full z-[1] mq800:flex-wrap">
                <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.625rem] mq450:text-[1.813rem] mq450:leading-[0.75rem] mq800:text-[2.375rem] mq800:leading-[1rem]">
                  3.
                </div>
                <div className="relative text-[0.75rem] leading-[1.25rem] font-medium text-slategray-100 inline-block max-w-full">
                  Your very own Indian server setup with the excellent Linux
                  high-performance version.
                </div>
                <div className="h-[4.625rem] w-[66.625rem] relative rounded-mini border-indigo-100 border-[1px] border-solid box-border hidden max-w-full" />
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[3.312rem] box-border max-w-full text-center text-[0.938rem] text-slategray-100 mq1150:pl-[1.625rem] mq1150:pr-[1.625rem] mq1150:box-border">
              <div className="flex-1 relative leading-[1.25rem] inline-block max-w-full z-[1]">
                <p className="m-0">
                  Out of our cloud dedicated servers in India, you will receive
                  excellent features such as these. So, hosting the websites on
                  Tier III dedicated server hosting at Indian data centres is
                  possible as we offer 99.99% Uptime guarantee and frees you
                  from worrying.
                </p>
                <p className="m-0">
                  Hostlasting designed the most adequate Linux dedicated server
                  hosting plans to store additional server cost for your
                  company. This implies that you may fully manage and receive
                  cheap plans with reliable support. The dedicated server is
                  packed with plenty of premium quality features and tools to
                  easily manage your website data traffic, high resolution
                  images, and many domain names along with handling tasks such
                  as Start, Stop, and Rebuild full control of the server panel.
                </p>
                <p className="m-0">
                  Our dedicated server hosting in India offers you a full
                  control of your server infrastructure and easily handle and
                  sets up applications. Now, it is your time to purchase
                  dedicated server in India with Hostlasting along with
                  constructed enterprise hardware with the newest technology in
                  a low cost. Our servers provide your projects with the high
                  demanding source, RAM, and most recent powerful processors.
                </p>
                <p className="m-0">
                  So opt for Hostlasting‘s Dedicated server Hosting India plans.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  });

export default AdvantageDedicatedServer;

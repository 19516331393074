import { FunctionComponent, memo } from "react";
import FrameComponent112 from "./FrameComponent112";
import FrameComponent27 from "./FrameComponent27";

export type FAQCyberPanelType = {
  className?: string;
};

const FAQCyberPanel: FunctionComponent<FAQCyberPanelType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[1.937rem] pl-[1.5rem] pr-[1.25rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins ${className}`}
      >
        <div className="w-[66.813rem] flex flex-col items-start justify-start gap-[2.312rem] max-w-full mq750:gap-[1.125rem]">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.5rem]">
            <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
              FAQs Regarding CyberPanel VPS Hosting:
            </h2>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[0.125rem] max-w-full text-[0.875rem] text-slategray-100">
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.25rem] pr-[0rem] box-border max-w-full text-black">
              <FrameComponent112
                whatExactlyIsCyberPanel="1.What exactly is CyberPanel?"
                vector335="/vector-335.svg"
              />
            </div>
            <FrameComponent27
              cyberPanelIsAnAdvancedEas="CyberPanel is an advanced, easy-to-operate, and simplified web hosting control panel, which is"
              designedAndDevelopedByOpe="designed and developed by OpenLiteSpeed and LiteSpeed Enterprise. This can be used as an"
              alternativeControlPanelTo="alternative control panel to the widely-regarded control panels such as cPanel or Plesk."
              cyberPanelIsEquippedWithD="CyberPanel is equipped with different essential features, including FTP server, SSL certificate,"
              backupsOneClickInstallerP="backups, one-click installer, PHP management, and many others. Moreover, you will be able to"
              transferYourWebServerFrom="transfer your web server from OpenLiteSpeed to LiteSpeed with only a click."
            />
            <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.562rem] box-border gap-[0.125rem] max-w-full">
              <FrameComponent112
                propFlex="unset"
                propAlignSelf="stretch"
                whatExactlyIsCyberPanel="2.What is CyberPanel VPS Hosting?"
                vector335="/vector-335.svg"
              />
              <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[3.125rem] px-[4.312rem] box-border min-h-[12.188rem] max-w-full mq1050:pl-[2.125rem] mq1050:pr-[2.125rem] mq1050:box-border">
                <div className="h-[12.188rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                  <p className="m-0">
                    VPS is a short-form of Virtual Private Server. CyberPanel
                    VPS hosting provides an unmatched
                  </p>
                  <p className="m-0">
                    power of VPS hosting using CyberPanel, which is a web
                    hosting control panel helping you
                  </p>
                  <p className="m-0">
                    manage websites, entire VPS server, and others under a
                    single platform. CyberPanel is
                  </p>
                  <p className="m-0">
                    equipped with notable features provided by OpenLiteSpeed for
                    performance enhancements.
                  </p>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.562rem] box-border gap-[0.125rem] max-w-full">
              <FrameComponent112
                propFlex="unset"
                propAlignSelf="stretch"
                whatExactlyIsCyberPanel="3.What is the Procedure to Set Up SSL Certificate in CyberPanel?"
                vector335="/vector-337.svg"
              />
              <FrameComponent27
                cyberPanelIsAnAdvancedEas={`You can avail unlimited and free-of-cost Let&#39;s Encrypt SSL Certificate using CyberPanel.`}
                designedAndDevelopedByOpe={`Following are the steps to set up an SSL certificate in CyberPanel:
 
1. Navigate CyberPanel and click on it`}
                alternativeControlPanelTo={`2. From the menu list coming after clicking, choose the &quot;Websites&quot; option`}
                cyberPanelIsEquippedWithD="3. Select the List Websites option and the primary information related to your websites will"
                backupsOneClickInstallerP="be displayed"
                transferYourWebServerFrom={`4. Click on the &quot;Issue SSL&quot; link to install SSL certificate`}
              />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.562rem] box-border gap-[0.125rem] max-w-full">
              <FrameComponent112
                propFlex="unset"
                propAlignSelf="stretch"
                whatExactlyIsCyberPanel="4.How much time is required for setting up CyberPanel VPS hosting account?"
                vector335="/vector-337.svg"
              />
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
                <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-end py-[2.687rem] px-[0.062rem] box-border min-h-[10.313rem] max-w-full">
                  <div className="h-[10.313rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="w-[61.875rem] relative tracking-[0.04em] leading-[1.063rem] flex items-center shrink-0 max-w-full z-[1]">
                    <span className="w-full">
                      <p className="m-0">
                        With Hostlasting, we set up your CyberPanel VPS hosting
                        account quickly. As soon as you
                      </p>
                      <p className="m-0">
                        select the suitable plan and complete the payment, our
                        experts will begin the process to set up
                      </p>
                      <p className="m-0">
                        the account right away. Then we will provide the login
                        details of CyberPanel, and you will have a
                      </p>
                      <p className="m-0">
                        capability to go live using the VPS server.
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.562rem] box-border gap-[0.125rem] max-w-full">
              <FrameComponent112
                propFlex="unset"
                propAlignSelf="stretch"
                whatExactlyIsCyberPanel="5.Is technical knowledge required for using CyberPanel?"
                vector335="/vector-337.svg"
              />
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
                <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-end py-[3.062rem] px-[0.062rem] box-border min-h-[10.313rem] max-w-full">
                  <div className="h-[10.313rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="w-[61.875rem] relative tracking-[0.04em] leading-[1.063rem] flex items-center shrink-0 max-w-full z-[1]">
                    <span className="w-full">
                      <p className="m-0">
                        If you possess a good technical knowledge, it will be
                        favorable for you. If not, you do not need to
                      </p>
                      <p className="m-0">
                        worry. As CyberPanel provides an easy-to-use and
                        navigate graphical interface, there is no
                      </p>
                      <p className="m-0">need for sound technical knowledge.</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0.125rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
                <FrameComponent112
                  propFlex="unset"
                  propAlignSelf="stretch"
                  whatExactlyIsCyberPanel="6.How CyberPanel does stand out from other control panels available?"
                  vector335="/vector-337.svg"
                />
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
                  <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[2.062rem] px-[4.312rem] box-border max-w-full mq1050:pl-[2.125rem] mq1050:pr-[2.125rem] mq1050:box-border">
                    <div className="h-[7.313rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                    <div className="relative tracking-[0.04em] leading-[1.063rem] z-[1]">
                      <p className="m-0">
                        CyberPanel stands out from other control panels due to
                        its exceptional features. For instance,
                      </p>
                      <p className="m-0">
                        the integration of OpenLiteSpeed or LiteSpeed into its
                        core offering. With this, you will be able to
                      </p>
                      <p className="m-0">
                        avail benefits such as rapid website loading, website
                        optimization, and high-security measures.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default FAQCyberPanel;

import { FunctionComponent, memo } from "react";

export type HostingFeatures1Type = {
  className?: string;
};

const HostingFeatures1: FunctionComponent<HostingFeatures1Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`flex-1 flex flex-col items-start justify-start gap-[4.125rem] min-w-[19.063rem] max-w-full text-left text-[1.25rem] text-indigo-100 font-poppins mq750:gap-[2.063rem] ${className}`}
      >
        <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1.625rem] gap-[1.125rem]">
          <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit inline-block min-w-[7.063rem] z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
            Affordable:
          </h3>
          <div className="self-stretch h-[6.375rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0 z-[1]">
            High-quality resources, features, and solutions are available at
            affordable prices. Our flexible plans will not burden your pockets,
            but definitely ensure seamless operation of your website.
          </div>
        </div>
        <div className="w-[17.938rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[2.206rem] box-border">
          <div className="w-[7.688rem] flex flex-col items-start justify-start gap-[1.381rem]">
            <img
              className="w-[7.688rem] h-[7.688rem] relative object-cover z-[1]"
              loading="lazy"
              alt=""
              src="/complain-to-customer-servicea-11@2x.png"
            />
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.375rem] pr-[1.437rem]">
              <div className="h-[0.538rem] flex-1 relative [filter:blur(11.4px)] rounded-[50%] bg-gray-1300 z-[1]" />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[2.562rem] box-border gap-[1.125rem] max-w-full">
          <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit inline-block max-w-full z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
            Simplified and uncomplicated interface:
          </h3>
          <div className="self-stretch h-[5.75rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0 z-[1]">
            Our simplified and easy to operate control panel interface will help
            you in operating your entire website without any hassles. With
            smooth navigation, you can manage the VPS efficiently.
          </div>
        </div>
        <div className="w-[17.938rem] flex flex-row items-start justify-start pt-[0rem] px-[1.75rem] pb-[0.375rem] box-border">
          <div className="h-[14.438rem] flex-1 relative bg-[url('/public/safety-moneya-3@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
            <img
              className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
              alt=""
              src="/safety-moneya-3@2x.png"
            />
            <div className="absolute top-[13.375rem] left-[4.75rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[2.062rem] gap-[1.125rem]">
          <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
            Lightening fast SSD Drive:
          </h3>
          <div className="self-stretch h-[5.813rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0 z-[1]">
            Our built-in SSD drive has the best configuration required for
            seamless functioning of the VPS. It enhances the overall speed and
            loading time for pages and web applications.
          </div>
        </div>
        <div className="w-[17.938rem] flex flex-row items-start justify-start py-[0rem] px-[3.562rem] box-border mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
          <div className="flex-1 flex flex-col items-start justify-start gap-[1.187rem]">
            <img
              className="w-[10.813rem] h-[10.813rem] relative object-cover z-[1]"
              loading="lazy"
              alt=""
              src="/77f63307e597425693d30bc8fd5d92d0a-2@2x.png"
            />
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[2.937rem] pr-[3.187rem]">
              <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[2.875rem] box-border gap-[1.125rem] max-w-full">
          <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit inline-block max-w-full z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
            No interruptions, seamless operation:
          </h3>
          <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
            Run your applications and websites seamlessly and with 99.99% uptime
            with our cPanel VPS services. We ensure there are no interruptions
            while you operate with our user-friendly dashboard. Our
            best-in-class servers will offer the optimum stability.
          </div>
        </div>
        <div className="w-[17.938rem] flex flex-row items-start justify-start py-[0rem] px-[4.875rem] box-border mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
          <div className="flex-1 flex flex-col items-start justify-start">
            <img
              className="w-[8.188rem] h-[8.188rem] relative object-cover z-[1]"
              loading="lazy"
              alt=""
              src="/86e2e2fcf66c41a4a2f792b940aabe0ca-511@2x.png"
            />
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.625rem] pr-[1.875rem]">
              <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[2]" />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default HostingFeatures1;

import { FunctionComponent, memo } from "react";
import HeaderPanal from "./HeaderPanal";

export type HeaderHeroDedicatedHostingType = {
  className?: string;
};

const HeaderHeroDedicatedHosting: FunctionComponent<HeaderHeroDedicatedHostingType> =
  memo(({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[5.75rem] box-border max-w-full text-center text-[0.875rem] text-lightgray-100 font-poppins mq450:pb-[2.438rem] mq450:box-border mq1150:pb-[3.75rem] mq1150:box-border ${className}`}
      >
        <div className="flex-1 [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1rem] box-border gap-[6.312rem] max-w-full mq450:gap-[1.563rem] mq800:gap-[3.125rem]">
          <HeaderPanal />
          <div className="w-[86rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <div className="w-[70rem] flex flex-col items-start justify-start gap-[4.187rem] max-w-full mq800:gap-[1.063rem] mq1150:gap-[2.063rem]">
              <div className="self-stretch flex flex-row items-end justify-start [row-gap:20px] max-w-full mq1150:flex-wrap">
                <div className="h-[27.875rem] w-[27.875rem] relative bg-[url('/public/web-designv1-9a-1@2x.png')] bg-cover bg-no-repeat bg-[top] min-w-[27.875rem] max-w-full mq800:min-w-full mq1150:flex-1">
                  <img
                    className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                    alt=""
                    src="/web-designv1-9a-1@2x.png"
                  />
                  <div className="absolute top-[24.063rem] left-[6.813rem] [filter:blur(26.2px)] rounded-[50%] bg-gray-800 w-[14.25rem] h-[1rem] z-[1]" />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[5.562rem] box-border min-w-[27.375rem] min-h-[22.25rem] max-w-full mq450:pb-[3.625rem] mq450:box-border mq800:min-w-full">
                  <div className="self-stretch flex flex-col items-end justify-start gap-[1.75rem] max-w-full">
                    <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[4.312rem] pr-[4rem] box-border max-w-full mq800:pl-[2.125rem] mq800:pr-[2rem] mq800:box-border">
                      <div className="flex-1 flex flex-col items-start justify-start gap-[1.312rem] max-w-full">
                        <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.625rem]">
                          <h1 className="relative leading-[1.25rem] font-medium">
                            Cheap Dedicated server Hosting in India
                          </h1>
                        </div>
                        <h1 className="m-0 self-stretch relative text-[3rem] tracking-[0.11em] leading-[1.5rem] font-semibold font-inherit text-white text-left z-[2] mq450:text-[1.813rem] mq450:leading-[0.875rem] mq800:text-[2.375rem] mq800:leading-[1.188rem]">
                          Dedicated Hosting
                        </h1>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1.437rem] box-border gap-[1.5rem] max-w-full">
                      <div className="self-stretch relative leading-[1.25rem] font-medium">
                        <p className="m-0">{`Following are some of the most important parts of the Indian Dedicated server and some of the `}</p>
                        <p className="m-0">
                          special schemes are also highlighted in the process
                        </p>
                      </div>
                      <div className="self-stretch h-[0.438rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                        <div className="self-stretch w-[31rem] relative rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none [background:linear-gradient(89.99deg,_#5c2281,_#fff)] max-w-full z-[2]" />
                      </div>
                    </div>
                    <div className="w-[38.938rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full text-indigo-100">
                      <div className="w-[21.563rem] rounded-11xl bg-white flex flex-row items-start justify-center py-[1rem] px-[1.25rem] box-border whitespace-nowrap max-w-full z-[2]">
                        <div className="h-[3.25rem] w-[21.563rem] relative rounded-11xl bg-white hidden max-w-full" />
                        <div className="relative tracking-[0.03em] leading-[1.25rem] font-medium z-[1]">
                          GET STARTED RIGHT NOW
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-start justify-start py-[0rem] px-[3.312rem] box-border max-w-full text-[1rem] text-white mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                <div className="flex flex-row items-start justify-start gap-[0.543rem]">
                  <div className="flex flex-col items-start justify-start pt-[1rem] px-[0rem] pb-[0rem]">
                    <img
                      className="w-[1.5rem] h-[1.5rem] relative overflow-hidden shrink-0 z-[2]"
                      loading="lazy"
                      alt=""
                      src="/icroundhome.svg"
                    />
                  </div>
                  <a className="[text-decoration:none] relative leading-[3.5rem] font-medium text-plum inline-block min-w-[3rem] z-[2]">
                    Home
                  </a>
                  <div className="relative text-[1.25rem] leading-[3.5rem] font-medium inline-block min-w-[0.688rem] z-[2] mq450:text-[1rem] mq450:leading-[2.813rem]">
                    /
                  </div>
                  <div className="relative leading-[3.5rem] font-medium z-[2]">
                    Dedicated Hosting
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  });

export default HeaderHeroDedicatedHosting;

import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type DetailsGridOptionsType = {
  className?: string;
  titles?: string;

  /** Style props */
  propWidth?: CSSProperties["width"];
  propPadding?: CSSProperties["padding"];
};

const DetailsGridOptions: FunctionComponent<DetailsGridOptionsType> = memo(
  ({ className = "", propWidth, propPadding, titles }) => {
    const frameDiv10Style: CSSProperties = useMemo(() => {
      return {
        width: propWidth,
        padding: propPadding,
      };
    }, [propWidth, propPadding]);

    return (
      <div
        className={`w-[29.5rem] flex flex-row items-start justify-start pt-[0rem] px-[0.125rem] pb-[3.5rem] box-border max-w-full text-left text-[1.25rem] text-indigo-100 font-poppins ${className}`}
        style={frameDiv10Style}
      >
        <div className="flex-1 flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
          <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] box-border max-w-full">
            <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
              {titles}
            </h3>
          </div>
          <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
            <p className="m-0">
              Hostlasting’s offering optimizes the performance of your website
              by taking it to the next level.
            </p>
            <p className="m-0">
              You can avail the exceptional 1 Gbps network connection with all
              of our VPS hosting plans. This
            </p>
            <p className="m-0">
              network with huge speed leads to the lightning-fast loading time
              for websites running different
            </p>
            <p className="m-0">resource-intensive applications.</p>
          </div>
        </div>
      </div>
    );
  }
);

export default DetailsGridOptions;

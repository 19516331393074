import { FunctionComponent } from "react";
import HeaderHeroJoomla from "../components/HeaderHeroJoomla";
import PriceOfferReliable from "../components/PriceOfferReliable";
import LiteSpeedPanel from "../components/LiteSpeedPanel";
import PlanDetails from "../components/PlanDetails";
import PlanPriceComponent from "../components/PlanPriceComponent";
import PlanPriceEnjoy from "../components/PlanPriceEnjoy";
import FeatureCheapJoomla from "../components/FeatureCheapJoomla";
import AdditionalFeature from "../components/AdditionalFeature";
import FQAJoomla from "../components/FQAJoomla";
import FooterComponent from "../components/FooterComponent";

const Joomla: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-end justify-start gap-[2.812rem] leading-[normal] tracking-[normal] mq750:gap-[1.375rem]">
      <HeaderHeroJoomla />
      <div className="w-[0.188rem] h-[9.938rem] relative border-white border-r-[3px] border-solid box-border hidden" />
      <section className="self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[0.875rem] box-border max-w-full text-center text-[2.25rem] text-white font-poppins">
        <div className="w-[76.188rem] flex flex-col items-end justify-start gap-[3.062rem] max-w-full mq750:gap-[1.5rem]">
          <PriceOfferReliable />
          <div className="self-stretch flex flex-row items-start justify-end py-[0rem] px-[1.062rem] box-border max-w-full">
            <div className="flex-1 flex flex-row items-start justify-center gap-[1.437rem] max-w-full mq1050:flex-wrap">
              <div className="flex-1 flex flex-col items-start justify-start pt-[0.125rem] px-[0rem] pb-[0rem] box-border min-w-[16.063rem] max-w-[17.438rem]">
                <div className="self-stretch rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid flex flex-col items-start justify-start pt-[0.75rem] pb-[1.562rem] pl-[0.687rem] pr-[0.625rem] gap-[0.312rem]">
                  <div className="w-[17.438rem] h-[44.813rem] relative rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                  <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0.125rem] pb-[0.625rem]">
                    <div className="flex-1 rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] flex flex-row items-start justify-start py-[2.625rem] px-[4.375rem] whitespace-nowrap z-[1] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                      <div className="h-[8.188rem] w-[15.75rem] relative rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] hidden" />
                      <h1 className="m-0 relative text-inherit tracking-[0.05em] leading-[2.938rem] font-medium font-inherit inline-block min-w-[7rem] z-[2]">
                        Plan 1
                      </h1>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.25rem] pr-[3.375rem] text-[1.5rem] text-black mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                    <div className="flex-1 flex flex-col items-start justify-start gap-[0.343rem]">
                      <div className="relative leading-[2.938rem] font-semibold z-[1] mq450:text-[1.188rem] mq450:leading-[2.375rem]">
                        ₹70/mo ($1)
                      </div>
                      <div className="self-stretch flex flex-col items-end justify-start text-[0.688rem]">
                        <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0rem] pr-[0.125rem]">
                          <div className="h-[2.938rem] flex-1 relative shrink-0">
                            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem] z-[1]" />
                            <div className="absolute top-[0rem] left-[2.125rem] leading-[2.938rem] font-medium inline-block min-w-[5rem] z-[2]">
                              Host 1 Website
                            </div>
                          </div>
                        </div>
                        <div className="self-stretch h-[2.938rem] relative shrink-0 mt-[-0.438rem]">
                          <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem] z-[1]" />
                          <div className="absolute top-[0rem] left-[1.625rem] leading-[2.938rem] font-medium inline-block min-w-[6rem] z-[3]">
                            5 GB SSD Storage
                          </div>
                        </div>
                        <div className="self-stretch h-[2.938rem] relative shrink-0 mt-[-0.438rem]">
                          <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem] z-[1]" />
                          <div className="absolute top-[0rem] left-[1.813rem] leading-[2.938rem] font-medium inline-block min-w-[5.563rem] z-[2]">
                            5 GB bandwidth
                          </div>
                        </div>
                        <div className="self-stretch h-[2.938rem] relative shrink-0 mt-[-0.438rem]">
                          <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem] z-[1]" />
                          <div className="absolute top-[0rem] left-[2.438rem] leading-[2.938rem] font-medium inline-block min-w-[4.375rem] z-[2]">
                            3 FTP, MySQL
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-start py-[0rem] pl-[0.562rem] pr-[0.625rem] text-[0.938rem] text-lightgray-200">
                        <div className="relative leading-[2.938rem] font-semibold z-[1]">
                          Free Let's Encrypt
                        </div>
                      </div>
                    </div>
                  </div>
                  <LiteSpeedPanel
                    emailID="10 Email ID"
                    y252070mo="3Y ₹2520 - 70/mo"
                  />
                </div>
              </div>
              <div className="flex-[0.9176] rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border flex flex-col items-start justify-start pt-[0.75rem] pb-[1.562rem] pl-[0.687rem] pr-[0.625rem] gap-[0.312rem] min-w-[16.063rem] max-w-[17.438rem] mq450:flex-1">
                <div className="w-[17.438rem] h-[44.813rem] relative rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0.125rem] pb-[0.625rem]">
                  <div className="flex-1 rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] flex flex-row items-start justify-start py-[2.625rem] pl-[3.875rem] pr-[3.687rem] whitespace-nowrap z-[1] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                    <div className="h-[8.188rem] w-[15.75rem] relative rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] hidden" />
                    <h1 className="m-0 relative text-inherit tracking-[0.1em] leading-[2.938rem] font-medium font-inherit z-[2]">
                      Plan 2
                    </h1>
                  </div>
                </div>
                <PlanDetails propMargin="unset" />
                <LiteSpeedPanel
                  emailID="Unlimited Email ID"
                  y252070mo="3Y ₹5040 - 140/mo"
                />
              </div>
              <div className="flex-[0.9176] rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border flex flex-col items-start justify-start pt-[0.75rem] pb-[1.562rem] pl-[0.687rem] pr-[0.625rem] gap-[0.937rem] min-w-[16.063rem] max-w-[17.438rem] mq450:flex-1">
                <div className="w-[17.438rem] h-[44.813rem] relative rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                  <div className="flex-1 rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] flex flex-row items-start justify-start py-[2.625rem] pl-[3.812rem] pr-[3.687rem] whitespace-nowrap z-[1] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                    <div className="h-[8.188rem] w-[15.75rem] relative rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] hidden" />
                    <h1 className="m-0 relative text-inherit tracking-[0.1em] leading-[2.938rem] font-medium font-inherit z-[2]">
                      Plan 3
                    </h1>
                  </div>
                </div>
                <PlanPriceComponent propMargin="unset" />
              </div>
              <div className="flex-1 flex flex-col items-start justify-start pt-[0.125rem] px-[0rem] pb-[0rem] box-border min-w-[16.063rem] max-w-[17.438rem]">
                <div className="self-stretch rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid flex flex-col items-start justify-start pt-[0.75rem] pb-[1.562rem] pl-[0.687rem] pr-[0.625rem] gap-[0.937rem]">
                  <div className="w-[17.438rem] h-[44.813rem] relative rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                    <div className="flex-1 rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] flex flex-row items-start justify-start py-[2.625rem] pl-[3.75rem] pr-[3.687rem] whitespace-nowrap z-[1] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                      <div className="h-[8.188rem] w-[15.75rem] relative rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] hidden" />
                      <h1 className="m-0 relative text-inherit tracking-[0.1em] leading-[2.938rem] font-medium font-inherit z-[2]">
                        Plan 4
                      </h1>
                    </div>
                  </div>
                  <PlanPriceEnjoy
                    propPadding="0rem 2.687rem 0rem 2.437rem"
                    mo3="₹340/mo ($4)"
                    host3Website="Host Unlimited Websites"
                    propLeft="0.438rem"
                    propDisplay="unset"
                    propMinWidth="unset"
                    propMargin="unset"
                    propPadding1="1.25rem 1.437rem 0rem 1.312rem"
                    y7560210mo="3Y ₹12240 - 340/mo"
                    propMinWidth1="7.563rem"
                  />
                </div>
              </div>
            </div>
          </div>
          <FeatureCheapJoomla />
        </div>
      </section>
      <AdditionalFeature />
      <FQAJoomla />
      <FooterComponent
        hOSTINGMargin="unset"
        dOMAINSSSLMargin="unset"
        cOMPANYMargin="unset"
        wordPressHostingTextDecoration="unset"
        aboutUsTextDecoration="unset"
        cheapSSDHostingTextDecoration="unset"
        supportTextDecoration="unset"
        vPSHostingTextDecoration="unset"
        blogTextDecoration="unset"
        dedicatedHostingTextDecoration="unset"
        offersTextDecoration="unset"
        contactUsTextDecoration="unset"
        privacyPolicyTextDecoration="none"
        sitemapTextDecoration="unset"
      />
    </div>
  );
};

export default Joomla;

import { FunctionComponent } from "react";
import FrameComponent1 from "../components/VPSHostingHeaderHero";
import GroupComponent3 from "../components/GroupComponent3";
import GroupComponent2 from "../components/GroupComponent2";
import GroupComponent1 from "../components/GroupComponent1";
import FrameComponent from "../components/FrameComponent";
import WebHostingContent from "../components/WebHostingContent";
import DataTransfer1 from "../components/DataTransfer1";
import HostingFeatures from "../components/HostingFeatures";
import SlideParent from "../components/SlideParent";
import CustomerServiceContainer from "../components/CustomerServiceContainer";
import AffordableHosting from "../components/AffordableHosting";
import DomainContainer from "../components/DomainContainer";
import AboutUsContainer from "../components/AboutUsContainer";
import Testimonials from "../components/Testimonials";
import Locations from "../components/Locations";
import FooterComponent from "../components/FooterComponent";

const V3VPSFinal: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[4.812rem] leading-[normal] tracking-[normal] mq450:gap-[1.188rem] mq800:gap-[2.375rem]">
      <div className="self-stretch h-[41rem] relative hidden" />
      <FrameComponent1 />
      <img
        className="w-[33rem] relative max-h-full object-contain hidden max-w-full"
        alt=""
        src="/hostlastingslide2-1@2x.png"
      />
      <img
        className="w-[21.438rem] relative max-h-full object-contain hidden max-w-full"
        alt=""
        src="/security-1@2x.png"
      />
      <div className="w-[0.188rem] h-[9.938rem] relative border-white border-r-[3px] border-solid box-border hidden" />
      <section className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[1.125rem] pl-[1.312rem] pr-[1.25rem] box-border max-w-full">
        <div className="w-[59.625rem] flex flex-row items-start justify-center gap-[1.875rem] max-w-full mq1125:flex-wrap">
          <GroupComponent3
            trustpilot1="/trustpilot-1.svg"
            ratingStars4="/rating-stars1.svg"
            empty="4.0"
          />
          <GroupComponent2 />
          <GroupComponent3
            propFlex="1"
            propPadding="1.875rem 1.437rem 1.937rem"
            trustpilot1="/trustindex-1.svg"
            ratingStars4="/rating-stars.svg"
            empty="5.0"
          />
          <GroupComponent1 />
        </div>
      </section>
      <FrameComponent />
      <section className="self-stretch flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[2.437rem] box-border gap-[0.037rem] max-w-full text-center text-[0.938rem] text-dimgray-100 font-poppins">
        <WebHostingContent />
        <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[3.337rem] pl-[1.375rem] pr-[1.25rem] box-border max-w-full">
          <div className="w-[65.563rem] relative leading-[1.313rem] flex items-center justify-center shrink-0 max-w-full">{`Hostlasting offers the affordable plans along with high level of server security, cooperative 24x7 support, and 24x LiteSpeed SSD servers. Hostlasting provides reliable and affordable web hosting services like dedicated hosting, VPS hosting, reseller hosting, & cheap shared hosting. We have adopted the latest technologies and the best-in-class server practices. This sets us apart from other competitors. Customer satisfaction is our first priority.`}</div>
        </div>
        <DataTransfer1 />
        <div className="mr-[-0.063rem] mb-[1.087rem] w-[46.438rem] h-[1.375rem] relative rounded-tl-xl rounded-tr-none rounded-br-none rounded-bl-xl bg-indigo-100 max-w-full" />
        <HostingFeatures />
      </section>
      <section className="self-stretch flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[6.068rem] box-border gap-[4.187rem] max-w-full mq450:gap-[1.063rem] mq800:gap-[2.063rem] mq800:pb-[2.563rem] mq800:box-border mq1325:pb-[3.938rem] mq1325:box-border">
        <SlideParent />
        <CustomerServiceContainer />
      </section>
      <AffordableHosting />
      <DomainContainer />
      <AboutUsContainer />
      <Testimonials />
      <Locations />
      <FooterComponent />
    </div>
  );
};

export default V3VPSFinal;

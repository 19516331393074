import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type GroupComponent3Type = {
  className?: string;
  trustpilot1?: string;
  ratingStars4?: string;
  empty?: string;

  /** Style props */
  propFlex?: CSSProperties["flex"];
  propPadding?: CSSProperties["padding"];
};

const GroupComponent3: FunctionComponent<GroupComponent3Type> = memo(
  ({
    className = "",
    propFlex,
    propPadding,
    trustpilot1,
    ratingStars4,
    empty,
  }) => {
    const groupDivStyle: CSSProperties = useMemo(() => {
      return {
        flex: propFlex,
        padding: propPadding,
      };
    }, [propFlex, propPadding]);

    return (
      <div
        className={`flex-[0.8235] rounded-3xs bg-ghostwhite-200 flex flex-col items-start justify-start pt-[1.687rem] px-[2.375rem] pb-[1.75rem] box-border gap-[0.75rem] min-w-[11.875rem] max-w-[13.5rem] text-left text-[0.625rem] text-indigo-100 font-poppins mq450:flex-1 ${className}`}
        style={groupDivStyle}
      >
        <div className="w-[13.5rem] h-[7.375rem] relative rounded-3xs bg-ghostwhite-200 hidden" />
        <img
          className="self-stretch h-[2.125rem] relative max-w-full overflow-hidden shrink-0 z-[1]"
          loading="lazy"
          alt=""
          src={trustpilot1}
        />
        <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[0.5rem]">
          <div className="flex-1 flex flex-row items-start justify-start gap-[0.75rem]">
            <div className="flex-1 flex flex-col items-start justify-start pt-[0.062rem] px-[0rem] pb-[0rem]">
              <div className="self-stretch flex flex-row items-start justify-start gap-[0.312rem]">
                <img
                  className="h-[0.938rem] w-[0.938rem] relative min-h-[0.938rem] z-[1]"
                  alt=""
                  src="/rating-stars.svg"
                />
                <img
                  className="h-[0.938rem] w-[0.938rem] relative min-h-[0.938rem] z-[1]"
                  alt=""
                  src="/rating-stars.svg"
                />
                <img
                  className="h-[0.938rem] w-[0.938rem] relative min-h-[0.938rem] z-[1]"
                  alt=""
                  src="/rating-stars.svg"
                />
                <img
                  className="h-[0.938rem] w-[0.938rem] relative min-h-[0.938rem] z-[1]"
                  alt=""
                  src="/rating-stars.svg"
                />
                <img
                  className="h-[0.938rem] w-[0.938rem] relative min-h-[0.938rem] z-[1]"
                  alt=""
                  src={ratingStars4}
                />
              </div>
            </div>
            <div className="relative tracking-[0.04em] leading-[1.063rem] font-semibold inline-block min-w-[1.063rem] z-[1]">
              {empty}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default GroupComponent3;

import { FunctionComponent, memo } from "react";
import HeaderPanal from "./HeaderPanal";

export type HeaderHeroBannerType = {
  className?: string;
};

const HeaderHeroBanner: FunctionComponent<HeaderHeroBannerType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[0.687rem] box-border max-w-full text-left text-[3rem] text-white font-poppins ${className}`}
      >
        <HeaderPanal />
        <div className="w-[79.375rem] flex flex-row items-start justify-start py-[0rem] pl-[1.25rem] pr-[0rem] box-border gap-[7.875rem] max-w-full lg:gap-[3.938rem] mq450:gap-[1rem] mq750:gap-[1.938rem] mq1050:flex-wrap">
          <div className="h-[43.313rem] w-[20.563rem] flex flex-col items-start justify-start pt-[17.625rem] px-[0rem] pb-[0rem] box-border min-w-[20.563rem] max-w-full mq750:pt-[11.438rem] mq750:box-border mq1050:flex-1">
            <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[13rem] max-w-full mq450:gap-[6.5rem]">
              <div className="self-stretch flex-1 flex flex-row items-start justify-start py-[0rem] pl-[0.125rem] pr-[0rem] box-border max-w-full">
                <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[1.312rem] max-w-full">
                  <div className="self-stretch w-[0.625rem] relative rounded-tl-none rounded-tr-xl rounded-br-xl rounded-bl-none [background:linear-gradient(0deg,_#7c4f9a,_#fff)] z-[1]" />
                  <div className="flex-1 flex flex-col items-start justify-start pt-[1.937rem] px-[0rem] pb-[0rem]">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[1.062rem]">
                      <h1 className="m-0 relative text-inherit tracking-[0.11em] leading-[1.5rem] font-semibold font-inherit z-[1] mq450:text-[1.813rem] mq450:leading-[0.875rem] mq1050:text-[2.375rem] mq1050:leading-[1.188rem]">
                        UNLIMITED
                      </h1>
                      <h3 className="m-0 relative text-[1.25rem] leading-[1.5rem] font-medium font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.188rem]">
                        Shared web hosting
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-start justify-start py-[0rem] pl-[0rem] pr-[1.25rem] gap-[0.543rem] text-center text-[1rem]">
                <div className="flex flex-col items-start justify-start pt-[1rem] px-[0rem] pb-[0rem]">
                  <img
                    className="w-[1.5rem] h-[1.5rem] relative overflow-hidden shrink-0"
                    loading="lazy"
                    alt=""
                    src="/icroundhome.svg"
                  />
                </div>
                <a className="[text-decoration:none] relative leading-[3.5rem] font-medium text-plum inline-block min-w-[3rem]">
                  Home
                </a>
                <h3 className="m-0 relative text-[1.25rem] leading-[3.5rem] font-medium font-inherit inline-block min-w-[0.688rem] mq450:text-[1rem] mq450:leading-[2.813rem]">
                  /
                </h3>
                <div className="relative leading-[3.5rem] font-medium">
                  Shared SSD Hosting
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-row items-start justify-start py-[8.75rem] px-[11.937rem] box-border bg-[url('/public/geometricabstractbackgroundwithconnectedlinedotsnetworkconnectionbackgroundyourpresentationgraphicpolygonalbackgroundscientificillustrationrasterillustration-1@2x.png')] bg-cover bg-no-repeat bg-[top] min-w-[32.313rem] min-h-[42.875rem] max-w-full z-[1] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq750:py-[5.688rem] mq750:px-[5.938rem] mq750:box-border mq750:min-w-full mq1050:min-h-[auto]">
            <img
              className="h-[42.875rem] w-[49.688rem] relative object-cover hidden max-w-full"
              alt=""
              src="/geometricabstractbackgroundwithconnectedlinedotsnetworkconnectionbackgroundyourpresentationgraphicpolygonalbackgroundscientificillustrationrasterillustration-1@2x.png"
            />
            <div className="h-[19.188rem] w-[20.5rem] relative max-w-full">
              <div className="absolute top-[17.125rem] left-[0rem] [filter:blur(24.2px)] rounded-[50%] bg-gray-1500 w-[20.5rem] h-[2.063rem] z-[2]" />
              <img
                className="absolute w-[calc(100%_-_21px)] top-[0rem] right-[0.688rem] left-[0.625rem] max-w-full overflow-hidden h-[19.188rem] object-cover z-[3]"
                loading="lazy"
                alt=""
                src="/ssd-linux-1@2x.png"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default HeaderHeroBanner;

import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type OSQuestionType = {
  className?: string;
  whichHypervisorIsUsedInTh?: string;
  compatAnswer?: string;

  /** Style props */
  propGap?: CSSProperties["gap"];
  propPadding?: CSSProperties["padding"];
};

const OSQuestion: FunctionComponent<OSQuestionType> = memo(
  ({
    className = "",
    propGap,
    propPadding,
    whichHypervisorIsUsedInTh,
    compatAnswer,
  }) => {
    const oSQuestionStyle: CSSProperties = useMemo(() => {
      return {
        gap: propGap,
        padding: propPadding,
      };
    }, [propGap, propPadding]);

    return (
      <div
        className={`self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-between pt-[0.5rem] pb-[0.562rem] pl-[4.687rem] pr-[3.812rem] box-border max-w-full gap-[1.25rem] text-left text-[0.875rem] text-black font-poppins mq1150:pl-[2.313rem] mq1150:pr-[1.875rem] mq1150:box-border ${className}`}
        style={oSQuestionStyle}
      >
        <div className="h-[4rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
        <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium z-[1]">
          {whichHypervisorIsUsedInTh}
        </div>
        <div className="flex flex-col items-start justify-start pt-[1.25rem] px-[0rem] pb-[0rem]">
          <img
            className="w-[0.75rem] h-[0.375rem] relative object-contain z-[1]"
            alt=""
            src={compatAnswer}
          />
        </div>
      </div>
    );
  }
);

export default OSQuestion;

import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type FrameComponent24Type = {
  className?: string;
  whatAreTheWaysInWhichSSLM?: string;
  anSSLCertificateContainsV?: string;
  betweenThemAndTheWebsiteE?: string;
  maximumObservableAssuranc?: string;
  certificatesAccordingToYo?: string;

  /** Style props */
  propFlex?: CSSProperties["flex"];
  propAlignSelf?: CSSProperties["alignSelf"];
};

const FrameComponent24: FunctionComponent<FrameComponent24Type> = memo(
  ({
    className = "",
    propFlex,
    propAlignSelf,
    whatAreTheWaysInWhichSSLM,
    anSSLCertificateContainsV,
    betweenThemAndTheWebsiteE,
    maximumObservableAssuranc,
    certificatesAccordingToYo,
  }) => {
    const frameDiv7Style: CSSProperties = useMemo(() => {
      return {
        flex: propFlex,
        alignSelf: propAlignSelf,
      };
    }, [propFlex, propAlignSelf]);

    return (
      <div
        className={`flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full text-left text-[0.875rem] text-black font-poppins ${className}`}
        style={frameDiv7Style}
      >
        <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-between pt-[0.5rem] pb-[0.562rem] pl-[4.687rem] pr-[3.812rem] box-border max-w-full gap-[1.25rem] mq1050:pl-[2.313rem] mq1050:pr-[1.875rem] mq1050:box-border">
          <div className="h-[4rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
          <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium z-[1]">
            {whatAreTheWaysInWhichSSLM}
          </div>
          <div className="flex flex-col items-start justify-start pt-[1.25rem] px-[0rem] pb-[0rem]">
            <img
              className="w-[0.75rem] h-[0.375rem] relative z-[1]"
              loading="lazy"
              alt=""
              src="/vector-337.svg"
            />
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full text-slategray-100">
          <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[1.875rem] px-[4.25rem] pb-[1.812rem] box-border max-w-full mq1050:pl-[2.125rem] mq1050:pr-[2.125rem] mq1050:box-border">
            <div className="h-[7.938rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
            <div className="h-[4.25rem] relative tracking-[0.04em] leading-[1.063rem] flex items-center z-[1]">
              <span>
                <p className="m-0">{anSSLCertificateContainsV}</p>
                <p className="m-0">{betweenThemAndTheWebsiteE}</p>
                <p className="m-0">{maximumObservableAssuranc}</p>
                <p className="m-0">{certificatesAccordingToYo}</p>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent24;

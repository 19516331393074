import { FunctionComponent, memo } from "react";

export type GroupComponent31Type = {
  className?: string;
  vPSS2?: string;
  x240GHzCPU?: string;
  gB?: string;
  gB1?: string;
  tB?: string;
  mo?: string;
};

const GroupComponent31: FunctionComponent<GroupComponent31Type> = memo(
  ({ className = "", vPSS2, x240GHzCPU, gB, gB1, tB, mo }) => {
    return (
      <div
        className={`self-stretch shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white overflow-x-auto flex flex-row items-end justify-between py-[1.187rem] pl-[3.25rem] pr-[1.812rem] gap-[1.25rem] z-[1] text-center text-[0.813rem] text-slategray-100 font-poppins mq1150:pl-[1.625rem] mq1150:box-border ${className}`}
      >
        <div className="h-[5.313rem] w-[65.125rem] relative shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white shrink-0 hidden" />
        <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.812rem] pl-[0rem] pr-[2.187rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[3.063rem] z-[1]">
            {vPSS2}
          </div>
        </div>
        <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[0.562rem]">
          <div className="relative leading-[2.938rem] font-semibold inline-block min-w-[6.375rem] z-[1]">
            {x240GHzCPU}
          </div>
        </div>
        <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.812rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[1.813rem] z-[1]">
            {gB}
          </div>
        </div>
        <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.812rem] pl-[0rem] pr-[2.062rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.375rem] z-[1]">
            {gB1}
          </div>
        </div>
        <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.875rem] pl-[0rem] pr-[0.812rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[1.688rem] z-[1]">
            {tB}
          </div>
        </div>
        <div className="w-[11.938rem] shrink-0 flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.375rem] box-border text-[0.938rem]">
          <div className="self-stretch flex flex-row items-end justify-between gap-[1.25rem]">
            <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.437rem]">
              <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[3.313rem] z-[1]">
                {mo}
              </div>
            </div>
            <div className="rounded-xl bg-goldenrod flex flex-row items-start justify-start pt-[0.5rem] px-[1.937rem] pb-[0.437rem] z-[1] text-[0.875rem] text-white">
              <div className="h-[2.188rem] w-[6.375rem] relative rounded-xl bg-goldenrod hidden" />
              <a href="#" className="[text-decoration:none] relative leading-[1.25rem] font-medium text-white inline-block min-w-[2.5rem] z-[1]">
                Order
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default GroupComponent31;

import { FunctionComponent, memo } from "react";
import GroupComponent15 from "./GroupComponent15";

export type PricePanelDedicatedHostingType = {
  className?: string;
};

const PricePanelDedicatedHosting: FunctionComponent<PricePanelDedicatedHostingType> =
  memo(({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[2.875rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins mq800:pb-[1.875rem] mq800:box-border ${className}`}
      >
        <div className="w-[66.813rem] flex flex-col items-start justify-start gap-[3.125rem] max-w-full mq800:gap-[1.563rem]">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <div className="flex flex-col items-start justify-start gap-[0.187rem] max-w-full">
              <div className="flex flex-row items-start justify-start py-[0rem] pl-[0.937rem] pr-[0.875rem]">
                <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
                  Dedicated Hosting Plans
                </h2>
              </div>
              <div className="relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
                Choose your Hostlasting dedicated server now
              </div>
            </div>
          </div>
          <div className="self-stretch rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[3.5rem] box-border gap-[1.937rem] max-w-full text-center text-[0.875rem] text-white mq800:gap-[0.938rem] mq800:pb-[2.25rem] mq800:box-border">
            <div className="self-stretch h-[33.313rem] relative rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] hidden" />
            <div className="self-stretch rounded-t-11xl rounded-b-none bg-indigo-300 overflow-x-auto flex flex-row items-start justify-start pt-[1.937rem] px-[4.625rem] pb-[1.562rem] gap-[5.312rem] z-[1] mq450:gap-[1.313rem] mq1150:gap-[2.625rem] mq1150:pl-[2.313rem] mq1150:pr-[2.313rem] mq1150:box-border">
              <div className="h-[4.75rem] w-[66.813rem] relative rounded-t-11xl rounded-b-none bg-indigo-300 shrink-0 hidden" />
              <div className="w-[5.438rem] shrink-0 flex flex-col items-start justify-start">
                <div className="relative leading-[1.25rem] font-medium inline-block min-w-[2.188rem] z-[2]">
                  PLAN
                </div>
              </div>
              <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.875rem]">
                <div className="relative leading-[1.25rem] font-medium inline-block min-w-[1.813rem] z-[2]">
                  CPU
                </div>
              </div>
              <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.437rem]">
                <div className="relative leading-[1.25rem] font-medium inline-block min-w-[1.938rem] z-[2]">
                  RAM
                </div>
              </div>
              <div className="relative leading-[1.25rem] font-medium inline-block min-w-[4.063rem] z-[2]">
                STORAGE
              </div>
              <div className="relative leading-[1.25rem] font-medium inline-block min-w-[5.25rem] z-[2]">
                BANDWIDTH
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.937rem] pr-[0.75rem] box-border max-w-full text-[0.813rem] text-slategray-100">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
                <GroupComponent15
                  sERVERS="SERVER S"
                  coreCPU="4 Core CPU"
                  gB="8 GB"
                  gBSSDSpace="300 GB SSD Space"
                  gB1="500 GB"
                  mo="₹ 9000/mo"
                />
                <div className="self-stretch shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white flex flex-row items-start justify-between py-[0rem] pl-[0rem] pr-[1.812rem] box-border max-w-full gap-[1.25rem] z-[1] text-white mq1150:flex-wrap mq1150:p-[1.25rem] mq1150:box-border">
                  <div className="self-stretch w-[65.125rem] relative shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white hidden max-w-full" />
                  <div className="h-[5.313rem] w-[17.688rem] flex flex-row items-start justify-start gap-[1.312rem]">
                    <div className="self-stretch w-[2.563rem] rounded-tl-xl rounded-tr-none rounded-br-none rounded-bl-xl bg-indigo-100 flex flex-row items-end justify-start pt-[1.062rem] px-[0.062rem] pb-[1rem] box-border z-[2]">
                      <div className="h-[5.313rem] w-[2.563rem] relative rounded-tl-xl rounded-tr-none rounded-br-none rounded-bl-xl bg-indigo-100 hidden" />
                      <div className="h-[2.625rem] w-[1.25rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.562rem] box-border">
                        <div className="w-[2.063rem] relative leading-[1.25rem] font-semibold inline-block [transform:_rotate(-90deg)] z-[3]">
                          Most
                        </div>
                      </div>
                      <div className="self-stretch w-[0.938rem] flex flex-col items-start justify-start py-[0rem] px-[0rem] box-border">
                        <div className="ml-[-0.313rem] w-[3.25rem] relative leading-[1.25rem] font-semibold inline-block [transform:_rotate(-90deg)] z-[4]">
                          Popular
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col items-start justify-start pt-[1.187rem] px-[0rem] pb-[0rem] text-slategray-100">
                      <div className="self-stretch flex flex-row items-start justify-between gap-[1.25rem]">
                        <div className="flex flex-col items-start justify-start pt-[0.25rem] px-[0rem] pb-[0rem]">
                          <div className="flex flex-col items-start justify-start">
                            <div className="flex flex-row items-start justify-start py-[0rem] px-[1.312rem]">
                              <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[3.938rem] shrink-0 z-[1]">
                                SERVER M
                              </div>
                            </div>
                            <div className="h-[1.188rem] flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0rem] box-border">
                              <div className="mt-[-0.063rem] relative leading-[1.25rem] font-medium inline-block min-w-[6.563rem] shrink-0 z-[1]">
                                Full Root Access
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="relative leading-[2.938rem] font-semibold inline-block min-w-[4.625rem] z-[1]">
                          8 Core CPU
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-[40rem] flex flex-col items-start justify-start pt-[1.562rem] px-[0rem] pb-[0rem] box-border max-w-full text-slategray-100">
                    <div className="self-stretch flex flex-row items-end justify-between gap-[1.25rem] mq800:flex-wrap">
                      <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.437rem] pl-[0rem] pr-[0.75rem]">
                        <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.188rem] z-[1]">
                          16 GB
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.437rem] pl-[0rem] pr-[0.625rem]">
                        <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[7.313rem] z-[1]">
                          1 TB SATA Storage
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.437rem]">
                        <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.875rem] z-[1]">
                          750 GB
                        </div>
                      </div>
                      <div className="flex flex-row items-end justify-start gap-[1.75rem] text-[0.938rem]">
                        <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.437rem]">
                          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[5.375rem] z-[1]">
                            ₹10000/mo
                          </div>
                        </div>
                        <div className="rounded-xl bg-deepskyblue flex flex-row items-start justify-start pt-[0.5rem] px-[1.937rem] pb-[0.437rem] z-[1] text-[0.875rem] text-white">
                          <div className="h-[2.188rem] w-[6.375rem] relative rounded-xl bg-deepskyblue hidden" />
                          <a href="#" className="[text-decoration:none] relative leading-[1.25rem] text-white font-medium inline-block min-w-[2.5rem] z-[1]">
                            Order
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white flex flex-row items-start justify-between py-[1.187rem] pl-[1.562rem] pr-[1.812rem] box-border max-w-full gap-[1.25rem] z-[1] mq1150:flex-wrap">
                  <div className="h-[5.313rem] w-[65.125rem] relative shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white hidden max-w-full" />
                  <div className="flex flex-row items-end justify-start gap-[3.937rem] max-w-full mq450:gap-[1rem] mq800:flex-wrap mq800:gap-[1.938rem]">
                    <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.25rem] pl-[0rem] pr-[1rem]">
                      <div className="flex flex-col items-start justify-start">
                        <div className="flex flex-row items-start justify-start py-[0rem] px-[1.5rem]">
                          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[3.563rem] shrink-0 z-[1]">
                            SERVER L
                          </div>
                        </div>
                        <div className="h-[1.188rem] flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0rem] box-border">
                          <div className="mt-[-0.063rem] relative leading-[1.25rem] font-medium inline-block min-w-[6.563rem] shrink-0 z-[1]">
                            Full Root Access
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.625rem]">
                      <div className="relative leading-[2.938rem] font-semibold inline-block min-w-[4.625rem] z-[1]">
                        8 Core CPU
                      </div>
                    </div>
                    <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.812rem]">
                      <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.313rem] z-[1]">
                        32 GB
                      </div>
                    </div>
                    <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.812rem] pl-[0rem] pr-[0.562rem]">
                      <div className="relative leading-[1.25rem] font-semibold z-[1]">
                        1 TB SATA / 480 GB SSD
                      </div>
                    </div>
                    <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.812rem]">
                      <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[1.688rem] z-[1]">
                        1 TB 
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start pt-[0.375rem] px-[0rem] pb-[0rem] text-[0.938rem]">
                    <div className="flex flex-row items-end justify-start gap-[1.75rem]">
                      <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.437rem]">
                        <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[5.125rem] z-[1]">
                          ₹11000/mo
                        </div>
                      </div>
                      <div className="rounded-xl bg-deeppink flex flex-row items-start justify-start pt-[0.5rem] px-[1.937rem] pb-[0.437rem] z-[1] text-[0.875rem] text-white">
                        <div className="h-[2.188rem] w-[6.375rem] relative rounded-xl bg-deeppink hidden" />
                        <a href="#" className="[text-decoration:none] relative leading-[1.25rem] text-white font-medium inline-block min-w-[2.5rem] z-[1]">
                          Order
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <GroupComponent15
                  sERVERS="SERVER XL+"
                  coreCPU="8 Core CPU"
                  gB="32 GB"
                  gBSSDSpace="1 TB SSD Space"
                  gB1="1 TB"
                  mo="₹12000/mo"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  });

export default PricePanelDedicatedHosting;

import { FunctionComponent, memo } from "react";
import FrameComponent26 from "./FrameComponent26";

export type SSDLightCompetitionDetailsType = {
  className?: string;
};

const SSDLightCompetitionDetails: FunctionComponent<SSDLightCompetitionDetailsType> =
  memo(({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-col items-start justify-start gap-[8.125rem] max-w-full text-left text-[0.75rem] text-slategray-100 font-poppins mq450:gap-[2rem] mq750:gap-[4.063rem] ${className}`}
      >
        <div className="self-stretch bg-ghostwhite-100 flex flex-col items-start justify-start pt-[3.687rem] pb-[8.187rem] pl-[18.75rem] pr-[11.437rem] box-border gap-[0.75rem] max-w-full z-[1] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq750:pt-[2.375rem] mq750:pb-[5.313rem] mq750:pl-[9.375rem] mq750:pr-[5.688rem] mq750:box-border">
          <div className="w-[89.438rem] h-[44.5rem] relative bg-ghostwhite-100 hidden max-w-full" />
          <div className="flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.437rem] box-border max-w-full text-[1.5rem] text-darkslategray-100">
            <h1 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit z-[2] mq450:text-[1.188rem] mq450:leading-[2.313rem]">
              The LiteSpeed beats the competition when it comes to performance.
            </h1>
          </div>
          <FrameComponent26
            withoutProvidingAKeepAliv="Without providing a keep-alive connection, the LiteSpeed Hosting has following advantages:"
            around89FasterAsComparedT="Around 89% faster as compared to Apache 2.2 with pre-fork MPM."
            nearly167FasterInComparis="Nearly 167% faster in comparison to Apache 2.4 with occasion MPM."
            quickerThanNginx="15% quicker than Nginx."
          />
          <div className="self-stretch flex flex-row items-start justify-end max-w-full">
            <FrameComponent26
              propPadding="unset"
              withoutProvidingAKeepAliv="With keep-alive connections, the LiteSpeed webserver has been:"
              around89FasterAsComparedT="245% faster as compared to Apache 2.2 with pre-fork MPM."
              nearly167FasterInComparis="533% faster as compared to Apache 2.4 with event MPM."
              quickerThanNginx="67% faster in comparison to Nginx (VPS vs. Dedicated Server)"
            />
          </div>
          <div className="w-[51.938rem] flex flex-row items-start justify-start py-[0rem] px-[4.312rem] box-border max-w-full text-center mq1050:pl-[2.125rem] mq1050:pr-[2.125rem] mq1050:box-border">
            <div className="flex-1 relative leading-[1.125rem] font-medium inline-block max-w-full z-[2]">
              Apache brought revolution in the web in 1995. Now is time to wake
              up for taking the next leap on LiteSpeed Servers. Our servers are
              located in the U.K., the U.S., Canada, India, Germany, Singapore,
              France, etc.
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full text-center text-[0.813rem] text-white">
          <div className="rounded-11xl bg-indigo-100 flex flex-row items-start justify-start py-[1rem] pl-[3.625rem] pr-[3.562rem] box-border whitespace-nowrap max-w-full mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
            <div className="h-[3.25rem] w-[21.563rem] relative rounded-11xl bg-indigo-100 hidden max-w-full" />
            <div className="relative tracking-[0.03em] leading-[1.25rem] font-medium z-[1]">{`Explore Our Affordable Plans Now `}</div>
          </div>
        </div>
      </section>
    );
  });

export default SSDLightCompetitionDetails;

import { FunctionComponent, memo } from "react";
import HeaderPanal from "./HeaderPanal";

export type HeaderHeroCheapSSLType = {
  className?: string;
};

const HeaderHeroCheapSSL: FunctionComponent<HeaderHeroCheapSSLType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[1.312rem] box-border max-w-full text-left text-[3rem] text-white font-poppins ${className}`}
      >
        <div className="flex-1 [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1rem] box-border gap-[5.687rem] max-w-full mq750:gap-[2.813rem] mq450:gap-[1.438rem]">
          <HeaderPanal />
          <div className="w-[87.438rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <div className="w-[72.813rem] flex flex-col items-start justify-start gap-[3.937rem] max-w-full lg:gap-[1.938rem] mq750:gap-[1rem]">
              <div className="self-stretch flex flex-row items-start justify-start gap-[2.375rem] max-w-full mq750:gap-[1.188rem] mq1050:flex-wrap">
                <div className="h-[28.75rem] w-[28.75rem] relative min-w-[28.75rem] max-w-full mq750:min-w-full mq1050:flex-1">
                  <div className="absolute top-[23.438rem] left-[2.5rem] [filter:blur(26.2px)] rounded-[50%] bg-gray-800 w-[14.25rem] h-[1rem]" />
                  <img
                    className="absolute top-[0rem] left-[0rem] w-full h-full object-cover z-[1]"
                    loading="lazy"
                    alt=""
                    src="/ssl-certificate-1-sidea-1@2x.png"
                  />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start pt-[8rem] px-[0rem] pb-[0rem] box-border min-w-[27.125rem] max-w-full mq750:min-w-full">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[1.75rem] max-w-full">
                    <h1 className="m-0 relative text-inherit tracking-[0.11em] leading-[1.5rem] font-semibold font-inherit z-[2] mq1050:text-[2.375rem] mq1050:leading-[1.188rem] mq450:text-[1.813rem] mq450:leading-[0.875rem]">
                      Cheap SSL Certificates
                    </h1>
                    <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1rem] pr-[1.375rem] text-center text-[0.875rem] text-lightgray-100">
                      <div className="w-[39.313rem] relative leading-[1.25rem] font-medium inline-block z-[2]">
                        <p className="m-0">{`Secure and cheap SSL Certificates help in integrating your business functioning and thus `}</p>
                        <p className="m-0 whitespace-pre-wrap">{`provide much-required security to your business operations.     `}</p>
                      </div>
                    </div>
                    <div className="self-stretch h-[0.438rem] flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.562rem] box-border max-w-full">
                      <div className="self-stretch w-[31rem] relative rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none [background:linear-gradient(89.99deg,_#5d2482,_#fff)] max-w-full z-[2]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-start justify-start py-[0rem] px-[4rem] box-border max-w-full text-center text-[1rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                <div className="flex flex-row items-start justify-start gap-[0.543rem]">
                  <div className="flex flex-col items-start justify-start pt-[1rem] px-[0rem] pb-[0rem]">
                    <img
                      className="w-[1.5rem] h-[1.5rem] relative overflow-hidden shrink-0 z-[2]"
                      loading="lazy"
                      alt=""
                      src="/icroundhome.svg"
                    />
                  </div>
                  <a className="[text-decoration:none] relative leading-[3.5rem] font-medium text-plum inline-block min-w-[3rem] z-[2]">
                    Home
                  </a>
                  <h3 className="m-0 relative text-[1.25rem] leading-[3.5rem] font-medium font-inherit inline-block min-w-[0.688rem] z-[2] mq450:text-[1rem] mq450:leading-[2.813rem]">
                    /
                  </h3>
                  <div className="relative leading-[3.5rem] font-medium z-[2]">
                    Cheap SSL Certificates
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default HeaderHeroCheapSSL;

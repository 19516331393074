import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type GroupComponent23Type = {
  className?: string;
  tierIIIServerPlatform?: string;
  ourDedicatedServersAreLoc?: string;

  /** Style props */
  propGap?: CSSProperties["gap"];
  propFlexWrap?: CSSProperties["flexWrap"];
  propAlignSelf?: CSSProperties["alignSelf"];
  propWidth?: CSSProperties["width"];
};

const GroupComponent23: FunctionComponent<GroupComponent23Type> = memo(
  ({
    className = "",
    propGap,
    propFlexWrap,
    propAlignSelf,
    propWidth,
    tierIIIServerPlatform,
    ourDedicatedServersAreLoc,
  }) => {
    const groupDiv4Style: CSSProperties = useMemo(() => {
      return {
        gap: propGap,
        flexWrap: propFlexWrap,
        alignSelf: propAlignSelf,
        width: propWidth,
      };
    }, [propGap, propFlexWrap, propAlignSelf, propWidth]);

    return (
      <div
        className={`self-stretch rounded-mini border-indigo-100 border-[1px] border-solid box-border flex flex-row items-start justify-start py-[0.75rem] px-[2.5rem] gap-[1.687rem] max-w-full z-[2] text-left text-[0.75rem] text-darkslategray-100 font-poppins mq1150:flex-wrap mq1150:justify-center ${className}`}
        style={groupDiv4Style}
      >
        <div className="flex flex-row items-start justify-start relative">
          <div className="h-[2.313rem] w-[10.5rem] absolute !m-[0] bottom-[0.25rem] left-[-1.25rem] rounded-3xs bg-indigo-200" />
          <div className="relative leading-[2.875rem] font-semibold z-[1]">
            {tierIIIServerPlatform}
          </div>
        </div>
        <div className="flex flex-col items-start justify-start pt-[0.812rem] px-[0rem] pb-[0rem] box-border max-w-full text-center text-slategray-100">
          <div className="relative leading-[1.25rem]">
            {ourDedicatedServersAreLoc}
          </div>
        </div>
        <div className="h-[4.625rem] w-[66.625rem] relative rounded-mini border-indigo-100 border-[1px] border-solid box-border hidden max-w-full" />
      </div>
    );
  }
);

export default GroupComponent23;

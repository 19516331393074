import { FunctionComponent, memo } from "react";

export type SlideParentType = {
  className?: string;
};

const SlideParent: FunctionComponent<SlideParentType> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`w-[83.688rem] flex flex-row items-start justify-between py-[0rem] pl-[1.25rem] pr-[0rem] box-border max-w-full gap-[1.25rem] text-left text-[2rem] text-darkslategray-100 font-poppins mq1325:flex-wrap ${className}`}
      >
        <div className="w-[30.313rem] flex flex-col items-start justify-start pt-[0.937rem] px-[0rem] pb-[0rem] box-border min-w-[30.313rem] max-w-full mq800:min-w-full mq1325:flex-1">
          <div className="self-stretch h-[20.5rem] relative">
            <img
              className="absolute h-full top-[0rem] bottom-[0rem] left-[0rem] max-h-full w-[27.375rem] object-cover"
              alt=""
              src="/hostlastingslide2-11@2x.png"
            />
            <img
              className="absolute top-[0.563rem] left-[7.875rem] w-[22.438rem] h-[16.831rem] object-contain z-[1]"
              loading="lazy"
              alt=""
              src="/trer5-1@2x.png"
            />
          </div>
        </div>
        <div className="w-[45.188rem] flex flex-col items-start justify-start gap-[2.375rem] min-w-[45.188rem] max-w-full mq800:gap-[1.188rem] mq800:min-w-full mq1325:flex-1">
          <div className="self-stretch h-[1.375rem] relative rounded-tl-xl rounded-tr-none rounded-br-none rounded-bl-xl bg-indigo-100" />
          <div className="w-[40.625rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <div className="w-[29.25rem] flex flex-col items-start justify-start gap-[0.375rem] max-w-full">
              <h1 className="m-0 self-stretch relative text-inherit tracking-[0.04em] leading-[2.5rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[1.5rem] mq800:text-[1.625rem] mq800:leading-[2rem]">
                One-Click Installer For 420+ Apps
              </h1>
              <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100">
                Softaculous is a superb Auto Installer with 320+ great Scripts,
                1115 PHP Categories, and they are still incorporating more. This
                is nothing but a script-library of the web application installer
                that utilizes the free applications that can set up with only
                one-click for Blog, CMS, Forum, Picture Gallery, Shopping Carts,
                and others.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default SlideParent;

import { FunctionComponent, memo } from "react";

export type FrameComponentType = {
  className?: string;
};

const FrameComponent: FunctionComponent<FrameComponentType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`ml-[-0.188rem] bg-whitesmoke-100 flex flex-row items-start justify-start pt-[4.625rem] pb-[4.25rem] pl-[3.437rem] pr-[2.875rem] box-border gap-[4rem] max-w-full z-[1] text-center text-[1.5rem] text-midnightblue font-poppins mq450:gap-[1rem] mq800:gap-[2rem] mq800:pt-[3rem] mq800:pb-[2.75rem] mq800:pl-[1.688rem] mq800:pr-[1.438rem] mq800:box-border ${className}`}
      >
        <div className="h-[42.375rem] w-[89.625rem] relative bg-whitesmoke-100 hidden max-w-full" />
        <div className="flex flex-col items-start justify-start pt-[15.187rem] px-[0rem] pb-[0rem]">
          <img
            className="w-[2.75rem] h-[2.75rem] relative overflow-hidden shrink-0 object-contain z-[2]"
            alt=""
            src="/heroiconssolidchevronright.svg"
          />
        </div>
        <div className="w-[69.813rem] flex flex-row items-start justify-start py-[0rem] pl-[0rem] pr-[0.312rem] box-border gap-[1rem] max-w-[calc(100%_-_216px)] mq1125:flex-wrap">
          <div className="flex-[0.7361] rounded bg-white flex flex-col items-end justify-start pt-[3.437rem] pb-[3.187rem] pl-[2.937rem] pr-[3rem] box-border gap-[1.812rem] min-w-[16.875rem] max-w-full z-[2] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq450:flex-1 mq800:pt-[2.25rem] mq800:pb-[2.063rem] mq800:box-border">
            <div className="w-[22.5rem] h-[33.438rem] relative rounded bg-white hidden max-w-full" />
            <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[2.312rem] pr-[2.25rem]">
              <img
                className="h-[8rem] flex-1 relative max-w-full overflow-hidden object-cover z-[1]"
                loading="lazy"
                alt=""
                src="/wordpressa-3@2x.png"
              />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start">
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[0.937rem]">
                <h2 className="m-0 relative text-inherit leading-[2rem] font-bold font-inherit z-[1] mq450:text-[1.188rem] mq450:leading-[1.625rem]">
                  WordPress Hosting
                </h2>
              </div>
              <div className="relative text-[0.875rem] leading-[1.5rem] z-[1]">
                Cheap WordPress Hosting at low cost.
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-end pt-[0rem] px-[0.062rem] pb-[0.437rem] text-[0.875rem]">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.687rem]">
                <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
                  <div className="relative leading-[1.5rem] whitespace-pre-wrap inline-block min-w-[4.813rem] z-[1]">
                    Starting at
                  </div>
                </div>
                <div className="self-stretch relative leading-[3.813rem] z-[1] text-[2.25rem] mq450:text-[1.375rem] mq450:leading-[2.125rem] mq800:text-[1.813rem] mq800:leading-[2.813rem]">
                  <span className="font-semibold">₹</span>
                  <b className="text-[3rem]">70/</b>
                  <span className="font-semibold">mo</span>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[1.375rem] pr-[1.312rem] top-[0] z-[99] sticky text-[1rem] text-white">
              <div className="flex-1 rounded-3xs bg-darkslategray-100 flex flex-row items-start justify-between py-[1rem] pl-[4.187rem] pr-[3.687rem] gap-[1.25rem] z-[1]">
                <div className="h-[3.438rem] w-[13.875rem] relative rounded-3xs bg-darkslategray-100 hidden" />
                <div className="relative leading-[1.438rem] font-medium inline-block min-w-[3.625rem] z-[1]">
                  Explore
                </div>
                <div className="flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem]">
                  <img
                    className="w-[0.875rem] h-[0.856rem] relative z-[1]"
                    alt=""
                    src="/vector-431-stroke.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-[0.7333] rounded bg-white flex flex-col items-start justify-start pt-[3.812rem] px-[3rem] pb-[3.187rem] box-border gap-[1.812rem] min-w-[16.875rem] max-w-full z-[2] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq450:flex-1 mq800:pt-[2.5rem] mq800:pb-[2.063rem] mq800:box-border">
            <div className="w-[22.5rem] h-[33.438rem] relative rounded bg-white hidden max-w-full" />
            <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[0.312rem] pl-[4.562rem] pr-[4.625rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
              <div className="h-[7.313rem] flex-1 relative">
                <div className="absolute top-[6.875rem] left-[0.563rem] [filter:blur(11.9px)] rounded-[50%] bg-dimgray-200 w-[6.188rem] h-[0.438rem] z-[1]" />
                <img
                  className="absolute top-[0rem] left-[0rem] w-full h-full object-cover z-[2]"
                  loading="lazy"
                  alt=""
                  src="/servera-2@2x.png"
                />
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.687rem] pr-[0.812rem]">
              <div className="flex-1 flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[1.562rem]">
                  <h2 className="m-0 relative text-inherit leading-[2rem] font-bold font-inherit z-[1] mq450:text-[1.188rem] mq450:leading-[1.625rem]">
                    Shared Hosting
                  </h2>
                </div>
                <div className="relative text-[0.875rem] leading-[1.5rem] z-[1]">
                  Cheap Shared hosting at low cost.
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.437rem] gap-[0.687rem] text-[0.875rem]">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
                <div className="relative leading-[1.5rem] whitespace-pre-wrap inline-block min-w-[4.813rem] z-[1]">
                  Starting at
                </div>
              </div>
              <div className="self-stretch relative leading-[3.813rem] z-[1] text-[2.25rem] mq450:text-[1.375rem] mq450:leading-[2.125rem] mq800:text-[1.813rem] mq800:leading-[2.813rem]">
                <span className="font-semibold">₹</span>
                <b className="text-[3rem]">70/</b>
                <span className="font-semibold">mo</span>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[1.312rem] text-[1rem] text-white">
              <div className="flex-1 rounded-3xs bg-darkslategray-100 flex flex-row items-start justify-between py-[1rem] pl-[4.187rem] pr-[3.687rem] gap-[1.25rem] z-[1]">
                <div className="h-[3.438rem] w-[13.875rem] relative rounded-3xs bg-darkslategray-100 hidden" />
                <div className="relative leading-[1.438rem] font-medium inline-block min-w-[3.625rem] z-[1]">
                  Explore
                </div>
                <div className="flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem]">
                  <img
                    className="w-[0.875rem] h-[0.856rem] relative z-[1]"
                    alt=""
                    src="/vector-431-stroke.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start pt-[0.062rem] px-[0rem] pb-[0rem] box-border min-w-[16.875rem] max-w-full">
            <div className="self-stretch rounded bg-white flex flex-col items-start justify-start pt-[2.812rem] px-[3rem] pb-[3.187rem] box-border gap-[1.375rem] max-w-full z-[2] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq800:pt-[1.813rem] mq800:pb-[2.063rem] mq800:box-border">
              <div className="w-[22.5rem] h-[33.438rem] relative rounded bg-white hidden max-w-full" />
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.687rem] pr-[3.75rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                <div className="flex-1 flex flex-row items-start justify-start relative">
                  <div className="h-[0.438rem] w-[6.188rem] absolute !m-[0] bottom-[0.75rem] left-[calc(50%_-_49.5px)] [filter:blur(11.9px)] rounded-[50%] bg-dimgray-200 z-[1]" />
                  <img
                    className="h-[9.063rem] w-[9.063rem] relative object-cover z-[2]"
                    loading="lazy"
                    alt=""
                    src="/3d-illustration-server-security-and-unlocka-2@2x.png"
                  />
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[0.437rem] pl-[1.437rem] pr-[1.562rem]">
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[2.125rem]">
                    <h2 className="m-0 relative text-inherit leading-[2rem] font-bold font-inherit z-[1] mq450:text-[1.188rem] mq450:leading-[1.625rem]">
                      VPS Hosting
                    </h2>
                  </div>
                  <div className="relative text-[0.875rem] leading-[1.5rem] z-[1]">
                    Cheap VPS hosting at low cost.
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.875rem] gap-[0.687rem] text-[0.875rem]">
                <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
                  <div className="relative leading-[1.5rem] whitespace-pre-wrap inline-block min-w-[4.813rem] z-[1]">
                    Starting at
                  </div>
                </div>
                <div className="self-stretch relative leading-[3.813rem] z-[1] text-[2.25rem] mq450:text-[1.375rem] mq450:leading-[2.125rem] mq800:text-[1.813rem] mq800:leading-[2.813rem]">
                  <span className="font-semibold">$</span>
                  <b className="text-[3rem]">5/</b>
                  <span className="font-semibold">mo</span>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[1.312rem] text-[1rem] text-white">
                <div className="flex-1 rounded-3xs bg-darkslategray-100 flex flex-row items-start justify-between py-[1rem] pl-[4.187rem] pr-[3.687rem] gap-[1.25rem] z-[1]">
                  <div className="h-[3.438rem] w-[13.875rem] relative rounded-3xs bg-darkslategray-100 hidden" />
                  <div className="relative leading-[1.438rem] font-medium inline-block min-w-[3.625rem] z-[1]">
                    Explore
                  </div>
                  <div className="flex flex-col items-start justify-start pt-[0.125rem] px-[0rem] pb-[0rem]">
                    <img
                      className="w-[0.875rem] h-[0.856rem] relative z-[1]"
                      alt=""
                      src="/vector-431-stroke.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start pt-[15.187rem] px-[0rem] pb-[0rem]">
          <img
            className="w-[2.75rem] h-[2.75rem] relative overflow-hidden shrink-0 z-[2]"
            alt=""
            src="/heroiconssolidchevronright-1.svg"
          />
        </div>
      </section>
    );
  }
);

export default FrameComponent;

import { FunctionComponent, memo } from "react";
import FeatureElements from "./FeatureElements";

export type MainType = {
  className?: string;
};

const Main: FunctionComponent<MainType> = memo(({ className = "" }) => {
  return (
    <div
      className={`w-[87rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full text-left text-[1rem] text-white font-poppins ${className}`}
    >
      <div className="w-[68.375rem] flex flex-row items-start justify-start gap-[5.75rem] max-w-full mq800:gap-[1.438rem] mq1150:flex-wrap mq1150:gap-[2.875rem]">
        <div className="h-[26.125rem] w-[24.5rem] flex flex-col items-start justify-start gap-[0.937rem] min-w-[24.5rem] max-w-full mq800:min-w-full mq1150:flex-1">
          <img
            className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
            loading="lazy"
            alt=""
            src="/cloud-computing-server-3d-illustrationa-1@2x.png"
          />
          <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[3.812rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
            <div className="h-[0.688rem] flex-1 relative [filter:blur(22.8px)] rounded-[50%] bg-gray-900" />
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start pt-[4rem] px-[0rem] pb-[0rem] box-border min-w-[24.75rem] max-w-full mq800:min-w-full">
          <div className="self-stretch flex flex-col items-start justify-start gap-[1.625rem] max-w-full">
            <div className="self-stretch flex flex-col items-start justify-start gap-[0.875rem]">
              <div className="self-stretch flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem]">
                  <div className="relative tracking-[0.11em] leading-[3.125rem] font-semibold z-[2]">
                    HOSTINGLASTING
                  </div>
                </div>
                <h1 className="m-0 relative text-[3rem] leading-[3.125rem] font-semibold font-inherit z-[1] mq800:text-[2.375rem] mq800:leading-[2.5rem] mq450:text-[1.813rem] mq450:leading-[1.875rem]">
                  Unmanaged VPS Hosting
                </h1>
              </div>
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.75rem] text-center text-[0.875rem] text-lightgray-100">
                <div className="relative leading-[1.25rem] font-medium z-[1]">
                  Best VPS Web Hosting
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[1.062rem] box-border max-w-full text-center text-[0.813rem] text-lightgray-100">
              <div className="flex-1 flex flex-col items-start justify-start gap-[2.062rem] max-w-full mq800:gap-[1rem]">
                <div className="self-stretch h-[0.438rem] flex flex-row items-start justify-start py-[0rem] px-[2.5rem] box-border max-w-full">
                  <div className="self-stretch flex-1 relative rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none [background:linear-gradient(89.99deg,_#5f2784,_#fff)] max-w-full z-[1]" />
                </div>
                <div className="self-stretch flex flex-row items-end justify-between gap-[1.25rem] mq800:flex-wrap">
                  <div className="flex flex-col items-start justify-start gap-[0.437rem]">
                    <div className="flex flex-row items-start justify-start py-[0rem] pl-[1.437rem] pr-[1.5rem]">
                      <img
                        className="h-[2.25rem] w-[2.25rem] relative overflow-hidden shrink-0 z-[1]"
                        loading="lazy"
                        alt=""
                        src="/mdiipoutline.svg"
                      />
                    </div>
                    <div className="relative leading-[1.25rem] font-medium inline-block min-w-[5.188rem] z-[1]">
                      Dedicated IP
                    </div>
                  </div>
                  <FeatureElements
                    carbonmanageProtection="/carbonmanageprotection.svg"
                    fullRootAccess="Full Root Access"
                  />
                  <div className="w-[15.563rem] flex flex-row items-end justify-start gap-[2.937rem]">
                    <FeatureElements
                      propPadding="unset"
                      propGap="0.5rem"
                      carbonmanageProtection="/riusersharedline.svg"
                      fullRootAccess="SSD Disk Drives"
                    />
                    <FeatureElements
                      propPadding="unset"
                      propGap="0.687rem"
                      carbonmanageProtection="/eosiconsip.svg"
                      fullRootAccess="4 GBPS Network"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Main;

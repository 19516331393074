import { FunctionComponent, memo } from "react";
import HeroSection2 from "./HeroSection2";
import Content from "./Content";

export type HeaderTop2Type = {
  className?: string;
};

const HeaderTop2: FunctionComponent<HeaderTop2Type> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[3.187rem] box-border max-w-full text-left text-[1rem] text-white font-poppins mq1150:pb-[2.063rem] mq1150:box-border mq450:pb-[1.313rem] mq450:box-border ${className}`}
      >
        <div className="flex-1 [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[1rem] box-border gap-[1.25rem] max-w-full">
          <HeroSection2 />
          <Content />
        </div>
      </section>
    );
  }
);

export default HeaderTop2;

import { FunctionComponent, memo } from "react";

export type QuestionItemType = {
  className?: string;
  whatIfTheBandwidthIAvaile?: string;
  inCaseYouRunOutOfBandwidt?: string;
  weWillNotSuspendYourServi?: string;
};

const QuestionItem: FunctionComponent<QuestionItemType> = memo(
  ({
    className = "",
    whatIfTheBandwidthIAvaile,
    inCaseYouRunOutOfBandwidt,
    weWillNotSuspendYourServi,
  }) => {
    return (
      <div
        className={`self-stretch flex flex-col items-start justify-start gap-[0.125rem] max-w-full text-left text-[0.875rem] text-black font-poppins ${className}`}
      >
        <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-between pt-[0.5rem] pb-[0.562rem] pl-[4.687rem] pr-[3.812rem] box-border max-w-full gap-[1.25rem] mq1050:pl-[2.313rem] mq1050:pr-[1.875rem] mq1050:box-border">
          <div className="h-[4rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
          <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium z-[1]">
            {whatIfTheBandwidthIAvaile}
          </div>
          <div className="flex flex-col items-start justify-start pt-[1.25rem] px-[0rem] pb-[0rem]">
            <img
              className="w-[0.75rem] h-[0.375rem] relative z-[1]"
              loading="lazy"
              alt=""
              src="/vector-337.svg"
            />
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full text-slategray-100">
          <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-end pt-[2rem] px-[2.25rem] pb-[1.937rem] box-border max-w-full">
            <div className="h-[6.063rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
            <div className="relative tracking-[0.04em] leading-[1.063rem] z-[1]">
              <p className="m-0">{inCaseYouRunOutOfBandwidt}</p>
              <p className="m-0">{weWillNotSuspendYourServi}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default QuestionItem;

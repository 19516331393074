import { FunctionComponent, memo } from "react";

export type FrameComponent51Type = {
  className?: string;
};

const FrameComponent51: FunctionComponent<FrameComponent51Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch rounded-t-11xl rounded-b-none bg-indigo-300 overflow-x-auto flex flex-row items-start justify-start pt-[1.937rem] px-[2.75rem] pb-[1.562rem] gap-[3.125rem] z-[2] text-center text-[0.875rem] text-white font-poppins mq800:gap-[1.563rem] mq1150:pl-[1.375rem] mq1150:pr-[1.375rem] mq1150:box-border ${className}`}
      >
        <div className="h-[4.75rem] w-[66.813rem] relative rounded-t-11xl rounded-b-none bg-indigo-300 shrink-0 hidden" />
        <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.125rem]">
          <div className="relative leading-[1.25rem] font-medium inline-block min-w-[2.188rem] z-[3]">
            PLAN
          </div>
        </div>
        <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.125rem]">
          <div className="relative leading-[1.25rem] font-medium inline-block min-w-[2.438rem] z-[3]">
            VCPU
          </div>
        </div>
        <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.062rem]">
          <div className="relative leading-[1.25rem] font-medium inline-block min-w-[1.938rem] z-[3]">
            RAM
          </div>
        </div>
        <div className="relative leading-[1.25rem] font-medium inline-block min-w-[5rem] z-[3]">
          DISK SPACE
        </div>
        <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.187rem]">
          <div className="relative leading-[1.25rem] font-medium inline-block min-w-[3.5rem] z-[3]">
            TRAFFIC
          </div>
        </div>
        <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[2.125rem]">
          <div className="relative leading-[1.25rem] font-medium inline-block min-w-[0.813rem] z-[3]">
            IP
          </div>
        </div>
        <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.312rem]">
          <div className="relative leading-[1.25rem] font-medium inline-block min-w-[4.938rem] z-[3]">
            LOCATIONS
          </div>
        </div>
        <div className="relative leading-[1.25rem] font-medium inline-block min-w-[2.5rem] z-[3]">
          PRICE
        </div>
      </div>
    );
  }
);

export default FrameComponent51;

import { FunctionComponent } from "react";
import HeaderTop2 from "../components/HeaderTop2";
import FrameComponent72 from "../components/FrameComponent72";
import VpsFeatureOne from "../components/VpsFeatureOne";
import FrameComponent61 from "../components/FrameComponent61";
import Plans from "../components/Plans";
import FrameComponent18 from "../components/FrameComponent18";
import GroupComponent7 from "../components/GroupComponent7";

const StorageVPS: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-end justify-start gap-[2.625rem] leading-[normal] tracking-[normal] mq800:gap-[1.313rem]">
      <HeaderTop2 />
      <div className="w-[0.188rem] h-[9.938rem] relative border-white border-r-[3px] border-solid box-border hidden" />
      <section className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[2.5rem] pl-[1.312rem] pr-[1.25rem] box-border max-w-full text-left text-[1.25rem] text-darkslategray-100 font-poppins">
        <div className="w-[60.875rem] flex flex-col items-start justify-start gap-[0.187rem] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem]">
            <h2 className="m-0 relative text-inherit tracking-[0.11em] leading-[3.125rem] font-medium font-inherit mq450:text-[1rem] mq450:leading-[2.5rem]">
              Choose the best and cheap storage servers VPS hosting
            </h2>
          </div>
          <div className="self-stretch relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
            Advance confidently by keeping huge data generated by your websites
            and online business safe and secure in our VPS cheap storage
            servers. Find the plan suitable to your requirements and choose
            accordingly.
          </div>
        </div>
      </section>
      <FrameComponent72 />
      <section className="self-stretch flex flex-row items-start justify-start relative max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins">
        <img
          className="h-[23.5rem] w-[66.063rem] absolute !m-[0] bottom-[-18.75rem] left-[calc(50%_-_528.5px)] object-cover"
          loading="lazy"
          alt=""
          src="/vps-features-1@2x.png"
        />
        <div className="flex-1 flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[8.75rem] box-border gap-[3.375rem] max-w-full mq800:gap-[1.688rem] mq800:pb-[2.375rem] mq800:box-border mq1350:pb-[3.688rem] mq1350:box-border">
          <VpsFeatureOne />
          <FrameComponent61 />
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem]">
            <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
              One click install OS Dockers
            </h3>
          </div>
        </div>
      </section>
      <img
        className="w-[19.375rem] h-[19.375rem] relative object-cover hidden"
        alt=""
        src="/1531135-4091-11@2x.png"
      />
      <section className="self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[5.5rem] box-border max-w-full">
        <div className="w-[57.188rem] flex flex-col items-end justify-start gap-[0.968rem] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[17.062rem] mq450:pr-[1.25rem] mq450:box-border">
            <div className="h-[0.313rem] w-[9.75rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
          </div>
          <div className="w-[6.063rem] h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
          <div className="self-stretch flex flex-row items-start justify-start max-w-full">
            <div className="w-[48.188rem] flex flex-row items-end justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
              <div className="h-[0.313rem] w-[6.063rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
              <div className="w-[16.875rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.687rem] box-border">
                <div className="self-stretch flex flex-row items-start justify-between gap-[1.25rem]">
                  <div className="w-[6.063rem] flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem] box-border">
                    <div className="self-stretch h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                  </div>
                  <div className="h-[0.313rem] w-[6.063rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-[87.563rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[3.375rem] box-border max-w-full">
        <div className="w-[55.938rem] flex flex-col items-start justify-start gap-[0.531rem] max-w-full">
          <div className="w-[9.688rem] h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
          <div className="self-stretch flex flex-row items-start justify-end">
            <div className="h-[0.313rem] w-[14.188rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
          </div>
          <div className="w-[48.625rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <div className="w-[19.875rem] flex flex-row items-end justify-between gap-[1.25rem]">
              <div className="w-[5.5rem] flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[0.687rem] box-border">
                <div className="self-stretch h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
              </div>
              <div className="w-[4.813rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.187rem] box-border">
                <div className="self-stretch h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
              </div>
              <div className="h-[0.313rem] w-[4.813rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
            </div>
          </div>
        </div>
      </section>
      <Plans />
      <FrameComponent18 />
      <section className="self-stretch shadow-[0px_-4px_4px_rgba(0,_0,_0,_0.25)_inset,_0px_7px_4px_rgba(0,_0,_0,_0.25)_inset] bg-darkslategray-100 flex flex-row items-start justify-between py-[3.437rem] pl-[17.062rem] pr-[11rem] box-border max-w-full gap-[1.25rem] text-left text-[1.5rem] text-white font-poppins mq800:pl-[8.5rem] mq800:pr-[5.5rem] mq800:box-border mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq1350:flex-wrap mq1350:justify-center">
        <div className="h-[13.25rem] w-[89.438rem] relative shadow-[0px_-4px_4px_rgba(0,_0,_0,_0.25)_inset,_0px_7px_4px_rgba(0,_0,_0,_0.25)_inset] bg-darkslategray-100 hidden max-w-full" />
        <div className="flex flex-col items-start justify-start gap-[0.375rem] min-w-[27.688rem] max-w-full mq1150:min-w-full mq1350:flex-1">
          <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit z-[1] mq450:text-[1.188rem] mq450:leading-[2.313rem]">
            Your best VPS hosting option is here:
          </h2>
          <div className="rounded-3xs bg-white flex flex-row items-start justify-start py-[0.125rem] px-[3.187rem] whitespace-nowrap z-[1] text-[1.125rem] text-black">
            <div className="h-[3.125rem] w-[11.25rem] relative rounded-3xs bg-white hidden" />
            <h3 className="m-0 relative text-inherit leading-[2.875rem] font-medium font-inherit inline-block min-w-[4.875rem] z-[2]">
              Buy Now
            </h3>
          </div>
        </div>
        <div className="w-[22.813rem] flex flex-col items-start justify-start pt-[1.75rem] px-[0rem] pb-[0rem] box-border max-w-full text-[4rem]">
          <h1 className="m-0 self-stretch relative text-inherit leading-[2.875rem] font-semibold font-inherit z-[1] mq800:text-[3.188rem] mq800:leading-[2.313rem] mq450:text-[2.375rem] mq450:leading-[1.75rem]">
            $8/month*
          </h1>
        </div>
      </section>
      <GroupComponent7 />
    </div>
  );
};

export default StorageVPS;

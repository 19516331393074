import { FunctionComponent, memo } from "react";

export type SecurityType = {
  className?: string;
};

const Security: FunctionComponent<SecurityType> = memo(({ className = "" }) => {
  return (
    <div
      className={`self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[0.375rem] pl-[1.375rem] pr-[1.25rem] box-border max-w-full text-left text-[0.875rem] text-darkslategray-100 font-poppins ${className}`}
    >
      <div className="w-[66.688rem] flex flex-col items-start justify-start gap-[3.125rem] max-w-full mq750:gap-[1.563rem]">
        <div className="self-stretch h-[0.125rem] flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.312rem] box-border max-w-full">
          <div className="self-stretch w-[28.75rem] relative rounded-8xs bg-indigo-100 max-w-full z-[1]" />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[1.187rem] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.312rem]">
            <h2 className="relative leading-[2.875rem] font-semibold z-[1]">
              Malware Protection Tools to eliminate the threats posed by
              cyber-attacks.
            </h2>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[1.375rem] max-w-full text-[0.75rem]">
            <div className="self-stretch rounded-mini border-indigo-100 border-[1px] border-solid box-border flex flex-row flex-wrap items-start justify-center py-[0.75rem] pl-[2.437rem] pr-[1.375rem] gap-[1.625rem] max-w-full z-[2]">
              <div className="flex flex-row items-start justify-start relative">
                <div className="h-[2.313rem] w-[10.5rem] absolute !m-[0] bottom-[0.25rem] left-[-1.25rem] rounded-3xs bg-indigo-200" />
                <div className="relative leading-[2.875rem] font-semibold z-[1]">
                  Unlimited SSD Storage
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start pt-[0.812rem] px-[0rem] pb-[0rem] box-border min-w-[34.125rem] max-w-full text-center text-slategray-100 mq750:min-w-full">
                <div className="relative leading-[1.25rem]">
                  Hostlasting deploys premium SSD hosting platform with
                  unlimited storage capacities. This eliminates the concerns
                  regarding loading speed.
                </div>
              </div>
              <div className="h-[4.625rem] w-[66.625rem] relative rounded-mini border-indigo-100 border-[1px] border-solid box-border hidden max-w-full" />
            </div>
            <div className="self-stretch rounded-mini border-indigo-100 border-[1px] border-solid box-border flex flex-row items-start justify-start py-[0.75rem] px-[1.25rem] gap-[0.937rem] max-w-full z-[2] mq1050:flex-wrap mq1050:justify-center">
              <div className="h-[2.875rem] w-[10.5rem] relative">
                <div className="absolute top-[0.313rem] left-[0rem] rounded-3xs bg-indigo-200 w-[10.5rem] h-[2.313rem]" />
                <div className="absolute top-[0rem] left-[2.125rem] leading-[2.875rem] font-semibold inline-block min-w-[6.313rem] z-[1]">
                  Weekly Backups
                </div>
              </div>
              <div className="flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem] box-border max-w-full text-slategray-100">
                <div className="relative leading-[1.25rem]">
                  <p className="m-0">{`To ensure your data is safe and can be recovered in case of unanticipated attacks and threats, `}</p>
                  <p className="m-0">
                    we create weekly backups of your data with JetBackup.
                  </p>
                </div>
              </div>
              <div className="h-[4.625rem] w-[66.625rem] relative rounded-mini border-indigo-100 border-[1px] border-solid box-border hidden max-w-full" />
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
              <div className="flex-1 rounded-mini border-indigo-100 border-[1px] border-solid box-border flex flex-row items-end justify-start py-[0.75rem] px-[2.5rem] gap-[1.812rem] max-w-full z-[2] mq1050:flex-wrap">
                <div className="flex flex-row items-start justify-start relative">
                  <div className="h-[2.313rem] w-[15.563rem] absolute !m-[0] bottom-[0.25rem] left-[-1.25rem] rounded-3xs bg-indigo-200" />
                  <div className="relative leading-[2.875rem] font-semibold z-[1]">
                    Around the clock expert assistance
                  </div>
                </div>
                <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.125rem] box-border max-w-full text-slategray-100">
                  <div className="relative leading-[1.25rem]">
                    <p className="m-0">{`A team of experts and highly skilled professionals is at your disposal to solve all of your queries at any time. `}</p>
                    <p className="m-0">
                      Our team of experts and professionals is available for you
                      through chats, emails, phone calls, and WhatsApp.
                    </p>
                  </div>
                </div>
                <div className="h-[4.625rem] w-[66.625rem] relative rounded-mini border-indigo-100 border-[1px] border-solid box-border hidden max-w-full" />
              </div>
            </div>
            <div className="self-stretch rounded-mini border-indigo-100 border-[1px] border-solid box-border flex flex-row items-end justify-start pt-[0.75rem] px-[2.5rem] pb-[0.687rem] gap-[1.937rem] max-w-full z-[2] mq450:gap-[0.938rem] mq1050:flex-wrap">
              <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.062rem]">
                <div className="flex flex-row items-start justify-start relative">
                  <div className="h-[2.313rem] w-[12.313rem] absolute !m-[0] bottom-[0.25rem] left-[-1.25rem] rounded-3xs bg-indigo-200" />
                  <div className="relative leading-[2.875rem] font-semibold z-[1]">
                    Strong security and safety
                  </div>
                </div>
              </div>
              <div className="relative leading-[1.25rem] text-slategray-100 inline-block max-w-full">
                <p className="m-0">{`With multi-layer protection in place and implementation of RAID technology, `}</p>
                <p className="m-0">
                  we take utmost precautions and implement advanced security
                  measures to keep your data safe and secure.
                </p>
              </div>
              <div className="h-[4.625rem] w-[66.625rem] relative rounded-mini border-indigo-100 border-[1px] border-solid box-border hidden max-w-full" />
            </div>
            <div className="self-stretch rounded-mini border-indigo-100 border-[1px] border-solid box-border flex flex-row items-start justify-start py-[0.75rem] px-[1.25rem] gap-[0.937rem] max-w-full z-[2] mq1050:flex-wrap">
              <div className="h-[2.875rem] w-[12.313rem] relative">
                <div className="absolute top-[0.313rem] left-[0rem] rounded-3xs bg-indigo-200 w-[12.313rem] h-[2.313rem]" />
                <div className="absolute top-[0rem] left-[1.938rem] leading-[2.875rem] font-semibold z-[1]">
                  Licensed cPanel/WHM
                </div>
              </div>
              <div className="flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem] box-border max-w-full text-slategray-100">
                <div className="relative leading-[1.25rem]">
                  <p className="m-0">
                    With availability of licensed cPanel/WHM with all of our
                    plans, you will avail an entire access to the control
                  </p>
                  <p className="m-0">
                    {" "}
                    dashboard to manage website and data over the server.
                  </p>
                </div>
              </div>
              <div className="h-[4.625rem] w-[66.625rem] relative rounded-mini border-indigo-100 border-[1px] border-solid box-border hidden max-w-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Security;

import { FunctionComponent } from "react";
import HeaderHeroDedicatedHosting from "../components/HeaderHeroDedicatedHosting";
import PricePanelDedicatedHosting from "../components/PricePanelDedicatedHosting";
import DedicatedServerSpecific from "../components/DedicatedServerSpecific";
import OperatingSystems from "../components/OperatingSystems";
import AdvantageDedicatedServer from "../components/AdvantageDedicatedServer";
import ImportantFeatureOnDedicatedServer from "../components/ImportantFeatureOnDedicatedServer";
import ContactForDedicationServer from "../components/ContactForDedicationServer";
import TryFreePanelDedicatedServer from "../components/TryFreePanelDedicatedServer";
import FooterComponent from "../components/FooterComponent";

const DedicatedHosting: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[0.062rem] leading-[normal] tracking-[normal] text-left text-[1.5rem] text-darkslategray-100 font-poppins">
      <HeaderHeroDedicatedHosting />
      <div className="w-[0.188rem] h-[9.938rem] relative border-white border-r-[3px] border-solid box-border hidden" />
      <section className="self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[10.687rem] box-border max-w-full text-left text-[1.25rem] text-darkslategray-100 font-poppins mq800:pb-[6.938rem] mq800:box-border">
        <div className="w-[66.563rem] flex flex-col items-start justify-start max-w-full">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem]">
            <h2 className="relative leading-[2.875rem] font-semibold mq450:text-[1rem] mq450:leading-[2.313rem]">
              Why You Should Buy Indian Dedicated server hosting from
              Hostlasting?
            </h2>
          </div>
          <div className="self-stretch relative text-[0.875rem] leading-[1.25rem] text-slategray-100">
            <p className="m-0">
              Hostlasting is a perfect software orientation program that is
              necessary for providing the comprehensive line up for the
              dedicated server base. The entry-level to the standard level
              application of the orientation program would provide a managed
              software orientation management program. The software is a
              one-stop solution that provides many other opportunities with the
              creation of provision that would enable the entitlement of the
              software integration process. The Linux Operating System provides
              a reliable software base that can be optimized digitally and used
              in a variety of processes for further integration of the system
              software. 
            </p>
            <p className="m-0">
              The Software Integration offers full control over your Account
              Details. This would mean that your account will be integrated and
              maintained in a streamlined and uniform manner so that better
              prospects can be evaluated and the software can function more
              efficiently. Getting hold of your account details would also
              enable us to maintain account integrity and data safety so that
              your details are not leaked due to lack of technological
              implementation. 
            </p>
            <p className="m-0">
              India is a huge technological base for the company as it does not
              only offers the opportunity of a huge clientele base but also
              provides adequate provision of software services that if
              efficiently utilised can bring about desirable results through the
              application of the process. Thus Hostlasting can help in bringing
              better management operation of services and can implement the
              techniques associated with the functioning of the server-based
              integration management program.
            </p>
          </div>
        </div>
      </section>
      <PricePanelDedicatedHosting />
      <DedicatedServerSpecific />
      <OperatingSystems />
      <section className="self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[4.437rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins">
        <div className="w-[60.063rem] flex flex-col items-start justify-start gap-[0.937rem] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
            <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
              Can you explain what a dedicated server is?
            </h2>
          </div>
          <div className="self-stretch relative text-[0.938rem] leading-[1.25rem] text-slategray-100 text-center">
            <p className="m-0">
              A dedicated server is a single web server in a company that is
              filled with computer system access from a data centre. Dedicated
              servers are utilized by the maximum capability to create multiples
              little servers for your clientele.
            </p>
            <p className="m-0">
              If you're a newcomer in a dedicated server market in India, we
              must give you the top Indian dedicated server supplier in web
              hosting business. The Hostlasting is the only supplier to buy a
              dedicated server in India at the cheapest amount. We deliver web
              server together with the highest quality of hardware and a secure
              and robust server environment. You will find the complete root
              server access on your hand with higher reliability expertise,
              higher functionality flexibility in setup, and simple configuring
              your web server.
            </p>
          </div>
        </div>
      </section>
      <AdvantageDedicatedServer />
      <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[3.625rem]">
        <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
          Important Features
        </h2>
      </div>
      <ImportantFeatureOnDedicatedServer />
      <ContactForDedicationServer />
      <TryFreePanelDedicatedServer />
      <FooterComponent
        hOSTINGMargin="unset"
        dOMAINSSSLMargin="unset"
        cOMPANYMargin="unset"
        wordPressHostingTextDecoration="none"
        aboutUsTextDecoration="none"
        cheapSSDHostingTextDecoration="unset"
        supportTextDecoration="none"
        vPSHostingTextDecoration="unset"
        blogTextDecoration="none"
        dedicatedHostingTextDecoration="unset"
        offersTextDecoration="unset"
        contactUsTextDecoration="none"
        privacyPolicyTextDecoration="none"
        sitemapTextDecoration="unset"
      />
    </div>
  );
};

export default DedicatedHosting;

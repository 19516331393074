import { FunctionComponent, memo } from "react";

export type FeatureOfBusinessEmailType = {
  className?: string;
};

const FeatureOfBusinessEmail: FunctionComponent<FeatureOfBusinessEmailType> =
  memo(({ className = "" }) => {
    return (
      <div
        className={`w-[81.875rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full text-left text-[3rem] text-white font-poppins ${className}`}
      >
        <div className="w-[59.25rem] flex flex-col items-start justify-start gap-[3.312rem] max-w-full mq750:gap-[1.625rem]">
          <div className="self-stretch flex flex-row items-start justify-end max-w-full">
            <div className="w-[51.313rem] flex flex-col items-start justify-start gap-[3rem] max-w-full mq450:gap-[1.5rem]">
              <h1 className="m-0 self-stretch relative text-inherit tracking-[0.11em] leading-[1.5rem] font-semibold font-inherit z-[2] mq450:text-[1.813rem] mq450:leading-[0.875rem] mq1050:text-[2.375rem] mq1050:leading-[1.188rem]">
                Best Business Email Hosting
              </h1>
              <div className="self-stretch h-[0.563rem] flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.625rem] box-border max-w-full">
                <div className="self-stretch w-[38.438rem] relative rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none [background:linear-gradient(89.99deg,_#66318a,_#fff)] max-w-full z-[2]" />
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start gap-[0.518rem] text-center text-[1rem]">
            <div className="flex flex-col items-start justify-start pt-[1rem] px-[0rem] pb-[0rem]">
              <img
                className="w-[1.5rem] h-[1.5rem] relative overflow-hidden shrink-0 z-[2]"
                loading="lazy"
                alt=""
                src="/icroundhome.svg"
              />
            </div>
            <div className="relative leading-[3.5rem] font-medium text-plum inline-block min-w-[3rem] z-[2]">
              Home
            </div>
            <div className="relative text-[1.25rem] leading-[3.5rem] font-medium inline-block min-w-[0.688rem] z-[2] mq450:text-[1rem] mq450:leading-[2.813rem]">
              /
            </div>
            <div className="relative leading-[3.5rem] font-medium z-[2]">
              Business Email Hosting
            </div>
          </div>
        </div>
      </div>
    );
  });

export default FeatureOfBusinessEmail;

import { FunctionComponent, memo } from "react";
import GroupComponent31 from "./GroupComponent31";

export type PricingType = {
  className?: string;
};

const Pricing: FunctionComponent<PricingType> = memo(({ className = "" }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[0.875rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins ${className}`}
    >
      <div className="w-[66.813rem] flex flex-col items-end justify-start gap-[1.187rem] max-w-full">
        <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[2.25rem] pl-[1.312rem] pr-[1.25rem]">
          <div className="flex flex-col items-start justify-start gap-[0.187rem]">
            <div className="flex flex-row items-start justify-start py-[0rem] pl-[1.812rem] pr-[1.875rem]">
              <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">{`Plans & Pricing`}</h2>
            </div>
            <div className="relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
              Choose from our affordable plans.
            </div>
          </div>
        </div>
        <div className="self-stretch h-[44rem] relative max-w-full text-center text-[0.875rem] text-white">
          <div className="absolute top-[0rem] left-[0rem] rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] w-full flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[10.625rem] box-border gap-[1.937rem] max-w-full mq800:gap-[0.938rem]">
            <div className="self-stretch h-[40.438rem] relative rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] hidden" />
            <div className="self-stretch rounded-t-11xl rounded-b-none bg-indigo-300 overflow-x-auto flex flex-row items-start justify-start pt-[1.937rem] px-[4.625rem] pb-[1.562rem] gap-[4.687rem] z-[1] mq450:gap-[1.188rem] mq1150:gap-[2.313rem] mq1150:pl-[2.313rem] mq1150:pr-[2.313rem] mq1150:box-border">
              <div className="h-[4.75rem] w-[66.813rem] relative rounded-t-11xl rounded-b-none bg-indigo-300 shrink-0 hidden" />
              <div className="w-[8.688rem] shrink-0 flex flex-col items-start justify-start">
                <div className="relative leading-[1.25rem] font-medium inline-block min-w-[2.188rem] z-[2]">
                  PLAN
                </div>
              </div>
              <div className="w-[4.563rem] shrink-0 flex flex-col items-start justify-start">
                <div className="relative leading-[1.25rem] font-medium inline-block min-w-[1.813rem] z-[2]">
                  CPU
                </div>
              </div>
              <div className="relative leading-[1.25rem] font-medium inline-block min-w-[1.938rem] z-[2]">
                RAM
              </div>
              <div className="relative leading-[1.25rem] font-medium inline-block min-w-[4.063rem] z-[2]">
                STORAGE
              </div>
              <div className="relative leading-[1.25rem] font-medium inline-block min-w-[5.25rem] z-[2]">
                BANDWIDTH
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.937rem] pr-[0.75rem] box-border max-w-full text-[0.813rem] text-slategray-100">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
                <GroupComponent31
                  vPSS2="VPS-S2"
                  x240GHzCPU="1 x 2.40GHz CPU"
                  gB="2 GB"
                  gB1="20 GB"
                  tB="2 TB"
                  mo="$4/mo"
                />
                <GroupComponent31
                  vPSS2="VPS-S4"
                  x240GHzCPU="1 x 2.40GHz CPU"
                  gB="4 GB"
                  gB1="40 GB"
                  tB="4 TB"
                  mo="$8/mo"
                />
                <GroupComponent31
                  vPSS2="VPS-S8"
                  x240GHzCPU="2 x 2.40GHz CPU"
                  gB="8 GB"
                  gB1="80 GB"
                  tB="8 TB"
                  mo="$13/mo"
                />
                <GroupComponent31
                  vPSS2="VPS-S16"
                  x240GHzCPU="4 x 2.40GHz CPU"
                  gB="16 GB"
                  gB1="160 GB"
                  tB="16 TB"
                  mo="$21/mo"
                />
              </div>
            </div>
          </div>
          <div className="absolute top-[31rem] left-[24.938rem] w-[17rem] h-[13rem] bg-[url('/public/6663bcc18d0b4f41a07e5b091e66916a-1a-2@2x.png')] bg-cover bg-no-repeat bg-[top] z-[3]">
            <img
              className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
              alt=""
              src="/6663bcc18d0b4f41a07e5b091e66916a-1a-2@2x.png"
            />
            <div className="absolute top-[11.625rem] left-[6.313rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[3rem] pr-[2.937rem] box-border max-w-full mq1150:pl-[1.5rem] mq1150:pr-[1.438rem] mq1150:box-border">
          <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem] max-w-full">
            <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.312rem]">
              <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
                What is VPS hosting?
              </h2>
            </div>
            <div className="self-stretch relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
              VPS hosting simulates the environment of the dedicated server in a
              shared server. It is affordable as compared to dedicated server
              hosting. Moreover, it offers superior performance, reliability,
              and security in comparison to shared hosting. Users can avail the
              access to the root, enabling them a freedom to install
              applications and carry out changes without getting in touch with
              the hosting provider, which is a case with shared hosting. VPS
              hosting should be chosen for secure and affordable hosting.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Pricing;

import { FunctionComponent, memo } from "react";
import HeaderPrivacyPolicy from "./HeaderPrivacyPolicy";
import FrameComponent111 from "./FrameComponent111";

export type SSDHeaderHeroSectionType = {
  className?: string;
};

const SSDHeaderHeroSection: FunctionComponent<SSDHeaderHeroSectionType> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[1.812rem] box-border max-w-full text-left text-[0.875rem] text-white font-poppins ${className}`}
      >
        <div className="flex-1 [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] overflow-hidden flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1rem] box-border gap-[1.687rem] max-w-full">
          <HeaderPrivacyPolicy />
          <FrameComponent111 />
        </div>
      </div>
    );
  }
);

export default SSDHeaderHeroSection;

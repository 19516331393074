import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type FrameComponent112Type = {
  className?: string;
  whatExactlyIsCyberPanel?: string;
  vector335?: string;

  /** Style props */
  propFlex?: CSSProperties["flex"];
  propAlignSelf?: CSSProperties["alignSelf"];
};

const FrameComponent112: FunctionComponent<FrameComponent112Type> = memo(
  ({
    className = "",
    propFlex,
    propAlignSelf,
    whatExactlyIsCyberPanel,
    vector335,
  }) => {
    const frameDiv11Style: CSSProperties = useMemo(() => {
      return {
        flex: propFlex,
        alignSelf: propAlignSelf,
      };
    }, [propFlex, propAlignSelf]);

    return (
      <div
        className={`flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-between pt-[0.5rem] pb-[0.562rem] pl-[4.687rem] pr-[3.812rem] box-border max-w-full gap-[1.25rem] text-left text-[0.875rem] text-black font-poppins mq1050:pl-[2.313rem] mq1050:pr-[1.875rem] mq1050:box-border ${className}`}
        style={frameDiv11Style}
      >
        <div className="h-[4rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
        <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium z-[1]">
          {whatExactlyIsCyberPanel}
        </div>
        <div className="flex flex-col items-start justify-start pt-[1.25rem] px-[0rem] pb-[0rem]">
          <img
            className="w-[0.75rem] h-[0.375rem] relative object-contain z-[1]"
            alt=""
            src={vector335}
          />
        </div>
      </div>
    );
  }
);

export default FrameComponent112;

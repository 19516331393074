import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type OperatingSystemsOptionsType = {
  className?: string;
  deviconPlaincentos?: string;
  centOS?: string;

  /** Style props */
  propPadding?: CSSProperties["padding"];
};

const OperatingSystemsOptions: FunctionComponent<OperatingSystemsOptionsType> =
  memo(({ className = "", propPadding, deviconPlaincentos, centOS }) => {
    const operatingSystemsOptionsStyle: CSSProperties = useMemo(() => {
      return {
        padding: propPadding,
      };
    }, [propPadding]);

    return (
      <div
        className={`flex flex-col items-end justify-start py-[0rem] pl-[0rem] pr-[2.937rem] gap-[0.812rem] text-center text-[0.875rem] text-white font-poppins ${className}`}
        style={operatingSystemsOptionsStyle}
      >
        <div className="flex flex-row items-start justify-end py-[0rem] pl-[0.125rem] pr-[0.062rem]">
          <img
            className="h-[3.125rem] w-[3.125rem] relative overflow-hidden shrink-0 z-[1]"
            loading="lazy"
            alt=""
            src={deviconPlaincentos}
          />
        </div>
        <div className="relative leading-[1.25rem] inline-block min-w-[3.313rem] z-[1]">
          {centOS}
        </div>
      </div>
    );
  });

export default OperatingSystemsOptions;

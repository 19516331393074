import { FunctionComponent, memo } from "react";
import QuestionContent from "./QuestionContent";
import FaqKVM from "./FaqKVM";
import QuestionItems from "./QuestionItems";
import FrameComponent24 from "./FrameComponent24";

export type FaqSSLType = {
  className?: string;
};

const FaqSSL: FunctionComponent<FaqSSLType> = memo(({ className = "" }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[5.625rem] pl-[1.437rem] pr-[1.25rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins lg:pb-[3.625rem] lg:box-border mq750:pb-[1.563rem] mq750:box-border mq1050:pb-[2.375rem] mq1050:box-border ${className}`}
    >
      <div className="w-[66.875rem] flex flex-col items-start justify-start gap-[2.312rem] max-w-full mq750:gap-[1.125rem]">
        <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[3.062rem] pr-[1.25rem] mq450:pl-[1.25rem] mq450:box-border">
          <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
            FREQUENTLY ASKED QUESTIONS
          </h2>
        </div>
        <div className="self-stretch flex flex-col items-end justify-start gap-[0.687rem] max-w-full text-[0.875rem] text-black">
          <div className="self-stretch flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
            <QuestionContent
              isThereAnyNeedForSSLForTh="1.Is there any need for SSL for the websites?"
              cpanelFunction="/vector-335.svg"
            />
            <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[0.937rem] pb-[0.875rem] pl-[4.687rem] pr-[4.562rem] box-border max-w-full text-slategray-100 mq1050:pl-[2.313rem] mq1050:pr-[2.25rem] mq1050:box-border">
              <div className="h-[5.938rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
              <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                SSL is a major backbone of internet security and ensure
                protection of data. As your sensitive information circulates
                through the world's different computer networks, SSL becomes a
                major factor to protect your site. Though there is no facility
                to manage sensitive information such as credit cards, it
                provides data integrity, privacy, and critical security for the
                websites and individual information of the user. We will help
                you avail cheap SSL Certificates as per your needs.
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[0.125rem] max-w-full text-slategray-100">
            <QuestionContent
              isThereAnyNeedForSSLForTh="2.How SSL provides assistance in achieving PCI Compliance?"
              cpanelFunction="/vector-335.svg"
            />
            <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-end py-[2.062rem] px-[3.125rem] box-border max-w-full mq1050:pl-[1.563rem] mq1050:pr-[1.563rem] mq1050:box-border">
              <div className="h-[6.25rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
              <div className="relative tracking-[0.04em] leading-[1.063rem] z-[1]">
                <p className="m-0">{`To accept credit card information on your website, you have to pass particular audits that reveal that you are complying `}</p>
                <p className="m-0">
                  with all the Payment Card Industry (PCI) standards. By
                  fulfilling those requirements, you can correctly utilize a SSL
                  Certificate.
                </p>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
            <QuestionContent
              isThereAnyNeedForSSLForTh="3.How to choose the right SSL Certificate? How will it be the right encryption mechanism?"
              cpanelFunction="/vector-337.svg"
            />
            <FaqKVM
              propPadding="2rem 1.75rem"
              hostlastingProvidesThreeT="Even if you're not a professional server administrator, you can easily select your SSL Certificate type foundation based on your "
              thesePlansIncludeVPSL2VPS="client base, kind of company, the importance of data transmitted, the domain name you want to pay (Multi-Domain certificate),"
              andMalwareProtectionTheCh=" EV (Extended Validation) Certificates, and several others. Please get in contact with us on live chat to learn more. We will help "
              theCostOfEachPlanVariesWi="you in obtaining cheap SSL Certificates."
            />
          </div>
          <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.187rem] pr-[0.062rem] box-border max-w-full text-slategray-100">
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
              <QuestionContent
                isThereAnyNeedForSSLForTh="4.Do I need a dedicated IP for the SSL certificate? How is the certification provided beneficial?"
                cpanelFunction="/vector-337.svg"
              />
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
                <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-end pt-[1.812rem] px-[0.062rem] pb-[2.437rem] box-border max-w-full">
                  <div className="h-[7.438rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="w-[61.875rem] relative tracking-[0.04em] leading-[1.063rem] flex items-center shrink-0 max-w-full z-[1]">
                    Yes, to set up an SSL certificate, you need Dedicated IP
                    since the SSL is the private property and will need to
                    install on a particular IP that only you own. In case you
                    are installing SSL in your own VPS/Dedicated server and
                    would like to place it had been your shared SSL, you do not
                    require different IP.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[0.125rem] max-w-full text-slategray-100">
            <QuestionContent
              isThereAnyNeedForSSLForTh="5.What do you mean by Certificate Signing Request (CSR)? What are its other associated concepts?"
              cpanelFunction="/vector-337.svg"
            />
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
              <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[2.062rem] pl-[4.312rem] pr-[4.062rem] box-border max-w-full mq1050:pl-[2.125rem] mq1050:pr-[2rem] mq1050:box-border">
                <div className="h-[7.313rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                <div className="relative tracking-[0.04em] leading-[1.063rem] z-[1]">
                  <p className="m-0">
                    A Certificate Signing Request (CSR) constitutes a block of
                    encoded text, offered to the Certificate Authority when you
                    are
                  </p>
                  <p className="m-0">
                    sending your application for an SSL Certificate. It is
                    generally created on the host. The certificate will be set
                    up and contains
                  </p>
                  <p className="m-0">
                    information in the certificate such as the company name,
                    common name (domain name), area, and country.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <QuestionItems
            whatIsTheProcedureToCreat="6.What is the procedure to creating a CSR along with private key?"
            theCPanelContainsFunction="The cPanel contains functions to create a CSR along with private key. If you were uncertain how to make it on, you could"
            alwaysTakeSupportFromOur2="always take support from our 24x7 Technical service staff."
          />
          <div className="self-stretch flex flex-row items-start justify-end py-[0rem] px-[0.125rem] box-border max-w-full">
            <FrameComponent24
              whatAreTheWaysInWhichSSLM="7.What are the ways in which SSL makes the website more secure, reliable and trustworthy?"
              anSSLCertificateContainsV="An SSL certificate contains verified information about the website and confirms users that there is a secure communication"
              betweenThemAndTheWebsiteE="between them and the website. Extended Validation is the industry's highest quality of the verification and supplies the"
              maximumObservableAssuranc="maximum observable assurance for customers. Making website secure is possible as we help you in getting cheap SSL"
              certificatesAccordingToYo="Certificates according to your need."
            />
          </div>
          <FrameComponent24
            propFlex="unset"
            propAlignSelf="stretch"
            whatAreTheWaysInWhichSSLM="8.Please explain the functionality of Extended Validation."
            anSSLCertificateContainsV="You can see the address bar as it turns green for high-security browsers. This assures that this site has a legitimate EV SSL"
            betweenThemAndTheWebsiteE="Certificate. When you go to the page through HTTPS, your internet browser will automatically turn into the address bar green."
            maximumObservableAssuranc="Additionally, on the ideal hand side of the address bar, you will find a box that alternates between your legal firm names along"
            certificatesAccordingToYo="with the certificate authority that issued the EV Certificate."
          />
          <QuestionItems
            whatIsTheProcedureToCreat="8.How to reissue the SSL Certificate and use it properly?"
            theCPanelContainsFunction="For reissuance of the SSL certificate, kindly get in touch with our support team. They will help for you to reissue as they "
            alwaysTakeSupportFromOur2="validate the ownership of the single account."
          />
        </div>
      </div>
    </section>
  );
});

export default FaqSSL;

import { FunctionComponent, memo } from "react";
import FrameComponent121 from "./FrameComponent121";

export type FrameComponent111Type = {
  className?: string;
};

const FrameComponent111: FunctionComponent<FrameComponent111Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-start py-[0rem] px-[3.375rem] box-border max-w-full text-center text-[1rem] text-white font-poppins mq750:pl-[1.688rem] mq750:pr-[1.688rem] mq750:box-border ${className}`}
      >
        <div className="w-[82.125rem] flex flex-row flex-wrap items-start justify-start gap-[6.375rem] max-w-full mq450:gap-[1.563rem] mq750:gap-[3.188rem]">
          <div className="flex-1 flex flex-col items-start justify-start pt-[6.5rem] px-[0rem] pb-[0rem] box-border max-w-full mq750:pt-[4.25rem] mq750:box-border mq750:min-w-full">
            <div className="self-stretch flex flex-col items-start justify-start max-w-full">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[25.437rem] pr-[1.25rem] text-left mq450:pl-[1.25rem] mq450:box-border mq750:pl-[12.688rem] mq750:box-border">
                <div className="relative tracking-[0.11em] leading-[3.125rem] font-semibold z-[1]">
                  HOSTINGLASTING
                </div>
              </div>
              <div className="w-[53rem] flex flex-row items-start justify-start relative max-w-full text-[0.875rem] text-lightgray-100">
                <div className="absolute !m-[0] right-[-18.437rem] bottom-[0.438rem] leading-[1.25rem] font-medium z-[2]">
                  <p className="m-0">
                    A LITTLE MONEY GETS YOU LOTS OF CLOUD CLOUD SERVERS STARTING
                    AT $5/ month
                  </p>
                  <p className="m-0">
                    Hostlasting is expanding in the US and is now offering cloud
                    servers with AMD processors in Ashburn, Virginia, and
                    Hillsboro, Oregon, USA!
                  </p>
                </div>
                <div className="flex-1 flex flex-row items-start justify-end pt-[0rem] px-[0rem] pb-[4.687rem] box-border max-w-full text-left text-[3rem] text-white">
                  <h1 className="relative leading-[3.125rem] font-semibold z-[1] mq450:text-[1.813rem] mq450:leading-[1.875rem] mq750:text-[2.375rem] mq750:leading-[2.5rem]">
                    SSD Cloud VPS
                  </h1>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.437rem] box-border gap-[3.625rem] max-w-full text-[0.813rem] text-lightgray-100 mq750:gap-[1.813rem] mq1100:flex-wrap">
                <img
                  className="w-[20.5rem] relative max-h-full object-contain max-w-full z-[1] mq1100:flex-1"
                  alt=""
                  src="/04-cloud-server-icona-1@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start pt-[1.5rem] px-[0rem] pb-[0rem] box-border min-w-[22.438rem] max-w-full mq750:min-w-full">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[5.187rem] max-w-full mq450:gap-[1.313rem] mq750:gap-[2.563rem]">
                    <div className="w-[31.625rem] h-[0.438rem] flex flex-row items-start justify-start py-[0rem] px-[0.312rem] box-border max-w-full">
                      <div className="self-stretch flex-1 relative rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none [background:linear-gradient(90deg,_#6e3b8f,_#fff)] max-w-full z-[1]" />
                    </div>
                    <div className="self-stretch flex flex-row items-end justify-between gap-[1.25rem] mq450:flex-wrap">
                      <div className="flex flex-col items-start justify-start gap-[0.437rem]">
                        <div className="flex flex-row items-start justify-start py-[0rem] px-[1.5rem]">
                          <img
                            className="h-[2.25rem] w-[2.25rem] relative overflow-hidden shrink-0 z-[1]"
                            alt=""
                            src="/mdiipoutline.svg"
                          />
                        </div>
                        <div className="relative leading-[1.25rem] font-medium inline-block min-w-[5.25rem] z-[1]">
                          FLOATING IPs
                        </div>
                      </div>
                      <FrameComponent121
                        carbonmanageProtection="/carbonmanageprotection.svg"
                        dDosProtection="DDos Protection"
                      />
                      <FrameComponent121
                        propGap="0.5rem"
                        carbonmanageProtection="/riusersharedline.svg"
                        dDosProtection="Shared/ Dedi IPs"
                      />
                      <div className="flex flex-col items-end justify-start gap-[0.687rem]">
                        <div className="flex flex-row items-start justify-end py-[0rem] pl-[0.562rem] pr-[0.5rem]">
                          <img
                            className="h-[2.25rem] w-[2.25rem] relative overflow-hidden shrink-0 z-[1]"
                            alt=""
                            src="/eosiconsip.svg"
                          />
                        </div>
                        <div className="relative leading-[1.25rem] font-medium inline-block min-w-[3.313rem] z-[1]">
                          IP v4/v6
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[34.125rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                <div className="flex flex-row items-start justify-start gap-[0.543rem] mq450:flex-wrap">
                  <div className="flex flex-col items-start justify-start pt-[1rem] px-[0rem] pb-[0rem]">
                    <img
                      className="w-[1.5rem] h-[1.5rem] relative overflow-hidden shrink-0 z-[1]"
                      alt=""
                      src="/icroundhome.svg"
                    />
                  </div>
                  <div className="relative leading-[3.5rem] font-medium text-plum inline-block min-w-[3rem] z-[1]">
                    Home
                  </div>
                  <div className="relative text-[1.25rem] leading-[3.5rem] font-medium inline-block min-w-[0.688rem] z-[1] mq450:text-[1rem] mq450:leading-[2.813rem] mq450:w-full mq450:h-[0.688rem]">
                    /
                  </div>
                  <div className="relative leading-[3.5rem] font-medium z-[1]">
                    SSD Cloud VPS Hosting
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            className="h-[15.75rem] w-[17.125rem] relative object-contain z-[1]"
            alt=""
            src="/04-cloud-server-icona-2@2x.png"
          />
        </div>
      </div>
    );
  }
);

export default FrameComponent111;

import { FunctionComponent, memo } from "react";
import GroupComponent23 from "./GroupComponent23";

export type ReadyToPurchaseKvmVpsType = {
  className?: string;
};

const ReadyToPurchaseKvmVps: FunctionComponent<ReadyToPurchaseKvmVpsType> =
  memo(({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[1.375rem] box-border max-w-full text-left text-[0.875rem] text-darkslategray-100 font-poppins ${className}`}
      >
        <div className="flex-1 bg-ghostwhite-100 flex flex-col items-center justify-start pt-[5rem] px-[1.25rem] pb-[4.812rem] box-border max-w-full mq800:pt-[2.125rem] mq800:pb-[2rem] mq800:box-border mq1150:pt-[3.25rem] mq1150:pb-[3.125rem] mq1150:box-border">
          <div className="w-[89.438rem] h-[62.875rem] relative bg-ghostwhite-100 hidden max-w-full" />
          <div className="w-[66.688rem] flex flex-row items-start justify-center py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
            <h2 className="relative leading-[2.875rem] font-semibold z-[1]">
              Ready to purchase the best Linux KVM VPS hosting service?
            </h2>
          </div>
          <div className="w-[66.688rem] flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[2.875rem] box-border max-w-full text-center text-slategray-100">
            <div className="flex-1 relative leading-[1.25rem] inline-block max-w-full z-[2]">
              <p className="m-0">
                Hostlasting’s Linux KVM VPS hosting solutions are supported by
                the best-in-class hardware and security features. Moreover,
                well-designed plans, top-notch server infrastructure, and around
                the clock support will ensure optimum efficiency and uptime.
              </p>
              <p className="m-0">
                Hostlasting – Linux KVM VPS hosting to serve your business needs
              </p>
              <p className="m-0">
                Hostlasting’s Linux KVM VPS hosting solutions are supported by
                the latest technologies, SSD disk storage, RAID 5 technology,
                and root access for offering optimum performance, flexibility,
                and reliability. Moreover, these solutions offer scalability and
                control over the resources.
              </p>
              <p className="m-0">
                Our Linux-based VPS server hosting combined with KVM
                virtualization would provide offer you the range of
                capabilities. You can run various operating systems such as
                CentOS, Ubuntu, Fedora, and Debian. Unmetered KVM VPS hosting is
                gaining prominence among users due to advantages of Linux-based
                systems such as ease in operation, security, and flexibility and
                benefits of virtualization.
              </p>
              <p className="m-0">
                In addition, we offer services tailored to your business
                requirements such as storage capacity, RAM, and bandwidth. Our
                plans are designed for different configurations and hosting
                requirements. We offer customized plans as per the client
                requirements. Dedicated servers, ease in accessibility, and high
                security are key features for opting Hostlasting as your service
                provider.
              </p>
            </div>
          </div>
          <div className="w-[66.688rem] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[1.375rem] box-border gap-[2.25rem] max-w-full text-[0.75rem] mq800:gap-[1.125rem]">
            <div className="self-stretch h-[0.125rem] flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem] box-border max-w-full">
              <div className="self-stretch w-[28.75rem] relative rounded-8xs bg-indigo-100 max-w-full z-[1]" />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[1.362rem] max-w-full">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem] text-center text-[0.875rem]">
                <h2 className="relative leading-[1.25rem] font-semibold z-[1]">
                  Key reasons for choosing Hostlasting’s Linux KVM VPS hosting
                  solutions:
                </h2>
              </div>
              <div className="self-stretch rounded-mini border-indigo-100 border-[1px] border-solid box-border flex flex-row flex-wrap items-start justify-center py-[0.75rem] px-[1.187rem] gap-[0.75rem] max-w-full z-[2]">
                <div className="h-[2.875rem] w-[10.5rem] relative">
                  <div className="absolute top-[0.313rem] left-[0rem] rounded-3xs bg-indigo-200 w-[10.5rem] h-[2.313rem]" />
                  <div className="absolute top-[0rem] left-[1.25rem] leading-[2.875rem] font-semibold inline-block min-w-[8rem] z-[1]">
                    Top-notch hardware
                  </div>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start pt-[0.812rem] px-[0rem] pb-[0rem] box-border min-w-[34.375rem] max-w-full text-center text-slategray-100 mq800:min-w-full">
                  <div className="relative leading-[1.25rem]">
                    Powered by multi-core and multi-processor infrastructure,
                    the Linux VPS hosting and KVM virtualization is carried out
                    on a powerful hardware.
                  </div>
                </div>
                <div className="h-[4.625rem] w-[66.625rem] relative rounded-mini border-indigo-100 border-[1px] border-solid box-border hidden max-w-full" />
              </div>
              <GroupComponent23
                tierIIIServerPlatform="Tier III server platform"
                ourDedicatedServersAreLoc="Our dedicated servers are located at Tier III datacenters at Lithuania, Europe to ensure optimum reliability and uptime."
              />
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
                <div className="flex-1 rounded-mini border-indigo-100 border-[1px] border-solid box-border flex flex-row items-start justify-start py-[0.75rem] px-[1.25rem] gap-[0.75rem] max-w-full z-[2] mq800:flex-wrap">
                  <div className="w-[10.5rem] flex flex-row items-end justify-start">
                    <div className="w-[10.5rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.25rem] box-border">
                      <div className="self-stretch h-[2.313rem] relative rounded-3xs bg-indigo-200" />
                    </div>
                    <div className="relative leading-[2.875rem] font-semibold inline-block min-w-[4.125rem] z-[1] ml-[-7.313rem]">
                      Save costs
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem] box-border max-w-full text-slategray-100">
                    <div className="relative leading-[1.25rem]">
                      <p className="m-0">{`Our plans are tailored to ensure you avail optimum value of services at a minimum cost. `}</p>
                      <p className="m-0">
                        As compared to other service providers, our services are
                        relatively cheaper and add more value.
                      </p>
                    </div>
                  </div>
                  <div className="h-[4.625rem] w-[66.625rem] relative rounded-mini border-indigo-100 border-[1px] border-solid box-border hidden max-w-full" />
                </div>
              </div>
              <GroupComponent23
                propGap="2rem"
                propFlexWrap="wrap"
                propAlignSelf="stretch"
                propWidth="unset"
                tierIIIServerPlatform="Run multiple applications"
                ourDedicatedServersAreLoc="Our KVM VPS hosting servers are capable of running multiple applications at the same time. They will help you in gaining business agility."
              />
            </div>
          </div>
          <GroupComponent23
            propGap="3.312rem"
            propFlexWrap="unset"
            propAlignSelf="unset"
            propWidth="66.625rem"
            tierIIIServerPlatform="Rapid provisioning"
            ourDedicatedServersAreLoc="Our best-in-class Linux-based KVM VPS hosting environment is designed to offer provisioning in few minutes."
          />
        </div>
      </section>
    );
  });

export default ReadyToPurchaseKvmVps;

import { FunctionComponent, memo } from "react";

export type FaqItemsListType = {
  className?: string;
  whyDoYouNeedSharedHosting?: string;
  moreIconsList?: string;
  theMajorAdvantageOfShared?: string;
};

const FaqItemsList: FunctionComponent<FaqItemsListType> = memo(
  ({
    className = "",
    whyDoYouNeedSharedHosting,
    moreIconsList,
    theMajorAdvantageOfShared,
  }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[0.562rem] pl-[0.062rem] pr-[0.125rem] box-border max-w-full text-left text-[0.875rem] text-black font-poppins ${className}`}
      >
        <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
          <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-between pt-[0.5rem] pb-[0.562rem] pl-[4.687rem] pr-[3.812rem] box-border max-w-full gap-[1.25rem] mq1050:pl-[2.313rem] mq1050:pr-[1.875rem] mq1050:box-border">
            <div className="h-[4rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
            <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium z-[1]">
              {whyDoYouNeedSharedHosting}
            </div>
            <div className="flex flex-col items-start justify-start pt-[1.25rem] px-[0rem] pb-[0rem]">
              <img
                className="w-[0.75rem] h-[0.375rem] relative object-contain z-[1]"
                alt=""
                src={moreIconsList}
              />
            </div>
          </div>
          <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[2.375rem] px-[4.562rem] pb-[2.312rem] box-border max-w-full text-slategray-100 mq1050:pl-[2.25rem] mq1050:pr-[2.25rem] mq1050:box-border">
            <div className="h-[10rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
            <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
              {theMajorAdvantageOfShared}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FaqItemsList;

import { FunctionComponent, memo } from "react";

export type LiteSpeedPanelType = {
  className?: string;
  emailID?: string;
  y252070mo?: string;
};

const LiteSpeedPanel: FunctionComponent<LiteSpeedPanelType> = memo(
  ({ className = "", emailID, y252070mo }) => {
    return (
      <div
        className={`self-stretch flex flex-col items-start justify-start gap-[0.937rem] text-center text-[0.75rem] text-black font-poppins ${className}`}
      >
        <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.75rem] pr-[1.812rem] text-[0.688rem]">
          <div className="h-[7.688rem] flex-1 relative">
            <div className="absolute top-[4.75rem] left-[0rem] w-[12.438rem] h-[2.938rem]">
              <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[12.438rem] h-[1.75rem] z-[1]" />
              <div className="absolute top-[0rem] left-[1.75rem] leading-[2.938rem] font-medium z-[4]">
                99.99% LiteSpeed Uptime
              </div>
            </div>
            <div className="absolute top-[0rem] left-[1.5rem] w-[9.25rem] h-[2.938rem]">
              <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem] z-[1]" />
              <div className="absolute top-[0rem] left-[2.688rem] leading-[2.938rem] font-medium inline-block min-w-[3.625rem] z-[2]">
                {emailID}
              </div>
            </div>
            <div className="absolute top-[2.375rem] left-[1.5rem] w-[9.25rem] h-[2.938rem]">
              <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem] z-[1]" />
              <div className="absolute top-[0rem] left-[1.875rem] leading-[2.938rem] font-medium inline-block min-w-[5.438rem] z-[3]">
                cPanel Account
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.437rem] text-slategray-100">
          <div className="h-[2.875rem] flex-1 rounded-8xs bg-white border-whitesmoke-400 border-[1px] border-solid box-border flex flex-row items-end justify-between pt-[1.25rem] pb-[0rem] pl-[1.625rem] pr-[1.5rem] gap-[1.25rem] z-[1]">
            <div className="h-[2.875rem] w-[16rem] relative rounded-8xs bg-white border-whitesmoke-400 border-[1px] border-solid box-border hidden" />
            <div className="relative leading-[2.938rem] font-medium inline-block min-w-[6.813rem] z-[2]">
              {y252070mo}
            </div>
            <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[1.25rem]">
              <img
                className="w-[0.75rem] h-[0.375rem] relative object-contain z-[2]"
                alt=""
                src="/vector-335.svg"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.312rem] pr-[3.375rem] text-white mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
          <div className="flex-1 flex flex-row items-start justify-start">
            <div className="w-[9.313rem] flex flex-col items-start justify-start pt-[0.25rem] px-[0rem] pb-[0rem] box-border">
              <div className="self-stretch h-[2.438rem] relative rounded-8xs bg-indigo-100 z-[1]" />
            </div>
            <a href="#" className="[text-decoration:none] relative leading-[2.938rem] font-semibold text-white inline-block min-w-[4.063rem] z-[2] ml-[-6.75rem]">
              Order Now
            </a>
          </div>
        </div>
      </div>
    );
  }
);

export default LiteSpeedPanel;

import { FunctionComponent, memo } from "react";

export type CustomerServiceContainerType = {
  className?: string;
};

const CustomerServiceContainer: FunctionComponent<CustomerServiceContainerType> =
  memo(({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-col items-start justify-start gap-[0.937rem] max-w-full text-left text-[2rem] text-darkslategray-100 font-poppins ${className}`}
      >
        <div className="w-[46.438rem] h-[1.375rem] relative rounded-tl-none rounded-tr-xl rounded-br-xl rounded-bl-none bg-indigo-100 max-w-full" />
        <div className="self-stretch flex flex-row items-start justify-end max-w-full">
          <div className="w-[79.25rem] flex flex-row items-start justify-start py-[0rem] pl-[1.25rem] pr-[0rem] box-border gap-[2.687rem] max-w-full mq800:gap-[1.313rem] mq1125:flex-wrap">
            <div className="w-[30.125rem] flex flex-col items-start justify-start gap-[14.062rem] min-w-[30.125rem] max-w-full mq800:gap-[7rem] mq800:min-w-full mq1125:flex-1">
              <div className="self-stretch flex flex-col items-start justify-start gap-[0.937rem]">
                <h1 className="m-0 h-[5.625rem] relative text-inherit tracking-[0.04em] leading-[2.813rem] font-semibold font-inherit flex items-center mq450:text-[1.188rem] mq450:leading-[1.688rem] mq800:text-[1.625rem] mq800:leading-[2.25rem]">
                  <span>
                    <p className="m-0">Around the Clock Customer</p>
                    <p className="m-0">Service</p>
                  </span>
                </h1>
                <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 pr-[1.5rem]">
                  We offer a 24x7x365 real-time support with live chat and
                  email. We had extensively analyzed each individual in our
                  staff and hired the individuals with necessary and appropriate
                  knowledge. That is why our technicians are incredibly
                  proficient and ready to help you.
                </div>
              </div>
              <div className="w-[28.188rem] flex flex-row items-start justify-start py-[0rem] px-[2.875rem] box-border max-w-full mq450:pl-[1.438rem] mq450:pr-[1.438rem] mq450:box-border">
                <img
                  className="h-[16.244rem] flex-1 relative max-w-full overflow-hidden object-cover"
                  loading="lazy"
                  alt=""
                  src="/happy-customer-1@2x.png"
                />
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-start pt-[0.562rem] px-[0rem] pb-[0rem] box-border min-w-[29.375rem] max-w-full mq800:min-w-full">
              <div className="self-stretch flex flex-col items-start justify-start gap-[6.506rem] max-w-full mq450:gap-[1.625rem] mq800:gap-[3.25rem]">
                <div className="w-[33.688rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                  <img
                    className="w-[22.438rem] relative max-h-full object-cover max-w-full"
                    loading="lazy"
                    alt=""
                    src="/customer-service-1@2x.png"
                  />
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[2.375rem] max-w-full mq800:gap-[1.188rem]">
                  <div className="self-stretch h-[1.375rem] relative rounded-tl-xl rounded-tr-none rounded-br-none rounded-bl-xl bg-indigo-100" />
                  <div className="w-[38.625rem] flex flex-row items-start justify-start py-[0rem] px-[4.687rem] box-border max-w-full mq800:pl-[2.313rem] mq800:pr-[2.313rem] mq800:box-border">
                    <div className="flex-1 flex flex-col items-start justify-start gap-[0.937rem] max-w-full">
                      <h1 className="m-0 relative text-inherit tracking-[0.04em] leading-[2.938rem] font-semibold font-inherit inline-block max-w-full mq450:text-[1.188rem] mq450:leading-[1.688rem] mq800:text-[1.625rem] mq800:leading-[2.25rem]">
                        Data Migration at No Cost
                      </h1>
                      <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100">
                        We, at Hostlasting, believe that it is a part of our
                        duty to offer FREE migration services to our customers.
                        You need to offer the desired domain name information
                        along with EPP code/authorization code for accessing the
                        details. The migration team will help you by analyzing
                        and migrating the account. It will carry out the basic
                        testing before and after migration and ensure the
                        migration has been done properly.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

export default CustomerServiceContainer;

import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type GroupComponent22Type = {
  className?: string;
  cX11?: string;
  intel?: string;
  gB?: string;
  gB1?: string;
  iPv4v6?: string;
  mo?: string;

  /** Style props */
  propOverflowX?: CSSProperties["overflowX"];
};

const GroupComponent22: FunctionComponent<GroupComponent22Type> = memo(
  ({ className = "", propOverflowX, cX11, intel, gB, gB1, iPv4v6, mo }) => {
    const groupDiv2Style: CSSProperties = useMemo(() => {
      return {
        overflowX: propOverflowX,
      };
    }, [propOverflowX]);

    return (
      <div
        className={`self-stretch shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white flex flex-row items-end justify-between py-[1.562rem] pl-[2.125rem] pr-[0.875rem] box-border max-w-full gap-[1.25rem] z-[2] text-center text-[0.813rem] text-slategray-100 font-poppins mq1350:flex-wrap ${className}`}
        style={groupDiv2Style}
      >
        <div className="h-[5.313rem] w-[65.125rem] relative shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white hidden max-w-full" />
        <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.437rem] pl-[0rem] pr-[2.312rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[1.813rem] z-[1]">
            {cX11}
          </div>
        </div>
        <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.437rem] pl-[0rem] pr-[2.187rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.313rem] z-[1]">
            {intel}
          </div>
        </div>
        <div className="w-[5rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.437rem] box-border">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[1.813rem] z-[1]">
            {gB}
          </div>
        </div>
        <div className="w-[5.25rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.437rem] box-border">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.375rem] z-[1]">
            {gB1}
          </div>
        </div>
        <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.437rem] pl-[0rem] pr-[1.562rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.188rem] z-[1]">
            20 TB
          </div>
        </div>
        <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.437rem] pl-[0rem] pr-[2.125rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[3.188rem] z-[1]">
            {iPv4v6}
          </div>
        </div>
        <div className="w-[6.5rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.25rem] box-border">
          <div className="flex flex-row items-start justify-start gap-[0.687rem]">
            <img
              className="h-[1.625rem] w-[1.625rem] relative object-cover min-h-[1.625rem] z-[3]"
              loading="lazy"
              alt=""
              src="/germany-2@2x.png"
            />
            <img
              className="h-[1.625rem] w-[1.625rem] relative object-cover min-h-[1.625rem] z-[3]"
              alt=""
              src="/finland-2@2x.png"
            />
          </div>
        </div>
        <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.437rem] text-[0.938rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[3.313rem] z-[1]">
            {mo}
          </div>
        </div>
        <div className="rounded-xl bg-goldenrod flex flex-row items-start justify-start pt-[0.5rem] px-[1.937rem] pb-[0.437rem] z-[1] text-[0.875rem] text-white">
          <div className="h-[2.188rem] w-[6.375rem] relative rounded-xl bg-goldenrod hidden" />
          <a href="#" className="[text-decoration:none] relative leading-[1.25rem] font-medium text-white inline-block min-w-[2.5rem] z-[1]">
            Order
          </a>
        </div>
      </div>
    );
  }
);

export default GroupComponent22;

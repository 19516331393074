import { FunctionComponent, memo } from "react";

export type AdditionalFeatureType = {
  className?: string;
};

const AdditionalFeature: FunctionComponent<AdditionalFeatureType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[4.312rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins mq750:pb-[2.813rem] mq750:box-border ${className}`}
      >
        <div className="flex-1 bg-ghostwhite-100 flex flex-col items-center justify-start pt-[2.125rem] px-[1.25rem] pb-[0.687rem] box-border gap-[5.437rem] max-w-full lg:gap-[2.688rem] mq750:gap-[1.375rem] mq750:pt-[1.375rem] mq750:pb-[1.25rem] mq750:box-border">
          <div className="w-[89.438rem] h-[42.125rem] relative bg-ghostwhite-100 hidden max-w-full" />
          <div className="w-[66.563rem] flex flex-row items-start justify-center max-w-full">
            <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit z-[1] mq450:text-[1.188rem] mq450:leading-[2.313rem]">
              Additional Features and Guarantees Provided
            </h2>
          </div>
          <div className="w-[66.25rem] flex flex-row flex-wrap items-start justify-center gap-[7.75rem] max-w-full text-[1.25rem] text-indigo-100 mq450:gap-[0.938rem] mq750:gap-[1.938rem] mq1050:gap-[3.875rem]">
            <div className="flex-1 flex flex-col items-start justify-start pt-[1rem] px-[0rem] pb-[0rem] box-border min-w-[19rem] max-w-full">
              <div className="self-stretch flex flex-col items-start justify-start gap-[1.562rem] max-w-full">
                <div className="w-[28.188rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                  <div className="flex flex-col items-start justify-start gap-[0.812rem]">
                    <img
                      className="w-[7.563rem] h-[7.563rem] relative object-cover z-[1]"
                      loading="lazy"
                      alt=""
                      src="/7c01d9e1ffc44ba6901c5b177e560b3fa-1@2x.png"
                    />
                    <div className="w-[6.563rem] flex flex-row items-start justify-start py-[0rem] px-[0.937rem] box-border">
                      <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.375rem] box-border max-w-full">
                  <h3 className="relative tracking-[0.04em] leading-[3.313rem] font-semibold inline-block max-w-full z-[1] mq450:text-[1rem] mq450:leading-[2.625rem]">
                    Extensive Web-Server Application 
                  </h3>
                  <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[2] mt-[-0.563rem]">
                    We have a dedicated system of server applications that
                    ensure we provide the most dedicated and diverse range of
                    services and also provision for a safe and secure mode of
                    operation that suits our organizational ethics.
                  </div>
                </div>
                <div className="w-[28.188rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[0.375rem] box-border max-w-full">
                  <img
                    className="h-[7.563rem] w-[7.563rem] relative object-contain z-[1]"
                    loading="lazy"
                    alt=""
                    src="/complain-to-customer-servicea-3@2x.png"
                  />
                </div>
                <div className="w-[27.188rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                  <div className="h-[0.375rem] w-[4.688rem] relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-start gap-[1.875rem] min-w-[19rem] max-w-full">
              <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem]">
                <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  30 Days Return Guarantee
                </h3>
                <div className="self-stretch h-[6.75rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0 z-[1]">
                  We offer 30 Days Return Guarantee if the user if our service
                  finds our mechanism incompetent and thus gives an idea that
                  there is something wrong with our system application.
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                <div className="w-[25.813rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[1.437rem] box-border relative max-w-full">
                  <img
                    className="h-[7.563rem] w-[7.563rem] relative object-contain z-[1]"
                    loading="lazy"
                    alt=""
                    src="/4be87fb9fb4945a7a33271afcbac6186a-3@2x.png"
                  />
                  <div className="h-[0.375rem] w-[4.688rem] absolute !m-[0] right-[10.563rem] bottom-[0.25rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                </div>
                <h3 className="relative leading-[1.688rem] font-semibold inline-block max-w-full z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Continuous Technical Phone Support
                </h3>
                <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                  We are available around the clock for 365 days. You can raise
                  any questions or doubts to our technical support team.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default AdditionalFeature;

import { FunctionComponent, memo } from "react";
import HeaderPanal from "./HeaderPanal";
import Main from "./Main";

export type UnmanagedVPSType = {
  className?: string;
};

const UnmanagedVPS: FunctionComponent<UnmanagedVPSType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.625rem] box-border max-w-full text-left text-[1rem] text-white font-poppins ${className}`}
      >
        <div className="flex-1 [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1rem] box-border gap-[6.125rem] max-w-full mq800:gap-[3.063rem] mq450:gap-[1.5rem]">
          <HeaderPanal />
          <Main />
          <div className="w-[39.125rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full text-center">
            <div className="flex flex-row items-start justify-start gap-[0.543rem]">
              <div className="flex flex-col items-start justify-start pt-[1rem] px-[0rem] pb-[0rem]">
                <img
                  className="w-[1.5rem] h-[1.5rem] relative overflow-hidden shrink-0 z-[1]"
                  loading="lazy"
                  alt=""
                  src="/icroundhome.svg"
                />
              </div>
              <a className="[text-decoration:none] relative leading-[3.5rem] font-medium text-plum inline-block min-w-[3rem] z-[1]">
                Home
              </a>
              <h3 className="m-0 relative text-[1.25rem] leading-[3.5rem] font-medium font-inherit inline-block min-w-[0.688rem] z-[1] mq450:text-[1rem] mq450:leading-[2.813rem]">
                /
              </h3>
              <div className="relative leading-[3.5rem] font-medium z-[1]">
                Cheap VPS Hosting
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default UnmanagedVPS;

import { FunctionComponent, memo } from "react";
import HeaderPanal from "./HeaderPanal";

export type HeaderHeroJoomlaType = {
  className?: string;
};

const HeaderHeroJoomla: FunctionComponent<HeaderHeroJoomlaType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch h-[50.375rem] [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] flex flex-row items-end justify-start pt-[0rem] px-[0rem] pb-[1rem] box-border max-w-full text-left text-[3rem] text-white font-poppins ${className}`}
      >
        <div className="self-stretch w-[89.438rem] flex flex-col items-start justify-start gap-[16.625rem] max-w-full mq450:gap-[4.125rem] mq750:gap-[8.313rem]">
          <HeaderPanal />
          <div className="w-[38.25rem] flex-1 flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <div className="self-stretch w-[15.625rem] flex flex-col items-start justify-start gap-[12.562rem] mq450:gap-[6.25rem]">
              <div className="self-stretch flex-1 flex flex-row items-start justify-start py-[0rem] pl-[0.125rem] pr-[0rem]">
                <div className="self-stretch flex-1 flex flex-row items-end justify-start gap-[1.312rem]">
                  <div className="self-stretch w-[0.625rem] relative rounded-tl-none rounded-tr-xl rounded-br-xl rounded-bl-none [background:linear-gradient(0deg,_#7c4f9a,_#fff)] z-[1]" />
                  <div className="flex-1 flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[1.437rem]">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[1.062rem]">
                      <h1 className="m-0 relative text-inherit tracking-[0.11em] leading-[1.5rem] font-semibold font-inherit z-[1] mq450:text-[1.813rem] mq450:leading-[0.875rem] mq1050:text-[2.375rem] mq1050:leading-[1.188rem]">
                        Joomla
                      </h1>
                      <div className="flex flex-col items-start justify-start gap-[0.312rem] text-[1.25rem]">
                        <div className="relative leading-[1.5rem] font-medium inline-block min-w-[4.75rem] z-[1] mq450:text-[1rem] mq450:leading-[1.188rem]">
                          hosting
                        </div>
                        <h2 className="relative text-[0.813rem] leading-[1.25rem] font-medium z-[1]">
                          Best Joomla Hosting
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-start justify-start gap-[0.543rem] text-center text-[1rem]">
                <div className="flex flex-col items-start justify-start pt-[1rem] px-[0rem] pb-[0rem]">
                  <img
                    className="w-[1.5rem] h-[1.5rem] relative overflow-hidden shrink-0 z-[1]"
                    loading="lazy"
                    alt=""
                    src="/icroundhome.svg"
                  />
                </div>
                <a className="[text-decoration:none] relative leading-[3.5rem] font-medium text-plum inline-block min-w-[3rem] z-[1]">
                  Home
                </a>
                <div className="relative text-[1.25rem] leading-[3.5rem] font-medium inline-block min-w-[0.688rem] z-[1] mq450:text-[1rem] mq450:leading-[2.813rem]">
                  /
                </div>
                <div className="relative leading-[3.5rem] font-medium inline-block min-w-[7.938rem] z-[1]">
                  Joomla Hosting
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[37.125rem] w-[38.063rem] flex flex-col items-start justify-start max-w-full ml-[-45.188rem]">
          <div className="self-stretch h-[32.938rem] relative">
            <div className="absolute top-[3.938rem] left-[4.75rem] w-[25.813rem] h-[25.813rem] bg-[url('/public/geometricabstractbackgroundwithconnectedlinedotsnetworkconnectionbackgroundyourpresentationgraphicpolygonalbackgroundscientificillustrationrasterillustration-11@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
              <img
                className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                alt=""
                src="/geometricabstractbackgroundwithconnectedlinedotsnetworkconnectionbackgroundyourpresentationgraphicpolygonalbackgroundscientificillustrationrasterillustration-11@2x.png"
              />
              <div className="absolute top-[19.125rem] left-[7.938rem] [filter:blur(4px)] rounded-[50%] bg-gray-1400 w-[14.625rem] h-[1.875rem] z-[2]" />
            </div>
            <img
              className="absolute top-[0rem] left-[0rem] w-full h-full object-cover z-[3]"
              loading="lazy"
              alt=""
              src="/rubaitulazadjhpwltj8gvwunsplash-1@2x.png"
            />
          </div>
        </div>
      </section>
    );
  }
);

export default HeaderHeroJoomla;

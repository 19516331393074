import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type FrameComponent4Type = {
  className?: string;
  doIHaveTheLibertyToUpgrad?: string;
  yesYouHaveTheLibertyToUpg?: string;

  /** Style props */
  propGap?: CSSProperties["gap"];
};

const FrameComponent4: FunctionComponent<FrameComponent4Type> = memo(
  ({
    className = "",
    propGap,
    doIHaveTheLibertyToUpgrad,
    yesYouHaveTheLibertyToUpg,
  }) => {
    const frameDiv2Style: CSSProperties = useMemo(() => {
      return {
        gap: propGap,
      };
    }, [propGap]);

    return (
      <div
        className={`self-stretch flex flex-col items-start justify-start gap-[0.125rem] max-w-full text-left text-[0.875rem] text-black font-poppins ${className}`}
        style={frameDiv2Style}
      >
        <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-between pt-[0.5rem] pb-[0.562rem] pl-[4.687rem] pr-[3.812rem] box-border max-w-full gap-[1.25rem] mq1050:pl-[2.313rem] mq1050:pr-[1.875rem] mq1050:box-border">
          <div className="h-[4rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
          <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium z-[1]">
            {doIHaveTheLibertyToUpgrad}
          </div>
          <div className="flex flex-col items-start justify-start pt-[1.25rem] px-[0rem] pb-[0rem]">
            <img
              className="w-[0.75rem] h-[0.375rem] relative z-[1]"
              loading="lazy"
              alt=""
              src="/vector-337.svg"
            />
          </div>
        </div>
        <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[1.687rem] px-[4.625rem] pb-[1.75rem] box-border max-w-full text-slategray-100 mq750:pl-[2.313rem] mq750:pr-[2.313rem] mq750:box-border">
          <div className="h-[4.5rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
          <div className="relative tracking-[0.04em] leading-[1.063rem] z-[1]">
            {yesYouHaveTheLibertyToUpg}
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent4;

import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type QuestionOneType = {
  className?: string;
  doesHostlastingProvideIPv?: string;
  thenHowManyAddressesCanBe?: string;
  yesWeProvideSupportForIPv?: string;
  inAdditionWeProvideSuppor?: string;

  /** Style props */
  propPadding?: CSSProperties["padding"];
  propGap?: CSSProperties["gap"];
};

const QuestionOne: FunctionComponent<QuestionOneType> = memo(
  ({
    className = "",
    propPadding,
    propGap,
    doesHostlastingProvideIPv,
    thenHowManyAddressesCanBe,
    yesWeProvideSupportForIPv,
    inAdditionWeProvideSuppor,
  }) => {
    const questionOneStyle: CSSProperties = useMemo(() => {
      return {
        padding: propPadding,
        gap: propGap,
      };
    }, [propPadding, propGap]);

    return (
      <div
        className={`self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.562rem] box-border gap-[0.125rem] max-w-full text-left text-[0.875rem] text-black font-poppins ${className}`}
        style={questionOneStyle}
      >
        <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-between py-[0.875rem] pl-[4.687rem] pr-[3.812rem] box-border max-w-full gap-[1.25rem] mq1150:pl-[2.313rem] mq1150:pr-[1.875rem] mq1150:box-border">
          <div className="h-[4rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
          <div className="relative tracking-[0.04em] leading-[1.125rem] font-medium z-[1]">
            <p className="m-0">{doesHostlastingProvideIPv}</p>
            <p className="m-0">{thenHowManyAddressesCanBe}</p>
          </div>
          <div className="flex flex-col items-start justify-start pt-[0.875rem] px-[0rem] pb-[0rem]">
            <img
              className="w-[0.75rem] h-[0.375rem] relative z-[1]"
              loading="lazy"
              alt=""
              src="/vector-337.svg"
            />
          </div>
        </div>
        <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[2.25rem] px-[4.625rem] box-border max-w-full text-slategray-100 mq1150:pl-[2.313rem] mq1150:pr-[2.313rem] mq1150:box-border">
          <div className="h-[9.813rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
          <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
            <p className="m-0">{yesWeProvideSupportForIPv}</p>
            <p className="m-0">{inAdditionWeProvideSuppor}</p>
          </div>
        </div>
      </div>
    );
  }
);

export default QuestionOne;

import { FunctionComponent, memo } from "react";

export type HostingFeaturesType = {
  className?: string;
};

const HostingFeatures: FunctionComponent<HostingFeaturesType> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-start max-w-full text-left text-[2rem] text-darkslategray-100 font-poppins ${className}`}
      >
        <div className="w-[81.375rem] flex flex-row items-end justify-start py-[0rem] pl-[0rem] pr-[1.25rem] box-border gap-[5.687rem] max-w-full mq450:gap-[1.438rem] mq800:gap-[2.813rem] mq1125:flex-wrap">
          <div className="flex-1 flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.375rem] box-border max-w-full mq800:min-w-full">
            <div className="self-stretch flex flex-col items-end justify-start gap-[2.375rem] max-w-full mq800:gap-[1.188rem]">
              <div className="w-[34.688rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[3.918rem] box-border max-w-full">
                <img
                  className="w-[22.438rem] relative max-h-full object-contain max-w-full"
                  loading="lazy"
                  alt=""
                  src="/trer2-1@2x.png"
                />
              </div>
              <div className="self-stretch h-[1.375rem] relative rounded-tl-none rounded-tr-xl rounded-br-xl rounded-bl-none bg-indigo-100" />
              <div className="w-[34.688rem] flex flex-row items-start justify-end py-[0rem] px-[0.937rem] box-border max-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem] max-w-full">
                  <h1 className="m-0 self-stretch relative text-inherit tracking-[0.04em] leading-[2.5rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[1.5rem] mq800:text-[1.625rem] mq800:leading-[2rem]">
                    CloudLinux, Sitepad, Sitejet Website Budilder
                  </h1>
                  <div className="w-[29.313rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center max-w-full">
                    Hostlasting uses the CloudLinux Kernal on its Linux shared
                    web-hosting platform for enhancing performance, reliability,
                    stability, and security of the servers. You can also enable
                    different Powerfull services such as SiteApps Optimization
                    Command Center, Cloudflare , SitePad, Sitejet website
                    builders and others for the SMB website. There are a lot of
                    tools available in the control panel.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[29.25rem] flex flex-col items-start justify-start gap-[7.5rem] min-w-[29.25rem] max-w-full mq800:gap-[3.75rem] mq800:min-w-full mq1125:flex-1">
            <div className="self-stretch flex flex-col items-start justify-start gap-[1.375rem]">
              <h1 className="m-0 self-stretch relative text-inherit tracking-[0.04em] leading-[2.5rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[1.5rem] mq800:text-[1.625rem] mq800:leading-[2rem]">
                Become Web Hosting Provider
              </h1>
              <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100">
                There is a way to earn massive profits: Become a web hosting
                reseller. In addition, this is a white-labelled web hosting
                business. We provide inexpensive Linux reseller hosting plans
                that are flexible and suitable as per your requirements and
                budget plans. They are equipped with different features and help
                you in beginning your own hosting business in few minutes along
                with availing free private name servers.
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.375rem] pr-[3.437rem] box-border max-w-full mq800:pl-[1.688rem] mq800:pr-[1.688rem] mq800:box-border">
              <img
                className="h-[16.25rem] flex-1 relative max-w-full overflow-hidden object-cover"
                loading="lazy"
                alt=""
                src="/one-1@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default HostingFeatures;

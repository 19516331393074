import { FunctionComponent, memo } from "react";

export type BodyType = {
  className?: string;
};

const Body: FunctionComponent<BodyType> = memo(({ className = "" }) => {
  return (
    <div
      className={`w-[87.125rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full text-left text-[1rem] text-white font-poppins ${className}`}
    >
      <div className="w-[64.5rem] flex flex-col items-start justify-start gap-[2rem] max-w-full mq750:gap-[1rem]">
        <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[2.375rem] pr-[0rem] box-border max-w-full">
          <div className="flex-1 flex flex-col items-start justify-start gap-[1.5rem] max-w-full">
            <div className="self-stretch flex flex-col items-start justify-start gap-[0.875rem] max-w-full">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.312rem] box-border max-w-full">
                <div className="w-[40.188rem] flex flex-col items-start justify-start max-w-full">
                  <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.437rem] pr-[1.25rem]">
                    <div className="relative tracking-[0.11em] leading-[3.125rem] font-semibold z-[2]">
                      Managed VPS Hosting with cPanel License
                    </div>
                  </div>
                  <h1 className="m-0 relative text-[3rem] leading-[3.125rem] font-semibold font-inherit z-[2] mq450:text-[1.813rem] mq450:leading-[1.875rem] mq1050:text-[2.375rem] mq1050:leading-[2.5rem]">
                    Cheap cPanel VPS Hosting
                  </h1>
                </div>
              </div>
              <div className="self-stretch relative text-[0.875rem] leading-[1.25rem] font-medium text-lightgray-100 text-center z-[2]">
                <p className="m-0">{`cPanel includes easy to use and technically advanced control panels for management of hosting packages and websites on the web server. `}</p>
                <p className="m-0">
                  Hostlasting provides one of the cheapest cPanel VPS hosting
                  services to help in pre-installing this superior control panel
                  on the server.
                </p>
              </div>
            </div>
            <div className="self-stretch h-[0.438rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
              <div className="self-stretch w-[31rem] relative rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none [background:linear-gradient(89.99deg,_#6e3b8f,_#fff)] max-w-full z-[2]" />
            </div>
          </div>
        </div>
        <div className="w-[51.25rem] flex flex-row flex-wrap items-end justify-start gap-[0.543rem] max-w-full text-center text-plum">
          <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[1rem]">
            <img
              className="w-[1.5rem] h-[1.5rem] relative overflow-hidden shrink-0 z-[2]"
              alt=""
              src="/icroundhome.svg"
            />
          </div>
          <a className="[text-decoration:none] relative leading-[3.5rem] font-medium text-[inherit] inline-block min-w-[3rem] z-[2]">
            Home
          </a>
          <h3 className="m-0 relative text-[1.25rem] leading-[3.5rem] font-medium font-inherit text-white inline-block min-w-[0.688rem] z-[2] mq450:text-[1rem] mq450:leading-[2.813rem]">
            /
          </h3>
          <div className="flex-1 flex flex-row items-start justify-center gap-[1.062rem] min-w-[28.875rem] max-w-full text-[0.813rem] text-lightgray-100 mq750:flex-wrap mq750:min-w-full">
            <div className="w-[13.625rem] flex flex-col items-start justify-start pt-[0.375rem] px-[0rem] pb-[0rem] box-border min-w-[13.625rem] mq750:flex-1">
              <div className="self-stretch flex flex-col items-end justify-start gap-[13.937rem]">
                <div className="flex flex-col items-start justify-start gap-[1.125rem]">
                  <div className="flex flex-row items-start justify-start py-[0rem] pl-[1.437rem] pr-[1.5rem]">
                    <img
                      className="h-[2.25rem] w-[2.25rem] relative overflow-hidden shrink-0 z-[2]"
                      loading="lazy"
                      alt=""
                      src="/mdiipoutline.svg"
                    />
                  </div>
                  <div className="relative leading-[1.25rem] font-medium inline-block min-w-[5.188rem] z-[2]">
                    Dedicated IP
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem] text-white">
                  <div className="relative leading-[3.5rem] font-medium z-[2]">
                    cPanel VPS Hosting
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-start gap-[2.562rem] min-w-[19.313rem] max-w-full mq750:gap-[1.25rem]">
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.75rem] pr-[0rem] box-border max-w-full">
                <div className="flex-1 flex flex-col items-end justify-start gap-[0.75rem] max-w-full mq750:flex-1">
                  <div className="w-[26.938rem] flex flex-row items-start justify-end py-[0rem] px-[2.062rem] box-border max-w-full">
                    <div className="flex-1 flex flex-row items-start justify-between max-w-full gap-[1.25rem] mq450:flex-wrap">
                      <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.187rem]">
                        <img
                          className="w-[3rem] h-[3rem] relative overflow-hidden shrink-0 z-[2]"
                          loading="lazy"
                          alt=""
                          src="/simpleiconscpanel1.svg"
                        />
                      </div>
                      <div className="flex flex-col items-start justify-start pt-[0.375rem] px-[0rem] pb-[0rem]">
                        <img
                          className="w-[2.25rem] h-[2.25rem] relative overflow-hidden shrink-0 z-[2]"
                          loading="lazy"
                          alt=""
                          src="/riusersharedline.svg"
                        />
                      </div>
                      <div className="flex flex-col items-start justify-start pt-[0.375rem] px-[0rem] pb-[0rem]">
                        <img
                          className="w-[2.25rem] h-[2.25rem] relative overflow-hidden shrink-0 z-[2]"
                          loading="lazy"
                          alt=""
                          src="/eosiconsip.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-between gap-[1.25rem] mq450:flex-wrap">
                    <div className="relative leading-[1.25rem] font-medium z-[2]">
                      No cost cPanel License
                    </div>
                    <div className="relative leading-[1.25rem] font-medium inline-block min-w-[6.188rem] z-[2]">
                      SSD Disk Drives
                    </div>
                    <div className="relative leading-[1.25rem] font-medium inline-block min-w-[6.438rem] z-[2]">
                      4 GBPS Network
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[23.875rem] h-[13.75rem] relative max-w-full">
                <img
                  className="absolute h-full top-[0rem] bottom-[0rem] left-[0rem] max-h-full w-[13.75rem] object-cover"
                  alt=""
                  src="/4be87fb9fb4945a7a33271afcbac6186a-1@2x.png"
                />
                <img
                  className="absolute top-[5.313rem] left-[10.125rem] w-[13.75rem] h-[3.063rem] overflow-hidden z-[1]"
                  loading="lazy"
                  alt=""
                  src="/logoscpanel.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Body;

import { FunctionComponent, memo } from "react";
import BenefitsList from "./BenefitsList";

export type FrameComponent15Type = {
  className?: string;
};

const FrameComponent15: FunctionComponent<FrameComponent15Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-start py-[0rem] px-[4.437rem] box-border max-w-full text-left text-[0.875rem] text-darkslategray-100 font-poppins mq800:pl-[2.188rem] mq800:pr-[2.188rem] mq800:box-border ${className}`}
      >
        <div className="flex-1 flex flex-col items-start justify-start gap-[2.437rem] max-w-full mq800:gap-[1.188rem]">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <div className="w-[66.688rem] flex flex-col items-end justify-start gap-[1.375rem] max-w-full">
              <div className="self-stretch flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
                  <h3 className="relative leading-[2.875rem] font-semibold">
                    Know more about us:
                  </h3>
                </div>
                <div className="self-stretch relative leading-[1.25rem] text-slategray-100 text-center z-[1]">
                  Hostlasting has been instrumental in providing a top-notch
                  performance and uninterrupted services with the Tier III
                  datacenters in Europe. Moreover, we are committed to offer
                  around the clock support. The major benefits of choosing the
                  fast, affordable, and the best VPS web hosting services: You
                  can choose the best services while achieving the cost-saving
                  benefits. Our affordable plans are tailored to suit your
                  business needs without compromising the quality of the
                  service.
                </div>
              </div>
              <div className="self-stretch h-[0.125rem] flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem] box-border max-w-full">
                <div className="self-stretch w-[28.75rem] relative rounded-8xs bg-royalblue-100 max-w-full" />
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[1.375rem] max-w-full text-center text-[0.75rem] text-white">
            <BenefitsList
              payForOnlyWhatYouNeed="Pay for only what you need"
              theBestWayToAchieveCostEf="The best way to achieve cost-effectiveness while operating a business is to pay for the only services you need. Our plans ensure that you pay for the services that you need and avoid the redundant spending. You can achieve scalability while paying for only required services. With our plans, you can avail VPS hosting at $5 per month."
            />
            <div className="flex-1 shadow-[0px_4px_8.4px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-indigo-100 flex flex-col items-start justify-start pt-[1.25rem] px-[1rem] pb-[5.25rem] box-border min-w-[18.5rem] max-w-full">
              <div className="w-[28.438rem] h-[11.25rem] relative shadow-[0px_4px_8.4px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-indigo-100 hidden max-w-full" />
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
                <div className="relative leading-[1.25rem] font-semibold z-[2]">
                  All the control in your hands
                </div>
              </div>
              <div className="self-stretch relative text-[0.688rem] leading-[0.875rem] z-[1]">
                You can avail the control through the root access. This enables
                you to control and change all the functionalities without the
                need to contact the service provider. The VPS hosting has an
                advantage over the shared hosting. In addition, we provide
                expert support to cater to your needs.
              </div>
            </div>
            <BenefitsList
              propPadding="1.25rem 1rem 4rem 1.062rem"
              propGap="0.375rem"
              payForOnlyWhatYouNeed="Choice of software"
              theBestWayToAchieveCostEf="Along with our best and cheapest VPS web hosting services, you will gain a freedom to choose the operating system and software. This is one of the VPS hosting advantages over the shared hosting. Our services are compatible with the leading software and operating systems."
            />
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent15;

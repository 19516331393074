import { FunctionComponent, memo } from "react";

export type FrameComponent61Type = {
  className?: string;
};

const FrameComponent61: FunctionComponent<FrameComponent61Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[1.25rem] box-border max-w-full text-left text-[1.25rem] text-indigo-100 font-poppins ${className}`}
      >
        <div className="flex-1 bg-ghostwhite-100 flex flex-row flex-wrap items-end justify-center py-[3.25rem] px-[11.437rem] box-border gap-[7.937rem] min-h-[134.063rem] max-w-full mq800:gap-[2rem] mq800:py-[1.25rem] mq800:px-[2.813rem] mq800:box-border mq1150:pt-[1.375rem] mq1150:pb-[1.375rem] mq1150:box-border mq450:gap-[1rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq1350:gap-[3.938rem] mq1350:py-[2.125rem] mq1350:px-[5.688rem] mq1350:box-border">
          <div className="h-[134.063rem] w-[89.438rem] relative bg-ghostwhite-100 hidden max-w-full" />
          <div className="flex-1 flex flex-col items-start justify-start gap-[4.625rem] min-w-[19.063rem] max-w-full mq800:gap-[2.313rem]">
            <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[1.187rem] pl-[1.25rem] pr-[1.625rem]">
              <div className="h-[12.75rem] w-[16rem] relative bg-[url('/public/web-dev-v2-10-08-22-1@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
                <img
                  className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                  alt=""
                  src="/web-dev-v2-10-08-22-1@2x.png"
                />
                <div className="absolute top-[11.875rem] left-[6rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[2.5rem] gap-[1.125rem]">
              <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Server infrastructure in Europe:
                </h3>
              </div>
              <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                Our Tier III data centers located in Lithunia, Europe will
                ensure the optimum uptime and excellent performance. You can
                avail access to your stored data whenever you need.
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[2.625rem] pl-[1.562rem] pr-[1.25rem]">
              <div className="flex flex-col items-start justify-start gap-[1rem]">
                <img
                  className="w-[8.813rem] h-[8.813rem] relative object-cover z-[1]"
                  loading="lazy"
                  alt=""
                  src="/2-weba-5@2x.png"
                />
                <div className="w-[8.063rem] flex flex-row items-start justify-start py-[0rem] px-[1.687rem] box-border">
                  <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[1.125rem] pl-[0.125rem] pr-[0rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-end justify-start gap-[3.687rem] max-w-full mq800:gap-[1.813rem]">
                <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem]">
                  <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                    <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Cost saving benefits:
                    </h3>
                  </div>
                  <div className="self-stretch h-[8rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0 z-[1]">
                    <span>
                      <p className="m-0">
                        Hostlasting designed its plans to not let our client put
                        much burden on their pockets while availing storage VPS
                        hosting services. Our cost-effective services do not
                        compromise on quality. Avail our
                      </p>
                      <p className="m-0">
                        cheap storage VPS hosting services by choosing the best
                        suitable plan for your business needs.
                      </p>
                    </span>
                  </div>
                </div>
                <div className="self-stretch h-[11.563rem] flex flex-row items-start justify-center py-[0rem] pl-[1.5rem] pr-[1.25rem] box-border">
                  <div className="self-stretch w-[11.25rem] flex flex-col items-start justify-start gap-[1rem]">
                    <img
                      className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover z-[1]"
                      loading="lazy"
                      alt=""
                      src="/3d-illustration-server-security-and-unlocka-3@2x.png"
                    />
                    <div className="w-[10.438rem] flex flex-row items-start justify-start py-[0rem] px-[2.875rem] box-border">
                      <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-end justify-start gap-[4.125rem] max-w-full mq800:gap-[2.063rem]">
              <div className="self-stretch flex flex-col items-start justify-start gap-[2.187rem] max-w-full mq800:gap-[1.063rem]">
                <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] box-border max-w-full">
                  <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                    Management of servers in your hands:
                  </h3>
                </div>
                <div className="self-stretch h-[7.063rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0 z-[1]">
                  <span>
                    <p className="m-0">
                      With each of our cheap storage VPS hosting plans, you can
                      avail a root access that can be connected with the
                      compatible application. This will give you freedom to
                      manage your storage without contacting to service
                      providers. Entire control panel will be in your hands.
                    </p>
                  </span>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.5rem] pr-[1.25rem]">
                <div className="w-[8.625rem] flex flex-col items-start justify-start gap-[1.687rem]">
                  <img
                    className="w-[8.625rem] h-[8.625rem] relative object-cover z-[1]"
                    loading="lazy"
                    alt=""
                    src="/cloud-computing-server-3d-illustrationa-2@2x.png"
                  />
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.812rem] pr-[2.125rem]">
                    <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[2.187rem] mq800:gap-[1.063rem]">
              <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Around the clock support:
                </h3>
              </div>
              <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                Our technical assistants and experts are at your disposal at any
                time you need. We offer around the clock support to address your
                queries and solve your issues. Please get in touch with us at
                any time from anywhere and we will be obliged to help you.
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.331rem] box-border min-w-[19rem] max-w-full">
            <div className="self-stretch flex flex-col items-start justify-start gap-[3.125rem] max-w-full mq800:gap-[1.563rem]">
              <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.687rem] box-border gap-[1.125rem] max-w-full">
                <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit inline-block max-w-full z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Ease in Setup for Reseller Hosting:
                </h3>
                <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                  Hostlasting eases up your reseller hosting business with
                  flexible plans that include sufficient bandwidth and huge disk
                  space. You can create different plan offerings for your
                  customers. These plans are bundled with cPanel, one-click app
                  installers, and other services. We assist you at each step and
                  make the entire process of hosting hassle-free.
                </div>
              </div>
              <div className="w-[22rem] flex flex-row items-start justify-start py-[0rem] px-[4.562rem] box-border max-w-full mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                <div className="h-[12.875rem] flex-1 relative bg-[url('/public/www-domain-name-1@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
                  <img
                    className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                    alt=""
                    src="/www-domain-name-1@2x.png"
                  />
                  <div className="absolute top-[11.688rem] left-[4.063rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[7.5rem] gap-[1.125rem]">
                <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Software support:
                </h3>
                <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                  Our storage VPS servers run on various operating systems and
                  control panels offered by us will give you access and control
                  over your data. The VPS 1TB storage capabilities are supported
                  by all the software tools that would keep servers up and
                  running and secure.
                </div>
              </div>
              <div className="w-[21.938rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[2.062rem] box-border max-w-full">
                <div className="h-[9.375rem] w-[9.188rem] relative bg-[url('/public/iconbusinessv3-9-a-3@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
                  <img
                    className="absolute top-[0rem] left-[0rem] w-[9.188rem] h-[9.188rem] object-cover hidden"
                    alt=""
                    src="/iconbusinessv3-9-a-3@2x.png"
                  />
                  <div className="absolute top-[9rem] left-[2.25rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[5.562rem] gap-[1.125rem]">
                <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Necessary tools:
                </h3>
                <div className="self-stretch h-[5.563rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0 z-[1]">
                  We equip you with necessary tools along with providing optimum
                  storage capabilities at the best prices. You can avail free
                  Domain Name Server (DNS) manager and OS installation along
                  with storage space.
                </div>
              </div>
              <div className="w-[21.5rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[0.5rem] box-border max-w-full">
                <div className="w-[9.625rem] flex flex-col items-end justify-start">
                  <img
                    className="w-[9.625rem] h-[9.625rem] relative object-cover z-[1]"
                    loading="lazy"
                    alt=""
                    src="/fp1222-databasea-2@2x.png"
                  />
                  <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[2.687rem] pr-[2.25rem]">
                    <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[2]" />
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[3.062rem] gap-[2.187rem] mq800:gap-[1.063rem]">
                <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Massive scaling capabilities:
                </h3>
                <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                  Once you opt for a particular plan and your storage needs
                  increase along with your business growth, we offer upgrading
                  facilities. You can scale up storage capacity at any time.
                  With deployment of secure tools, we ensure you get required
                  storage space.
                </div>
              </div>
              <div className="w-[22.188rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                <div className="w-[7.688rem] flex flex-col items-start justify-start gap-[1.381rem]">
                  <img
                    className="w-[7.688rem] h-[7.688rem] relative object-cover z-[1]"
                    loading="lazy"
                    alt=""
                    src="/complain-to-customer-servicea-11@2x.png"
                  />
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.375rem] pr-[1.437rem]">
                    <div className="h-[0.538rem] flex-1 relative [filter:blur(11.4px)] rounded-[50%] bg-gray-1300 z-[1]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent61;

import { FunctionComponent, memo } from "react";

export type FrameComponent7Type = {
  className?: string;
};

const FrameComponent7: FunctionComponent<FrameComponent7Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[1.687rem] box-border max-w-full ${className}`}
      >
        <div className="w-[66.063rem] flex flex-row items-start justify-start relative max-w-full">
          <img
            className="h-[23.438rem] flex-1 relative max-w-full overflow-hidden object-cover"
            alt=""
            src="/cloud-vps-os-1@2x.png"
          />
          <div className="w-[62.5rem] !m-[0] absolute bottom-[0.75rem] left-[1.375rem] flex flex-col items-start justify-start gap-[2.125rem] max-w-full">
            <div className="w-[26.75rem] flex flex-row items-start justify-start py-[0rem] px-[2.437rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.375rem] max-w-full">
                <div className="w-[6.063rem] h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                <div className="self-stretch flex flex-row items-start justify-end">
                  <div className="h-[0.313rem] w-[6.063rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                </div>
              </div>
            </div>
            <div className="w-[6.063rem] h-[0.313rem] absolute !m-[0] top-[2.375rem] right-[22.563rem] rounded-[50%] bg-gray-1100 z-[1]" />
            <div className="self-stretch flex flex-row items-end justify-between max-w-full gap-[1.25rem] mq1100:flex-wrap">
              <div className="w-[23.188rem] flex flex-row items-end justify-start gap-[2.937rem] min-w-[23.188rem] max-w-full mq450:flex-wrap mq450:min-w-full mq1100:flex-1">
                <div className="w-[8.5rem] flex flex-col items-end justify-start gap-[8.312rem] min-w-[8.5rem] mq450:flex-1">
                  <div className="w-[6.063rem] h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                  <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0rem] pr-[1.562rem]">
                    <div className="flex-1 flex flex-col items-start justify-start gap-[5rem]">
                      <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.875rem] pr-[0rem]">
                        <div className="h-[0.313rem] flex-1 relative rounded-[50%] bg-gray-1100 z-[1]" />
                      </div>
                      <div className="w-[6.063rem] h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex flex-col items-end justify-start gap-[8.187rem] min-w-[7.625rem]">
                  <div className="w-[6.938rem] flex flex-row items-start justify-end py-[0rem] px-[0.437rem] box-border">
                    <div className="h-[0.313rem] flex-1 relative rounded-[50%] bg-gray-1100 z-[1]" />
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[3.812rem]">
                    <div className="self-stretch flex flex-row items-start justify-end">
                      <div className="h-[0.313rem] w-[8.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                    </div>
                    <div className="w-[8.313rem] h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                  </div>
                </div>
              </div>
              <div className="w-[33.375rem] flex flex-row items-end justify-start gap-[2.937rem] min-w-[33.375rem] max-w-full mq750:flex-wrap mq750:min-w-full mq1100:flex-1">
                <div className="w-[8.313rem] flex flex-col items-start justify-start gap-[6.5rem] min-w-[8.313rem] mq750:flex-1">
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.937rem] pr-[1.312rem]">
                    <div className="h-[0.313rem] flex-1 relative rounded-[50%] bg-gray-1100 z-[1]" />
                  </div>
                  <div className="self-stretch h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                </div>
                <div className="flex-1 flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.437rem] box-border min-w-[14.375rem] max-w-full">
                  <div className="self-stretch flex flex-col items-end justify-start gap-[4.937rem] max-w-full">
                    <div className="w-[18.063rem] flex flex-row items-end justify-start gap-[1.562rem]">
                      <div className="w-[4.313rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[1.75rem] box-border">
                        <div className="self-stretch h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                      </div>
                      <div className="flex-1 flex flex-col items-start justify-start gap-[5.812rem]">
                        <div className="self-stretch flex flex-row items-start justify-between gap-[1.25rem]">
                          <div className="h-[0.313rem] w-[4.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                          <div className="h-[0.313rem] w-[4.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[2.937rem] pr-[3.187rem]">
                          <div className="h-[0.313rem] flex-1 relative rounded-[50%] bg-gray-1100 z-[1]" />
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0rem] pr-[1.062rem] box-border max-w-full">
                      <div className="flex-1 flex flex-col items-start justify-start gap-[3.062rem] max-w-full">
                        <div className="w-[4.313rem] h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                        <div className="self-stretch flex flex-row items-start justify-end">
                          <div className="w-[14rem] flex flex-row items-start justify-between gap-[1.25rem]">
                            <div className="h-[0.313rem] w-[6.063rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                            <div className="h-[0.313rem] w-[4.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent7;

import { FunctionComponent, memo } from "react";

export type FrameComponent12Type = {
  className?: string;
  whatIsJoomla?: string;
  vector335?: string;
  joomlaIsATotallyFreeSimpl?: string;
};

const FrameComponent12: FunctionComponent<FrameComponent12Type> = memo(
  ({ className = "", whatIsJoomla, vector335, joomlaIsATotallyFreeSimpl }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-start py-[0rem] px-[0.062rem] box-border max-w-full text-left text-[0.875rem] text-black font-poppins ${className}`}
      >
        <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
          <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-between pt-[0.5rem] pb-[0.562rem] pl-[4.687rem] pr-[3.812rem] box-border max-w-full gap-[1.25rem] mq1050:pl-[2.313rem] mq1050:pr-[1.875rem] mq1050:box-border">
            <div className="h-[4rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
            <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium z-[1]">
              {whatIsJoomla}
            </div>
            <div className="flex flex-col items-start justify-start pt-[1.25rem] px-[0rem] pb-[0rem]">
              <img
                className="w-[0.75rem] h-[0.375rem] relative object-contain z-[1]"
                alt=""
                src={vector335}
              />
            </div>
          </div>
          <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[1.75rem] px-[4.625rem] box-border max-w-full text-slategray-100 mq1050:pl-[2.313rem] mq1050:pr-[2.313rem] mq1050:box-border">
            <div className="h-[5.625rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
            <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
              {joomlaIsATotallyFreeSimpl}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent12;

import { FunctionComponent, memo } from "react";

export type GroupComponent1Type = {
  className?: string;
};

const GroupComponent1: FunctionComponent<GroupComponent1Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`flex-[0.7294] rounded-3xs bg-ghostwhite-200 flex flex-col items-end justify-start pt-[1.625rem] px-[2.875rem] pb-[1.687rem] box-border gap-[0.75rem] min-w-[11.875rem] max-w-[13.5rem] text-left text-[0.625rem] text-indigo-100 font-poppins mq450:flex-1 ${className}`}
      >
        <div className="w-[13.5rem] h-[7.375rem] relative rounded-3xs bg-ghostwhite-200 hidden" />
        <div className="flex flex-row items-start justify-end py-[0rem] pl-[0.437rem] pr-[0.375rem]">
          <img
            className="h-[2.25rem] w-[6.938rem] relative overflow-hidden shrink-0 z-[1]"
            alt=""
            src="/logosgoogle.svg"
          />
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[0.75rem]">
          <div className="flex-1 flex flex-col items-start justify-start pt-[0.062rem] px-[0rem] pb-[0rem]">
            <div className="self-stretch flex flex-row items-start justify-start gap-[0.312rem]">
              <img
                className="h-[0.938rem] w-[0.938rem] relative min-h-[0.938rem] z-[1]"
                alt=""
                src="/rating-stars.svg"
              />
              <img
                className="h-[0.938rem] w-[0.938rem] relative min-h-[0.938rem] z-[1]"
                alt=""
                src="/rating-stars.svg"
              />
              <img
                className="h-[0.938rem] w-[0.938rem] relative min-h-[0.938rem] z-[1]"
                alt=""
                src="/rating-stars.svg"
              />
              <img
                className="h-[0.938rem] w-[0.938rem] relative min-h-[0.938rem] z-[1]"
                alt=""
                src="/rating-stars.svg"
              />
              <img
                className="h-[0.938rem] w-[0.938rem] relative min-h-[0.938rem] z-[1]"
                alt=""
                src="/rating-stars.svg"
              />
            </div>
          </div>
          <div className="relative tracking-[0.04em] leading-[1.063rem] font-semibold inline-block min-w-[1.063rem] z-[1]">
            5.0
          </div>
        </div>
      </div>
    );
  }
);

export default GroupComponent1;

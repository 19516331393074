import { FunctionComponent, memo } from "react";

export type HeaderHeroSSDLiteSpeedHostType = {
  className?: string;
};

const HeaderHeroSSDLiteSpeedHost: FunctionComponent<HeaderHeroSSDLiteSpeedHostType> =
  memo(({ className = "" }) => {
    return (
      <div
        className={`self-stretch h-[50.375rem] relative max-w-full text-left text-[3rem] text-white font-poppins ${className}`}
      >
        <div className="absolute top-[0rem] left-[0rem] w-full flex flex-row items-start justify-start max-w-full h-full">
          <img
            className="h-[50.375rem] flex-1 relative max-w-full overflow-hidden"
            alt=""
            src="/hero-section.svg"
          />
          <h1 className="!m-[0] absolute right-[24.125rem] bottom-[10.813rem] text-inherit tracking-[0.11em] leading-[1.5rem] font-semibold font-inherit z-[1] mq450:text-[1.813rem] mq450:leading-[0.875rem] mq1050:text-[2.375rem] mq1050:leading-[1.188rem]">
            SSD LiteSpeed Hosting
          </h1>
          <div className="h-[0.438rem] w-[31rem] absolute !m-[0] right-[29.188rem] bottom-[8.25rem] rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none [background:linear-gradient(89.99deg,_#67318a,_#fff)] z-[1]" />
        </div>
        <header className="absolute top-[0rem] left-[0rem] bg-indigo-100 w-full overflow-hidden flex flex-row items-start justify-between pt-[2.25rem] pb-[2.625rem] pl-[3.75rem] pr-[5.562rem] box-border gap-[1.25rem] max-w-full z-[1] text-left text-[0.938rem] text-black font-poppins lg:w-[48.063rem] mq450:pr-[1.25rem] mq450:box-border mq750:pl-[1.875rem] mq750:pr-[2.75rem] mq750:box-border">
          <div className="w-[15.438rem] flex flex-col items-start justify-start pt-[0.156rem] px-[0rem] pb-[0rem] box-border">
            <div className="self-stretch flex flex-row items-start justify-start gap-[1.25rem]">
              <div className="flex-1 flex flex-col items-start justify-start pt-[0.062rem] px-[0rem] pb-[0rem]">
                <img
                  className="self-stretch h-[2.125rem] relative max-w-full overflow-hidden shrink-0 object-cover z-[2]"
                  loading="lazy"
                  alt=""
                  src="/hostlasting-logo@2x.png"
                />
              </div>
              <div className="w-[5.688rem] rounded-xl border-white border-[1px] border-solid box-border flex flex-row items-start justify-start py-[0.25rem] pl-[1.25rem] pr-[1.187rem] gap-[0.625rem] z-[2]">
                <div className="h-[2.313rem] w-[5.688rem] relative rounded-xl border-white border-[1px] border-solid box-border hidden" />
                <img
                  className="h-[1.563rem] w-[1.625rem] relative object-contain z-[1]"
                  alt=""
                  src="/us-2@2x.png"
                />
                <div className="flex flex-col items-start justify-start pt-[0.312rem] px-[0rem] pb-[0rem]">
                  <img
                    className="w-[0.875rem] h-[0.875rem] relative overflow-hidden shrink-0 z-[1]"
                    alt=""
                    src="/heroiconssolidchevrondown.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <nav className="m-0 w-[41.375rem] flex flex-col items-start justify-start pt-[0.562rem] px-[0rem] pb-[0rem] box-border max-w-full lg:hidden">
            <nav className="m-0 self-stretch h-[1.5rem] relative text-left text-[0.875rem] text-white font-poppins">
              <a className="[text-decoration:none] absolute top-[0rem] left-[0rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[3.438rem] z-[2]">
                SHARED
              </a>
              <a className="[text-decoration:none] absolute top-[0rem] left-[6.063rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[3.813rem] z-[2]">
                RESELLER
              </a>
              <div className="absolute top-[0rem] left-[12.5rem] leading-[1.5rem] font-medium flex items-center min-w-[1.688rem] z-[2]">
                VPS
              </div>
              <a className="[text-decoration:none] absolute top-[0rem] left-[16.813rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[4.25rem] z-[2]">
                ADD-ONS
              </a>
              <a className="[text-decoration:none] absolute top-[0rem] left-[23.688rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[4.813rem] z-[2]">
                DEDICATED
              </a>
              <a className="[text-decoration:none] absolute top-[0rem] left-[31.125rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[4rem] whitespace-nowrap z-[2]">
                About Us
              </a>
              <a className="[text-decoration:none] absolute top-[0rem] left-[37.75rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[3.625rem] z-[2]">
                Contact
              </a>
            </nav>
          </nav>
          <div className="flex flex-row items-start justify-start gap-[1.625rem]">
            <div className="rounded-3xs bg-white flex flex-row items-start justify-start py-[0.562rem] px-[2rem] whitespace-nowrap z-[2]">
              <div className="h-[2.625rem] w-[6.75rem] relative rounded-3xs bg-white hidden" />
              <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[2.75rem] whitespace-nowrap z-[1]">
                Log In
              </a>
            </div>
            <div className="flex flex-col items-start justify-start pt-[0.062rem] px-[0rem] pb-[0rem]">
              <img
                className="w-[2.438rem] h-[2.438rem] relative rounded-3xs object-cover z-[2]"
                loading="lazy"
                alt=""
                src="/24hourssupport-1@2x.png"
              />
            </div>
          </div>
        </header>
      </div>
    );
  });

export default HeaderHeroSSDLiteSpeedHost;

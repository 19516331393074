import { FunctionComponent, memo } from "react";
import PlanFeatures from "./PlanFeatures";
import Offer from "./Offer";
import SecondPlanFeatures from "./SecondPlanFeatures";
import PlanDetails from "./PlanDetails";
import PlanPriceComponent from "./PlanPriceComponent";
import PlanPriceEnjoy from "./PlanPriceEnjoy";

export type SSDLightPlansAndDetailsType = {
  className?: string;
};

const SSDLightPlansAndDetails: FunctionComponent<SSDLightPlansAndDetailsType> =
  memo(({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full text-left text-[0.688rem] text-slategray-100 font-poppins ${className}`}
      >
        <div className="w-[75.188rem] flex flex-col items-end justify-start gap-[2.875rem] max-w-full mq750:gap-[1.438rem]">
          <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[0.75rem] pl-[1.375rem] pr-[1.25rem] box-border max-w-full">
            <div className="flex flex-col items-start justify-start gap-[0.312rem] max-w-full">
              <div className="flex flex-row items-start justify-start py-[0rem] pl-[3.625rem] pr-[3.687rem] mq450:pl-[1.813rem] mq450:pr-[1.813rem] mq450:box-border">
                <div className="relative leading-[1.25rem] font-medium">
                  Not sure about which plan to choose? We are here to help you.
                </div>
              </div>
              <div className="flex flex-row items-start justify-start py-[0rem] pl-[0.687rem] pr-[0.812rem] text-[0.813rem]">
                <div className="relative leading-[1.25rem] font-medium">{`Choose from our variety of plans that best suites your requirement. `}</div>
              </div>
              <h2 className="m-0 relative text-[2.25rem] tracking-[0.04em] leading-[2.938rem] font-semibold font-inherit text-indigo-100 text-center mq450:text-[1.375rem] mq450:leading-[1.75rem] mq1050:text-[1.813rem] mq1050:leading-[2.375rem]">
                SSD Linux Hosting Plans
              </h2>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-end pt-[0rem] px-[0.562rem] pb-[1.812rem] box-border max-w-full text-center text-[2.25rem] text-white">
            <div className="flex-1 flex flex-row items-start justify-center gap-[1.437rem] max-w-full mq1050:flex-wrap">
              <div className="flex-1 flex flex-col items-start justify-start pt-[0.125rem] px-[0rem] pb-[0rem] box-border min-w-[16.063rem] max-w-[17.438rem]">
                <div className="self-stretch rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid flex flex-col items-start justify-start pt-[0.75rem] pb-[1.562rem] pl-[0.687rem] pr-[0.625rem] gap-[0.312rem]">
                  <div className="w-[17.438rem] h-[44.813rem] relative rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                  <PlanFeatures lIVE="LIVE" />
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.25rem] pr-[3.375rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                    <Offer propMargin="0" />
                  </div>
                  <SecondPlanFeatures
                    emailID="10 Email ID"
                    y252070mo="3Y ₹2520 - 70/mo"
                  />
                </div>
              </div>
              <div className="flex-[0.9176] rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border flex flex-col items-start justify-start pt-[0.75rem] pb-[1.562rem] pl-[0.687rem] pr-[0.625rem] gap-[0.312rem] min-w-[16.063rem] max-w-[17.438rem] mq450:flex-1">
                <div className="w-[17.438rem] h-[44.813rem] relative rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                <PlanFeatures lIVE="LAUGH" />
                <PlanDetails propMargin="0" />
                <SecondPlanFeatures
                  emailID="Unlimited Email ID"
                  y252070mo="3Y ₹5040 - 140/mo"
                />
              </div>
              <div className="flex-[0.9176] rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border flex flex-col items-start justify-start pt-[0.75rem] pb-[1.562rem] pl-[0.687rem] pr-[0.625rem] gap-[0.937rem] min-w-[16.063rem] max-w-[17.438rem] mq450:flex-1">
                <div className="w-[17.438rem] h-[44.813rem] relative rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                  <div className="flex-1 rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] flex flex-row items-start justify-start py-[2.625rem] pl-[4.75rem] pr-[4.562rem] z-[1] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                    <div className="h-[8.188rem] w-[15.75rem] relative rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] hidden" />
                    <h1 className="m-0 relative text-inherit tracking-[0.1em] leading-[2.938rem] font-medium font-inherit inline-block min-w-[6.438rem] z-[2] mq450:text-[1.375rem] mq450:leading-[1.75rem] mq1050:text-[1.813rem] mq1050:leading-[2.375rem]">
                      LOVE
                    </h1>
                  </div>
                </div>
                <PlanPriceComponent propMargin="0" />
              </div>
              <div className="flex-1 flex flex-col items-start justify-start pt-[0.125rem] px-[0rem] pb-[0rem] box-border min-w-[16.063rem] max-w-[17.438rem]">
                <div className="self-stretch rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid flex flex-col items-start justify-start pt-[0.75rem] pb-[1.562rem] pl-[0.687rem] pr-[0.625rem] gap-[0.937rem]">
                  <div className="w-[17.438rem] h-[44.813rem] relative rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                    <div className="flex-1 rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] flex flex-row items-start justify-start py-[2.625rem] pl-[3.75rem] pr-[3.625rem] z-[1] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                      <div className="h-[8.188rem] w-[15.75rem] relative rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] hidden" />
                      <h1 className="m-0 relative text-inherit tracking-[0.1em] leading-[2.938rem] font-medium font-inherit z-[2] mq450:text-[1.375rem] mq450:leading-[1.75rem] mq1050:text-[1.813rem] mq1050:leading-[2.375rem]">
                        ENJOY
                      </h1>
                    </div>
                  </div>
                  <PlanPriceEnjoy
                    propPadding="0rem 3rem 0rem 2.75rem"
                    mo3="₹210/mo ($3)"
                    host3Website="Host 3 Website"
                    propLeft="2.063rem"
                    propDisplay="inline-block"
                    propMinWidth="5.125rem"
                    propMargin="0"
                    propPadding1="1.25rem 1.5rem 0rem 1.437rem"
                    y7560210mo="3Y ₹7560 - 210/mo"
                    propMinWidth1="7.125rem"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-[0.937rem] max-w-full text-[0.938rem] text-darkslategray-100 mq1050:flex-wrap">
            <div className="flex-1 rounded-xl bg-lavender-200 flex flex-col items-start justify-start py-[4.375rem] pl-[4.5rem] pr-[1.25rem] box-border gap-[1.062rem] max-w-full mq750:pl-[2.25rem] mq750:box-border">
              <div className="w-[37.125rem] h-[21.25rem] relative rounded-xl bg-lavender-200 hidden max-w-full" />
              <h3 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit z-[1]">
                Loaded with Outstanding Capabilities!
              </h3>
              <div className="w-[28.125rem] h-[8.563rem] relative text-[0.875rem] leading-[1.313rem] text-slategray-100 flex items-center shrink-0 max-w-full z-[1]">{`Google and Facebook stand is Litespeed. This is simply due to better capabilities HTTP/3 protocol as compared to HTTP/2. We are already in service and race over more than 37,000 HTTP/3 sites with SSD space. LiteSpeed Reseller Hosting & WordPress Website let you receive blazing 24x quicker performance. Indeed, you may experience far better speed and performance.`}</div>
            </div>
            <div className="flex-1 rounded-xl bg-lavender-200 flex flex-col items-start justify-start py-[4.375rem] pl-[4.5rem] pr-[1.25rem] box-border gap-[1.062rem] max-w-full mq750:pl-[2.25rem] mq750:box-border">
              <div className="w-[37.125rem] h-[21.25rem] relative rounded-xl bg-lavender-200 hidden max-w-full" />
              <h3 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit z-[1]">{`Reputable & Excellent Performance`}</h3>
              <div className="w-[28.125rem] h-[8.563rem] relative text-[0.875rem] leading-[1.313rem] text-slategray-100 flex items-center shrink-0 max-w-full z-[1]">
                <span className="w-full">
                  <p className="m-0">
                    Each LiteSpeed reseller hosting and shared hosting have been
                    precisely tuned and offer lightening fast sped using
                    LiteSpeed server. Hostlasting provides the top LiteSpeed SSD
                    hosting packages that are designed to fulfil your
                    requirements.
                  </p>
                  <p className="m-0">
                    With Hostlasting, create your website performing at
                    ultra-lighting speed
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-end pt-[0rem] pb-[2.437rem] pl-[4.5rem] pr-[4.125rem] box-border max-w-full text-[1.25rem] text-darkslategray-100 lg:pl-[2.25rem] lg:pr-[2.063rem] lg:box-border">
            <div className="flex-1 flex flex-col items-start justify-start gap-[1.625rem] max-w-full">
              <div className="self-stretch h-[9.438rem] flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.562rem] box-border max-w-full">
                <div className="self-stretch w-[24.5rem] flex flex-col items-start justify-start max-w-full">
                  <img
                    className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                    loading="lazy"
                    alt=""
                    src="/34519609-m028t0191-c-50-loading-11nov22-1@2x.png"
                  />
                  <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.312rem]">
                    <div className="h-[0.375rem] w-[8.438rem] relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.562rem]">
                  <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1rem] mq450:leading-[2.313rem]">
                    Website Page Loading
                  </h2>
                </div>
                <div className="self-stretch relative text-[0.875rem] leading-[1.25rem] text-slategray-100 z-[1]">
                  <p className="m-0">
                    The speed of website page loading is essential nowadays. In
                    addition to the search engine optimization variables,
                    customers need faster speed and optimum performance.
                    Hostlasting’s SSD LiteSpeed servers offer extraordinary and
                    high-quality support at cost-effective SSD LiteSpeed
                    hosting, shared hosting, reseller hosting. We also offer
                    lightning-fast speed for the shared hosting with the help of
                    the LiteSpeed cache technologies. In addition, you will get
                    a free SSL Certificate. 
                  </p>
                  <p className="m-0">
                    Additionally, reducing the usage of RAM, I/O, IOPS, and CPU
                    using LiteSpeed Web Server hosting will save the VPS server
                    price. Our LiteSpeed Reseller hosting helps you in gaining
                    customer leads rapidly and supports LiteSpeed hosting
                    WordPress plugin. This plugin will be free to help you in
                    acquiring a massive increase in speed. LiteSpeed Cloud
                    hosting India offers the perfect combination of the
                    affordable SSD storage for shared hosting and resellers.
                    Hostlasting LiteSpeed web server is another extraordinary
                    alternative for Apache web server hosting. Additionally, it
                    also provides extraordinary Loading speeds as compared to
                    Apache. LiteSpeed web server increases the website speed as
                    well as accelerates the cPanel and WHM access.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-end py-[0rem] px-[4.312rem] box-border max-w-full text-center text-[0.75rem] lg:pl-[2.125rem] lg:pr-[2.125rem] lg:box-border">
            <div className="flex-1 flex flex-col items-start justify-start gap-[2.375rem] max-w-full mq750:gap-[1.188rem]">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[2.812rem] pr-[1.25rem] box-border max-w-full mq1050:pl-[1.375rem] mq1050:box-border">
                <div className="flex flex-row items-start justify-start gap-[1.187rem] max-w-full">
                  <div className="flex flex-col items-start justify-start pt-[0.312rem] px-[0rem] pb-[0rem]">
                    <div className="flex flex-col items-start justify-start gap-[1.25rem]">
                      <img
                        className="w-[0.875rem] h-[0.625rem] relative"
                        loading="lazy"
                        alt=""
                        src="/vector1.svg"
                      />
                      <img
                        className="w-[0.875rem] h-[0.625rem] relative"
                        loading="lazy"
                        alt=""
                        src="/vector1.svg"
                      />
                      <img
                        className="w-[0.875rem] h-[0.625rem] relative"
                        loading="lazy"
                        alt=""
                        src="/vector1.svg"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start gap-[0.625rem] max-w-[calc(100%_-_33px)]">
                    <div className="relative leading-[1.25rem] inline-block max-w-full">
                      Outstanding functionality - 24x faster performance in
                      comparison to Apache Web Server
                    </div>
                    <div className="relative leading-[1.25rem] inline-block max-w-full">
                      Blazing PHP Performance – Improves PHP performance by 50%
                      with the LiteSpeed cache server.
                    </div>
                    <div className="relative leading-[1.25rem]">
                      SSD Reseller Hosting, SSD Web Hosting, SSD WordPress
                      Hosting implement the LiteSpeed Web Server to your
                      account.
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch relative text-[0.875rem] leading-[1.25rem] text-left">
                The time has arrived when we can deploy a QUIC protocol (HTTP/3)
                technology for hosting your sites with the help of Hostlasting.
                Accessible over more than 8 countries including the U.K.,
                Europe, the U.S., India, and others. Speeding up your website
                speed is in your control now.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

export default SSDLightPlansAndDetails;

import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type FrameComponent26Type = {
  className?: string;
  withoutProvidingAKeepAliv?: string;
  around89FasterAsComparedT?: string;
  nearly167FasterInComparis?: string;
  quickerThanNginx?: string;

  /** Style props */
  propPadding?: CSSProperties["padding"];
};

const FrameComponent26: FunctionComponent<FrameComponent26Type> = memo(
  ({
    className = "",
    propPadding,
    withoutProvidingAKeepAliv,
    around89FasterAsComparedT,
    nearly167FasterInComparis,
    quickerThanNginx,
  }) => {
    const frameDiv9Style: CSSProperties = useMemo(() => {
      return {
        padding: propPadding,
      };
    }, [propPadding]);

    return (
      <div
        className={`w-[40.313rem] flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.5rem] box-border max-w-full text-left text-[0.75rem] text-slategray-100 font-poppins ${className}`}
        style={frameDiv9Style}
      >
        <div className="relative leading-[2.313rem] font-medium inline-block max-w-full z-[2]">
          {withoutProvidingAKeepAliv}
        </div>
        <div className="self-stretch rounded-3xs [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] flex flex-row items-start justify-start pt-[2.437rem] px-[3rem] pb-[2.375rem] box-border gap-[0.812rem] max-w-full z-[3] text-center text-white mq750:pl-[1.5rem] mq750:pr-[1.5rem] mq750:box-border">
          <div className="h-[9.563rem] w-[40.313rem] relative rounded-3xs [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] hidden max-w-full" />
          <div className="flex flex-col items-start justify-start pt-[0.25rem] px-[0rem] pb-[0rem]">
            <div className="flex flex-col items-start justify-start gap-[1.125rem]">
              <img
                className="w-[0.875rem] h-[0.625rem] relative z-[1]"
                loading="lazy"
                alt=""
                src="/vector-31.svg"
              />
              <img
                className="w-[0.875rem] h-[0.625rem] relative z-[1]"
                loading="lazy"
                alt=""
                src="/vector-31.svg"
              />
              <img
                className="w-[0.875rem] h-[0.625rem] relative z-[1]"
                loading="lazy"
                alt=""
                src="/vector-31.svg"
              />
            </div>
          </div>
          <div className="flex flex-col items-start justify-start gap-[0.5rem] max-w-[calc(100%_-_27px)]">
            <div className="relative leading-[1.25rem] inline-block max-w-full z-[1]">
              {around89FasterAsComparedT}
            </div>
            <div className="relative leading-[1.25rem] z-[1]">
              {nearly167FasterInComparis}
            </div>
            <div className="relative leading-[1.25rem] z-[1]">
              {quickerThanNginx}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent26;

import { FunctionComponent } from "react";
import HeaderHeroKVMVPS from "../components/HeaderHeroKVMVPS";
import KvmVpsServerPlanPrice from "../components/KvmVpsServerPlanPrice";
import KvmVpsHostingCheapestHosting from "../components/KvmVpsHostingCheapestHosting";
import ReadyToPurchaseKvmVps from "../components/ReadyToPurchaseKvmVps";
import Locations1 from "../components/Locations1";
import SharedVCPUContent from "../components/SharedVCPUContent";
import SharedPlanTable from "../components/SharedPlanTable";
import GroupComponent13 from "../components/GroupComponent13";
import GroupComponent6 from "../components/GroupComponent6";
import FQAKvmVpsHosting from "../components/FQAKvmVpsHosting";
import FooterComponent from "../components/FooterComponent";

const KVMVPS: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[2.625rem] leading-[normal] tracking-[normal] mq800:gap-[1.313rem]">
      <HeaderHeroKVMVPS />
      <div className="w-[0.188rem] h-[9.938rem] relative border-white border-r-[3px] border-solid box-border hidden" />
      <KvmVpsServerPlanPrice />
      <KvmVpsHostingCheapestHosting />
      <section className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[8.75rem] pl-[1.312rem] pr-[1.25rem] relative text-left text-[1.5rem] text-darkslategray-100 font-poppins">
        <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
          One click install OS Dockers
        </h3>
        <img
          className="h-[23.5rem] w-[66.063rem] absolute !m-[0] right-[11.688rem] bottom-[-18.75rem] object-cover"
          loading="lazy"
          alt=""
          src="/vps-features-1@2x.png"
        />
      </section>
      <img
        className="w-[29.188rem] relative max-h-full object-cover hidden max-w-full"
        alt=""
        src="/1531135-4091-1@2x.png"
      />
      <section className="self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[5.5rem] box-border max-w-full">
        <div className="w-[57.188rem] flex flex-col items-end justify-start gap-[0.968rem] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[17.062rem] mq450:pr-[1.25rem] mq450:box-border">
            <div className="h-[0.313rem] w-[9.75rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
          </div>
          <div className="w-[6.063rem] h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
          <div className="self-stretch flex flex-row items-start justify-start max-w-full">
            <div className="w-[48.188rem] flex flex-row items-end justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
              <div className="h-[0.313rem] w-[6.063rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
              <div className="w-[16.875rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.687rem] box-border">
                <div className="self-stretch flex flex-row items-start justify-between gap-[1.25rem]">
                  <div className="w-[6.063rem] flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem] box-border">
                    <div className="self-stretch h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                  </div>
                  <div className="h-[0.313rem] w-[6.063rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[2.562rem] pl-[3.125rem] pr-[1.25rem] box-border max-w-full mq1150:pl-[1.563rem] mq1150:box-border">
        <div className="w-[55.938rem] flex flex-col items-start justify-start gap-[0.531rem] max-w-full">
          <div className="w-[9.688rem] h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
          <div className="self-stretch flex flex-row items-start justify-end">
            <div className="h-[0.313rem] w-[14.188rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
          </div>
          <div className="w-[48.625rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <div className="w-[19.875rem] flex flex-row items-end justify-between gap-[1.25rem]">
              <div className="w-[5.5rem] flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[0.687rem] box-border">
                <div className="self-stretch h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
              </div>
              <div className="w-[4.813rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.187rem] box-border">
                <div className="self-stretch h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
              </div>
              <div className="h-[0.313rem] w-[4.813rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
            </div>
          </div>
        </div>
      </div>
      <ReadyToPurchaseKvmVps />
      <section className="self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[2.75rem] box-border max-w-full text-center text-[3rem] text-darkslategray-100 font-poppins mq1350:pb-[1.25rem] mq1350:box-border">
        <div className="flex-1 bg-ghostwhite-100 flex flex-col items-end justify-start pt-[11.312rem] px-[11rem] pb-[13.875rem] box-border gap-[10rem] max-w-full mq450:gap-[2.5rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq800:gap-[5rem] mq800:pt-[3.125rem] mq800:px-[5.5rem] mq800:pb-[3.813rem] mq800:box-border mq1350:pt-[4.813rem] mq1350:pb-[5.875rem] mq1350:box-border">
          <div className="w-[89.438rem] h-[145.125rem] relative bg-ghostwhite-100 hidden max-w-full" />
          <div className="w-[50.875rem] flex flex-row items-start justify-end py-[0rem] px-[0.562rem] box-border max-w-full">
            <div className="flex-1 flex flex-col items-end justify-start gap-[8.187rem] max-w-full mq450:gap-[2.063rem] mq800:gap-[4.063rem]">
              <div className="flex flex-col items-start justify-start gap-[1.375rem] max-w-full">
                <div className="flex flex-row items-start justify-start relative">
                  <div className="h-[22.875rem] w-[25.313rem] !m-[0] absolute bottom-[-12.812rem] left-[-24.375rem] flex flex-row items-start justify-start z-[1]">
                    <div className="h-full w-full absolute !m-[0] top-[0rem] right-[0rem] bottom-[0rem] left-[0rem]">
                      <img
                        className="absolute top-[3.875rem] left-[0rem] w-[25.313rem] h-[19rem] object-cover"
                        alt=""
                        src="/hostlastingslide2-12@2x.png"
                      />
                      <img
                        className="absolute top-[0rem] left-[0.938rem] w-[20.188rem] h-[20.188rem] object-contain z-[1]"
                        loading="lazy"
                        alt=""
                        src="/domain-1@2x.png"
                      />
                    </div>
                  </div>
                  <h2 className="m-0 relative text-inherit leading-[1.25rem] font-semibold font-inherit z-[2] mq450:text-[1.813rem] mq450:leading-[0.75rem] mq800:text-[2.375rem] mq800:leading-[1rem]">
                    Check out
                  </h2>
                </div>
                <h2 className="m-0 relative text-[2rem] leading-[1.25rem] font-medium font-inherit inline-block min-w-[3.375rem] z-[2] mq450:text-[1.188rem] mq450:leading-[0.75rem] mq800:text-[1.625rem] mq800:leading-[1rem]">
                  our
                </h2>
                <h2 className="m-0 relative text-inherit leading-[1.25rem] font-semibold font-inherit text-indigo-100 z-[2] mq450:text-[1.813rem] mq450:leading-[0.75rem] mq800:text-[2.375rem] mq800:leading-[1rem]">
                  CLOUD VPS HOSTING
                </h2>
                <h2 className="m-0 relative text-[2rem] leading-[1.25rem] font-medium font-inherit inline-block min-w-[5.5rem] z-[2] mq450:text-[1.188rem] mq450:leading-[0.75rem] mq800:text-[1.625rem] mq800:leading-[1rem]">
                  Plans
                </h2>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start max-w-full">
                <div className="w-[33.25rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
                  <Locations1 uS1="/us-1@2x.png" unitedStates="United States" />
                  <Locations1 uS1="/finland-1@2x.png" unitedStates="Finland" />
                  <Locations1 uS1="/germany-1@2x.png" unitedStates="Germany" />
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[4.687rem] max-w-full mq800:gap-[1.188rem] mq1150:gap-[2.313rem]">
            <div className="self-stretch flex flex-col items-end justify-start gap-[0.812rem] max-w-full">
              <div className="self-stretch flex flex-col items-start justify-start gap-[4rem] max-w-full mq800:gap-[1rem] mq1150:gap-[2rem]">
                <SharedVCPUContent sharedVCPUx86="Shared vCPU(x86)" />
                <div className="self-stretch rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[3.5rem] box-border gap-[1.937rem] max-w-full z-[1] mq800:gap-[0.938rem] mq800:pb-[2.25rem] mq800:box-border">
                  <div className="self-stretch h-[39.25rem] relative rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] hidden" />
                  <SharedPlanTable />
                  <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.937rem] pr-[0.75rem] box-border max-w-full">
                    <div className="flex-1 flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
                      <GroupComponent13
                        cX11="CX11"
                        intel="1 Intel"
                        gB="2 GB"
                        gB1="20 GB"
                        iPv4v6="IPv4/v6"
                        mo="$5/mo"
                      />
                      <GroupComponent6
                        cPX11="CPX11"
                        aMD="2 AMD"
                        gB="2 GB"
                        gB1="40 GB"
                        mo="$6/mo"
                      />
                      <GroupComponent13
                        propOverflowX="auto"
                        cX11="CX21"
                        intel="2 Intel"
                        gB="4 GB"
                        gB1="40 GB"
                        iPv4v6="IPv4/v6"
                        mo="$7/mo"
                      />
                      <GroupComponent6
                        cPX11="CPX21"
                        aMD="3 AMD"
                        gB="4 GB"
                        gB1="80 GB"
                        mo="$10/mo"
                      />
                      <GroupComponent13
                        propOverflowX="auto"
                        cX11="CX31"
                        intel="2 Intel"
                        gB="8 GB"
                        gB1="80 GB"
                        iPv4v6="Ipv4"
                        mo="$13/mo"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <SharedVCPUContent
                propAlignSelf="unset"
                propWidth="64.75rem"
                sharedVCPUx86="Dedicated vCPU"
              />
            </div>
            <div className="self-stretch rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[2.875rem] box-border gap-[1.937rem] max-w-full z-[1] mq450:pb-[1.875rem] mq450:box-border mq800:gap-[0.938rem]">
              <div className="self-stretch h-[26.75rem] relative rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] hidden" />
              <SharedPlanTable />
              <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.937rem] pr-[0.75rem] box-border max-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
                  <GroupComponent6
                    cPX11="CCX12"
                    aMD="2 Intel"
                    gB="8 GB"
                    gB1="80 GB"
                    mo="$30/mo"
                  />
                  <GroupComponent6
                    cPX11="CCX22"
                    aMD="4 AMD"
                    gB="16 GB"
                    gB1="160 GB"
                    mo="$50/mo"
                  />
                  <GroupComponent6
                    cPX11="CCX32"
                    aMD="8 AMD"
                    gB="32 GB"
                    gB1="240 GB"
                    mo="$100/mo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FQAKvmVpsHosting />
      <section className="self-stretch shadow-[0px_-4px_4px_rgba(0,_0,_0,_0.25)_inset,_0px_4px_4px_rgba(0,_0,_0,_0.25)_inset] bg-darkslategray-100 flex flex-row items-start justify-between py-[3.437rem] pl-[17.062rem] pr-[11rem] box-border max-w-full gap-[1.25rem] text-left text-[1.5rem] text-white font-poppins mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq800:pl-[8.5rem] mq800:pr-[5.5rem] mq800:box-border mq1350:flex-wrap mq1350:justify-center">
        <div className="h-[13.25rem] w-[89.438rem] relative shadow-[0px_-4px_4px_rgba(0,_0,_0,_0.25)_inset,_0px_4px_4px_rgba(0,_0,_0,_0.25)_inset] bg-darkslategray-100 hidden max-w-full" />
        <div className="flex flex-col items-start justify-start gap-[0.375rem] min-w-[27.688rem] max-w-full mq1150:min-w-full mq1350:flex-1">
          <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit z-[1] mq450:text-[1.188rem] mq450:leading-[2.313rem]">
            Your best VPS hosting option is here:
          </h2>
          <div className="rounded-3xs bg-white flex flex-row items-start justify-start py-[0.125rem] px-[3.187rem] whitespace-nowrap z-[1] text-[1.125rem] text-black">
            <div className="h-[3.125rem] w-[11.25rem] relative rounded-3xs bg-white hidden" />
            <h3 className="m-0 relative text-inherit leading-[2.875rem] font-medium font-inherit inline-block min-w-[4.875rem] z-[2]">
              Buy Now
            </h3>
          </div>
        </div>
        <div className="w-[22.813rem] flex flex-col items-start justify-start pt-[1.75rem] px-[0rem] pb-[0rem] box-border max-w-full text-[4rem]">
          <h1 className="m-0 self-stretch relative text-inherit leading-[2.875rem] font-semibold font-inherit z-[1] mq450:text-[2.375rem] mq450:leading-[1.75rem] mq800:text-[3.188rem] mq800:leading-[2.313rem]">
            $8/month*
          </h1>
        </div>
      </section>
      <FooterComponent
        hOSTINGMargin="0"
        dOMAINSSSLMargin="0"
        cOMPANYMargin="0"
        wordPressHostingTextDecoration="none"
        aboutUsTextDecoration="none"
        cheapSSDHostingTextDecoration="none"
        supportTextDecoration="none"
        vPSHostingTextDecoration="none"
        blogTextDecoration="none"
        dedicatedHostingTextDecoration="none"
        offersTextDecoration="none"
        contactUsTextDecoration="none"
        privacyPolicyTextDecoration="none"
        sitemapTextDecoration="none"
      />
    </div>
  );
};

export default KVMVPS;

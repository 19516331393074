import { FunctionComponent, memo } from "react";
import PlanPricingTableHead from "./PlanPricingTableHead";
import GroupComponent32 from "./GroupComponent32";

export type KvmVpsServerPlanPriceType = {
  className?: string;
};

const KvmVpsServerPlanPrice: FunctionComponent<KvmVpsServerPlanPriceType> =
  memo(({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[1.687rem] pl-[1.375rem] pr-[1.25rem] box-border max-w-full text-left text-[1.25rem] text-darkslategray-100 font-poppins ${className}`}
      >
        <div className="w-[69.313rem] flex flex-col items-start justify-start gap-[0.812rem] max-w-full">
          <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[2.062rem] box-border gap-[2.875rem] max-w-full mq800:gap-[1.438rem]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[0.875rem] max-w-full">
              <div className="self-stretch flex flex-col items-start justify-start gap-[0.187rem]">
                <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.375rem] pr-[1.25rem]">
                  <h2 className="m-0 relative text-inherit tracking-[0.11em] leading-[3.125rem] font-medium font-inherit mq450:text-[1rem] mq450:leading-[2.5rem]">
                    Choose the best KVM VPS server
                  </h2>
                </div>
                <div className="relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
                  Take a step up by upgrading your business with the best and
                  cheap Linux KVM VPS servers and choose the plan that is
                  suitable for your business requirements.
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[4.187rem] pr-[4.25rem] box-border max-w-full text-center text-[0.875rem] text-slategray-100 mq1150:pl-[2.063rem] mq1150:pr-[2.125rem] mq1150:box-border">
                <div className="flex-1 relative leading-[1.25rem] inline-block max-w-full">
                  Linux-based VPS hosting with KVM virtualization is one of the
                  best solutions for fulfilling your hosting needs. With our
                  highly secured infrastructure and best-in-class service,
                  Hostlasting provides an entire set of solutions designed to
                  empower your businesses and websites with necessary
                  flexibility and technical expertise.
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.312rem] text-[1.5rem]">
              <div className="flex flex-col items-start justify-start gap-[0.187rem]">
                <div className="flex flex-row items-start justify-start py-[0rem] pl-[1.812rem] pr-[1.875rem]">
                  <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">{`Plans & Pricing`}</h2>
                </div>
                <div className="relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
                  Choose from our affordable plans.
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.187rem] pr-[1.312rem] box-border max-w-full text-center text-[0.875rem] text-white">
            <div className="flex-1 rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[5.812rem] box-border gap-[1.937rem] max-w-full mq800:gap-[0.938rem] mq800:pb-[3.75rem] mq800:box-border">
              <div className="self-stretch h-[35.625rem] relative rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] hidden" />
              <PlanPricingTableHead />
              <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.937rem] pr-[0.75rem] box-border max-w-full text-[0.813rem] text-slategray-100">
                <div className="flex-1 flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
                  <GroupComponent32
                    vPSL2="VPS-L2"
                    x260GHzCPU="1 x 2.60GHz CPU"
                    gB="2 GB"
                    gB1="20 GB"
                    tB="2 TB"
                    mo="$8/mo"
                  />
                  <GroupComponent32
                    vPSL2="VPS-L4"
                    x260GHzCPU="1 x 2.60GHz CPU"
                    gB="4 GB"
                    gB1="40 GB"
                    tB="4 TB"
                    mo="$13/mo"
                  />
                  <GroupComponent32
                    vPSL2="VPS-L8"
                    x260GHzCPU="2 x 2.60GHz CPU"
                    gB="8 GB"
                    gB1="80 GB"
                    tB="8 TB"
                    mo="$22/mo"
                  />
                  <GroupComponent32
                    vPSL2="VPS-L16"
                    x260GHzCPU="4 x 2.60GHz CPU"
                    gB="16 GB"
                    gB1="160 GB"
                    tB="16 TB"
                    mo="$38/mo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[4.187rem] pr-[4.25rem] box-border max-w-full text-[1.5rem] mq1150:pl-[2.063rem] mq1150:pr-[2.125rem] mq1150:box-border">
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.875rem] max-w-full">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.312rem] box-border max-w-full">
                <div className="w-[23.938rem] flex flex-col items-start justify-start gap-[1.062rem] max-w-full">
                  <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem]">
                    <div className="w-[9.75rem] flex flex-col items-start justify-start gap-[0.812rem]">
                      <img
                        className="w-[9.75rem] h-[9.75rem] relative object-cover"
                        loading="lazy"
                        alt=""
                        src="/4be87fb9fb4945a7a33271afcbac6186a-4@2x.png"
                      />
                      <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[2.5rem] pr-[2.562rem]">
                        <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600" />
                      </div>
                    </div>
                  </div>
                  <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
                    What is Linux KVM VPS hosting?
                  </h2>
                </div>
              </div>
              <div className="self-stretch relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
                Linux Kernel-based Virtual Machine (KVM) VPS hosting is a type
                of virtualization in which VPS operates as a separate server and
                virtualization environment is built on a standard Linux Kernel.
                This virtualization environment runs on hardware and operates
                various virtual machines in the process. With the support from
                Linux-based operating system and software, you can avail
                reliable, secure, and stable VPS hosting services. Moreover,
                Hostlasting’s set of solutions is cost-effective and scalable.
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  });

export default KvmVpsServerPlanPrice;

import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type ToggleFAQType = {
  className?: string;
  title?: string;

  /** Style props */
  frameDivAlignSelf?: CSSProperties["alignSelf"];
  frameDivPadding?: CSSProperties["padding"];
  frameDivFlex?: CSSProperties["flex"];
  frameDivPadding1?: CSSProperties["padding"];
};

const ToggleFAQ: FunctionComponent<ToggleFAQType> = memo(
  ({
    className = "",
    title,
    frameDivAlignSelf,
    frameDivPadding,
    frameDivFlex,
    frameDivPadding1,
  }) => {
    const frameDivStyle: CSSProperties = useMemo(() => {
      return {
        alignSelf: frameDivAlignSelf,
        padding: frameDivPadding,
        flex: frameDivFlex,
      };
    }, [frameDivAlignSelf, frameDivPadding, frameDivFlex]);

    const frameDiv1Style: CSSProperties = useMemo(() => {
      return {
        padding: frameDivPadding1,
      };
    }, [frameDivPadding1]);

    return (
      <div
        className={`self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-between pt-[0.5rem] pb-[0.562rem] pl-[4.687rem] pr-[3.812rem] box-border max-w-full gap-[1.25rem] text-left text-[0.875rem] text-black font-poppins mq1050:pl-[2.313rem] mq1050:pr-[1.875rem] mq1050:box-border ${className}`}
        style={frameDivStyle}
      >
        <div className="h-[4rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
        <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium z-[1]">
          {title}
        </div>
        <div
          className="flex flex-col items-start justify-start pt-[1.25rem] px-[0rem] pb-[0rem]"
          style={frameDiv1Style}
        >
          <img
            className="w-[0.75rem] h-[0.375rem] relative z-[1]"
            loading="lazy"
            alt=""
            src="/vector-337.svg"
          />
        </div>
      </div>
    );
  }
);

export default ToggleFAQ;

import { FunctionComponent, memo } from "react";
import GroupComponent33 from "./GroupComponent33";

export type FrameComponent72Type = {
  className?: string;
};

const FrameComponent72: FunctionComponent<FrameComponent72Type> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[8.25rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins mq1150:pb-[5.375rem] mq1150:box-border mq450:pb-[3.5rem] mq450:box-border ${className}`}
      >
        <div className="w-[66.813rem] flex flex-col items-start justify-start gap-[3.437rem] max-w-full mq800:gap-[1.688rem]">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <div className="w-[26.438rem] flex flex-col items-start justify-start gap-[0.187rem] max-w-full">
              <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">{`Cheap Storage VPS Plans & Pricing`}</h2>
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem] text-center text-[0.875rem] text-slategray-100">
                <div className="relative leading-[1.25rem]">
                  Choose from our affordable plans.
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[5.312rem] box-border relative gap-[1.937rem] max-w-full text-center text-[0.875rem] text-white mq800:gap-[0.938rem] mq800:pb-[3.438rem] mq800:box-border">
            <div className="self-stretch h-[35.125rem] relative rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] hidden z-[0]" />
            <div className="self-stretch rounded-t-11xl rounded-b-none bg-indigo-300 overflow-x-auto flex flex-row items-start justify-start pt-[1.937rem] px-[4.625rem] pb-[1.562rem] gap-[4.687rem] z-[1] mq1150:gap-[2.313rem] mq1150:pl-[2.313rem] mq1150:pr-[2.313rem] mq1150:box-border mq450:gap-[1.188rem]">
              <div className="h-[4.75rem] w-[66.813rem] relative rounded-t-11xl rounded-b-none bg-indigo-300 shrink-0 hidden" />
              <div className="w-[8.688rem] shrink-0 flex flex-col items-start justify-start">
                <div className="relative leading-[1.25rem] font-medium inline-block min-w-[2.188rem] z-[2]">
                  PLAN
                </div>
              </div>
              <div className="w-[4.563rem] shrink-0 flex flex-col items-start justify-start">
                <div className="relative leading-[1.25rem] font-medium inline-block min-w-[1.813rem] z-[2]">
                  CPU
                </div>
              </div>
              <div className="relative leading-[1.25rem] font-medium inline-block min-w-[1.938rem] z-[2]">
                RAM
              </div>
              <div className="relative leading-[1.25rem] font-medium inline-block min-w-[4.063rem] z-[2]">
                STORAGE
              </div>
              <div className="relative leading-[1.25rem] font-medium inline-block min-w-[5.25rem] z-[2]">
                BANDWIDTH
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.937rem] pr-[0.75rem] box-border max-w-full text-[0.813rem] text-slategray-100">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
                <GroupComponent33
                  vPS025TB="VPS-0.25TB"
                  x170GHzCPU="1 x 1.70GHz CPU"
                  mB="256 MB"
                  gB="256 GB"
                  tB="2 TB"
                  mo="$6/mo"
                />
                <GroupComponent33
                  propPadding="1.187rem 1.812rem 1.187rem 2.687rem"
                  vPS025TB="VPS-0.5TB"
                  x170GHzCPU="1 x 1.70GHz CPU"
                  mB="512 MB"
                  gB="512 GB"
                  tB="4 TB"
                  mo="$8/mo"
                />
                <GroupComponent33
                  propPadding="1.187rem 1.812rem 1.187rem 3.062rem"
                  vPS025TB="VPS-1TB"
                  x170GHzCPU="2 x 1.70GHz CPU"
                  mB="1024 MB"
                  gB="1024 GB"
                  tB="8 TB"
                  mo="$13/mo"
                />
                <GroupComponent33
                  propPadding="1.187rem 1.812rem 1.187rem 3.187rem"
                  vPS025TB="VPS-2TB"
                  x170GHzCPU="1 x 1.70GHz CPU"
                  mB="2 GB"
                  gB="2048 GB"
                  tB="16 TB"
                  mo="$21/mo"
                />
              </div>
            </div>
            <img
              className="w-[12.063rem] h-[12.75rem] absolute !m-[0] bottom-[-10.875rem] left-[calc(50%_-_96.5px)] object-contain z-[3]"
              loading="lazy"
              alt=""
              src="/cloudcomputingtechnologyonlinedatastoragebusinessnetworkconceptserverrackswithsecurityprotectionsystem3drendering-2@2x.png"
            />
          </div>
        </div>
      </section>
    );
  }
);

export default FrameComponent72;

import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";
import { Link } from "react-router-dom";

export type FooterComponentType = {
  className?: string;

  /** Style props */
  hOSTINGMargin?: CSSProperties["margin"];
  dOMAINSSSLMargin?: CSSProperties["margin"];
  cOMPANYMargin?: CSSProperties["margin"];
  wordPressHostingTextDecoration?: CSSProperties["textDecoration"];
  aboutUsTextDecoration?: CSSProperties["textDecoration"];
  cheapSSDHostingTextDecoration?: CSSProperties["textDecoration"];
  supportTextDecoration?: CSSProperties["textDecoration"];
  vPSHostingTextDecoration?: CSSProperties["textDecoration"];
  blogTextDecoration?: CSSProperties["textDecoration"];
  dedicatedHostingTextDecoration?: CSSProperties["textDecoration"];
  offersTextDecoration?: CSSProperties["textDecoration"];
  contactUsTextDecoration?: CSSProperties["textDecoration"];
  privacyPolicyTextDecoration?: CSSProperties["textDecoration"];
  sitemapTextDecoration?: CSSProperties["textDecoration"];
};

const FooterComponent: FunctionComponent<FooterComponentType> = memo(
  ({
    className = "",
    hOSTINGMargin,
    dOMAINSSSLMargin,
    cOMPANYMargin,
    wordPressHostingTextDecoration,
    aboutUsTextDecoration,
    cheapSSDHostingTextDecoration,
    supportTextDecoration,
    vPSHostingTextDecoration,
    blogTextDecoration,
    dedicatedHostingTextDecoration,
    offersTextDecoration,
    contactUsTextDecoration,
    privacyPolicyTextDecoration,
    sitemapTextDecoration,
  }) => {
    const hOSTINGStyle: CSSProperties = useMemo(() => {
      return {
        margin: hOSTINGMargin,
      };
    }, [hOSTINGMargin]);

    const dOMAINSSSLStyle: CSSProperties = useMemo(() => {
      return {
        margin: dOMAINSSSLMargin,
      };
    }, [dOMAINSSSLMargin]);

    const cOMPANYStyle: CSSProperties = useMemo(() => {
      return {
        margin: cOMPANYMargin,
      };
    }, [cOMPANYMargin]);

    const wordPressHostingStyle: CSSProperties = useMemo(() => {
      return {
        textDecoration: wordPressHostingTextDecoration,
      };
    }, [wordPressHostingTextDecoration]);

    const aboutUsStyle: CSSProperties = useMemo(() => {
      return {
        textDecoration: aboutUsTextDecoration,
      };
    }, [aboutUsTextDecoration]);

    const cheapSSDHostingStyle: CSSProperties = useMemo(() => {
      return {
        textDecoration: cheapSSDHostingTextDecoration,
      };
    }, [cheapSSDHostingTextDecoration]);

    const supportStyle: CSSProperties = useMemo(() => {
      return {
        textDecoration: supportTextDecoration,
      };
    }, [supportTextDecoration]);

    const vPSHostingStyle: CSSProperties = useMemo(() => {
      return {
        textDecoration: vPSHostingTextDecoration,
      };
    }, [vPSHostingTextDecoration]);

    const blogStyle: CSSProperties = useMemo(() => {
      return {
        textDecoration: blogTextDecoration,
      };
    }, [blogTextDecoration]);

    const dedicatedHostingStyle: CSSProperties = useMemo(() => {
      return {
        textDecoration: dedicatedHostingTextDecoration,
      };
    }, [dedicatedHostingTextDecoration]);

    const offersStyle: CSSProperties = useMemo(() => {
      return {
        textDecoration: offersTextDecoration,
      };
    }, [offersTextDecoration]);

    const contactUsStyle: CSSProperties = useMemo(() => {
      return {
        textDecoration: contactUsTextDecoration,
      };
    }, [contactUsTextDecoration]);

    const privacyPolicyStyle: CSSProperties = useMemo(() => {
      return {
        textDecoration: privacyPolicyTextDecoration,
      };
    }, [privacyPolicyTextDecoration]);

    const sitemapStyle: CSSProperties = useMemo(() => {
      return {
        textDecoration: sitemapTextDecoration,
      };
    }, [sitemapTextDecoration]);

    return (
      <div
        className={`self-stretch bg-indigo-100 flex flex-col items-start justify-start pt-[5.75rem] px-[0rem] pb-[0rem] box-border gap-[2.75rem] max-w-full text-left text-[1.25rem] text-white font-poppins mq750:gap-[1.375rem] mq750:pt-[3.75rem] mq750:box-border ${className}`}
      >
        <div className="self-stretch h-[43.5rem] relative bg-indigo-100 hidden" />
        <Link to="/" className="w-[32.688rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[1.25rem] box-border max-w-full">
          <img
            className="h-[2.438rem] w-[9.938rem] relative object-contain z-[1]"
            alt=""
            src="/hostlasting-logo-1@2x.png"
          />
        </Link>
        <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
          <div className="w-[44.688rem] flex flex-col items-start justify-start gap-[0.562rem] max-w-full">
            <div className="w-[42.875rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq750:flex-wrap">
              <div className="w-[11.313rem] flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.25rem] box-border">
                <div
                  className="relative leading-[2.875rem] font-semibold inline-block min-w-[5.625rem] z-[1] mq450:text-[1rem] mq450:leading-[2.313rem]"
                  style={hOSTINGStyle}
                >
                  HOSTING
                </div>
              </div>
              <div
                className="relative leading-[2.875rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[2.313rem]"
                style={dOMAINSSSLStyle}
              >{`DOMAINS & SSL`}</div>
              <div
                className="relative leading-[2.875rem] font-semibold inline-block min-w-[6.438rem] z-[1] mq450:text-[1rem] mq450:leading-[2.313rem]"
                style={cOMPANYStyle}
              >
                COMPANY
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start max-w-full text-[0.938rem]">
              <div className="w-[40.625rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
                <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.187rem] text-whitesmoke-200">
                  <Link to={'/wordpress-hosting'}
                    className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] z-[1]"
                    style={wordPressHostingStyle}
                  >
                    WordPress Hosting
                  </Link>
                </div>
                <Link to={'https://www.hostlasting.com/secure/cart.php?a=add&domain=register'} className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] z-[1]">
                  Register Domains
                </Link>
                <Link to={'/about-us'} className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] inline-block min-w-[4.188rem] z-[1]"
                  style={aboutUsStyle}
                >
                  About Us
                </Link>
              </div>
              <div className="w-[40.188rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
                <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.125rem] text-whitesmoke-200">
                  <Link to={'/ssd-shared-hosting'} className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] z-[2]"
                    style={cheapSSDHostingStyle}
                  >
                    Cheap SSD Hosting
                  </Link>
                </div>
                <Link to={'/domain-name-transfer'} className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] z-[2]">
                  Transfer Domains
                </Link>
                <Link to={''} className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] inline-block min-w-[3.75rem] z-[1]"
                  style={supportStyle}
                >
                  Support
                </Link>
              </div>
              <div className="w-[38.563rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
                <div className="w-[10.125rem] flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.25rem] box-border text-whitesmoke-200">
                  <Link to={'/kvm-vps-hosting'} className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] inline-block min-w-[5.563rem] z-[3]"
                    style={vPSHostingStyle}
                  >
                    VPS Hosting
                  </Link>
                </div>
                <Link to={'https://hostlasting.com/secure/index.php/login'} className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] z-[3]">
                  Manage Domains
                </Link>
                <Link to={'/blogs'} className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] inline-block min-w-[2.063rem] z-[1]"
                  style={blogStyle}
                >
                  Blog
                </Link>
              </div>
              <div className="self-stretch flex flex-row items-start justify-between gap-[1.25rem] mq450:flex-wrap">
                <div className="flex flex-col items-start justify-start text-whitesmoke-200">
                  <Link to={'/dedicated-hosting'} className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] z-[4]"
                    style={dedicatedHostingStyle}
                  >
                    Dedicated Hosting
                  </Link>
                  <Link to={'/cheap-reseller-hosting'} className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] inline-block min-w-[7.438rem] z-[5]">
                    Reseller Hosting
                  </Link>
                  <div className="flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0.187rem]">
                    <Link to={'/ssd-litespeed-hosting'} className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] z-[6]">
                      LiteSpedd Hosting
                    </Link>
                  </div>
                  <Link to={'/offers'}
                    className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] inline-block min-w-[2.813rem] z-[7]"
                    style={offersStyle}
                  >
                    Offers
                  </Link>
                </div>
                <Link to={'/cheap-ssl-certificates'} className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] inline-block min-w-[7.188rem] z-[4]">
                  SSL Certificates
                </Link>
                <div className="flex flex-col items-start justify-start">
                  <Link to={'/contact-us'} className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] inline-block min-w-[5.188rem] z-[1]"
                    style={contactUsStyle}
                  >
                    Contact Us
                  </Link>
                  <Link to={'/terms-of-services'} className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] z-[1]">
                    Terms of Services
                  </Link>
                  <Link to={'/privacy-policy'} className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] inline-block min-w-[6.375rem] z-[1]"
                    style={privacyPolicyStyle}
                  >
                    Privacy Policy
                  </Link>
                  <Link to={'/sitemap.xml'} className="[text-decoration:none] text-whitesmoke-200 relative leading-[2.875rem] inline-block min-w-[3.938rem] z-[2]"
                    style={sitemapStyle}
                  >
                    Sitemap
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-end justify-start gap-[1.937rem] max-w-full text-[0.75rem] text-black mq750:gap-[0.938rem]">
          <div className="w-[31rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
          <Link to="https://www.facebook.com/hostlasting/" className="flex flex-row items-start justify-start gap-[1.812rem]">
              <img
                className="h-[1.5rem] w-[1.5rem] relative min-h-[1.5rem] z-[1]"
                alt=""
                src="/rifacebookfill.svg"
              />
              </Link>
              <Link to="https://x.com/i/flow/login?redirect_after_login=%2Fhostlasting" className="flex flex-row items-start justify-start gap-[1.812rem]">
              <img
                className="h-[1.5rem] w-[1.5rem] relative min-h-[1.5rem] z-[1]"
                alt=""
                src="/akariconsxfill.svg"
              />
              </Link>
              <Link to="https://www.linkedin.com/company/hostlasting/" className="flex flex-row items-start justify-start gap-[1.812rem]">
              <img
                className="h-[1.5rem] w-[1.5rem] relative min-h-[1.5rem] z-[1]"
                alt=""
                src="/iconoirinstagram.svg"
              />
            </Link>
            <Link to="https://www.instagram.com/hostlasting/" className="flex flex-row items-start justify-start gap-[1.812rem]">
              <img
                className="h-[1.5rem] w-[1.5rem] relative min-h-[1.5rem] z-[1]"
                alt=""
                src="/iconoirinstagram.svg"
              />
            </Link>
          </div>
          <div className="self-stretch bg-white flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full z-[1]">
            <div className="self-stretch w-[89.438rem] relative bg-white hidden max-w-full" />
            <div className="w-[30.313rem] relative leading-[1.563rem] font-medium flex items-center shrink-0 max-w-full z-[2]">{`Copyright © Hostlasting.com , Designed & Developed by Cloud19 Technologies`}</div>
          </div>
        </div>
      </div>
    );
  }
);

export default FooterComponent;

import { FunctionComponent, memo } from "react";

export type FrameComponent22Type = {
  className?: string;
};

const FrameComponent22: FunctionComponent<FrameComponent22Type> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start relative max-w-full text-left text-[0.875rem] text-darkslategray-100 font-poppins ${className}`}
      >
        <img
          className="h-[23.5rem] w-[66.063rem] absolute !m-[0] bottom-[-21.312rem] left-[calc(50%_-_528.5px)] object-cover"
          loading="lazy"
          alt=""
          src="/vps-features-1@2x.png"
        />
        <div className="flex-1 flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[6.187rem] box-border gap-[2.5rem] max-w-full mq800:gap-[1.25rem] mq800:pb-[1.688rem] mq800:box-border mq1350:pb-[2.625rem] mq1350:box-border">
          <div className="self-stretch bg-ghostwhite-100 flex flex-col items-center justify-start pt-[2.812rem] px-[1.25rem] pb-[13.375rem] box-border gap-[4.25rem] max-w-full mq800:gap-[1.063rem] mq800:pb-[3.625rem] mq800:box-border mq1150:gap-[2.125rem] mq1350:pt-[1.25rem] mq1350:pb-[5.625rem] mq1350:box-border">
            <div className="w-[89.438rem] h-[149.813rem] relative bg-ghostwhite-100 hidden max-w-full" />
            <div className="w-[66.563rem] flex flex-row items-start justify-center py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
              <div className="w-[56.375rem] flex flex-col items-start justify-start max-w-full">
                <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
                  <h2 className="relative leading-[2.875rem] font-semibold z-[1]">
                    Know more about the cheapest VPS server hosting services
                  </h2>
                </div>
                <div className="self-stretch relative leading-[1.25rem] text-slategray-100 text-center z-[2]">
                  If you are looking for a budget-friendly VPS server hosting,
                  your search ends with Hostlasting. We offer the best plans
                  that would address your business requirements at affordable
                  prices. In addition, we have a proven track record of the
                  optimum uptime, excellent after-sales support, and providing
                  services with the best VPS hosting Europe Tier III datacenter.
                </div>
              </div>
            </div>
            <div className="w-[66.563rem] flex flex-row flex-wrap items-end justify-center gap-[7.937rem] max-w-full text-[1.25rem] text-indigo-100 mq800:gap-[2rem] mq450:gap-[1rem] mq1150:gap-[3.938rem]">
              <div className="flex-1 flex flex-col items-start justify-start gap-[4.75rem] min-w-[19.063rem] max-w-full mq800:gap-[2.375rem]">
                <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[6.25rem] pr-[1.25rem] mq450:pl-[1.25rem] mq450:box-border">
                  <div className="h-[11.875rem] w-[11.875rem] relative bg-[url('/public/ebe24492895e499ab1d02eeb589abda4a-3@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
                    <img
                      className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                      alt=""
                      src="/ebe24492895e499ab1d02eeb589abda4a-3@2x.png"
                    />
                    <div className="absolute top-[10.75rem] left-[3.563rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.75rem] box-border gap-[1.125rem] max-w-full">
                  <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] box-border max-w-full">
                    <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      European server infrastructure:
                    </h3>
                  </div>
                  <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                    With the best VPS hosting Tier III datacenter from Europe,
                    our servers are designed to provide the excellent
                    performance and maximum uptime. Our servers are located at
                    Lithuania, Europe. The locations of the servers are chosen
                    to offer high-speed performance and provide affordable
                    services.
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[3.437rem] pl-[6.187rem] pr-[1.25rem] mq450:pl-[1.25rem] mq450:box-border">
                  <div className="w-[8.813rem] flex flex-col items-start justify-start gap-[0.437rem]">
                    <img
                      className="w-[8.813rem] h-[8.813rem] relative object-cover z-[1]"
                      loading="lazy"
                      alt=""
                      src="/2-weba-5@2x.png"
                    />
                    <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[2.062rem]">
                      <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[0.75rem] pl-[0.125rem] pr-[0rem] box-border max-w-full">
                  <div className="flex-1 flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                    <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] box-border max-w-full">
                      <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">{`Budget-friendly & high-quality services:`}</h3>
                    </div>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                      Hostlasting offers services of the high-quality at
                      affordable rates. Our plans are designed to offer
                      excellent services at the cheapest rates. This would
                      enable you to address your VPS hosting needs while saving
                      a lot of costs.
                    </div>
                  </div>
                </div>
                <div className="self-stretch h-[13.25rem] flex flex-row items-start justify-center pt-[0rem] pb-[0.625rem] pl-[5.625rem] pr-[1.25rem] box-border mq450:pl-[1.25rem] mq450:box-border">
                  <div className="self-stretch w-[11.25rem] flex flex-col items-end justify-start gap-[2.062rem]">
                    <img
                      className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover z-[1]"
                      loading="lazy"
                      alt=""
                      src="/3d-illustration-server-security-and-unlocka-3@2x.png"
                    />
                    <div className="self-stretch flex flex-row items-start justify-end py-[0rem] px-[3rem]">
                      <div className="h-[0.375rem] w-[4.688rem] relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.312rem] gap-[1.125rem]">
                  <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                    <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Backup facility:
                    </h3>
                  </div>
                  <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                    Our premium services enable the automatic backups of
                    databases and files. This will keep your entire data safe in
                    case any unfortunate events occur. Our well-designed plans
                    and services provide the weekly backup services.
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[0.375rem] pl-[5.562rem] pr-[1.25rem] mq450:pl-[1.25rem] mq450:box-border">
                  <div className="w-[9.938rem] flex flex-col items-end justify-start gap-[2.062rem]">
                    <img
                      className="w-[9.938rem] h-[9.938rem] relative object-cover z-[1]"
                      loading="lazy"
                      alt=""
                      src="/fp0124-spacewalk-1@2x.png"
                    />
                    <div className="self-stretch flex flex-row items-start justify-end py-[0rem] px-[2.312rem]">
                      <div className="h-[0.375rem] w-[4.688rem] relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem]">
                  <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                    <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Around the clock support:
                    </h3>
                  </div>
                  <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                    Our team of professionals, consultants, and experts will
                    help you at any time and from anywhere. Solving your queries
                    and providing solutions all the issues will always be our
                    priority. Hostlasting takes pride in providing the
                    best-in-class service.
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.393rem] box-border min-w-[19rem] max-w-full">
                <div className="self-stretch flex flex-col items-start justify-start gap-[4.625rem] max-w-full mq800:gap-[2.313rem]">
                  <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[2rem] box-border gap-[1.125rem] max-w-full">
                    <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit inline-block max-w-full z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Secured and multi-layer protected storage:
                    </h3>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                      Our servers have a multi-layered protection, enabled
                      through a firewall configuration. This will keep your
                      valuable data safe and secure as the cybercriminals will
                      be kept at bay with stringent security measures.
                    </div>
                  </div>
                  <div className="w-[24.5rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                    <div className="w-[13.375rem] flex flex-col items-start justify-start gap-[1.25rem]">
                      <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.437rem] pr-[0.5rem]">
                        <img
                          className="h-[8.5rem] flex-1 relative rounded-31xl max-w-full overflow-hidden object-cover z-[1]"
                          loading="lazy"
                          alt=""
                          src="/realisticlithuaniaflagbackground-1@2x.png"
                        />
                      </div>
                      <div className="self-stretch h-[0.813rem] relative [filter:blur(14.5px)] rounded-[50%] bg-gray-1000 z-[1]" />
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[3.562rem] gap-[1.125rem]">
                    <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Right tools at your disposal:
                    </h3>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                      Our services make it easy for you to implement VPS hosting
                      solutions and control panel functions. With the access to
                      control panel, we provide tools such as free DNS
                      management, hostname management, and emergency access
                      console for enabling you to access your server.
                    </div>
                  </div>
                  <div className="w-[24.438rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[1.375rem] box-border max-w-full">
                    <div className="h-[9.188rem] w-[9.188rem] relative bg-[url('/public/iconbusinessv3-9-a-3@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
                      <img
                        className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                        alt=""
                        src="/iconbusinessv3-9-a-3@2x.png"
                      />
                      <div className="absolute top-[8.813rem] left-[2.25rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[3.125rem] gap-[1.125rem]">
                    <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Software stack store:
                    </h3>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                      Our full-scale and secure data storage platform provides
                      scalability, flexibility, and control over the functions
                      with WHM, VestaCP, Webmin, cPanel, Plesk, and others.
                      Moreover, you can set up email accounts for your websites
                      and businesses.
                    </div>
                  </div>
                  <div className="w-[24.5rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[0.812rem] box-border max-w-full">
                    <div className="w-[8.75rem] flex flex-col items-start justify-start">
                      <img
                        className="w-[8.75rem] h-[8.75rem] relative object-cover z-[1]"
                        loading="lazy"
                        alt=""
                        src="/86e2e2fcf66c41a4a2f792b940aabe0ca-51@2x.png"
                      />
                      <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[2rem] pr-[2.062rem]">
                        <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[2]" />
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[3.125rem] gap-[1.125rem]">
                    <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Free assistance and guidance:
                    </h3>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                      Our team of experts is at your disposal whenever you need
                      them. We provide necessary guidance and assistance about
                      each aspect of our services without any additional costs.
                    </div>
                  </div>
                  <div className="w-[24.438rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                    <div className="w-[7.688rem] flex flex-col items-start justify-start gap-[1.381rem]">
                      <img
                        className="w-[7.688rem] h-[7.688rem] relative object-cover z-[1]"
                        loading="lazy"
                        alt=""
                        src="/complain-to-customer-servicea-11@2x.png"
                      />
                      <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.375rem] pr-[1.437rem]">
                        <div className="h-[0.538rem] flex-1 relative [filter:blur(11.4px)] rounded-[50%] bg-gray-1300 z-[1]" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem] text-[1.5rem]">
            <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
              One click install OS Dockers
            </h3>
          </div>
        </div>
      </section>
    );
  }
);

export default FrameComponent22;

import { FunctionComponent } from "react";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";

const LinuxResellerHosting: FunctionComponent = () => {
  return (
    <div className="w-full h-[691.688rem] relative bg-white overflow-hidden leading-[normal] tracking-[normal] text-left text-[0.875rem] text-indigo-100 font-poppins mq450:h-auto mq450:min-h-[11067]">
      <section className="absolute top-[0rem] left-[0rem] [background:linear-gradient(255.11deg,_#b79fc7,_#43006e_83.56%)] w-full max-w-full mq450:gap-[2.125rem] mq725:gap-[4.188rem]">
        <img
          className="absolute top-[10.063rem] left-[67.5rem] w-[12.375rem] h-[15.125rem] object-contain"
          loading="lazy"
          alt=""
          src="/web-designv1-9a-2@2x.png"
        />
      </section>
      <img
        className="absolute top-[14.75rem] left-[44rem] w-[35.313rem] h-[26.5rem] object-contain hidden"
        alt=""
        src="/hostlastingslide2-3@2x.png"
      />
      <header className="w-full sticky top-[0] left-[0rem] bg-indigo-100 overflow-hidden z-[99] gap-[1.25rem] max-w-full mq450:pr-[1.25rem] mq450:box-border mq725:pl-[1.875rem] mq725:pr-[2.75rem] mq725:box-border" />
      <div className="absolute top-[18.594rem] left-[43.469rem] border-white border-r-[3px] border-solid box-border w-[0.188rem] h-[9.938rem] hidden" />
      <h2 className="m-0 absolute top-[123.25rem] left-[calc(50%_-_338.5px)] text-[1.5rem] leading-[2.875rem] font-semibold font-inherit text-darkslategray-100 mq450:text-[1.188rem] mq450:leading-[2.313rem]">
        Reasons to Start Web Hosting Business with Hostlasting
      </h2>
      <div className="absolute top-[137.063rem] left-[48.438rem] w-[29.25rem] h-[6.25rem] text-[0.938rem] text-slategray-100">
        <div className="w-full absolute top-[2.375rem] left-[0rem] tracking-[0.04em] leading-[1.25rem] inline-block">
          If you are not satisfied with our services and want to rescind them,
          we offer a full refund within 30 days from the date of purchase of
          your plan.
        </div>
        <h3 className="absolute top-[0rem] left-[0rem] text-[1.25rem] tracking-[0.04em] leading-[1.25rem] font-semibold text-indigo-100 inline-block max-w-full mq450:text-[1rem] mq450:leading-[1rem]">{`30-Day Money Back Guarantee `}</h3>
      </div>
      <h3 className="absolute top-[189.25rem] left-[48.75rem] text-[1.25rem] tracking-[0.04em] leading-[1.25rem] font-semibold mq450:text-[1rem] mq450:leading-[1rem]">
        Enhanced Performance
      </h3>
      <h3 className="absolute top-[204.75rem] left-[11.438rem] text-[1.25rem] tracking-[0.04em] leading-[1.25rem] font-semibold mq450:text-[1rem] mq450:leading-[1rem]">
        Unlimited Email Accounts
      </h3>
      <div className="absolute top-[163.938rem] left-[48.75rem] w-[29.25rem] h-[7.5rem] text-[1.25rem]">
        <h3 className="absolute top-[0rem] left-[0rem] tracking-[0.04em] leading-[1.25rem] font-semibold mq450:text-[1rem] mq450:leading-[1rem]">
          24*7 Support
        </h3>
        <div className="w-full absolute top-[2.375rem] left-[0rem] text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 inline-block">
          Our team of Linux reseller hosting experts and skilled professionals
          are at your service around the clock. We will help you in solving your
          problems and addressing each of your queries.
        </div>
      </div>
      <div className="w-full absolute top-[191.125rem] left-[48.75rem] text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 inline-block">
        With over a decade experience in the domain, deployment of top-notch
        hardware with high-performance processors, and finely-tuned SSD reseller
        web hosting plans, our services are aimed at improving performance for
        your cheap reseller hosting business.
      </div>
      <div className="absolute top-[219.625rem] left-[48.75rem] w-[29.25rem] h-[8.313rem] text-[1.25rem]">
        <h3 className="absolute top-[0rem] left-[0rem] tracking-[0.04em] leading-[1.25rem] font-semibold mq450:text-[1rem] mq450:leading-[1rem]">
          Effortless Upgrades
        </h3>
        <div className="w-full absolute top-[2.375rem] left-[0rem] text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center h-[5.938rem]">
          We ensure your business activities are not interrupted if the need for
          more resources arises. Please contact us to upgrade to a different
          plan and we help you upgrade without interrupting your business
          processes and at no additional cost.
        </div>
      </div>
      <div className="absolute top-[149.563rem] left-[11.438rem] w-[29.25rem] h-[7.5rem] text-[1.25rem]">
        <h3 className="absolute top-[0rem] left-[0rem] tracking-[0.04em] leading-[1.25rem] font-semibold mq450:text-[1rem] mq450:leading-[1rem]">{`cPanel Access At No Cost `}</h3>
        <div className="w-full absolute top-[2.375rem] left-[0rem] text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 inline-block">{`We provide reseller cpanel web hosting to help you manage multiple accounts, set up different plans, create customized hosting plans, upgrade & downgrade customer accounts, and others.`}</div>
      </div>
      <div className="absolute top-[177rem] left-[11.438rem] w-[29.25rem] h-[7.5rem] text-[1.25rem]">
        <h3 className="absolute top-[0rem] left-[0rem] tracking-[0.04em] leading-[1.25rem] font-semibold inline-block max-w-full mq450:text-[1rem] mq450:leading-[1rem]">
          Softaculous One-Click Installer
        </h3>
        <div className="w-full absolute top-[2.375rem] left-[0rem] text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 inline-block">
          You can install more than 400 website apps in a single click with our
          Softaculous one-click installer. This will save a lot of time as you
          need to install apps such as Wordpress, Drupal, and other for multiple
          websites
        </div>
      </div>
      <div className="w-full absolute top-[205.813rem] left-[11.438rem] text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 inline-block">
        Our plans are designed in such a way that you will be able to provide
        your clients with an ability to create multiple business email accounts.
        In addition, these email accounts can be accessed from anywhere.
      </div>
      <div className="absolute top-[232.5rem] left-[11.438rem] w-[29.25rem] h-[7.5rem] text-[1.25rem]">
        <h3 className="absolute top-[0rem] left-[0rem] tracking-[0.04em] leading-[1.25rem] font-semibold inline-block max-w-full mq450:text-[1rem] mq450:leading-[1rem]">
          Free of Cost Hosting Migration
        </h3>
        <div className="w-full absolute top-[2.375rem] left-[0rem] text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 inline-block">
          Our team of experts will help you to migrate from your current cPanel
          hosting to our cPanel hosting service without incurring any cost.
          Please contact us with the request and we will be obliged to help you.
        </div>
      </div>
      <img
        className="absolute top-[54.125rem] left-[6.625rem] w-[4.813rem] h-[4.063rem] object-cover"
        loading="lazy"
        alt=""
        src="/11684121-4391-4@2x.png"
      />
      <img
        className="w-full h-full absolute top-[60.125rem] left-[78rem] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/11684121-4391-4@2x.png"
      />
      <img
        className="absolute top-[16.75rem] left-[43.875rem] w-[21.938rem] h-[4.625rem] object-contain"
        loading="lazy"
        alt=""
        src="/icon-1@2x.png"
      />
      <img
        className="absolute top-[23.063rem] left-[53.75rem] w-[24.063rem] h-[8.063rem] object-contain"
        alt=""
        src="/icon-2-1@2x.png"
      />
      <img
        className="h-full absolute top-[33.25rem] left-[61.625rem] max-h-full w-[15.875rem] object-contain"
        loading="lazy"
        alt=""
        src="/icon-1-1@2x.png"
      />
      <div className="absolute top-[61.875rem] left-[20rem] w-[49.45rem] h-[3.125rem]">
        <img
          className="absolute top-[0.688rem] left-[0rem] w-[8.438rem] h-[1.75rem] object-contain"
          alt=""
          src="/icon-2@2x.png"
        />
        <img
          className="h-full absolute top-[0rem] left-[20.938rem] max-h-full w-[9.375rem] object-cover"
          loading="lazy"
          alt=""
          src="/icon-2-2@2x.png"
        />
        <img
          className="w-full absolute top-[0.688rem] left-[42.813rem] max-w-full overflow-hidden h-[1.75rem] object-contain"
          alt=""
          src="/icon-1-2@2x.png"
        />
      </div>
      <div className="absolute top-[25.188rem] left-[11.438rem] text-[1rem] tracking-[0.11em] leading-[3.125rem] font-medium text-lightgray-100">
        Cheap Unlimited
      </div>
      <h1 className="m-0 absolute top-[27.875rem] left-[11.438rem] text-[2.5rem] tracking-[0.11em] leading-[3.125rem] font-semibold font-inherit text-white mq450:text-[1.5rem] mq450:leading-[1.875rem] mq1000:text-[2rem] mq1000:leading-[2.5rem]">
        RESELLER HOSTING
      </h1>
      <div className="h-full absolute top-[32.125rem] left-[11.438rem] rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none [background:linear-gradient(90.01deg,_#490873,_#fff)] w-[32.938rem] max-w-full" />
      <div className="absolute top-[55.563rem] left-[26.188rem] leading-[1.25rem] font-medium text-slategray-100 text-center">
        <p className="m-0">cPanel + WHM + WHMCS License</p>
        <h2 className="m-0">SSD Linux Cheap Reseller Hosting Plans</h2>
        <p className="m-0">
          Choose from our affordable reseller hosting plans from $4/month and
          start earning.
        </p>
      </div>
      <div className="absolute top-[66.688rem] left-[4.125rem] w-[81.25rem] h-[50.75rem] hidden text-center text-[0.688rem] text-black">
        <div className="absolute top-[1.75rem] left-[3.563rem] w-[17.438rem] h-[44.813rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border w-full h-full" />
          <div className="absolute w-[calc(100%_-_27px)] top-[0.875rem] right-[0.813rem] left-[0.875rem] rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#407bff)] h-[8.188rem]" />
          <div className="absolute top-[3.5rem] left-[6.188rem] text-[2.25rem] tracking-[0.1em] leading-[2.938rem] font-medium text-white mq450:text-[1.375rem] mq450:leading-[1.75rem] mq1000:text-[1.813rem] mq1000:leading-[2.375rem]">
            LIVE
          </div>
          <div className="absolute top-[10rem] left-[4.125rem] text-[1.5rem] leading-[2.938rem] font-semibold mq450:text-[1.188rem] mq450:leading-[2.375rem]">
            ₹70/mo($1)
          </div>
          <div className="absolute top-[24.063rem] left-[4.563rem] text-[0.938rem] leading-[2.938rem] font-semibold text-lightgray-200">
            Free Let's Encrypt
          </div>
          <div className="absolute top-[13.313rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[2.125rem] leading-[2.938rem] font-medium">
              Host 1 Website
            </div>
          </div>
          <div className="absolute top-[18.313rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.813rem] leading-[2.938rem] font-medium">
              5 GB bandwidth
            </div>
          </div>
          <div className="absolute top-[20.813rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[2.438rem] leading-[2.938rem] font-medium">
              3 FTP, MySQL
            </div>
          </div>
          <div className="absolute top-[27.313rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[2.688rem] leading-[2.938rem] font-medium">
              10 Email ID
            </div>
          </div>
          <div className="absolute top-[29.688rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.875rem] leading-[2.938rem] font-medium">
              cPanel Account
            </div>
          </div>
          <div className="absolute top-[32.063rem] left-[2.5rem] w-[12.438rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[12.438rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.75rem] leading-[2.938rem] font-medium">
              99.99% LiteSpeed Uptime
            </div>
          </div>
          <div className="absolute top-[15.813rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.625rem] leading-[2.938rem] font-medium">
              5 GB SSD Storage
            </div>
          </div>
          <div className="absolute top-[35.938rem] left-[0.75rem] rounded-8xs bg-white border-whitesmoke-400 border-[1px] border-solid box-border w-[16rem] h-[2.875rem]" />
          <img
            className="absolute w-[4.3%] top-[37.188rem] right-[12.19%] left-[83.51%] max-w-full overflow-hidden h-[0.375rem] object-contain"
            alt=""
            src="/vector-335.svg"
          />
          <div className="absolute top-[35.875rem] left-[2.438rem] text-[0.75rem] leading-[2.938rem] font-medium text-slategray-100">
            3Y ₹2520 - 70/mo
          </div>
          <div className="absolute top-[40.438rem] left-[4.063rem] rounded-8xs bg-royalblue-100 w-[9.313rem] h-[2.438rem]" />
          <a href="#" className="[text-decoration:none] inline-block absolute top-[40.188rem] left-[6.625rem] text-[0.75rem] leading-[2.938rem] font-semibold text-white">
            Order Now
          </a>
        </div>
        <div className="absolute top-[1.625rem] left-[22.438rem] w-[17.438rem] h-[44.813rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border w-full h-full" />
          <div className="absolute w-[calc(100%_-_27px)] top-[0.875rem] right-[0.813rem] left-[0.875rem] rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#407bff)] h-[8.188rem]" />
          <div className="absolute top-[3.5rem] left-[4.5rem] text-[2.25rem] tracking-[0.1em] leading-[2.938rem] font-medium text-white mq450:text-[1.375rem] mq450:leading-[1.75rem] mq1000:text-[1.813rem] mq1000:leading-[2.375rem]">
            LAUGH
          </div>
          <div className="absolute top-[10rem] left-[3.438rem] text-[1.5rem] leading-[2.938rem] font-semibold mq450:text-[1.188rem] mq450:leading-[2.375rem]">
            ₹140/mo ($2)
          </div>
          <div className="absolute top-[24.063rem] left-[4.563rem] text-[0.938rem] leading-[2.938rem] font-semibold text-lightgray-200">
            Free Let's Encrypt
          </div>
          <div className="absolute top-[13.313rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[2.125rem] leading-[2.938rem] font-medium">
              Host 1 Website
            </div>
          </div>
          <div className="absolute top-[18.313rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[0.938rem] leading-[2.938rem] font-medium">
              Unlimited bandwidth
            </div>
          </div>
          <div className="absolute top-[20.813rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1rem] leading-[2.938rem] font-medium">
              Unlimited FTP, MySQL
            </div>
          </div>
          <div className="absolute top-[27.313rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.375rem] leading-[2.938rem] font-medium">
              Unlimited Email ID
            </div>
          </div>
          <div className="absolute top-[29.688rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.875rem] leading-[2.938rem] font-medium">
              cPanel Account
            </div>
          </div>
          <div className="absolute top-[32.063rem] left-[2.5rem] w-[12.438rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[12.438rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.75rem] leading-[2.938rem] font-medium">
              99.99% LiteSpeed Uptime
            </div>
          </div>
          <div className="absolute top-[15.813rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[0.75rem] leading-[2.938rem] font-medium">
              Unlimited SSD Storage
            </div>
          </div>
          <div className="absolute top-[35.938rem] left-[0.75rem] rounded-8xs bg-white border-whitesmoke-400 border-[1px] border-solid box-border w-[16rem] h-[2.875rem]" />
          <img
            className="absolute w-[4.3%] top-[37.188rem] right-[12.19%] left-[83.51%] max-w-full overflow-hidden h-[0.375rem] object-contain"
            alt=""
            src="/vector-335.svg"
          />
          <div className="absolute top-[35.875rem] left-[2.25rem] text-[0.75rem] leading-[2.938rem] font-medium text-slategray-100">
            3Y ₹5040 - 140/mo
          </div>
          <div className="absolute top-[40.438rem] left-[4.063rem] rounded-8xs bg-royalblue-100 w-[9.313rem] h-[2.438rem]" />
          <a href="#" className="[text-decoration:none] inline-block absolute top-[40.188rem] left-[6.625rem] text-[0.75rem] leading-[2.938rem] font-semibold text-white">
            Order Now
          </a>
        </div>
        <div className="absolute top-[1.625rem] left-[41.313rem] w-[17.438rem] h-[44.813rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border w-full h-full" />
          <div className="absolute w-[calc(100%_-_27px)] top-[0.875rem] right-[0.813rem] left-[0.875rem] rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#407bff)] h-[8.188rem]" />
          <div className="absolute top-[3.5rem] left-[5.625rem] text-[2.25rem] tracking-[0.1em] leading-[2.938rem] font-medium text-white mq450:text-[1.375rem] mq450:leading-[1.75rem] mq1000:text-[1.813rem] mq1000:leading-[2.375rem]">
            LOVE
          </div>
          <div className="absolute top-[10rem] left-[3.5rem] text-[1.5rem] leading-[2.938rem] font-semibold mq450:text-[1.188rem] mq450:leading-[2.375rem]">
            ₹210/mo ($3)
          </div>
          <div className="absolute top-[24.063rem] left-[4.563rem] text-[0.938rem] leading-[2.938rem] font-semibold text-lightgray-200">
            Free Let's Encrypt
          </div>
          <div className="absolute top-[13.313rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[2.063rem] leading-[2.938rem] font-medium">
              Host 3 Website
            </div>
          </div>
          <div className="absolute top-[18.313rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[0.938rem] leading-[2.938rem] font-medium">
              Unlimited bandwidth
            </div>
          </div>
          <div className="absolute top-[20.813rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1rem] leading-[2.938rem] font-medium">
              Unlimited FTP, MySQL
            </div>
          </div>
          <div className="absolute top-[27.313rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.375rem] leading-[2.938rem] font-medium">
              Unlimited Email ID
            </div>
          </div>
          <div className="absolute top-[29.688rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.875rem] leading-[2.938rem] font-medium">
              cPanel Account
            </div>
          </div>
          <div className="absolute top-[32.063rem] left-[2.5rem] w-[12.438rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[12.438rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.75rem] leading-[2.938rem] font-medium">
              99.99% LiteSpeed Uptime
            </div>
          </div>
          <div className="absolute top-[15.813rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[0.75rem] leading-[2.938rem] font-medium">
              Unlimited SSD Storage
            </div>
          </div>
          <div className="absolute top-[35.938rem] left-[0.75rem] rounded-8xs bg-white border-whitesmoke-400 border-[1px] border-solid box-border w-[16rem] h-[2.875rem]" />
          <img
            className="absolute w-[4.3%] top-[37.188rem] right-[12.19%] left-[83.51%] max-w-full overflow-hidden h-[0.375rem] object-contain"
            alt=""
            src="/vector-335.svg"
          />
          <div className="absolute top-[35.875rem] left-[2.25rem] text-[0.75rem] leading-[2.938rem] font-medium text-slategray-100">
            3Y ₹7560 - 210/mo
          </div>
          <div className="absolute top-[40.438rem] left-[4.063rem] rounded-8xs bg-royalblue-100 w-[9.313rem] h-[2.438rem]" />
          <a href="#" className="[text-decoration:none] inline-block absolute top-[40.188rem] left-[6.625rem] text-[0.75rem] leading-[2.938rem] font-semibold text-white">
            Order Now
          </a>
        </div>
        <div className="absolute top-[1.75rem] left-[60.188rem] w-[17.438rem] h-[44.813rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border w-full h-full" />
          <div className="absolute w-[calc(100%_-_27px)] top-[0.875rem] right-[0.813rem] left-[0.875rem] rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#407bff)] h-[8.188rem]" />
          <div className="absolute top-[3.5rem] left-[4.625rem] text-[2.25rem] tracking-[0.1em] leading-[2.938rem] font-medium text-white mq450:text-[1.375rem] mq450:leading-[1.75rem] mq1000:text-[1.813rem] mq1000:leading-[2.375rem]">
            ENJOY
          </div>
          <div className="absolute top-[10rem] left-[3.5rem] text-[1.5rem] leading-[2.938rem] font-semibold mq450:text-[1.188rem] mq450:leading-[2.375rem]">
            ₹210/mo ($3)
          </div>
          <div className="absolute top-[24.063rem] left-[4.563rem] text-[0.938rem] leading-[2.938rem] font-semibold text-lightgray-200">
            Free Let's Encrypt
          </div>
          <div className="absolute top-[13.313rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[2.063rem] leading-[2.938rem] font-medium">
              Host 3 Website
            </div>
          </div>
          <div className="absolute top-[18.313rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[0.938rem] leading-[2.938rem] font-medium">
              Unlimited bandwidth
            </div>
          </div>
          <div className="absolute top-[20.813rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1rem] leading-[2.938rem] font-medium">
              Unlimited FTP, MySQL
            </div>
          </div>
          <div className="absolute top-[27.313rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.375rem] leading-[2.938rem] font-medium">
              Unlimited Email ID
            </div>
          </div>
          <div className="absolute top-[29.688rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.875rem] leading-[2.938rem] font-medium">
              cPanel Account
            </div>
          </div>
          <div className="absolute top-[32.063rem] left-[2.5rem] w-[12.438rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[12.438rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.75rem] leading-[2.938rem] font-medium">
              99.99% LiteSpeed Uptime
            </div>
          </div>
          <div className="absolute top-[15.813rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[0.75rem] leading-[2.938rem] font-medium">
              Unlimited SSD Storage
            </div>
          </div>
          <div className="absolute top-[35.938rem] left-[0.75rem] rounded-8xs bg-white border-whitesmoke-400 border-[1px] border-solid box-border w-[16rem] h-[2.875rem]" />
          <img
            className="absolute w-[4.3%] top-[37.188rem] right-[12.19%] left-[83.51%] max-w-full overflow-hidden h-[0.375rem] object-contain"
            alt=""
            src="/vector-335.svg"
          />
          <div className="absolute top-[35.875rem] left-[2.25rem] text-[0.75rem] leading-[2.938rem] font-medium text-slategray-100">
            3Y ₹7560 - 210/mo
          </div>
          <div className="absolute top-[40.438rem] left-[4.063rem] rounded-8xs bg-royalblue-100 w-[9.313rem] h-[2.438rem]" />
          <a href="#" className="[text-decoration:none] inline-block absolute top-[40.188rem] left-[6.625rem] text-[0.75rem] leading-[2.938rem] font-semibold text-white">
            Order Now
          </a>
        </div>
        <div className="absolute top-[0rem] left-[0rem] [backdrop-filter:blur(6.4px)] rounded-xl bg-gray-1200 border-gainsboro border-[1px] border-solid box-border w-full h-full" />
        <div className="absolute top-[23.813rem] left-[30.438rem] text-[1.5rem] [text-decoration:underline] leading-[2.938rem] font-medium text-dimgray-300 mq450:text-[1.188rem] mq450:leading-[2.375rem]">
          Click and view pricing here
        </div>
      </div>
      <div className="absolute top-[249.438rem] left-[4.125rem] w-[81.25rem] h-[50.75rem] hidden text-center text-[0.688rem] text-black">
        <div className="absolute top-[1.75rem] left-[3.563rem] w-[17.438rem] h-[44.813rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border w-full h-full" />
          <div className="absolute w-[calc(100%_-_27px)] top-[0.875rem] right-[0.813rem] left-[0.875rem] rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#407bff)] h-[8.188rem]" />
          <div className="absolute top-[3.5rem] left-[6.188rem] text-[2.25rem] tracking-[0.1em] leading-[2.938rem] font-medium text-white mq450:text-[1.375rem] mq450:leading-[1.75rem] mq1000:text-[1.813rem] mq1000:leading-[2.375rem]">
            LIVE
          </div>
          <div className="absolute top-[10rem] left-[4.125rem] text-[1.5rem] leading-[2.938rem] font-semibold mq450:text-[1.188rem] mq450:leading-[2.375rem]">
            ₹70/mo($1)
          </div>
          <div className="absolute top-[24.063rem] left-[4.563rem] text-[0.938rem] leading-[2.938rem] font-semibold text-lightgray-200">
            Free Let's Encrypt
          </div>
          <div className="absolute top-[13.313rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[2.125rem] leading-[2.938rem] font-medium">
              Host 1 Website
            </div>
          </div>
          <div className="absolute top-[18.313rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.813rem] leading-[2.938rem] font-medium">
              5 GB bandwidth
            </div>
          </div>
          <div className="absolute top-[20.813rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[2.438rem] leading-[2.938rem] font-medium">
              3 FTP, MySQL
            </div>
          </div>
          <div className="absolute top-[27.313rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[2.688rem] leading-[2.938rem] font-medium">
              10 Email ID
            </div>
          </div>
          <div className="absolute top-[29.688rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.875rem] leading-[2.938rem] font-medium">
              cPanel Account
            </div>
          </div>
          <div className="absolute top-[32.063rem] left-[2.5rem] w-[12.438rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[12.438rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.75rem] leading-[2.938rem] font-medium">
              99.99% LiteSpeed Uptime
            </div>
          </div>
          <div className="absolute top-[15.813rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-200 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.625rem] leading-[2.938rem] font-medium">
              5 GB SSD Storage
            </div>
          </div>
          <div className="absolute top-[35.938rem] left-[0.75rem] rounded-8xs bg-white border-whitesmoke-400 border-[1px] border-solid box-border w-[16rem] h-[2.875rem]" />
          <img
            className="absolute w-[4.3%] top-[37.188rem] right-[12.19%] left-[83.51%] max-w-full overflow-hidden h-[0.375rem] object-contain"
            alt=""
            src="/vector-335.svg"
          />
          <div className="absolute top-[35.875rem] left-[2.438rem] text-[0.75rem] leading-[2.938rem] font-medium text-slategray-100">
            3Y ₹2520 - 70/mo
          </div>
          <div className="absolute top-[40.438rem] left-[4.063rem] rounded-8xs bg-royalblue-100 w-[9.313rem] h-[2.438rem]" />
          <a href="#" className="[text-decoration:none] inline-block absolute top-[40.188rem] left-[6.625rem] text-[0.75rem] leading-[2.938rem] font-semibold text-white">
            Order Now
          </a>
        </div>
        <div className="absolute top-[1.625rem] left-[22.438rem] w-[17.438rem] h-[44.813rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border w-full h-full" />
          <div className="absolute w-[calc(100%_-_27px)] top-[0.875rem] right-[0.813rem] left-[0.875rem] rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#407bff)] h-[8.188rem]" />
          <div className="absolute top-[3.5rem] left-[4.5rem] text-[2.25rem] tracking-[0.1em] leading-[2.938rem] font-medium text-white mq450:text-[1.375rem] mq450:leading-[1.75rem] mq1000:text-[1.813rem] mq1000:leading-[2.375rem]">
            LAUGH
          </div>
          <div className="absolute top-[10rem] left-[3.438rem] text-[1.5rem] leading-[2.938rem] font-semibold mq450:text-[1.188rem] mq450:leading-[2.375rem]">
            ₹140/mo ($2)
          </div>
          <div className="absolute top-[24.063rem] left-[4.563rem] text-[0.938rem] leading-[2.938rem] font-semibold text-lightgray-200">
            Free Let's Encrypt
          </div>
          <div className="absolute top-[13.313rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[2.125rem] leading-[2.938rem] font-medium">
              Host 1 Website
            </div>
          </div>
          <div className="absolute top-[18.313rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[0.938rem] leading-[2.938rem] font-medium">
              Unlimited bandwidth
            </div>
          </div>
          <div className="absolute top-[20.813rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1rem] leading-[2.938rem] font-medium">
              Unlimited FTP, MySQL
            </div>
          </div>
          <div className="absolute top-[27.313rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.375rem] leading-[2.938rem] font-medium">
              Unlimited Email ID
            </div>
          </div>
          <div className="absolute top-[29.688rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.875rem] leading-[2.938rem] font-medium">
              cPanel Account
            </div>
          </div>
          <div className="absolute top-[32.063rem] left-[2.5rem] w-[12.438rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[12.438rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.75rem] leading-[2.938rem] font-medium">
              99.99% LiteSpeed Uptime
            </div>
          </div>
          <div className="absolute top-[15.813rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[0.75rem] leading-[2.938rem] font-medium">
              Unlimited SSD Storage
            </div>
          </div>
          <div className="absolute top-[35.938rem] left-[0.75rem] rounded-8xs bg-white border-whitesmoke-400 border-[1px] border-solid box-border w-[16rem] h-[2.875rem]" />
          <img
            className="absolute w-[4.3%] top-[37.188rem] right-[12.19%] left-[83.51%] max-w-full overflow-hidden h-[0.375rem] object-contain"
            alt=""
            src="/vector-335.svg"
          />
          <div className="absolute top-[35.875rem] left-[2.25rem] text-[0.75rem] leading-[2.938rem] font-medium text-slategray-100">
            3Y ₹5040 - 140/mo
          </div>
          <div className="absolute top-[40.438rem] left-[4.063rem] rounded-8xs bg-royalblue-100 w-[9.313rem] h-[2.438rem]" />
          <a href="#" className="[text-decoration:none] inline-block absolute top-[40.188rem] left-[6.625rem] text-[0.75rem] leading-[2.938rem] font-semibold text-white">
            Order Now
          </a>
        </div>
        <div className="absolute top-[1.625rem] left-[41.313rem] w-[17.438rem] h-[44.813rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border w-full h-full" />
          <div className="absolute w-[calc(100%_-_27px)] top-[0.875rem] right-[0.813rem] left-[0.875rem] rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#407bff)] h-[8.188rem]" />
          <div className="absolute top-[3.5rem] left-[5.625rem] text-[2.25rem] tracking-[0.1em] leading-[2.938rem] font-medium text-white mq450:text-[1.375rem] mq450:leading-[1.75rem] mq1000:text-[1.813rem] mq1000:leading-[2.375rem]">
            LOVE
          </div>
          <div className="absolute top-[10rem] left-[3.5rem] text-[1.5rem] leading-[2.938rem] font-semibold mq450:text-[1.188rem] mq450:leading-[2.375rem]">
            ₹210/mo ($3)
          </div>
          <div className="absolute top-[24.063rem] left-[4.563rem] text-[0.938rem] leading-[2.938rem] font-semibold text-lightgray-200">
            Free Let's Encrypt
          </div>
          <div className="absolute top-[13.313rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[2.063rem] leading-[2.938rem] font-medium">
              Host 3 Website
            </div>
          </div>
          <div className="absolute top-[18.313rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[0.938rem] leading-[2.938rem] font-medium">
              Unlimited bandwidth
            </div>
          </div>
          <div className="absolute top-[20.813rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1rem] leading-[2.938rem] font-medium">
              Unlimited FTP, MySQL
            </div>
          </div>
          <div className="absolute top-[27.313rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.375rem] leading-[2.938rem] font-medium">
              Unlimited Email ID
            </div>
          </div>
          <div className="absolute top-[29.688rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.875rem] leading-[2.938rem] font-medium">
              cPanel Account
            </div>
          </div>
          <div className="absolute top-[32.063rem] left-[2.5rem] w-[12.438rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[12.438rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.75rem] leading-[2.938rem] font-medium">
              99.99% LiteSpeed Uptime
            </div>
          </div>
          <div className="absolute top-[15.813rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[0.75rem] leading-[2.938rem] font-medium">
              Unlimited SSD Storage
            </div>
          </div>
          <div className="absolute top-[35.938rem] left-[0.75rem] rounded-8xs bg-white border-whitesmoke-400 border-[1px] border-solid box-border w-[16rem] h-[2.875rem]" />
          <img
            className="absolute w-[4.3%] top-[37.188rem] right-[12.19%] left-[83.51%] max-w-full overflow-hidden h-[0.375rem] object-contain"
            alt=""
            src="/vector-335.svg"
          />
          <div className="absolute top-[35.875rem] left-[2.25rem] text-[0.75rem] leading-[2.938rem] font-medium text-slategray-100">
            3Y ₹7560 - 210/mo
          </div>
          <div className="absolute top-[40.438rem] left-[4.063rem] rounded-8xs bg-royalblue-100 w-[9.313rem] h-[2.438rem]" />
          <a href="#" className="[text-decoration:none] inline-block absolute top-[40.188rem] left-[6.625rem] text-[0.75rem] leading-[2.938rem] font-semibold text-white">
            Order Now
          </a>
        </div>
        <div className="absolute top-[1.75rem] left-[60.188rem] w-[17.438rem] h-[44.813rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border w-full h-full" />
          <div className="absolute w-[calc(100%_-_27px)] top-[0.875rem] right-[0.813rem] left-[0.875rem] rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#407bff)] h-[8.188rem]" />
          <div className="absolute top-[3.5rem] left-[4.625rem] text-[2.25rem] tracking-[0.1em] leading-[2.938rem] font-medium text-white mq450:text-[1.375rem] mq450:leading-[1.75rem] mq1000:text-[1.813rem] mq1000:leading-[2.375rem]">
            ENJOY
          </div>
          <div className="absolute top-[10rem] left-[3.5rem] text-[1.5rem] leading-[2.938rem] font-semibold mq450:text-[1.188rem] mq450:leading-[2.375rem]">
            ₹210/mo ($3)
          </div>
          <div className="absolute top-[24.063rem] left-[4.563rem] text-[0.938rem] leading-[2.938rem] font-semibold text-lightgray-200">
            Free Let's Encrypt
          </div>
          <div className="absolute top-[13.313rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[2.063rem] leading-[2.938rem] font-medium">
              Host 3 Website
            </div>
          </div>
          <div className="absolute top-[18.313rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[0.938rem] leading-[2.938rem] font-medium">
              Unlimited bandwidth
            </div>
          </div>
          <div className="absolute top-[20.813rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1rem] leading-[2.938rem] font-medium">
              Unlimited FTP, MySQL
            </div>
          </div>
          <div className="absolute top-[27.313rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.375rem] leading-[2.938rem] font-medium">
              Unlimited Email ID
            </div>
          </div>
          <div className="absolute top-[29.688rem] left-[4rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.875rem] leading-[2.938rem] font-medium">
              cPanel Account
            </div>
          </div>
          <div className="absolute top-[32.063rem] left-[2.5rem] w-[12.438rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[12.438rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[1.75rem] leading-[2.938rem] font-medium">
              99.99% LiteSpeed Uptime
            </div>
          </div>
          <div className="absolute top-[15.813rem] left-[4.125rem] w-[9.25rem] h-[2.938rem]">
            <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem]" />
            <div className="absolute top-[0rem] left-[0.75rem] leading-[2.938rem] font-medium">
              Unlimited SSD Storage
            </div>
          </div>
          <div className="absolute top-[35.938rem] left-[0.75rem] rounded-8xs bg-white border-whitesmoke-400 border-[1px] border-solid box-border w-[16rem] h-[2.875rem]" />
          <img
            className="absolute w-[4.3%] top-[37.188rem] right-[12.19%] left-[83.51%] max-w-full overflow-hidden h-[0.375rem] object-contain"
            alt=""
            src="/vector-335.svg"
          />
          <div className="absolute top-[35.875rem] left-[2.25rem] text-[0.75rem] leading-[2.938rem] font-medium text-slategray-100">
            3Y ₹7560 - 210/mo
          </div>
          <div className="absolute top-[40.438rem] left-[4.063rem] rounded-8xs bg-royalblue-100 w-[9.313rem] h-[2.438rem]" />
          <a href="#" className="[text-decoration:none] inline-block absolute top-[40.188rem] left-[6.625rem] text-[0.75rem] leading-[2.938rem] font-semibold text-white">
            Order Now
          </a>
        </div>
        <div className="absolute top-[0rem] left-[0rem] [backdrop-filter:blur(6.4px)] rounded-xl bg-gray-1200 border-gainsboro border-[1px] border-solid box-border w-full h-full" />
        <div className="absolute top-[23.813rem] left-[30.438rem] text-[1.5rem] [text-decoration:underline] leading-[2.938rem] font-medium text-dimgray-300 mq450:text-[1.188rem] mq450:leading-[2.375rem]">
          Click and view pricing here
        </div>
      </div>
      <div className="w-full absolute top-[126.438rem] left-[calc(50%_-_311.5px)] text-[0.813rem] leading-[1.25rem] font-medium text-slategray-100 text-center inline-block max-w-full">
        Reseller hosting account at zero cost – Set up your cheap unlimited
        reseller hosting account free of cost on our platform and sell domains,
        hosting services, and other products. Our cheap Linux reseller web
        hosting services would help you in achieving profitability for your
        business.
      </div>
      <section className="absolute top-[295.813rem] left-[0rem] bg-ghostwhite-100 w-[89.438rem] h-[95.563rem] max-w-full" />
      <h2 className="m-0 absolute top-[297.938rem] left-[calc(50%_-_243.5px)] text-[1.5rem] leading-[2.875rem] font-semibold font-inherit text-darkslategray-100 mq450:text-[1.188rem] mq450:leading-[2.313rem]">
        Features of Reseller cPanel Web Hosting
      </h2>
      <div className="absolute top-[303.438rem] left-[calc(50%_+_59.5px)] w-[29.25rem] h-[12.875rem] text-[1.25rem]">
        <h3 className="absolute top-[0rem] left-[calc(50%_-_234px)] leading-[1.688rem] font-semibold mq450:text-[1rem] mq450:leading-[1.375rem]">
          Ease in Setup for Reseller Hosting:
        </h3>
        <div className="w-full absolute top-[2.813rem] left-[0rem] text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center h-[10.063rem] max-w-full">
          Hostlasting eases up your cheap unlimited reseller hosting business
          with flexible plans that include sufficient bandwidth and huge disk
          space. You can create different plan offerings for your customers.
          These plans are bundled with cPanel, one-click app installers, and
          other services. We assist you at each step and make the entire process
          of hosting hassle-free.
        </div>
      </div>
      <div className="absolute top-[332.625rem] left-[calc(50%_+_64.5px)] w-[29.25rem] h-[13rem] text-[1.25rem]">
        <h3 className="absolute top-[0rem] left-[calc(50%_-_234px)] leading-[1.688rem] font-semibold inline-block max-w-full mq450:text-[1rem] mq450:leading-[1.375rem]">
          Entire Migration Assistance with No Cost:
        </h3>
        <div className="w-full absolute top-[2.813rem] left-[0rem] text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center h-[10.188rem]">
          It is a cumbersome task to migrate your business from the current
          reseller hosting provider to our platform. However, our migration
          experts are here to provide assistance to simplify your journey of
          migration. You do not need to wait for your tenure to end. Please get
          in touch with us and our migration experts will help you in migrating
          reseller hosting platform.
        </div>
      </div>
      <div className="absolute top-[360.938rem] left-[calc(50%_+_59.5px)] w-[29.25rem] h-[13rem] text-[1.25rem]">
        <h3 className="absolute top-[0rem] left-[calc(50%_-_234px)] leading-[1.688rem] font-semibold mq450:text-[1rem] mq450:leading-[1.375rem]">
          Hosting with LiteSpeed:
        </h3>
        <div className="w-full absolute top-[2.813rem] left-[0rem] text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center h-[10.188rem]">
          We empower you with an ability to enhance the speed of websites and
          web applications by nearly 20 times. Fast-loading websites have more
          chances to rank higher on the search engines as compared to other
          websites. The top-notch hardware that offers high processing speed and
          low-density servers will give the necessary boost to your websites.
        </div>
      </div>
      <div className="absolute top-[316.313rem] left-[calc(50%_-_532.5px)] w-[29.25rem] h-[17rem] text-[1.25rem]">
        <h3 className="absolute top-[0rem] left-[calc(50%_-_234px)] tracking-[0.04em] leading-[3.313rem] font-semibold mq450:text-[1rem] mq450:leading-[2.625rem]">
          Manage Your Business Effortlessly:
        </h3>
        <div className="w-full absolute top-[3.25rem] left-[0rem] text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center h-[13.75rem] max-w-full">
          With each of your plans, we offer reseller hosting with cPanel and
          WHMCS (reseller hosting with free whmcs) to help you in managing the
          entire business without putting too much stress upon your heads and
          resources. You can efficiently manage your reseller hosting business
          and serve your customers in the best possible manner through our
          OrderBox panel. It contains various features such as summarized view,
          orders, and payment gateways. Moreover, we offer modules with which
          you can integrate cPanel to gain a full control.
        </div>
      </div>
      <div className="absolute top-[346.375rem] left-[calc(50%_-_532.5px)] w-[29.25rem] h-[13rem] text-[1.25rem]">
        <h3 className="absolute top-[0rem] left-[calc(50%_-_234px)] tracking-[0.04em] leading-[3.313rem] font-semibold inline-block max-w-full mq450:text-[1rem] mq450:leading-[2.625rem]">
          More Than 400 Apps in a Single Click:
        </h3>
        <div className="w-full absolute top-[3.438rem] left-[0rem] text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center h-[9.563rem] max-w-full">
          <span>
            Hostlasting’s best web hosting reseller program comes with the
            Softculous one-click app installer. This installer offers you an
            advantage of installing more than 400 apps with a single click. As
            you need to manage multiple websites, you can save a lot of time,
            resources, and cost with this app installer. Popular apps such as 
            <a
              className="text-[inherit]"
              href="https://www.hostlasting.com/wordpress-hosting"
              target="_blank"
            >
              <span className="[text-decoration:underline]">Wordpress</span>
            </a>
            , Drupal, and others can be installed with a single click.
          </span>
        </div>
      </div>
      <h2 className="m-0 absolute top-[401.313rem] left-[calc(50%_-_186.5px)] text-[1.5rem] leading-[2.875rem] font-semibold font-inherit text-darkslategray-100 mq450:text-[1.188rem] mq450:leading-[2.313rem]">
        FREQUENTLY ASKED QUESTIONS
      </h2>
      <div className="absolute top-[407.313rem] left-[11.375rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[4rem] max-w-full" />
      <div className="absolute top-[421.625rem] left-[11.375rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[4rem] max-w-full" />
      <div className="absolute top-[435.938rem] left-[11.5rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[4rem] max-w-full" />
      <div className="absolute top-[450.25rem] left-[11.563rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[4rem] max-w-full" />
      <section className="absolute top-[411.438rem] left-[11.375rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[9.5rem] max-w-full" />
      <section className="absolute top-[425.75rem] left-[11.375rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[9.5rem] max-w-full" />
      <section className="absolute top-[440.063rem] left-[11.563rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[9.5rem] max-w-full" />
      <section className="absolute top-[454.375rem] left-[11.563rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[9.5rem] max-w-full" />
      <div className="absolute top-[407.813rem] left-[16.063rem] tracking-[0.04em] leading-[2.938rem] font-medium text-black">
        1.What is reseller hosting? How is it beneficial for clients?
      </div>
      <div className="absolute top-[422.125rem] left-[16.063rem] tracking-[0.04em] leading-[2.938rem] font-medium text-black">
        2.Are your services white-label hosting services?
      </div>
      <div className="absolute top-[436.438rem] left-[16.188rem] tracking-[0.04em] leading-[2.938rem] font-medium text-black">
        3.What is WHMCS? Can I avail WHMCS with your reseller hosting plans?
      </div>
      <div className="absolute top-[450.75rem] left-[16.25rem] tracking-[0.04em] leading-[2.938rem] font-medium text-black">
        4. Which control panels can be availed along with my reseller hosting
        account?
      </div>
      <section className="w-full absolute top-[413.25rem] left-[16.063rem] text-[0.875rem] tracking-[0.04em] leading-[1.063rem] font-poppins text-slategray-100 text-left inline-block max-w-full">
        Reseller hosting is one of the forms of web hosting in which a hosting
        provider can purchase the hosting services such as disk space,
        bandwidth, and others in bulk and offer them to individuals and third
        parties. It is a business model in which the entire range of services
        can be distributed among different hosting accounts and managed
        efficiently. Once you buy hosting services in bulk, you can sell them
        among individuals and companies at a higher margin to achieve
        profitability. With one of the best Linux reseller hosting providers at
        your disposal, you can run your business efficiently.
      </section>
      <section className="w-full absolute top-[427.313rem] left-[16.125rem] text-[0.875rem] tracking-[0.04em] leading-[1.063rem] font-poppins text-slategray-100 text-left inline-block max-w-full">
        Yes, absolutely. Our services are white-label hosting services.
        Hostlasting allows you to sell web hosting services under your brand
        umbrella and we ensure our name will not be mentioned anywhere. In
        addition, you can avail customization services that would help you in
        serving your clients with your flexibility and convenience. The control
        panel would enable you in managing everything effortlessly. You can sell
        the web hosting services with our white-label reseller program.
        Hostlasting will provide all the necessary technical expertise, server
        infrastructure, and management services, so that you can focus on
        growing your business.
      </section>
      <section className="absolute top-[441.188rem] left-[16.063rem] text-[0.875rem] tracking-[0.04em] leading-[1.063rem] font-poppins text-slategray-100 text-left flex items-center w-[56rem] max-w-full">
        <span className="w-full">
          <p className="m-0">
            WHMCS stands for Web Host Management Complete Solution. As the name
            suggests, it is an automated platform through which you can manage
            every aspect of your hosting service from a single interface. This
            platform helps you in efficient management of customers and
            services. It has become one of the most prominent customer
            administration and billing management platforms.
          </p>
          <p className="m-0">
            Yes, you can avail Linux reseller hosting with WHMCS with each of
            our plans. It is one of the majorly used platforms for reseller
            hosting.
          </p>
        </span>
      </section>
      <section className="w-full absolute top-[455.438rem] left-[16.125rem] text-[0.875rem] tracking-[0.04em] leading-[1.063rem] font-poppins text-slategray-100 text-left inline-block max-w-full">
        Hostlasting provides two control panels with your reseller hosting
        account. For management of your reseller hosting account, you get WHMCS
        and cPanel. WHMCS is a platform with which you can control each aspect
        of your business from administration of different websites to billing of
        clients. cPanel is a control panel designed for management of different
        hosting accounts. With access to different levels of the websites, all
        operations can be controlled. With these control panels, you can upgrade
        and downgrade plans, design customized plans, and offer streamlined
        services. Our different plans are available for providing you the best
        control panels to cater to your hosting needs.
      </section>
      <img
        className="absolute w-[0.84%] top-[409.063rem] right-[17.12%] left-[82.04%] max-w-full overflow-hidden h-[0.375rem] object-contain"
        loading="lazy"
        alt=""
        src="/vector-335.svg"
      />
      <img
        className="absolute w-[0.84%] top-[423.625rem] right-[17.12%] left-[82.04%] max-w-full overflow-hidden h-[0.375rem] object-contain"
        loading="lazy"
        alt=""
        src="/vector-335.svg"
      />
      <img
        className="absolute w-[0.84%] top-[437.688rem] right-[16.98%] left-[82.18%] max-w-full overflow-hidden h-[0.375rem]"
        loading="lazy"
        alt=""
        src="/vector-337.svg"
      />
      <img
        className="absolute w-[0.84%] top-[451.875rem] right-[16.84%] left-[82.32%] max-w-full overflow-hidden h-[0.375rem]"
        loading="lazy"
        alt=""
        src="/vector-337.svg"
      />
      <div className="absolute top-[464.563rem] left-[11.438rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[4rem] max-w-full" />
      <div className="absolute top-[468.688rem] left-[11.438rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[29.5rem] max-w-full" />
      <div className="absolute top-[465.063rem] left-[16.125rem] tracking-[0.04em] leading-[2.938rem] font-medium text-black">
        5. What are the advantages of availing Hostlasting’s reseller hosting
        services?
      </div>
      <section className="absolute top-[470.563rem] left-[16.063rem] text-[0.875rem] tracking-[0.04em] leading-[1.063rem] font-poppins text-slategray-100 text-left flex items-center w-[56rem] max-w-full">
        <span className="w-full">
          <p className="m-0">
            With a team of experts having a seasoned experience of more than a
            decade in the web hosting space, we are instrumental in offering
            technical expertise, rapid response services, and top-notch hardware
            to our clients. We are focused on building customer-centric services
            and support to ensure optimum value and profitability for our
            customers. The major advantages of Hostlasting’s reseller hosting
            services are listed below:
          </p>
          <ul className="m-0 font-inherit text-inherit pl-[1.357rem]">
            <li className="my-[undefined] mx-[undefined]">
              Avail reseller hosting with cPanel and WHMCS for efficient
              management of websites and web applications along with billing.
              Free of cost WHMCS billing application with each of our plans.
            </li>
            <li className="my-[undefined] mx-[undefined]">
              Infinite SSD Space available along with 20-times Faster LiteSpeed
              technology.
            </li>
            <li className="my-[undefined] mx-[undefined]">
              Free SSL License along with advanced security tools such as
              Imunify360 and Imunify AV+.
            </li>
            <li className="my-[undefined] mx-[undefined]">
              Liberty to set up and design your own hosting plans, pricing, and
              packages. This would offer you a competitive advantage.
            </li>
            <li className="my-[undefined] mx-[undefined]">
              A single-click Softaculous app installer to enable you with an
              ability to install more than 400 applications in a single click.
            </li>
            <li className="my-[undefined] mx-[undefined]">
              SitePad Website Builder with more than 1000 pre-installed
              templates and themes at zero cost. IT offers the programming
              languages support for languages such as Ruby,PHP, MySQL, and
              MariaDB.
            </li>
            <li className="my-[undefined] mx-[undefined]">
              Avail JetBackup for weekly storage and backups. Support to
              upgraded PHP 7.3 version.
            </li>
            <li className="my-[undefined] mx-[undefined]">
              100% White-label hosting service. You can get your brand name on
              the control panel of clients. This will enable you to grow your
              brand and avail more profitability by connecting with more
              customers. In addition, you can charge your customers as per your
              wishes.
            </li>
            <li className="my-[undefined] mx-[undefined]">
              Gain an entire control over different resources.
            </li>
            <li className="my-[undefined] mx-[undefined]">
              On-demand developer tools and expert support.
            </li>
            <li className="my-[undefined] mx-[undefined]">
              Our around the clock support would ensure maximum uptime and
              resolve all of your complicated server problems
            </li>
            <li>
              Free migration services from existing hosting provider to our
              services. Prevent data loss and migrate smoothly with our
              assistance.
            </li>
          </ul>
        </span>
      </section>
      <img
        className="absolute w-[0.84%] top-[466.313rem] right-[17.05%] left-[82.11%] max-w-full overflow-hidden h-[0.375rem]"
        loading="lazy"
        alt=""
        src="/vector-337.svg"
      />
      <div className="absolute top-[498.875rem] left-[11.438rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[4rem] max-w-full" />
      <section className="absolute top-[503.313rem] left-[11.438rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[7rem] max-w-full" />
      <div className="absolute top-[499.375rem] left-[16.125rem] tracking-[0.04em] leading-[2.938rem] font-medium text-black">
        6. Where are Linux KVM VPS hosting servers of Hostlasting located?
      </div>
      <div className="w-full absolute top-[504.688rem] left-[16.125rem] tracking-[0.04em] leading-[1.063rem] text-slategray-100 inline-block max-w-full">
        Our cost-efficient and high-quality Linux KVM VPS hosting servers are
        located at Tier III datacenter in Lithuania, Europe. The location is
        chosen to offer the optimum uptime and high performance for your
        resources around the world. The datacenter has a cooling infrastructure
        and employ strict security measures to ensure 99.99% uptime. The Cisco
        components employed in the servers would ensure recovery and security of
        data in case of downtimes.
      </div>
      <img
        className="absolute w-[0.84%] top-[500.625rem] right-[17.05%] left-[82.11%] max-w-full overflow-hidden h-[0.375rem]"
        loading="lazy"
        alt=""
        src="/vector-337.svg"
      />
      <div className="absolute top-[511rem] left-[11.438rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[4rem] max-w-full" />
      <div className="absolute top-[563.375rem] left-[11.375rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[4rem] max-w-full" />
      <div className="absolute top-[584.625rem] left-[11.5rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[4rem] max-w-full" />
      <div className="absolute top-[605.875rem] left-[11.5rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[4rem] max-w-full" />
      <div className="absolute top-[515.125rem] left-[11.375rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[16.438rem] max-w-full" />
      <div className="absolute top-[567.5rem] left-[11.313rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[16.438rem] max-w-full" />
      <div className="absolute top-[588.75rem] left-[11.438rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[16.438rem] max-w-full" />
      <section className="absolute top-[610rem] left-[11.438rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[27.063rem] max-w-full" />
      <div className="absolute top-[511.5rem] left-[16.125rem] tracking-[0.04em] leading-[2.938rem] font-medium text-black">
        7. What is the purpose of reseller hosting services?
      </div>
      <div className="absolute top-[563.875rem] left-[16.063rem] tracking-[0.04em] leading-[2.938rem] font-medium text-black">
        9. What is the right time for deployment of the reseller hosting
        services?
      </div>
      <div className="absolute top-[585.125rem] left-[16.188rem] tracking-[0.04em] leading-[2.938rem] font-medium text-black">
        9. What is the right time for deployment of the reseller hosting
        services?
      </div>
      <div className="absolute top-[606.375rem] left-[16.188rem] tracking-[0.04em] leading-[2.938rem] font-medium text-black">
        9. Which are the things that should be on the checklist before
        purchasing any reseller hosting service?
      </div>
      <section className="absolute top-[516.813rem] left-[16.063rem] text-[0.875rem] tracking-[0.04em] leading-[1.063rem] font-poppins text-slategray-100 text-left flex items-center w-[58.125rem] max-w-full">
        <span className="w-full">
          <p className="m-0">
            Reseller hosting services can become one of the prime and
            profit-making businesses as you enter the web hosting industry. With
            a reseller hosting account, you can offer hosting services to
            multiple clients, individuals, and organizations. As you avail disk
            space, bandwidth, and other parameters in bulk, you can distribute
            them among various clients and make money with reseller hosting. You
            can chargeyou’re your services at your desired rate. This way, you
            can build your own brand in the web hosting industry. Hostlasting
            will not display its name anywhere. We ensure you can seamlessly and
            cost-effectively enter the web hosting field.
          </p>
          <p className="m-0">
            Along with the top-notch server infrastructure, you can gain cPanel
            and WHMCS access to manage, control, and operate the business
            efficiently. Moreover, you can add to your profits with add-on
            services such as dedicated IP, backup services, SSL Certificates,
            dedicated hosting solutions, 
            <a
              className="text-[inherit]"
              href="https://www.hostlasting.com/ssd-shared-hosting"
              target="_blank"
            >
              <span className="[text-decoration:underline]">
                shared hosting plans
              </span>
            </a>
            , VPS hosting plans, and others. Reseller hosting servicservices
            offer flexibility and freedom in terms of control panel, email
            access, user interface, and others.
          </p>
        </span>
      </section>
      <section className="absolute top-[569.188rem] left-[16rem] text-[0.875rem] tracking-[0.04em] leading-[1.063rem] font-poppins text-slategray-100 text-left flex items-center w-[58.125rem] max-w-full">
        <span className="w-full">
          <p className="m-0">
            There is no right time for deployment of the reseller hosting
            services. You can start the reseller hosting business at any point
            of time. For those looking for managing clients and scaling up the
            business or build a brand in the web hosting field, this is one of
            the best businesses to start with. Even if you are looking forward
            to achieve profitability by being a web hosting professional,
            reseller hosting business would provide you profitability. Moreover,
            you can start the business under your name and there is no right
            time to start any business. With Hostlasting’s reseller hosting
            services, you can avail entire control panels to manage clients and
            services with flexibility and freedom. Along with these benefits,
            you can avail following benefits with reseller hosting:
          </p>
          <ul className="m-0 font-inherit text-inherit pl-[1.357rem]">
            <li className="my-[undefined] mx-[undefined]">
              Instant upgrades in few clicks.
            </li>
            <li className="my-[undefined] mx-[undefined]">
              Performance enhancement tools and the advanced server
              infrastructure.
            </li>
            <li>
              Free email accounts, SSL certificates, cPanel hosting, and FTP
              accounts.
            </li>
          </ul>
        </span>
      </section>
      <section className="absolute top-[590.438rem] left-[16.125rem] text-[0.875rem] tracking-[0.04em] leading-[1.063rem] font-poppins text-slategray-100 text-left flex items-center w-[58.125rem] max-w-full">
        <span className="w-full">
          <p className="m-0">
            There is no right time for deployment of the reseller hosting
            services. You can start the reseller hosting business at any point
            of time. For those looking for managing clients and scaling up the
            business or build a brand in the web hosting field, this is one of
            the best businesses to start with. Even if you are looking forward
            to achieve profitability by being a web hosting professional,
            reseller hosting business would provide you profitability. Moreover,
            you can start the business under your name and there is no right
            time to start any business. With Hostlasting’s reseller hosting
            services, you can avail entire control panels to manage clients and
            services with flexibility and freedom. Along with these benefits,
            you can avail following benefits with reseller hosting:
          </p>
          <ul className="m-0 font-inherit text-inherit pl-[1.357rem]">
            <li className="my-[undefined] mx-[undefined]">
              Instant upgrades in few clicks.
            </li>
            <li className="my-[undefined] mx-[undefined]">
              Performance enhancement tools and the advanced server
              infrastructure.
            </li>
            <li>
              Free email accounts, SSL certificates, cPanel hosting, and FTP
              accounts.
            </li>
          </ul>
        </span>
      </section>
      <section className="absolute top-[613.063rem] left-[16.125rem] text-[0.875rem] tracking-[0.04em] leading-[1.063rem] font-poppins text-slategray-100 text-left flex items-center w-[58.125rem] max-w-full">
        <span className="w-full">
          <p className="m-0">
            As reseller hosting is one of the best ways to get into the web
            hosting field, the field is very competitive. There are few things
            that need to be taken into consideration before purchasing reseller
            hosting service. Following are the things you need to check before
            opting for a service:
          </p>
          <ol className="m-0 font-inherit text-inherit pl-[1.357rem]">
            <li className="my-[undefined] mx-[undefined]">
              Dedicated around the clock technical support: The quality of
              customer service provided by you is directly dependent upon the
              support offered to you by the reseller hosting provider. So, avail
              the services from the provider who provides around the clock
              uninterrupted support for your queries.
            </li>
            <li className="my-[undefined] mx-[undefined]">
              . Top-notch features: A reseller hosting provider should provide
              following features to enable you to operate your businesses and
              manage the clients effectively:
            </li>
          </ol>
          <ul className="m-0 font-inherit text-inherit pl-[1.357rem]">
            <li className="my-[undefined] mx-[undefined]">
              Linux reseller hosting with WHMCS for billing and client
              management.
            </li>
            <li className="my-[undefined] mx-[undefined]">
              Reseller hosting with cPanel to enable smooth account management
            </li>
            <li className="my-[undefined] mx-[undefined]">
              Rapid email response and support
            </li>
            <li className="my-[undefined] mx-[undefined]">
              One-click web application installation with Softaculous
            </li>
            <li className="my-[undefined] mx-[undefined]">
              Free SSL Certificate
            </li>
            <li className="my-[undefined] mx-[undefined]">
              Ease in upgrading of resources
            </li>
          </ul>
          <ol className="m-0 font-inherit text-inherit pl-[1.357rem]">
            <li className="my-[undefined] mx-[undefined]">
              Optimum uptime: Avail services from only that hosting provider who
              offers guarantee of 99.9% uptime.
            </li>
            <li className="my-[undefined] mx-[undefined]">
              Top-notch hardware: The hardware infrastructure plays a crucial
              role in performance, reliability, and your overall services.
              Reseller hosting providers with cutting edge hardware and
              low-density servers should be preferred.
            </li>
            <li className="my-[undefined] mx-[undefined]">
              White-label Support: One of the major advantages of deployment of
              reseller hosting is to sell services under your own brand name.
              This also enables you to rebrand the hosting packages and sell
              range of customized hosting services.
            </li>
            <li>
              Multi-channel support: Avail the reseller hosting services from
              the provider who offers support across multiple channels such as
              email, calls, chat, and others.
            </li>
          </ol>
        </span>
      </section>
      <img
        className="absolute w-[0.84%] top-[512.813rem] right-[17.12%] left-[82.04%] max-w-full overflow-hidden h-[0.375rem]"
        loading="lazy"
        alt=""
        src="/vector-337.svg"
      />
      <div className="absolute top-[532.25rem] left-[11.5rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[4rem] max-w-full" />
      <div className="absolute top-[536.375rem] left-[11.438rem] rounded-sm bg-whitesmoke-300 w-[66.563rem] h-[26.313rem]" />
      <div className="absolute top-[532.75rem] left-[16.188rem] tracking-[0.04em] leading-[2.938rem] font-medium text-black">
        8.What differentiates reseller hosting from other types of hosting?
      </div>
      <section className="w-full absolute top-[543.125rem] left-[16.125rem] text-[0.875rem] tracking-[0.04em] leading-[1.063rem] font-poppins text-slategray-100 text-left inline-block max-w-full">
        <p className="m-0">
          Reseller hosting is about purchasing the hosting space and
          distributing among clients through repackaging and rebranding. The
          major difference is that reseller hosting enables you to sell you
          services under your brand name. There are other differences with other
          types of hosting services:
        </p>
        <p className="m-0">
          Reseller Hosting and Shared Hosting: For shared hosting, a single user
          buys the resources and space on the server for web hosting. The server
          is shared by different users. Whereas, for reseller hosting, an owner
          buys space and sells it to customers in parts.
        </p>
        <p className="m-0">
          Reseller Hosting vs VPS Hosting: VPS hosting is about dividing a
          single server into different virtual servers. Each virtual server can
          host a website and various accounts. For reseller hosting, an entire
          hosting space is sold to different users as per the need.
        </p>
        <p className="m-0">
          Reseller Hosting vs Cloud Hosting: In case of cloud hosting, a number
          of websites are hosted together on the cloud servers and data is
          stored. In case of reseller hosting, hosting space is divided and
          multiple websites can be hosted and data is stored by allocation of
          space.
        </p>
        <p className="m-0">
          Reseller Hosting vs Dedicated Hosting: Dedicated hosting is about
          allocation of the server space and resources to a single user. The
          single user can host multiple websites. However, in case of reseller
          hosting, multiple users can buy the hosting space as per the
          requirement.
        </p>
        <p className="m-0">
          Along with above differentiating factors, there are several factors
          that enable you to start reseller hosting business. You can add more
          resources and domains under your brand name. Moreover, you have a
          liberty to add more resellers under your brand umbrella. In addition
          to all the differentiating factors, you can host unlimited number of
          websites and domain at low cost
        </p>
      </section>
      <img
        className="absolute w-[0.84%] top-[534.063rem] right-[17.05%] left-[82.11%] max-w-full overflow-hidden h-[0.375rem]"
        loading="lazy"
        alt=""
        src="/vector-337.svg"
      />
      <img
        className="w-full absolute top-[69.375rem] left-[6.938rem] rounded-6xl max-w-full overflow-hidden h-[41.875rem] object-cover"
        alt=""
        src="/hostlastingresellerhostingplanstable-1@2x.png"
      />
      <img
        className="absolute top-[248.938rem] left-[6.938rem] rounded-6xl w-[75.563rem] h-[41.875rem] object-cover"
        alt=""
        src="/hostlastingresellerhostingplanstable-1@2x.png"
      />
      <div className="absolute top-[2.25rem] left-[3.75rem] flex flex-row items-center justify-start gap-[6.25rem] text-white">
        <div className="w-[15.438rem] relative h-[2.313rem]">
          <img
            className="w-full absolute top-[0.063rem] left-[0rem] max-w-full overflow-hidden h-[2.125rem] object-contain"
            alt=""
            src="/hostlasting-logo@2x.png"
          />
          <div className="absolute top-[0rem] left-[9.75rem] w-[5.688rem]">
            <div className="absolute top-[0rem] left-[0rem] rounded-xl border-white border-[1px] border-solid box-border w-[5.688rem] h-[2.313rem]" />
            <div className="absolute top-[0.375rem] left-[1.25rem] w-[3.125rem] h-[1.563rem]">
              <img
                className="absolute top-[0rem] left-[0rem] w-[1.625rem] h-[1.563rem] object-cover"
                alt=""
                src="/us-2@2x.png"
              />
              <img
                className="absolute top-[0.313rem] left-[2.25rem] w-[0.875rem] h-[0.875rem] overflow-hidden"
                alt=""
                src="/heroiconssolidchevrondown.svg"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row items-end justify-start gap-[2.625rem]">
          <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[3.438rem]">
            SHARED
          </a>
          <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[3.813rem]">
            RESELLER
          </a>
          <div className="relative leading-[1.5rem] font-medium inline-block min-w-[1.688rem]">
            VPS
          </div>
          <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[4.25rem]">
            ADD-ONS
          </a>
          <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[4.813rem]">
            DEDICATED
          </a>
          <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[4rem] whitespace-nowrap">
            About Us
          </a>
          <a className="[text-decoration:none] relative leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[3.625rem]">
            Contact
          </a>
        </div>
        <div className="w-[10.813rem] relative h-[2.625rem] text-[0.938rem] text-black">
          <img
            className="absolute top-[0.063rem] left-[8.375rem] rounded-3xs w-[2.438rem] h-[2.438rem] object-cover"
            alt=""
            src="/24hourssupport-1@2x.png"
          />
          <div className="absolute top-[0rem] left-[0rem] whitespace-nowrap">
            <div className="absolute top-[0rem] left-[0rem] rounded-3xs bg-white w-[6.75rem] h-[2.625rem]" />
            <a className="[text-decoration:none] absolute top-[0.563rem] left-[2rem] leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[2.75rem] whitespace-nowrap">
              Log In
            </a>
          </div>
        </div>
      </div>
      <Footer />
      <div className="absolute top-[137rem] left-[21.75rem] w-[6.25rem] h-[6.313rem]">
        <img
          className="absolute top-[0rem] left-[0rem] w-full h-full object-cover"
          loading="lazy"
          alt=""
          src="/iconbusinessv3-9-a-1@2x.png"
        />
        <div className="absolute top-[5.875rem] left-[1.125rem] [filter:blur(11.4px)] rounded-[50%] bg-gray-1300 w-[3.938rem] h-[0.438rem]" />
      </div>
      <div className="absolute top-[150.125rem] left-[56.313rem] w-[7.938rem] h-[6.375rem]">
        <div className="w-full absolute top-[5.938rem] left-[2rem] [filter:blur(11.4px)] rounded-[50%] bg-gray-1300 h-[0.438rem]" />
        <img
          className="w-full h-full absolute top-[0rem] left-[0rem] max-w-full overflow-hidden max-h-full object-cover"
          loading="lazy"
          alt=""
          src="/64a-1@2x.png"
        />
      </div>
      <div className="absolute top-[163.75rem] left-[21.75rem] w-[6.25rem] h-[7.813rem]">
        <div className="w-full absolute top-[7.375rem] left-[1.125rem] [filter:blur(11.4px)] rounded-[50%] bg-gray-1300 h-[0.438rem]" />
        <img
          className="absolute top-[0rem] left-[0rem] w-[6.25rem] h-[6.25rem] object-cover"
          loading="lazy"
          alt=""
          src="/complain-to-customer-servicea-1@2x.png"
        />
      </div>
      <div className="absolute top-[177.188rem] left-[57.188rem] w-[6.25rem] h-[7.188rem]">
        <div className="w-full absolute top-[6.75rem] left-[1.125rem] [filter:blur(11.4px)] rounded-[50%] bg-gray-1300 h-[0.438rem]" />
        <img
          className="absolute top-[0rem] left-[0rem] w-[6.25rem] h-[6.25rem] object-cover"
          loading="lazy"
          alt=""
          src="/security-tokena-1@2x.png"
        />
      </div>
      <div className="absolute top-[189.813rem] left-[21.75rem] w-[6.25rem] h-[7.063rem]">
        <div className="w-full absolute top-[6.625rem] left-[1.188rem] [filter:blur(11.4px)] rounded-[50%] bg-gray-1300 h-[0.438rem]" />
        <img
          className="absolute top-[0rem] left-[0rem] w-[6.25rem] h-[6.25rem] object-cover"
          loading="lazy"
          alt=""
          src="/iconbusinessv3-1--1@2x.png"
        />
      </div>
      <div className="absolute top-[204.188rem] left-[54.688rem] w-[10rem] h-[8.375rem]">
        <div className="w-full absolute top-[7.938rem] left-[3rem] [filter:blur(11.4px)] rounded-[50%] bg-gray-1300 h-[0.438rem]" />
        <img
          className="w-full h-full absolute top-[0rem] left-[0rem] max-w-full overflow-hidden max-h-full object-cover"
          loading="lazy"
          alt=""
          src="/3donemessageemailhasopenedgraphicillustrationiconpinkyellowbluea-2@2x.png"
        />
      </div>
      <div className="absolute top-[220rem] left-[21.75rem] w-[6.25rem] h-[7.563rem]">
        <div className="w-full absolute top-[7.125rem] left-[1.188rem] [filter:blur(11.4px)] rounded-[50%] bg-gray-1300 h-[0.438rem]" />
        <img
          className="absolute top-[0rem] left-[0rem] w-[6.25rem] h-[6.25rem] object-cover"
          loading="lazy"
          alt=""
          src="/cloud-storage-3a-1@2x.png"
        />
      </div>
      <div className="absolute top-[232.813rem] left-[56.875rem] w-[6.5rem] h-[6.938rem]">
        <div className="w-full absolute top-[6.5rem] left-[1.313rem] [filter:blur(11.4px)] rounded-[50%] bg-gray-1300 h-[0.438rem]" />
        <img
          className="absolute top-[0rem] left-[0rem] w-[6.5rem] h-[6.5rem] object-cover"
          loading="lazy"
          alt=""
          src="/iconbusinessv3-7--1@2x.png"
        />
      </div>
      <div className="absolute top-[375.938rem] left-[calc(50%_-_532.5px)] w-[29.25rem] h-[12.625rem] text-[1.25rem]">
        <h3 className="absolute top-[0rem] left-[calc(50%_-_234px)] tracking-[0.04em] leading-[3.313rem] font-semibold mq450:text-[1rem] mq450:leading-[2.625rem]">
          Hostlasting Advantage:
        </h3>
        <div className="w-full absolute top-[3.063rem] left-[0rem] text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 inline-block max-h-[9.563rem]">
          Our team has an experience of more than a decade in the field. We are
          here to offer around the clock support and address your concerns.
          Moreover, our plans are designed to suit your needs without putting
          much burden on your pockets. With reseller cPanel web hosting, you can
          manage your entire business and websites effortlessly and efficiently.
          Avail our services for seamless and cheap reseller hosting experience.
        </div>
      </div>
      <div className="absolute top-[113.938rem] left-[calc(50%_-_71.5px)] w-[9rem]">
        <img
          className="absolute top-[0rem] left-[calc(50%_-_72px)] w-[9rem] h-[9rem] object-cover"
          loading="lazy"
          alt=""
          src="/touchscreen-device-3d-vector-illustration-2@2x.png"
        />
        <div className="absolute top-[8.938rem] left-[2.125rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem]" />
      </div>
      <div className="absolute top-[306.375rem] left-[19.75rem] w-[7rem] h-[9rem]">
        <img
          className="absolute top-[0rem] left-[0rem] w-[7rem] h-[7rem] object-cover"
          loading="lazy"
          alt=""
          src="/web-designv2-10a-2@2x.png"
        />
        <div className="absolute top-[8.625rem] left-[1.125rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem]" />
      </div>
      <div className="absolute top-[321.313rem] left-[56.813rem] w-[6.25rem] h-[7.313rem]">
        <img
          className="absolute top-[0rem] left-[0rem] w-[6.25rem] h-[6.25rem] object-cover"
          loading="lazy"
          alt=""
          src="/iconbusinessv3-1--1@2x.png"
        />
        <div className="w-full absolute top-[6.938rem] left-[0.75rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 h-[0.375rem]" />
      </div>
      <div className="absolute top-[336rem] left-[20.125rem] w-[6.25rem] h-[8.25rem]">
        <img
          className="absolute top-[0rem] left-[0rem] w-[6.25rem] h-[6.25rem] object-cover"
          loading="lazy"
          alt=""
          src="/e8821b57bbf348d4a5af10a4752bad0aa-1@2x.png"
        />
        <div className="w-full absolute top-[7.875rem] left-[0.75rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 h-[0.375rem]" />
      </div>
      <div className="absolute top-[349rem] left-[54.813rem] w-[10.25rem] h-[8.813rem]">
        <img
          className="w-full h-full absolute top-[0rem] left-[0rem] max-w-full overflow-hidden max-h-full object-cover"
          loading="lazy"
          alt=""
          src="/9208696a-1@2x.png"
        />
        <div className="w-full absolute top-[8.438rem] left-[2.75rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 h-[0.375rem]" />
      </div>
      <div className="absolute top-[364rem] left-[19.813rem] w-[6.875rem] h-[8.875rem]">
        <img
          className="absolute top-[0rem] left-[0rem] w-[6.875rem] h-[6.875rem] object-cover"
          loading="lazy"
          alt=""
          src="/fp0124-rocketa-2@2x.png"
        />
        <div className="w-full absolute top-[8.5rem] left-[1.063rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 h-[0.375rem]" />
      </div>
      <div className="w-full absolute top-[376.938rem] left-[53.375rem] h-[10.875rem]">
        <img
          className="absolute top-[0rem] left-[0rem] w-full h-full object-cover"
          loading="lazy"
          alt=""
          src="/3dillustrationcartoonpeopleusingsmartphonesurfinginternetcommunicatesocialmediaonlineapplication-1@2x.png"
        />
        <div className="absolute top-[10.5rem] left-[4.188rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem]" />
      </div>
      <Breadcrumb />
    </div>
  );
};

export default LinuxResellerHosting;

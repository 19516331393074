import { FunctionComponent, memo } from "react";

export type SSLItemsType = {
  className?: string;
};

const SSLItems: FunctionComponent<SSLItemsType> = memo(({ className = "" }) => {
  return (
    <section
      className={`self-stretch flex flex-col items-end justify-start gap-[1.75rem] max-w-full text-center text-[1rem] text-slategray-100 font-poppins ${className}`}
    >
      <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem]">
        <div className="relative leading-[1.25rem] font-medium">
          Our SSL certificates are from some of the most trusted brands in
          Online Security
        </div>
      </div>
      <div className="self-stretch bg-ghostwhite-100 flex flex-row items-end justify-between py-[2.125rem] pl-[11.437rem] pr-[11.375rem] box-border max-w-full gap-[1.25rem] mq750:pl-[5.688rem] mq750:pr-[5.688rem] mq750:box-border mq1050:flex-wrap mq1050:justify-center mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
        <div className="h-[7rem] w-[89.438rem] relative bg-ghostwhite-100 hidden max-w-full" />
        <img
          className="h-[2.625rem] w-[11.75rem] relative object-contain z-[1]"
          loading="lazy"
          alt=""
          src="/rapidssllogo-1@2x.png"
        />
        <img
          className="self-stretch w-[12.563rem] relative max-h-full object-contain min-h-[2.75rem] z-[1]"
          loading="lazy"
          alt=""
          src="/digicertlogo-1@2x.png"
        />
        <img
          className="h-[2.625rem] w-[11.813rem] relative object-contain z-[1]"
          loading="lazy"
          alt=""
          src="/geotrustlogo-1@2x.png"
        />
      </div>
    </section>
  );
});

export default SSLItems;

import { FunctionComponent, memo } from "react";

export type DomainContainerType = {
  className?: string;
};

const DomainContainer: FunctionComponent<DomainContainerType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`w-[88.375rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[3.062rem] box-border max-w-full text-left text-[2.5rem] text-indigo-100 font-poppins mq800:pb-[2rem] mq800:box-border ${className}`}
      >
        <div className="w-[65.5rem] flex flex-row items-start justify-start gap-[6.937rem] max-w-full mq800:gap-[1.75rem] mq1125:flex-wrap mq1125:gap-[3.438rem]">
          <img
            className="w-[26.813rem] relative max-h-full object-cover max-w-full mq1125:flex-1"
            loading="lazy"
            alt=""
            src="/136528081-4b3aa7cd6ba54466812466528da97ef1-1@2x.png"
          />
          <div className="flex-1 flex flex-col items-start justify-start pt-[1.25rem] px-[0rem] pb-[0rem] box-border min-w-[20.625rem] max-w-full">
            <div className="self-stretch flex flex-col items-start justify-start gap-[2.437rem] max-w-full mq800:gap-[1.188rem]">
              <div className="self-stretch flex flex-col items-start justify-start gap-[0.437rem] max-w-full">
                <h1 className="m-0 self-stretch relative text-inherit tracking-[0.04em] leading-[3.313rem] font-bold font-inherit mq450:text-[1.5rem] mq450:leading-[2rem] mq800:text-[2rem] mq800:leading-[2.625rem]">
                  “IT ALL STARTS WITH A DOMAIN NAME”
                </h1>
                <div className="w-[29.25rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center max-w-full">
                  Find a great domain To find your new domain name, click on
                  search domain and grab it before somebody else. Have got your
                  own domain? Transfer it to us with easy steps. Click on
                  Transfer domain to get started.
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[1.562rem] max-w-full text-[0.813rem] text-gray-100">
                <div className="self-stretch flex flex-row items-start justify-between gap-[1.25rem] mq800:flex-wrap">
                  <div className="flex flex-row items-end justify-start gap-[0.687rem]">
                    <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.875rem]">
                      <img
                        className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0"
                        alt=""
                        src="/materialsymbolscheck-13.svg"
                      />
                    </div>
                    <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium inline-block min-w-[2.188rem]">
                      .com
                    </div>
                  </div>
                  <div className="flex flex-row items-end justify-start gap-[0.687rem]">
                    <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.875rem]">
                      <img
                        className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0"
                        alt=""
                        src="/materialsymbolscheck-13.svg"
                      />
                    </div>
                    <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium inline-block min-w-[1.75rem]">
                      .org
                    </div>
                  </div>
                  <div className="flex flex-row items-end justify-start gap-[0.687rem]">
                    <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.875rem]">
                      <img
                        className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0"
                        alt=""
                        src="/materialsymbolscheck-13.svg"
                      />
                    </div>
                    <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium inline-block min-w-[2.5rem]">
                      .store
                    </div>
                  </div>
                  <div className="flex flex-row items-end justify-start gap-[0.687rem]">
                    <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.875rem]">
                      <img
                        className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0"
                        alt=""
                        src="/materialsymbolscheck-13.svg"
                      />
                    </div>
                    <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium inline-block min-w-[1.688rem]">
                      .net
                    </div>
                  </div>
                  <div className="flex flex-row items-end justify-start gap-[0.687rem]">
                    <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.875rem]">
                      <img
                        className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0"
                        alt=""
                        src="/materialsymbolscheck-13.svg"
                      />
                    </div>
                    <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium inline-block min-w-[1.938rem]">
                      .info
                    </div>
                  </div>
                  <div className="flex flex-row items-end justify-start gap-[0.687rem]">
                    <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.875rem]">
                      <img
                        className="w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0"
                        alt=""
                        src="/materialsymbolscheck-13.svg"
                      />
                    </div>
                    <div className="relative tracking-[0.04em] leading-[2.938rem] font-medium inline-block min-w-[1.688rem]">
                      .me
                    </div>
                  </div>
                </div>
                <div className="w-[22.938rem] flex flex-row items-start justify-start gap-[1.312rem] max-w-full text-[1rem] text-white mq450:flex-wrap">
                  <div className="flex-1 flex flex-col items-start justify-start pt-[0.062rem] px-[0rem] pb-[0rem] box-border min-w-[7rem]">
                    <div className="self-stretch rounded-3xs bg-gray-200 flex flex-row items-start justify-start pt-[0.75rem] pb-[0.812rem] pl-[1.562rem] pr-[0.812rem] whitespace-nowrap">
                      <div className="h-[3.063rem] w-[10.813rem] relative rounded-3xs bg-gray-200 hidden" />
                      <div className="flex-1 relative leading-[1.5rem] font-medium z-[1]">
                        Search Domain
                      </div>
                    </div>
                  </div>
                  <div className="rounded-3xs bg-gray-200 flex flex-row items-start justify-start pt-[0.812rem] px-[1.187rem] pb-[0.75rem] whitespace-nowrap">
                    <div className="h-[3.063rem] w-[10.813rem] relative rounded-3xs bg-gray-200 hidden" />
                    <div className="relative leading-[1.5rem] font-medium z-[1]">
                      Transfer Domain
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default DomainContainer;

import { FunctionComponent, memo } from "react";
import FaqItemsList2 from "./FaqItemsList2";
import FaqItemsList1 from "./FaqItemsList1";
import FaqItemsList from "./FaqItemsList";
import ToggleFAQ from "./ToggleFAQ";

export type FaqUnlimitedType = {
  className?: string;
};

const FaqUnlimited: FunctionComponent<FaqUnlimitedType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins ${className}`}
      >
        <div className="w-[66.75rem] flex flex-col items-start justify-start gap-[2rem] max-w-full mq750:gap-[1rem]">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.312rem]">
            <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
              FREQUENTLY ASKED QUESTIONS
            </h2>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1.562rem] box-border gap-[0.125rem] max-w-full text-[0.875rem] text-slategray-100">
            <FaqItemsList2
              whatIsSharedHosting="1. What is Shared Hosting?"
              faqItemAnswersContent="/vector-335.svg"
            />
            <FaqItemsList1
              sharedHostingIsATypeOfWeb="Shared hosting is a type of website hosting in which multiple websites share the same server. This type of hosting lowers down the cost for each website. It is one of the most popular, easy-to-use, and affordable web hosting service. Various websites share bandwidth, disk space, RAM, and other parameters."
              hostlastingsLinuxSharedWe="Hostlasting’s Linux shared web hosting services provides unlimited bandwidth, huge disk space, free SSL certificate, free cPanel hosting, Softaculous one-click installer. Our best-in-class unlimited shared web hosting services and flexible hosting plans ease up your journey on the web."
            />
            <FaqItemsList
              whyDoYouNeedSharedHosting="2. Why do you need Shared Hosting?"
              moreIconsList="/vector-335.svg"
              theMajorAdvantageOfShared="The major advantage of shared hosting is low cost. Once you avail the domain name, you need a hosting to get your website up and running on the internet. If your website is new and yet to gain high number of visitors, shared hosting is the most feasible option for you. Multiple website will share the same server and resources. Consequently, the cost will be shared too. Moreover, it requires very low or little maintenance and you do not need to possess much technical knowledge. So, for beginners and new websites, this type of hosting is the preferred option"
            />
            <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.562rem] box-border gap-[0.125rem] max-w-full text-black">
              <ToggleFAQ title="3. What are the scenarios in which shared hosting is recommended?" />
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full text-slategray-100">
                <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[1.25rem] pl-[4.687rem] pr-[4.562rem] box-border min-h-[16.813rem] max-w-full mq1050:pl-[2.313rem] mq1050:pr-[2.25rem] mq1050:box-border">
                  <div className="h-[16.813rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                    <p className="m-0">
                      following are some the scenarios in which shared hosting
                      should be preferred:
                    </p>
                    <p className="m-0">
                      1. Beginners with low traffic on their websites should opt
                      for shared hosting. Sharing servers and other resources
                      for running your websites will be a cost-efficient option.
                      So, if you are starting your website or business with a
                      low budget, it can be the best option.
                    </p>
                    <p className="m-0">
                      2. Linux shared web hosting is preferable for websites
                      that do not require a lot of maintenance and control in
                      terms of software. Moreover, the websites that do not need
                      the root access should be hosted on the shared servers.
                      Even when the websites owners do not possess much
                      technical knowledge, this type of hosting becomes the
                      feasible option. Hostlasting provides around the clock
                      support to its clients. So, shared hosting becomes the
                      best option.
                    </p>
                    <p className="m-0">
                      3.Once your website is up and running and the traffic
                      grows considerably. You might have chosen the shared
                      hosting option with low traffic in the mind, but you can
                      upgrade your existing shared hosting plan to accommodate
                      the growing traffic.
                    </p>
                    <p className="m-0">
                      In short, new bloggers, small businesses, and startups
                      should prefer shared hosting option.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[0.562rem] pl-[0.125rem] pr-[0.062rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
                <ToggleFAQ
                  title="4. What are vital benefits of availing shared hosting services?"
                  frameDivAlignSelf="stretch"
                  frameDivPadding="0.5rem 3.812rem 0.562rem 4.687rem"
                  frameDivFlex="unset"
                  frameDivPadding1="1.25rem 0rem 0rem"
                />
                <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[2.375rem] pb-[2.437rem] pl-[4.687rem] pr-[4.562rem] box-border max-w-full mq1050:pl-[2.313rem] mq1050:pr-[2.25rem] mq1050:box-border">
                  <div className="h-[20.75rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                    <p className="m-0">
                      A. Shared hosting is the best option for saving cost and
                      money. Moreover, there is no need to possess extensive
                      technical knowledge. Following are some of the vital
                      benefits of shared hosting.
                    </p>
                    <p className="m-0">
                      1.Cost-effectiveness :With sharing of resources and
                      servers, the cost is divided between the website owners.
                      So, everyone can avail cost-effective benefits.
                    </p>
                    <p className="m-0">
                      2.Already-installed control panel :With inclusion of
                      cPanel, the simplified and easy to navigate dashboard will
                      give you a control over your websites, web applications,
                      databases, and others.
                    </p>
                    <p className="m-0">
                      3.No requirement of technical knowledge :With our experts
                      at your disposal around the clock, you will have your
                      technical and administrative issues will be resolved as
                      soon as possible.
                    </p>
                    <p className="m-0">
                      4.No efforts needed for management :With shared hosting in
                      place, the server management is handled by the provider,
                      freeing you from the worries of management. Moreover,
                      one-click installer provided with the plans helps you in
                      saving time and availing required applications.
                    </p>
                    <p className="m-0">
                      5.Ease in scalability :As the traffic grows and the need
                      to accommodate the growing traffic arises, you can upgrade
                      the existing plans without any hassles. You can get in
                      contact with us and we will help in upgrading your plan.
                    </p>
                    <p className="m-0">
                      6.SSL security :Security of your website is ensured with
                      the SSL certificate that comes with each of our plans.
                      This frees you up from the worries of cyber-attacks and
                      data breaches.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <FaqItemsList
              whyDoYouNeedSharedHosting="5. Is there offer a money-return guarantee?"
              moreIconsList="/vector-337.svg"
              theMajorAdvantageOfShared="Yes, there is. We provide 100 percent a money-return guarantee."
            />
            <FaqItemsList
              whyDoYouNeedSharedHosting="6. What differentiates the shared hosting from other types of hosting? Is it better than others?"
              moreIconsList="/vector-337.svg"
              theMajorAdvantageOfShared="There are two major factors that differentiate the shared hosting from other types of hosting. The first factor is the hosting architecture and the other is cost. In terms of hosting architecture, server space, RAM, bandwidth, and other resources are shared with other websites. This, in turn, lowers the overall cost of hosting. It is better for small businesses, new bloggers, and individuals who do not have huge budgets and just beginning their web journeys. The newcomers do not need to avail dedicated server space; they can rent the space they require on the server."
            />
            <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[0.562rem] pl-[0.125rem] pr-[0rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
                  <ToggleFAQ
                    title="7. Do you provide any money-back guarantee on Linux shared web hosting services plans?"
                    frameDivAlignSelf="unset"
                    frameDivPadding="0.5rem 3.812rem 0.562rem 4.687rem"
                    frameDivFlex="1"
                    frameDivPadding1="1.25rem 0rem 0rem"
                  />
                </div>
                <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[2.25rem] px-[4.812rem] box-border max-w-full mq750:pl-[2.375rem] mq750:pr-[2.375rem] mq750:box-border">
                  <div className="h-[5.563rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="relative tracking-[0.04em] leading-[1.063rem] z-[1]">
                    Yes, there is 30-day money-back guarantee on all of our
                    plans.
                  </div>
                </div>
              </div>
            </div>
            <FaqItemsList
              whyDoYouNeedSharedHosting="8.Do your shared hosting services include email hosting services?"
              moreIconsList="/vector-337.svg"
              theMajorAdvantageOfShared="Yes, our plans include email hosting services. On top of that, email hosting services are free of cost. A simplified webmail interface and support by POP3 and IMAP will help you in availing email access from anywhere. Moreover, you have a privilege to create an unlimited number of email hosting accounts. You website hosting space is utilized for the storage of email accounts and data."
            />
            <FaqItemsList
              whyDoYouNeedSharedHosting="9. Is upgrade services available for my Linux shared web hosting plan?"
              moreIconsList="/vector-337.svg"
              theMajorAdvantageOfShared="Yes, it is available. You can upgrade your current plan without any hassles as our team of expert will help you in the process."
            />
            <FaqItemsList
              whyDoYouNeedSharedHosting="10. Are Windows shared web hosting services available in your offerings?"
              moreIconsList="/vector-337.svg"
              theMajorAdvantageOfShared="Yes, we also provide Windows shared web hosting services that are equipped with advanced features and cost-effective."
            />
            <FaqItemsList2
              whatIsSharedHosting="11. Is buying a new domain a requirement to avail a Linux shared hosting plan?"
              faqItemAnswersContent="/vector-337.svg"
            />
            <FaqItemsList1
              sharedHostingIsATypeOfWeb="No, it is not a requirement. You can utilize your existing domain while availing Hostlasting’s Linux shared hosting plans. "
              hostlastingsLinuxSharedWe="While checkout, you can mention your existing domain name and you can avail our services."
            />
            <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0.062rem] pb-[0.562rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
                <ToggleFAQ
                  title="12. How can manage websites and databases with Hostlasting’s Linux shared hosting plans?"
                  frameDivAlignSelf="stretch"
                  frameDivPadding="0.5rem 3.812rem 0.562rem 4.687rem"
                  frameDivFlex="unset"
                  frameDivPadding1="1.25rem 0rem 0rem"
                />
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
                  <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-end py-[1.187rem] px-[2.687rem] box-border min-h-[7.625rem] max-w-full mq1050:pl-[1.313rem] mq1050:pr-[1.313rem] mq1050:box-border">
                    <div className="h-[7.625rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                    <div className="relative tracking-[0.04em] leading-[1.063rem] z-[1]">
                      <p className="m-0">{`With our Linux shared hosting plans, we offer cPanel VPS hosting without any additional cost. `}</p>
                      <p className="m-0">
                        This cPanel contains an easy to navigate and simplified
                        dashboard to help you in managing websites and databases
                        without
                      </p>
                      <p className="m-0">
                        any difficulty or technical knowledge.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0.062rem] pb-[0.562rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
                <FaqItemsList2
                  whatIsSharedHosting="13. Is there any specified number of websites that can be hosted with shared hosting plans?"
                  faqItemAnswersContent="/vector-337.svg"
                />
                <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[1.937rem] px-[4.875rem] box-border min-h-[7.625rem] max-w-full mq1050:pl-[2.438rem] mq1050:pr-[2.438rem] mq1050:box-border">
                  <div className="h-[7.625rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="relative tracking-[0.04em] leading-[1.063rem] z-[1]">
                    <p className="m-0">{`If you avail the personal shared web hosting plan, you can only host one website per domain. `}</p>
                    <p className="m-0">{`With Business and Pro plans, you can host multiple websites per domain. The number of websites depends `}</p>
                    <p className="m-0">upon the plan you availed.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[0.062rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
                <ToggleFAQ
                  title="14. Is the subdivision of my Linux shared hosting plan possible if I want to resell it?"
                  frameDivAlignSelf="stretch"
                  frameDivPadding="0.5rem 3.812rem 0.562rem 4.687rem"
                  frameDivFlex="unset"
                  frameDivPadding1="1.25rem 0rem 0rem"
                />
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
                  <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[1.937rem] px-[4.875rem] box-border min-h-[7.625rem] max-w-full mq1050:pl-[2.438rem] mq1050:pr-[2.438rem] mq1050:box-border">
                    <div className="h-[7.625rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                    <div className="relative tracking-[0.04em] leading-[1.063rem] z-[1]">
                      <p className="m-0">{`As per our company protocols and regulations, Hostlasting does not allow subdivision of Linux shared hosting plan. `}</p>
                      <p className="m-0">{`However, hosting multiple websites per domain is possible with the appropriate shared web hosting plans. Moreover, `}</p>
                      <p className="m-0">
                        you can avail
                        <a
                          className="text-[inherit]"
                          href="https://www.hostlasting.com/cheap-reseller-hosting"
                          target="_blank"
                        >
                          <span className="[text-decoration:underline]">
                            reseller hosting plans
                          </span>
                        </a>
                        to resell web hosting plans.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.312rem] text-[1.25rem] text-white">
            <div className="rounded-3xs bg-indigo-100 flex flex-row items-start justify-start py-[0.25rem] px-[3.437rem] gap-[0.5rem]">
              <div className="h-[3.375rem] w-[14.438rem] relative rounded-3xs bg-indigo-100 hidden" />
              <div className="flex flex-col items-start justify-start pt-[0.437rem] px-[0rem] pb-[0rem]">
                <img
                  className="w-[2rem] h-[2rem] relative overflow-hidden shrink-0 z-[1]"
                  alt=""
                  src="/fluentpersonsupport16filled.svg"
                />
              </div>
              <a href="#" className="[text-decoration:none]relative leading-[2.875rem] font-medium inline-block min-w-[5.063rem] text-white z-[1] mq450:text-[1rem] mq450:leading-[2.313rem]">
                Support
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default FaqUnlimited;

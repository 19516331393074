import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type PlanPriceEnjoyType = {
  className?: string;
  mo3?: string;
  host3Website?: string;
  y7560210mo?: string;

  /** Style props */
  propPadding?: CSSProperties["padding"];
  propLeft?: CSSProperties["left"];
  propDisplay?: CSSProperties["display"];
  propMinWidth?: CSSProperties["minWidth"];
  propMargin?: CSSProperties["margin"];
  propPadding1?: CSSProperties["padding"];
  propMinWidth1?: CSSProperties["minWidth"];
};

const PlanPriceEnjoy: FunctionComponent<PlanPriceEnjoyType> = memo(
  ({
    className = "",
    propPadding,
    mo3,
    host3Website,
    propLeft,
    propDisplay,
    propMinWidth,
    propMargin,
    propPadding1,
    y7560210mo,
    propMinWidth1,
  }) => {
    const encryptStyle: CSSProperties = useMemo(() => {
      return {
        padding: propPadding,
      };
    }, [propPadding]);

    const host3WebsiteStyle: CSSProperties = useMemo(() => {
      return {
        left: propLeft,
        display: propDisplay,
        minWidth: propMinWidth,
      };
    }, [propLeft, propDisplay, propMinWidth]);

    const freeLetsEncrypt3Style: CSSProperties = useMemo(() => {
      return {
        margin: propMargin,
      };
    }, [propMargin]);

    const priceStyle: CSSProperties = useMemo(() => {
      return {
        padding: propPadding1,
      };
    }, [propPadding1]);

    const y7560Style: CSSProperties = useMemo(() => {
      return {
        minWidth: propMinWidth1,
      };
    }, [propMinWidth1]);

    return (
      <div
        className={`self-stretch flex flex-col items-start justify-start gap-[0.312rem] text-center text-[0.688rem] text-black font-poppins ${className}`}
      >
        <div
          className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[2.75rem] pr-[3rem] text-[1.5rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border"
          style={encryptStyle}
        >
          <div className="relative leading-[2.938rem] font-semibold z-[1] mq450:text-[1.188rem] mq450:leading-[2.375rem]">
            {mo3}
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.25rem] pr-[3.375rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
          <div className="flex-1 flex flex-col items-end justify-start">
            <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0rem] pr-[0.125rem]">
              <div className="h-[2.938rem] flex-1 relative shrink-0">
                <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem] z-[1]" />
                <div
                  className="absolute top-[0rem] left-[2.063rem] leading-[2.938rem] font-medium inline-block min-w-[5.125rem] z-[2]"
                  style={host3WebsiteStyle}
                >
                  {host3Website}
                </div>
              </div>
            </div>
            <div className="self-stretch h-[2.938rem] relative shrink-0 mt-[-0.438rem]">
              <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem] z-[1]" />
              <div className="absolute top-[0rem] left-[0.75rem] leading-[2.938rem] font-medium inline-block min-w-[7.75rem] z-[3]">
                Unlimited SSD Storage
              </div>
            </div>
            <div className="self-stretch h-[2.938rem] relative shrink-0 mt-[-0.438rem]">
              <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem] z-[1]" />
              <div className="absolute top-[0rem] left-[0.938rem] leading-[2.938rem] font-medium inline-block min-w-[7.313rem] z-[2]">
                Unlimited bandwidth
              </div>
            </div>
            <div className="self-stretch h-[2.938rem] relative shrink-0 mt-[-0.438rem]">
              <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem] z-[1]" />
              <div className="absolute top-[0rem] left-[1rem] leading-[2.938rem] font-medium inline-block min-w-[7.313rem] z-[2]">
                Unlimited FTP, MySQL
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.812rem] pr-[4rem] text-[0.938rem] text-lightgray-200 mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
          <div
            className="relative leading-[2.938rem] font-semibold z-[1]"
            style={freeLetsEncrypt3Style}
          >
            Free Let's Encrypt
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[0.625rem] pl-[1.75rem] pr-[1.812rem]">
          <div className="h-[7.688rem] flex-1 relative">
            <div className="absolute top-[0rem] left-[1.5rem] w-[9.25rem] h-[2.938rem]">
              <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem] z-[1]" />
              <div className="absolute top-[0rem] left-[1.375rem] leading-[2.938rem] font-medium inline-block min-w-[6.313rem] z-[2]">
                Unlimited Email ID
              </div>
            </div>
            <div className="absolute top-[2.375rem] left-[1.5rem] w-[9.25rem] h-[2.938rem]">
              <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem] z-[1]" />
              <div className="absolute top-[0rem] left-[1.875rem] leading-[2.938rem] font-medium inline-block min-w-[5.438rem] z-[3]">
                cPanel Account
              </div>
            </div>
            <div className="absolute top-[4.75rem] left-[0rem] w-[12.438rem] h-[2.938rem]">
              <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[12.438rem] h-[1.75rem] z-[1]" />
              <div className="absolute top-[0rem] left-[1.75rem] leading-[2.938rem] font-medium z-[4]">
                99.99% LiteSpeed Uptime
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[1.062rem] text-[0.75rem] text-slategray-100">
          <div
            className="h-[2.875rem] flex-1 rounded-8xs bg-white border-whitesmoke-400 border-[1px] border-solid box-border flex flex-row items-end justify-between pt-[1.25rem] pb-[0rem] pl-[1.437rem] pr-[1.5rem] gap-[1.25rem] z-[1]"
            style={priceStyle}
          >
            <div className="h-[2.875rem] w-[16rem] relative rounded-8xs bg-white border-whitesmoke-400 border-[1px] border-solid box-border hidden" />
            <div
              className="relative leading-[2.938rem] font-medium inline-block min-w-[7.125rem] z-[2]"
              style={y7560Style}
            >
              {y7560210mo}
            </div>
            <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[1.25rem]">
              <img
                className="w-[0.75rem] h-[0.375rem] relative object-contain z-[2]"
                alt=""
                src="/vector-335.svg"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.312rem] pr-[3.375rem] text-[0.75rem] text-white mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
          <div className="flex-1 flex flex-row items-start justify-start">
            <div className="w-[9.313rem] flex flex-col items-start justify-start pt-[0.25rem] px-[0rem] pb-[0rem] box-border">
              <div className="self-stretch h-[2.438rem] relative rounded-8xs bg-indigo-100 z-[1]" />
            </div>
            <a href="#" className="[text-decoration:none] relative leading-[2.938rem] font-semibold text-white inline-block min-w-[4.063rem] z-[2] ml-[-6.75rem]">
              Order Now
            </a>
          </div>
        </div>
      </div>
    );
  }
);

export default PlanPriceEnjoy;

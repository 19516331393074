import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type GroupComponent10Type = {
  className?: string;
  redundancy?: string;
  commonlyConstructedWithRe?: string;
  technicalEngineersExpertT?: string;

  /** Style props */
  propGap?: CSSProperties["gap"];
};

const GroupComponent10: FunctionComponent<GroupComponent10Type> = memo(
  ({
    className = "",
    propGap,
    redundancy,
    commonlyConstructedWithRe,
    technicalEngineersExpertT,
  }) => {
    const groupDiv9Style: CSSProperties = useMemo(() => {
      return {
        gap: propGap,
      };
    }, [propGap]);

    return (
      <div
        className={`self-stretch rounded-mini border-indigo-100 border-[1px] border-solid box-border flex flex-row items-start justify-start py-[0.937rem] px-[1.75rem] gap-[1.125rem] max-w-full z-[1] text-left text-[3rem] text-slategray-200 font-poppins mq1150:flex-wrap mq1150:justify-center ${className}`}
        style={groupDiv9Style}
      >
        <div className="flex flex-col items-start justify-start pt-[0.625rem] px-[0rem] pb-[0rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[1.875rem] mq450:text-[1.813rem] mq450:leading-[0.75rem] mq800:text-[2.375rem] mq800:leading-[1rem]">
            {redundancy}
          </div>
        </div>
        <div className="relative text-[0.75rem] leading-[1.25rem] font-medium text-slategray-100 inline-block max-w-full">
          <p className="m-0">{commonlyConstructedWithRe}</p>
          <p className="m-0">{technicalEngineersExpertT}</p>
        </div>
        <div className="h-[4.625rem] w-[66.625rem] relative rounded-mini border-indigo-100 border-[1px] border-solid box-border hidden max-w-full" />
      </div>
    );
  }
);

export default GroupComponent10;

import { FunctionComponent, memo } from "react";

export type PriceOfferReliableType = {
  className?: string;
};

const PriceOfferReliable: FunctionComponent<PriceOfferReliableType> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch h-[23.313rem] relative max-w-full text-center text-[2rem] text-indigo-100 font-poppins mq1050:h-auto mq1050:min-h-[373] ${className}`}
      >
        <img
          className="absolute top-[0.938rem] left-[0rem] w-[4.813rem] h-[4.063rem] object-cover"
          loading="lazy"
          alt=""
          src="/11684121-4391-4@2x.png"
        />
        <div className="absolute top-[0rem] left-[3.188rem] w-[73rem] flex flex-col items-end justify-start gap-[3.187rem] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-start max-w-full">
            <div className="w-[69.875rem] flex flex-col items-end justify-start max-w-full">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem]">
                <h2 className="m-0 relative text-inherit tracking-[0.04em] leading-[2.938rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[1.75rem] mq1050:text-[1.625rem] mq1050:leading-[2.375rem]">
                  100% Reliable Joomla Hosting
                </h2>
              </div>
              <div className="self-stretch relative text-[0.75rem] leading-[1.25rem] font-medium text-slategray-100 z-[1]">
                <ul className="m-0 font-inherit text-inherit pl-[1.333rem]">
                  <li className="my-[undefined] mx-[undefined]">
                    Use and update the latest version of the platform to get the
                    best experience.
                  </li>
                  <li className="my-[undefined] mx-[undefined]">
                    The updated system is much more appealing and better than
                    the initial demo system.
                  </li>
                  <li className="my-[undefined] mx-[undefined]">{`Encompasses the most recent versions of Apache, MySQL, & PHP`}</li>
                </ul>
                <p className="m-0">
                  The format application of PHP operates as suPHP for boosted
                  and improved Joomla security application. This provides the
                  added security to the system.
                </p>
              </div>
            </div>
          </div>
          <div className="w-[60.188rem] flex flex-row items-start justify-between max-w-full gap-[1.25rem] mq1050:flex-wrap">
            <div className="w-[44.188rem] flex flex-col items-start justify-start pt-[2.062rem] px-[0rem] pb-[0rem] box-border max-w-full">
              <div className="self-stretch flex flex-col items-end justify-start gap-[2.125rem] max-w-full">
                <div className="self-stretch h-[0.063rem] flex flex-row items-start justify-end py-[0rem] px-[2.562rem] box-border max-w-full">
                  <div className="self-stretch flex-1 relative border-indigo-100 border-t-[1px] border-solid box-border max-w-full" />
                </div>
                <div className="self-stretch flex flex-col items-end justify-start">
                  <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.437rem] pr-[1.25rem]">
                    <h2 className="m-0 relative text-inherit tracking-[0.04em] leading-[2.938rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[1.75rem] mq1050:text-[1.625rem] mq1050:leading-[2.375rem]">
                      Pricing Plans
                    </h2>
                  </div>
                  <div className="self-stretch relative text-[0.813rem] leading-[1.25rem] font-medium text-slategray-100 z-[1]">
                    We, as an organization, offer 100% Secured Joomla Hosting.
                    Following is a list of our important Pricing Plans that
                    would provide you with options to select from
                  </div>
                </div>
              </div>
            </div>
            <img
              className="h-[4.063rem] w-[4.813rem] relative object-cover"
              loading="lazy"
              alt=""
              src="/11684121-4391-4@2x.png"
            />
          </div>
        </div>
      </div>
    );
  }
);

export default PriceOfferReliable;

import { FunctionComponent, memo } from "react";

export type AdditionalFeaturesType = {
  className?: string;
};

const AdditionalFeatures: FunctionComponent<AdditionalFeaturesType> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch bg-indigo-100 flex flex-col items-start justify-start pt-[1rem] pb-[3.562rem] pl-[14.062rem] pr-[14rem] box-border gap-[1.843rem] max-w-full z-[1] text-left text-[0.875rem] text-darkslategray-100 font-poppins mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq750:pl-[7rem] mq750:pr-[7rem] mq750:box-border ${className}`}
      >
        <div className="w-[89.438rem] h-[18.75rem] relative bg-indigo-100 hidden max-w-full" />
        <div className="self-stretch flex flex-row items-start justify-center text-[1rem] text-white">
          <h3 className="relative leading-[2.875rem] font-semibold z-[2]">
            Along with these vital features, we also offer
          </h3>
        </div>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[2.062rem] max-w-full mq750:gap-[1rem]">
          <div className="rounded-3xs bg-white flex flex-row items-start justify-start pt-[0.5rem] px-[1.375rem] pb-[0.437rem] whitespace-nowrap z-[2]">
            <div className="h-[3.813rem] w-[16.875rem] relative rounded-3xs bg-white hidden" />
            <div className="relative leading-[2.875rem] font-semibold z-[3]">
              Free SSL Security for your server
            </div>
          </div>
          <div className="flex-1 rounded-3xs bg-white flex flex-row items-start justify-start pt-[0.5rem] pb-[0.437rem] pl-[1.437rem] pr-[1.375rem] box-border min-w-[14.313rem] whitespace-nowrap max-w-full z-[2]">
            <div className="h-[3.813rem] w-[22.063rem] relative rounded-3xs bg-white hidden max-w-full" />
            <div className="relative leading-[2.875rem] font-semibold z-[3]">
              Instant installation of cPanel on your server
            </div>
          </div>
          <div className="rounded-3xs bg-white flex flex-row items-start justify-start pt-[0.5rem] px-[1.187rem] pb-[0.437rem] whitespace-nowrap z-[2]">
            <div className="h-[3.813rem] w-[18.313rem] relative rounded-3xs bg-white hidden" />
            <div className="relative leading-[2.875rem] font-semibold z-[3]">
              Around the clock aftersales support
            </div>
          </div>
        </div>
        <div className="w-[58.438rem] flex flex-row items-start justify-center max-w-full">
          <div className="w-[37.813rem] flex flex-row flex-wrap items-start justify-center gap-[2.875rem] max-w-full mq750:gap-[1.438rem]">
            <div className="flex-1 rounded-3xs bg-white flex flex-row items-start justify-start pt-[0.5rem] px-[1.375rem] pb-[0.437rem] box-border min-w-[14.625rem] whitespace-nowrap max-w-full z-[2]">
              <div className="h-[3.813rem] w-[22.5rem] relative rounded-3xs bg-white hidden max-w-full" />
              <div className="relative leading-[2.875rem] font-semibold z-[3]">
                Assurance of 99.99% uptime of your server
              </div>
            </div>
            <div className="rounded-3xs bg-white flex flex-row items-start justify-start pt-[0.5rem] px-[2.062rem] pb-[0.437rem] whitespace-nowrap z-[2]">
              <div className="h-[3.813rem] w-[12.438rem] relative rounded-3xs bg-white hidden" />
              <div className="relative leading-[2.875rem] font-semibold z-[3]">
                24x Loading speed
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default AdditionalFeatures;

import { FunctionComponent, memo } from "react";

export type VpsFeatureOneType = {
  className?: string;
};

const VpsFeatureOne: FunctionComponent<VpsFeatureOneType> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins ${className}`}
      >
        <div className="w-[60.875rem] flex flex-col items-start justify-start gap-[1.875rem] max-w-full">
          <div className="self-stretch flex flex-col items-start justify-start gap-[0.875rem]">
            <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.312rem]">
              <div className="w-[16.063rem] flex flex-col items-start justify-start gap-[0.5rem]">
                <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
                  <div className="h-[0.375rem] w-[4.688rem] relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[4]" />
                </div>
                <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
                  What is storage VPS?
                </h2>
              </div>
            </div>
            <div className="self-stretch relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
              Storage VPS is the secure, cost-effective, and reliable option to
              store a massive amount of data and information. Storing data on
              virtual private servers will not burden your pockets when it comes
              to budgeting for your storage needs. Moreover, you can access the
              data whenever you need. With an entire freedom to access the data,
              you can also avail automatic operating system installation
              services at affordable prices.
            </div>
          </div>
          <div className="w-[58.938rem] flex flex-row items-start justify-start py-[0rem] px-[0.312rem] box-border max-w-full text-[0.875rem]">
            <div className="flex-1 flex flex-row flex-wrap items-end justify-start gap-[1.625rem] max-w-full">
              <div className="h-[18.438rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.062rem] box-border">
                <div className="flex-1 flex flex-col items-start justify-start gap-[2.156rem]">
                  <div className="w-[0.313rem] flex-1 relative rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none bg-indigo-100" />
                  <div className="w-[0.313rem] flex-1 relative rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none bg-indigo-100" />
                  <div className="w-[0.313rem] flex-1 relative rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none bg-indigo-100" />
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.437rem] min-w-[36.625rem] max-w-full mq800:min-w-full">
                <div className="self-stretch flex flex-col items-start justify-start max-w-full">
                  <h3 className="relative leading-[2.875rem] font-semibold inline-block max-w-full">
                    Know more about our cheap storage VPS hosting services
                  </h3>
                  <div className="self-stretch relative leading-[1.25rem] text-slategray-100 z-[1]">
                    Hostlasting deploys excellent and best-in-class storage
                    server infrastructure to enable you with rapid business
                    operations. Our servers provide an optimum uptime with
                    installation of servers in Europe, full root access, and
                    high security.
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start max-w-full">
                  <h3 className="relative leading-[2.875rem] font-semibold inline-block max-w-full">
                    Ready to purchase the best storage VPS hosting service?
                  </h3>
                  <div className="self-stretch relative leading-[1.25rem] text-slategray-100 z-[1]">
                    With our reliable, secure, and best-in-class services, you
                    can fulfill your storage VPS hosting needs. Our top-notch
                    server infrastructure and around the clock support will
                    ensure optimum uptime and quality service.
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start max-w-full">
                  <h3 className="relative leading-[2.875rem] font-semibold inline-block max-w-full">
                    High Storage VPS – Choose your operating system (OS)
                  </h3>
                  <div className="self-stretch relative leading-[1.25rem] text-slategray-100 z-[1]">
                    Each of our cloud storage VPS hosting plans enable you to
                    opt for the operating system (OS) of your choice. With
                    installation of OS, you can avail convenience in managing
                    the server and its benefits.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default VpsFeatureOne;

import { FunctionComponent } from "react";
import HeaderHeroKvmWindow from "../components/HeaderHeroKvmWindow";
import KvmWindowPlansPricing from "../components/KvmWindowPlansPricing";
import KnowKVMGridSections from "../components/KnowKVMGridSections";
import FAQKVMWindow from "../components/FAQKVMWindow";
import FooterComponent from "../components/FooterComponent";

const WindowsVPS: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[1.25rem] leading-[normal] tracking-[normal]">
      <HeaderHeroKvmWindow />
      <div className="w-[0.188rem] h-[9.938rem] relative border-white border-r-[3px] border-solid box-border hidden" />
      <img
        className="w-[15.313rem] h-[15.313rem] relative object-cover hidden"
        alt=""
        src="/1531135-4091-12@2x.png"
      />
      <KvmWindowPlansPricing />
      <KnowKVMGridSections />
      <section className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[10.125rem] pl-[1.312rem] pr-[1.25rem] relative text-left text-[1.5rem] text-darkslategray-100 font-poppins">
        <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
          One click install OS Dockers
        </h3>
        <img
          className="h-[23.5rem] w-[66.063rem] absolute !m-[0] right-[11.688rem] bottom-[-17.375rem] object-cover"
          loading="lazy"
          alt=""
          src="/vps-features-1@2x.png"
        />
      </section>
      <section className="self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[6.875rem] box-border max-w-full">
        <div className="w-[57.188rem] flex flex-col items-end justify-start gap-[0.968rem] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[17.062rem] mq450:pr-[1.25rem] mq450:box-border">
            <div className="h-[0.313rem] w-[9.75rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
          </div>
          <div className="w-[6.063rem] h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
          <div className="self-stretch flex flex-row items-start justify-start max-w-full">
            <div className="w-[48.188rem] flex flex-row items-end justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
              <div className="h-[0.313rem] w-[6.063rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
              <div className="w-[16.875rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.687rem] box-border">
                <div className="self-stretch flex flex-row items-start justify-between gap-[1.25rem]">
                  <div className="w-[6.063rem] flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem] box-border">
                    <div className="self-stretch h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                  </div>
                  <div className="h-[0.313rem] w-[6.063rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[4.812rem] pl-[3.125rem] pr-[1.25rem] box-border max-w-full mq1050:pl-[1.563rem] mq1050:box-border">
        <div className="w-[55.938rem] flex flex-col items-start justify-start gap-[0.531rem] max-w-full">
          <div className="w-[9.688rem] h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
          <div className="self-stretch flex flex-row items-start justify-end">
            <div className="h-[0.313rem] w-[14.188rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
          </div>
          <div className="w-[48.625rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <div className="w-[19.875rem] flex flex-row items-end justify-between gap-[1.25rem]">
              <div className="w-[5.5rem] flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[0.687rem] box-border">
                <div className="self-stretch h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
              </div>
              <div className="w-[4.813rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.187rem] box-border">
                <div className="self-stretch h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
              </div>
              <div className="h-[0.313rem] w-[4.813rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
            </div>
          </div>
        </div>
      </section>
      <FAQKVMWindow />
      <FooterComponent
        hOSTINGMargin="0"
        dOMAINSSSLMargin="0"
        cOMPANYMargin="0"
        wordPressHostingTextDecoration="unset"
        aboutUsTextDecoration="unset"
        cheapSSDHostingTextDecoration="unset"
        supportTextDecoration="unset"
        vPSHostingTextDecoration="unset"
        blogTextDecoration="unset"
        dedicatedHostingTextDecoration="unset"
        offersTextDecoration="unset"
        contactUsTextDecoration="unset"
        privacyPolicyTextDecoration="none"
        sitemapTextDecoration="unset"
      />
    </div>
  );
};

export default WindowsVPS;

import { FunctionComponent, memo } from "react";

export type ContentType = {
  className?: string;
};

const Content: FunctionComponent<ContentType> = memo(({ className = "" }) => {
  return (
    <div
      className={`w-[86.938rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full text-left text-[1rem] text-white font-poppins ${className}`}
    >
      <div className="w-[65.313rem] flex flex-col items-start justify-start gap-[11.25rem] max-w-full mq800:gap-[2.813rem] mq1150:gap-[5.625rem] mq450:gap-[1.375rem]">
        <div className="self-stretch flex flex-row items-start justify-end max-w-full">
          <div className="w-[59.313rem] flex flex-col items-start justify-start gap-[2.937rem] max-w-full mq800:gap-[1.438rem]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[0.875rem] max-w-full">
              <div className="self-stretch flex flex-col items-start justify-start max-w-full">
                <div className="self-stretch flex flex-row items-start justify-end max-w-full">
                  <div className="w-[39.688rem] flex flex-row items-end justify-between gap-[1.25rem] max-w-full mq800:flex-wrap">
                    <div className="relative tracking-[0.11em] leading-[3.125rem] font-semibold z-[1]">
                      Cheap Storage VPS
                    </div>
                    <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[1rem]">
                      <img
                        className="w-[13.75rem] h-[13.75rem] relative object-cover"
                        loading="lazy"
                        alt=""
                        src="/86e2e2fcf66c41a4a2f792b940aabe0ca-2@2x.png"
                      />
                    </div>
                  </div>
                </div>
                <h1 className="m-0 relative text-[3rem] leading-[3.125rem] font-semibold font-inherit inline-block max-w-full z-[1] mq800:text-[2.375rem] mq800:leading-[2.5rem] mq450:text-[1.813rem] mq450:leading-[1.875rem]">
                  Unmanaged Storage VPS Hosting
                </h1>
              </div>
              <div className="w-[48rem] h-[0.438rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                <div className="self-stretch w-[31rem] relative rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none [background:linear-gradient(89.99deg,_#6e3b8f,_#fff)] max-w-full z-[1]" />
              </div>
            </div>
            <div className="flex flex-row items-start justify-start py-[0rem] px-[0.25rem] box-border max-w-full text-center text-[0.875rem] text-lightgray-100">
              <div className="w-[50.313rem] relative leading-[1.25rem] font-medium inline-block z-[1]">
                <p className="m-0">{`VPS with unlimited storage presents one of the best and feasible options to store huge amount of data on servers. `}</p>
                <p className="m-0">{`Our servers are located at the prime location in Europe. `}</p>
                <p className="m-0">
                  They are equipped with multilayered security protection and
                  ensure ease in availability of your valuable data.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-[0.562rem] text-center">
          <a className="[text-decoration:none] relative leading-[3.5rem] font-medium text-plum inline-block min-w-[3rem] z-[1]">
            Home
          </a>
          <h3 className="m-0 relative text-[1.25rem] leading-[3.5rem] font-medium font-inherit inline-block min-w-[0.688rem] z-[1] mq450:text-[1rem] mq450:leading-[2.813rem]">
            /
          </h3>
          <div className="flex flex-row items-start justify-start relative">
            <div className="relative leading-[3.5rem] font-medium inline-block min-w-[6rem] z-[1]">
              VPS Hosting
            </div>
            <div className="h-[14.5rem] w-[13.75rem] absolute !m-[0] top-[-12rem] left-[-7.375rem]">
              <img
                className="absolute top-[0rem] left-[0rem] w-[13.75rem] h-[13.75rem] object-cover"
                alt=""
                src="/86e2e2fcf66c41a4a2f792b940aabe0ca-2@2x.png"
              />
              <img
                className="absolute top-[13rem] left-[0.563rem] w-[1.5rem] h-[1.5rem] overflow-hidden z-[1]"
                loading="lazy"
                alt=""
                src="/icroundhome.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Content;

import { FunctionComponent, memo } from "react";

export type FrameComponent20Type = {
  className?: string;
};

const FrameComponent20: FunctionComponent<FrameComponent20Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[1.625rem] box-border max-w-full text-left text-[2rem] text-indigo-100 font-poppins ${className}`}
      >
        <div className="w-[66.688rem] flex flex-col items-end justify-start gap-[3.625rem] max-w-full mq750:gap-[1.813rem]">
          <div className="self-stretch flex flex-col items-start justify-start max-w-full">
            <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit inline-block max-w-full z-[1] mq450:text-[1.188rem] mq450:leading-[1.75rem] mq1050:text-[1.625rem] mq1050:leading-[2.313rem]">
              Best-in-class cPanel VPS hosting
            </h2>
            <div className="self-stretch flex flex-col items-start justify-start gap-[4.125rem] max-w-full text-[0.875rem] text-slategray-100 mq750:gap-[1rem] mq1050:gap-[2.063rem]">
              <div className="self-stretch flex flex-row items-start justify-start gap-[4rem] max-w-full mq750:gap-[1rem] mq1050:flex-wrap mq1050:gap-[2rem]">
                <div className="flex-1 flex flex-col items-start justify-start pt-[1.625rem] px-[0rem] pb-[0rem] box-border min-w-[23.438rem] max-w-full mq450:min-w-full">
                  <div className="self-stretch relative leading-[1.25rem] z-[1]">
                    <p className="m-0">
                      The business you built with your blood, sweat, and tears
                      deserve the attention from the million. You took one the
                      best and crucial decisions by taking your business online
                      in this digital era. Then, you have been putting all the
                      efforts into building quality services and products. These
                      services and products must gain the exposure it deserves.
                      Picking up the best-in-class cPanel VPS hosting will
                      provide the necessary boost to your website with enhanced
                      cPanel experience, and consequently, help your business
                      reach millions of customers. Hostlasting provides the
                      best-in-class and affordable solutions for cPanel VPS
                      hosting.
                    </p>
                    <p className="m-0">
                      Hostlasting: Reliable cPanel VPS Hosting Provider
                    </p>
                    <p className="m-0">
                      VPS hosting is becoming the preferred choice for choosing
                      the hosting for a website on the server as it offers
                      various advantages over other types of hosting solutions.
                      These advantages include security, lower cost, enhanced
                      performance, and reliability. With cPanel coming into
                      picture, you will gain access to the entire control
                      dashboard with which you can manage the web-hosting server
                      as per your preferences. You can manage the entire website
                      with a single click and make changes as per your
                      requirements. Hostlasting will provide you necessary
                      resources and services to ensure your website is running
                      successfully and managed efficiently. We strive to become
                      a reliable and trust-worthy partner in your success and
                      our cPanel VPS hosting services are proof of commitment.
                    </p>
                  </div>
                </div>
                <div className="w-[26.625rem] flex flex-row items-start justify-start min-w-[26.625rem] max-w-full z-[1] mq750:min-w-full mq1050:flex-1">
                  <div className="h-[27.125rem] flex-1 relative max-w-full">
                    <img
                      className="absolute top-[0rem] left-[0rem] w-full h-full object-cover"
                      loading="lazy"
                      alt=""
                      src="/10511516-1@2x.png"
                    />
                    <div className="absolute top-[26.188rem] left-[7.438rem] [filter:blur(10.9px)] rounded-[50%] bg-gray-1600 w-[11.75rem] h-[0.938rem] z-[1]" />
                  </div>
                </div>
              </div>
              <div className="w-[28.75rem] h-[0.125rem] relative rounded-8xs bg-indigo-100 max-w-full z-[1]" />
            </div>
          </div>
          <div className="w-[63.625rem] flex flex-col items-end justify-start gap-[4.25rem] max-w-full text-[0.875rem] text-darkslategray-100 mq750:gap-[1.063rem] mq1050:gap-[2.125rem]">
            <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0rem] pr-[1.5rem] box-border max-w-full">
              <div className="flex-1 flex flex-row flex-wrap items-start justify-start gap-[1.125rem] max-w-full">
                <div className="flex flex-col items-start justify-start pt-[0.312rem] px-[0rem] pb-[0rem]">
                  <div className="flex flex-col items-start justify-start gap-[2.812rem]">
                    <div className="w-[0.563rem] h-[2.813rem] relative">
                      <div className="absolute top-[0rem] left-[0rem] rounded-[50%] bg-indigo-100 w-[0.563rem] h-[0.563rem] z-[1]" />
                      <div className="absolute top-[2.25rem] left-[0rem] rounded-[50%] bg-indigo-100 w-[0.563rem] h-[0.563rem] z-[1]" />
                    </div>
                    <div className="w-[0.563rem] h-[0.563rem] relative rounded-[50%] bg-indigo-100 z-[1]" />
                  </div>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[0.937rem] min-w-[39.313rem] max-w-full mq750:min-w-full">
                  <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] box-border max-w-full text-center">
                    <div className="relative leading-[1.25rem] font-medium z-[1]">
                      Hostlasting has flexible and affordable plans.
                    </div>
                  </div>
                  <div className="h-[2.5rem] relative leading-[1.25rem] font-medium flex items-center z-[1]">
                    <span>
                      <p className="m-0">
                        Hostlasting provides efficiently managed cPanel/WHM VPS
                        hosting with the SSD hosting platform for superb
                        performance, almost around
                      </p>
                      <p className="m-0">
                        the clock uptime, enhanced security, and aftersales
                        support.
                      </p>
                    </span>
                  </div>
                  <div className="relative leading-[1.25rem] font-medium inline-block max-w-full z-[1]">
                    Hostlasting gives solutions to pre-install the control panel
                    on your server for proper management of website data.
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[28.75rem] h-[0.125rem] relative rounded-8xs bg-indigo-100 max-w-full z-[1]" />
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent20;

import { FunctionComponent, memo } from "react";

export type FrameComponent25Type = {
  className?: string;
  eMailID?: string;
  exExampleemailcom?: string;
};

const FrameComponent25: FunctionComponent<FrameComponent25Type> = memo(
  ({ className = "", eMailID, exExampleemailcom }) => {
    return (
      <div
        className={`self-stretch flex flex-col items-start justify-start gap-[0.5rem] max-w-full text-left text-[0.875rem] text-white font-poppins ${className}`}
      >
        <div className="relative leading-[1.5rem] font-medium inline-block min-w-[4.25rem] z-[1]">
          {eMailID}
        </div>
        <div className="self-stretch rounded-8xs bg-white border-slategray-300 border-[1px] border-solid box-border flex flex-row items-start justify-start py-[0.5rem] px-[2.437rem] whitespace-nowrap max-w-full z-[1] text-[0.75rem] text-gray-700">
          <div className="h-[2.75rem] w-[27.125rem] relative rounded-8xs bg-white border-slategray-300 border-[1px] border-solid box-border hidden max-w-full" />
          <div className="relative leading-[1.5rem] font-medium z-[2]">
            {exExampleemailcom}
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent25;

import { FunctionComponent } from "react";
import UnmanagedVPS from "../components/UnmanagedVPS";
import Pricing from "../components/Pricing";
import FrameComponent22 from "../components/FrameComponent22";
import FrameComponent14 from "../components/FrameComponent14";
import FrameComponent15 from "../components/FrameComponent15";
import CloudVPSHosting from "../components/CloudVPSHosting";
import DedicatedCPUPlans from "../components/DedicatedCPUPlans";
import GroupComponent5 from "../components/GroupComponent5";

const StandardVPS: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-end justify-start gap-[5.187rem] leading-[normal] tracking-[normal] mq800:gap-[2.563rem] mq450:gap-[1.313rem]">
      <UnmanagedVPS />
      <div className="w-[0.188rem] h-[9.938rem] relative border-white border-r-[3px] border-solid box-border hidden" />
      <section className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[3.187rem] pl-[1.312rem] pr-[1.25rem] box-border max-w-full text-left text-[1.25rem] text-darkslategray-100 font-poppins">
        <div className="w-[60.875rem] flex flex-col items-start justify-start gap-[0.875rem] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.375rem] pr-[3.437rem] box-border max-w-full mq1150:pl-[1.688rem] mq1150:pr-[1.688rem] mq1150:box-border">
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.187rem] max-w-full">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.437rem] pr-[1.25rem]">
                <h2 className="m-0 relative text-inherit tracking-[0.11em] leading-[3.125rem] font-medium font-inherit mq450:text-[1rem] mq450:leading-[2.5rem]">
                  Choose among the best VPS hosting servers
                </h2>
              </div>
              <div className="relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
                Upgrade your business with the best VPS hosting server and
                choose the plan that suits your business requirements the best.
              </div>
            </div>
          </div>
          <div className="self-stretch relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
            <p className="m-0">
              Virtual Private Server (VPS) hosting service offers an entirely
              reliable, affordable, and KVM based virtualization. Our
              best-in-class and the cheapest VPS server hosting services are
              tailored for largeenterprises and designed for catering to growing
              needs.
            </p>
            <p className="m-0">
              {" "}
              Avail a complete access and user control and gain solutions to
              your VPS hosting needs with Hostlasting.
            </p>
          </div>
        </div>
      </section>
      <Pricing />
      <FrameComponent22 />
      <section className="self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[2.937rem] box-border max-w-full">
        <div className="w-[57.188rem] flex flex-col items-end justify-start gap-[0.968rem] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[17.062rem] mq450:pr-[1.25rem] mq450:box-border">
            <div className="h-[0.313rem] w-[9.75rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
          </div>
          <div className="w-[6.063rem] h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
          <div className="self-stretch flex flex-row items-start justify-start max-w-full">
            <div className="w-[48.188rem] flex flex-row items-end justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
              <div className="h-[0.313rem] w-[6.063rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
              <div className="w-[16.875rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.687rem] box-border">
                <div className="self-stretch flex flex-row items-start justify-between gap-[1.25rem]">
                  <div className="w-[6.063rem] flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem] box-border">
                    <div className="self-stretch h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                  </div>
                  <div className="h-[0.313rem] w-[6.063rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <img
        className="w-[29.188rem] relative max-h-full object-cover hidden max-w-full"
        alt=""
        src="/1531135-4091-1@2x.png"
      />
      <div className="w-[87.563rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
        <div className="w-[55.938rem] flex flex-col items-start justify-start gap-[0.531rem] max-w-full">
          <div className="w-[9.688rem] h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
          <div className="self-stretch flex flex-row items-start justify-end">
            <div className="h-[0.313rem] w-[14.188rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
          </div>
          <div className="w-[48.625rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <div className="w-[19.875rem] flex flex-row items-end justify-between gap-[1.25rem]">
              <div className="w-[5.5rem] flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[0.687rem] box-border">
                <div className="self-stretch h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
              </div>
              <div className="w-[4.813rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.187rem] box-border">
                <div className="self-stretch h-[0.313rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
              </div>
              <div className="h-[0.313rem] w-[4.813rem] relative rounded-[50%] bg-gray-1100 z-[1]" />
            </div>
          </div>
        </div>
      </div>
      <section className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.375rem] box-border gap-[1.125rem] max-w-full">
        <FrameComponent14 />
        <FrameComponent15 />
      </section>
      <CloudVPSHosting />
      <DedicatedCPUPlans />
      <GroupComponent5 />
    </div>
  );
};

export default StandardVPS;

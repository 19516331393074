import { FunctionComponent, memo } from "react";

export type FrameComponent21Type = {
  className?: string;
};

const FrameComponent21: FunctionComponent<FrameComponent21Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[1.937rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins ${className}`}
      >
        <div className="flex-1 bg-ghostwhite-100 flex flex-col items-center justify-start pt-[6.937rem] pb-[7.125rem] pl-[1.687rem] pr-[1.25rem] box-border gap-[3.625rem] max-w-full mq750:gap-[1.813rem] mq750:pt-[2.938rem] mq750:pb-[3rem] mq750:box-border mq1275:pt-[4.5rem] mq1275:pb-[4.625rem] mq1275:box-border">
          <div className="w-[89.438rem] h-[86.063rem] relative bg-ghostwhite-100 hidden max-w-full" />
          <div className="w-[66.75rem] flex flex-row items-start justify-center py-[0rem] pl-[0rem] pr-[0.125rem] box-border max-w-full">
            <h2 className="relative leading-[2.875rem] font-semibold z-[1] mq450:text-[1.188rem] mq450:leading-[2.313rem]">
              Features of Cloud VPS Hosting
            </h2>
          </div>
          <div className="w-[66.75rem] flex flex-row items-start justify-center gap-[6.5rem] max-w-full text-[1.25rem] text-indigo-100 mq750:gap-[1.625rem] mq1100:flex-wrap mq1100:gap-[3.25rem]">
            <div className="flex-1 flex flex-col items-end justify-start gap-[3.625rem] min-w-[19.875rem] max-w-full mq750:gap-[1.813rem]">
              <div className="w-[28.063rem] h-[23.313rem] relative max-w-full">
                <img
                  className="absolute h-full top-[0rem] bottom-[0rem] left-[2.375rem] max-h-full w-[23.313rem] object-cover z-[1]"
                  alt=""
                  src="/geometricabstractbackgroundwithconnectedlinedotsnetworkconnectionbackgroundyourpresentationgraphicpolygonalbackgroundscientificillustrationrasterillustration-2@2x.png"
                />
                <img
                  className="absolute top-[4.5rem] left-[0rem] rounded-2xl w-[28.063rem] h-[14.313rem] object-cover z-[2]"
                  alt=""
                  src="/cloud-vps-hosting1-1@2x.png"
                />
              </div>
              <div className="self-stretch flex flex-row items-start justify-end pt-[0rem] pb-[3.187rem] pl-[0rem] pr-[1.312rem] box-border max-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[2.187rem] max-w-full mq750:gap-[1.063rem]">
                  <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] box-border max-w-full">
                    <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      READY TO USE WITHIN SECONDS:
                    </h3>
                  </div>
                  <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                    Start creating servers without any delay or interruptions.
                    You will be up and running within 10 seconds through our
                    user-friendly interface and clutter-free dashboard
                  </div>
                </div>
              </div>
              <div className="w-[29.375rem] flex flex-row items-start justify-end py-[0rem] px-[1.312rem] box-border max-w-full">
                <div className="h-[24.125rem] flex-1 relative max-w-full">
                  <img
                    className="absolute h-full top-[0rem] bottom-[0rem] left-[1.313rem] max-h-full w-[24.125rem] object-cover z-[1]"
                    alt=""
                    src="/geometricabstractbackgroundwithconnectedlinedotsnetworkconnectionbackgroundyourpresentationgraphicpolygonalbackgroundscientificillustrationrasterillustration-4@2x.png"
                  />
                  <img
                    className="absolute top-[2.75rem] left-[0rem] rounded-2xl w-[26.75rem] h-[18.563rem] object-cover z-[2]"
                    alt=""
                    src="/cloud-vps-hosting2-1@2x.png"
                  />
                </div>
              </div>
            </div>
            <div className="w-[29.688rem] flex flex-col items-start justify-start pt-[6.25rem] px-[0rem] pb-[0rem] box-border min-w-[29.688rem] max-w-full mq450:pt-[2.625rem] mq450:box-border mq750:min-w-full mq1100:flex-1 mq1100:pt-[4.063rem] mq1100:box-border">
              <div className="self-stretch flex flex-col items-start justify-start gap-[2.187rem] max-w-full mq750:gap-[1.063rem]">
                <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[2.812rem] pl-[0.437rem] pr-[0rem] box-border max-w-full">
                  <div className="flex-1 flex flex-col items-start justify-start gap-[2.187rem] max-w-full mq750:gap-[1.063rem]">
                    <h3 className="relative leading-[1.688rem] font-semibold inline-block max-w-full z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      INTUITIVE NAVIGATION AND SUPPORT:
                    </h3>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                      The intuitive, fast, and user-friendly interface helps you
                      navigate and manage the Hostlasting Cloud rapidly and
                      efficiently. Say goodbye to the long loading times and
                      execute tasks rapidly. You can utilize it for your team
                      projects as well.
                    </div>
                  </div>
                </div>
                <div className="w-[28.438rem] flex flex-col items-start justify-start gap-[2.062rem] max-w-full mq450:gap-[1rem]">
                  <div className="self-stretch h-[23.313rem] relative">
                    <img
                      className="absolute h-full top-[0rem] bottom-[0rem] left-[3.375rem] max-h-full w-[23.313rem] object-cover z-[1]"
                      alt=""
                      src="/geometricabstractbackgroundwithconnectedlinedotsnetworkconnectionbackgroundyourpresentationgraphicpolygonalbackgroundscientificillustrationrasterillustration-2@2x.png"
                    />
                    <img
                      className="absolute top-[5.813rem] left-[0rem] rounded-2xl w-[28.438rem] h-[12.375rem] object-cover z-[2]"
                      alt=""
                      src="/cloud-vps-hosting3-1@2x.png"
                    />
                  </div>
                  <div className="flex flex-row items-start justify-start py-[0rem] px-[0.437rem]">
                    <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      EXTRAORDINARY SERVICE:
                    </h3>
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.437rem] pr-[0rem] box-border max-w-full text-[0.938rem] text-slategray-100">
                  <div className="flex-1 relative tracking-[0.04em] leading-[1.25rem] inline-block max-w-full z-[1]">
                    Hostlasting’s level of customer service for SSD cloud VPS
                    hosting offerings goes above and beyond what is expected. It
                    involves determining the needs of customers, providing
                    timely and personalized support, and offering solutions to
                    customers’ problems.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent21;

import { FunctionComponent, memo } from "react";

export type LocationsType = {
  className?: string;
};

const Locations: FunctionComponent<LocationsType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[2.537rem] box-border max-w-full text-left text-[1rem] text-black font-poppins mq800:pb-[1.625rem] mq800:box-border ${className}`}
      >
        <div className="w-[63.438rem] flex flex-col items-end justify-start pt-[8.25rem] pb-[12.462rem] pl-[13.562rem] pr-[14.312rem] box-border relative gap-[2.687rem] max-w-full mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq800:gap-[1.313rem] mq800:pt-[5.375rem] mq800:pb-[8.125rem] mq800:pl-[3.375rem] mq800:pr-[3.563rem] mq800:box-border mq1125:pl-[6.75rem] mq1125:pr-[7.125rem] mq1125:box-border">
          <img
            className="w-full h-full absolute !m-[0] top-[0rem] right-[0rem] bottom-[0rem] left-[0rem] max-w-full overflow-hidden max-h-full"
            loading="lazy"
            alt=""
            src="/xmlid-7526.svg"
          />
          <div className="self-stretch flex flex-row items-start justify-start max-w-full">
            <div className="w-[28.813rem] flex flex-row items-end justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
              <div className="flex flex-row items-end justify-start gap-[0.75rem]">
                <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[1rem]">
                  <div className="w-[1.25rem] h-[1.25rem] relative rounded-[50%] bg-white border-indigo-100 border-[0px] border-solid box-border z-[1]" />
                </div>
                <div className="relative tracking-[0.04em] leading-[3.313rem] font-semibold inline-block min-w-[2.188rem] z-[1]">
                  USA
                </div>
              </div>
              <div className="w-[10.938rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.187rem] box-border">
                <div className="self-stretch flex flex-row items-end justify-start gap-[0.625rem]">
                  <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[2.375rem]">
                    <div className="w-[1.25rem] h-[1.25rem] relative rounded-[50%] bg-white border-indigo-100 border-[0px] border-solid box-border z-[1]" />
                  </div>
                  <div className="flex-1 flex flex-col items-start justify-start">
                    <div className="flex flex-row items-end justify-start gap-[0.75rem]">
                      <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[1rem]">
                        <div className="w-[1.25rem] h-[1.25rem] relative rounded-[50%] bg-white border-indigo-100 border-[0px] border-solid box-border z-[1]" />
                      </div>
                      <div className="relative tracking-[0.04em] leading-[3.313rem] font-semibold inline-block min-w-[5.563rem] z-[1]">
                        LITHUANIA
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.125rem] pr-[0rem] mt-[-1.313rem]">
                      <div className="flex-1 flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.04em] leading-[3.313rem] font-semibold inline-block min-w-[4.5rem] z-[1]">
                          FINLAND
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.687rem] pr-[0rem] mt-[-1.938rem]">
                          <div className="flex-1 flex flex-row items-end justify-start gap-[0.75rem]">
                            <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[1rem]">
                              <div className="w-[1.25rem] h-[1.25rem] relative rounded-[50%] bg-white border-indigo-100 border-[0px] border-solid box-border z-[1]" />
                            </div>
                            <div className="relative tracking-[0.04em] leading-[3.313rem] font-semibold inline-block min-w-[5.25rem] z-[1]">
                              GERMANY
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-end justify-start gap-[0.75rem]">
            <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.937rem]">
              <div className="w-[1.25rem] h-[1.25rem] relative rounded-[50%] bg-white border-indigo-100 border-[0px] border-solid box-border z-[1]" />
            </div>
            <div className="relative tracking-[0.04em] leading-[3.313rem] font-semibold inline-block min-w-[2.938rem] z-[1]">
              INDIA
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default Locations;

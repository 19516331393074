import { FunctionComponent, memo } from "react";
import StandardContainer from "./StandardContainer";
import QuestionOne from "./QuestionOne";

export type FQAKvmVpsHostingType = {
  className?: string;
};

const FQAKvmVpsHosting: FunctionComponent<FQAKvmVpsHostingType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[3rem] pl-[1.25rem] pr-[1.437rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins mq1150:pb-[1.25rem] mq1150:box-border mq1350:pb-[1.938rem] mq1350:box-border ${className}`}
      >
        <div className="w-[66.75rem] flex flex-col items-start justify-start gap-[2.562rem] max-w-full mq800:gap-[1.25rem]">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[2.062rem] pr-[1.25rem]">
            <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
              FREQUENTLY ASKED QUESTIONS
            </h2>
          </div>
          <div className="self-stretch flex flex-col items-end justify-start gap-[0.125rem] max-w-full text-[0.875rem] text-slategray-100">
            <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0rem] pr-[0.187rem] box-border max-w-full text-black">
              <StandardContainer
                whatIsTheDifferenceBetwee="1.What is the difference between standard VPS hosting and Linux KVM VPS hosting?"
                standardAnswer="/vector-335.svg"
              />
            </div>
            <div className="self-stretch flex flex-row items-start justify-end pt-[0rem] pb-[0.562rem] pl-[0rem] pr-[0.187rem] box-border max-w-full">
              <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[0.812rem] pb-[1.625rem] pl-[4.687rem] pr-[4.562rem] box-border max-w-full mq1150:pl-[2.313rem] mq1150:pr-[2.25rem] mq1150:box-border">
                <div className="h-[10.938rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                  <p className="m-0">
                    Linux KVM VPS hosting implies that virtual private server
                    hosting is carried out in Linux-based operating system and
                    KVM virtualization environment. KVM VPS servers are made up
                    of x86 processors and resources are dedicated to only one
                    user. Sharing with other users is not available. On the
                    other hand, standard VPS hosting comes with the OpenVZ
                    virtualization in which the resources are shared with other
                    users. Also known as container-based virtualization,
                    multiple users can share the resources and achieve the
                    cost-effectiveness.
                  </p>
                  <p className="m-0">
                    In both cases, we utilize high-end infrastructure to ensure
                    optimum uptime, flexibility, agility, and powerful
                    performance for you. You can choose the best possible
                    solution based on your business requirements. Our dedicated
                    servers and enterprise-class equipment will ensure you get
                    business continuity.
                  </p>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.562rem] box-border gap-[0.125rem] max-w-full">
              <StandardContainer
                propFlex="unset"
                propAlignSelf="stretch"
                propPadding="0.5rem 3.812rem 0.562rem 4.687rem"
                whatIsTheDifferenceBetwee="2.What are the benefits of Linux KVM VPS hosting?"
                standardAnswer="/vector-335.svg"
              />
              <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[1.875rem] pb-[1.812rem] pl-[4.687rem] pr-[4.562rem] box-border max-w-full mq1150:pl-[2.313rem] mq1150:pr-[2.25rem] mq1150:box-border">
                <div className="h-[7.938rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                  <p className="m-0">
                    Being one of the popular hosting types, this type of hosting
                    offers following benefits:
                  </p>
                  <p className="m-0">
                    Our VPS server provides the RAM capabilities from 2 GB to 16
                    GB. Moreover, the storage capacity for storing your website
                    data is from 20 GB to 160 GB. Moreover, the bandwidth for
                    transferring the data is from 1800 GB to 15900 GB. As per
                    the different plans, you will avail the storage and transfer
                    capabilities.
                  </p>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-end pt-[0rem] px-[0.062rem] pb-[0.625rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
                <StandardContainer
                  propFlex="unset"
                  propAlignSelf="stretch"
                  propPadding="0.5rem 3.812rem 0.562rem 4.687rem"
                  whatIsTheDifferenceBetwee="3.What are the services that you can expect with Hostlasting’s standard VPS hosting?"
                  standardAnswer="/vector-337.svg"
                />
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
                  <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-col items-start justify-start pt-[1rem] pb-[2.875rem] pl-[4.625rem] pr-[1.25rem] box-border gap-[0.562rem] max-w-full mq450:pt-[1.25rem] mq450:pb-[1.875rem] mq450:box-border mq1150:pl-[2.313rem] mq1150:box-border">
                    <div className="w-[66.563rem] h-[22.5rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                    <div className="relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                      You can avail following services by choosing Hostlasting’s
                      standard VPS hosting:
                    </div>
                    <div className="w-[57.125rem] relative tracking-[0.04em] leading-[1.063rem] flex items-center max-w-full z-[1]">
                      <span className="w-full">
                        <p className="m-0">
                          •Top-notch hardware – Hostlasting offers Linux VPS
                          hosting and KVM virtualization on a powerful hardware
                          with the multi-core and multi-processor
                          infrastructure.
                        </p>
                        <p className="m-0">&nbsp;</p>
                        <p className="m-0">
                          •Tier III server platform – For enabling maximum
                          reliability and uptime, the dedicated servers are
                          located at Tier III datacenters at Lithuania, Europe.
                        </p>
                        <p className="m-0">&nbsp;</p>
                        <p className="m-0">
                          •Cost-saving – Our plans are designed to offer maximum
                          value through services at a minimal cost. In
                          comparison to other service providers, we offer cheap
                          KVM VPS services and provide more value.
                        </p>
                        <p className="m-0">&nbsp;</p>
                        <p className="m-0">{`•Secure & protected – Hostlasting deploys stringent security measures to ensure protection and security of valuable data. With powerful hardware, firewall configuration, and skilled team at the background, we eliminate cyberattacks and online threats.`}</p>
                        <p className="m-0">&nbsp;</p>
                        <p className="m-0">
                          •Customizability and upgrade – VPS hosting solutions,
                          supported by Linux operating system and KVM
                          virtualization are easily customizable according to
                          business requirements. In addition, upgrade of these
                          solutions is possible with rising business needs.
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-end pt-[0rem] pb-[0.562rem] pl-[0.062rem] pr-[0.125rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
                <StandardContainer
                  propFlex="unset"
                  propAlignSelf="stretch"
                  propPadding="0.5rem 3.75rem 0.562rem 4.687rem"
                  whatIsTheDifferenceBetwee="4. Which operating systems (OS) are compatible with Hostlasting’s Linux KVM VPS hosting services?"
                  standardAnswer="/vector-337.svg"
                />
                <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-col items-start justify-start pt-[2.5rem] pb-[3.875rem] pl-[4.812rem] pr-[4.312rem] box-border gap-[0.562rem] max-w-full mq1150:pl-[2.375rem] mq1150:pr-[2.125rem] mq1150:box-border">
                  <div className="w-[66.563rem] h-[20.75rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="self-stretch relative tracking-[0.04em] leading-[1.063rem] z-[1]">
                    Hostlasting’s Linux KVM VPS hosting services are compatible
                    with four operating systems such as Ubuntu, Fedora, CentOS,
                    and Debian.
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.125rem] pr-[0rem] box-border max-w-full">
                    <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                      <p className="m-0">
                        Ubuntu – Ubuntu is one of the most prominent, free, and
                        open-source Linux distribution operating system. This OS
                        is supported by professionals as well as enterprises. It
                        can be utilized in a cloud-based environment.
                      </p>
                      <p className="m-0">&nbsp;</p>
                      <p className="m-0">
                        CentOS – CentOS implies Community Enterprise Operating
                        System. This Linux distribution system is developed by
                        Red Hat Enterprise Linux (RHEL). It is a Linux operating
                        system used by enterprises, and has a huge community
                        base to support.
                      </p>
                      <p className="m-0">&nbsp;</p>
                      <p className="m-0">
                        Fedora – This Linux distribution system is sponsored by
                        Red Hat and developed by the community known as Fedora
                        Project. It is free and open-source, and can be utilized
                        for studying and learning.
                      </p>
                      <p className="m-0">&nbsp;</p>
                      <p className="m-0">{`Debian – It is a free UNIX-based operating system. It is prominent among the network servers & personal computers users. Basic utilities and programs enable the system to run on computers.`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <QuestionOne
              doesHostlastingProvideIPv="5.Does Hostlasting provide IPv6 support? Do you also offer support for IPv4 addresses? If yes, "
              thenHowManyAddressesCanBe="then how many addresses can be added?"
              yesWeProvideSupportForIPv="Yes, we provide support for IPv6 and IPv4 address. With all of our virtual machines, you can avail a single IPv6 address. Moreover, there is a provision to add more addresses as per the business needs."
              inAdditionWeProvideSuppor="In addition, we provide support for a single IPv4 address with each of our plans. In case, there is a requirement for more IPv4 addresses, they can be availed as add-ons following the deployment of the server. You have the facility to add maximum of 31 addresses in a single instance."
            />
            <div className="self-stretch flex flex-row items-start justify-end pt-[0rem] pb-[0.562rem] pl-[0.125rem] pr-[0.062rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
                <StandardContainer
                  propFlex="unset"
                  propAlignSelf="stretch"
                  propPadding="1.437rem 3.812rem 1.437rem 4.687rem"
                  whatIsTheDifferenceBetwee="6.Where are Linux KVM VPS hosting servers of Hostlasting located?"
                  standardAnswer="/vector-337.svg"
                />
                <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[2.75rem] px-[4.625rem] pb-[2.812rem] box-border max-w-full mq1150:pl-[2.313rem] mq1150:pr-[2.313rem] mq1150:box-border">
                  <div className="h-[9.813rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                    Our cost-efficient and high-quality Linux KVM VPS hosting
                    servers are located at Tier III datacenter in Lithuania,
                    Europe. The location is chosen to offer the optimum uptime
                    and high performance for your resources around the world.
                    The datacenter has a cooling infrastructure and employ
                    strict security measures to ensure 99.99% uptime. The Cisco
                    components employed in the servers would ensure recovery and
                    security of data in case of downtimes.
                  </div>
                </div>
              </div>
            </div>
            <QuestionOne
              propPadding="unset"
              propGap="0.437rem"
              doesHostlastingProvideIPv="7. What are the different plans provided for Linux KVM VPS hosting by Hostlasting? "
              thenHowManyAddressesCanBe="What are the storage and transfer capabilities offered in various plans?"
              yesWeProvideSupportForIPv="Hostlasting provides four plans that are tailed to fulfill varying business requirements within budgetary constraints. In addition, we offer optimum performance and security with each plan. Our prices are lesser and value addition to our clients is more as compared to other providers."
              inAdditionWeProvideSuppor="Hostlasting’s server offers the RAM capabilities from 2 GB to 16 GB and the storage capacity from 20 GB to 160 GB. Moreover, with KVM VPS unlimited bandwidth option, the provided bandwidth to transfer the data is from 1800 GB to 15900 GB. As you avail the different plans, the storage and transfer capabilities differ. We offer customized plans at affordable prices to ensure your business needs are fulfilled. When it comes to fast processing capabilities, you can avail CPU capabilities from 2.7 GHz to advanced 2.8 GHz."
            />
          </div>
        </div>
      </section>
    );
  }
);

export default FQAKvmVpsHosting;

import { FunctionComponent, memo } from "react";

export type FrameComponent5Type = {
  className?: string;
};

const FrameComponent5: FunctionComponent<FrameComponent5Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch bg-ghostwhite-100 flex flex-col items-start justify-start pt-[3.187rem] px-[11.437rem] pb-[3.5rem] box-border gap-[0.687rem] max-w-full text-left text-[0.875rem] text-indigo-100 font-poppins mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq750:pt-[2.063rem] mq750:px-[5.688rem] mq750:pb-[2.25rem] mq750:box-border ${className}`}
      >
        <div className="w-[89.438rem] h-[36.563rem] relative bg-ghostwhite-100 hidden max-w-full" />
        <h3 className="relative leading-[2.875rem] font-semibold inline-block max-w-full z-[1]">
          Unmatched value for money - Wide scope for scalability:
        </h3>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[6.75rem] max-w-full mq750:gap-[1.688rem] mq1100:gap-[3.375rem]">
          <div className="flex-1 flex flex-col items-start justify-start gap-[2.375rem] min-w-[32.813rem] max-w-full mq450:gap-[1.188rem] mq1100:min-w-full">
            <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.062rem] pr-[0.125rem] box-border max-w-full text-slategray-100">
              <div className="flex-1 flex flex-row items-end justify-start gap-[1.062rem] max-w-full mq1100:flex-wrap">
                <div className="h-[10.188rem] flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.437rem] box-border">
                  <div className="w-[0.25rem] flex-1 relative bg-indigo-100 z-[1]" />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[2.375rem] min-w-[31.188rem] max-w-full mq450:gap-[1.188rem] mq1100:min-w-full">
                  <div className="self-stretch relative leading-[1.25rem] z-[1]">
                    You can avail the services for individual applications,
                    dynamic clusters, distributed systems, and development
                    environments. Our SSD cloud VPS hosting services are
                    available with the newest generation of CPU-optimized
                    servers that are equipped with AMD EPYC™ 2nd Gen processors.
                  </div>
                  <div className="self-stretch relative leading-[1.25rem] z-[1]">
                    There is no requirement for a minimum contract period. If
                    you need SSD Cloud VPS hosting services for less than a
                    month, you can avail them. You can pay for the services on
                    an hourly basis. We charge our services based on usage only.
                    If you are not using our services and do not need a server,
                    you can delete it and avoid being charged excessively.
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1.375rem] box-border gap-[0.687rem] max-w-full">
              <h3 className="h-[1.563rem] relative leading-[2.875rem] font-semibold flex items-center shrink-0 z-[1]">
                Dedicated vCPU Applications:
              </h3>
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.062rem] pr-[0rem] box-border max-w-full text-slategray-100">
                <div className="flex-1 flex flex-row items-end justify-start gap-[1.187rem] max-w-full mq1100:flex-wrap">
                  <div className="h-[3.125rem] w-[0.25rem] relative bg-indigo-100 z-[1] mq1100:w-full mq1100:h-[0.25rem]" />
                  <div className="flex-1 flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.187rem] box-border min-w-[31.188rem] max-w-full mq1100:min-w-full">
                    <div className="self-stretch relative leading-[1.25rem] z-[1]">
                      You can optimize the performance with our dedicated vCPUs.
                      We implement vCPUs for carrying out CPU-intensive
                      applications seamlessly and effectively. These
                      applications include video encoding, operating web
                      servers, implementing machine learning algorithms, and
                      conducting research calculations.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[17.375rem] pr-[1.25rem] mq450:pl-[1.25rem] mq450:box-border mq750:pl-[8.688rem] mq750:box-border">
              <div className="relative leading-[2.875rem] font-semibold z-[1]">
                Suitable for Primary IPv4 and IPv6 only
              </div>
            </div>
          </div>
          <div className="w-[9.188rem] flex flex-col items-start justify-start pt-[1.187rem] px-[0rem] pb-[0rem] box-border">
            <div className="self-stretch flex flex-col items-start justify-start gap-[2.375rem]">
              <div className="self-stretch h-[9.188rem] relative bg-[url('/public/iconbusinessv3-9-a-3@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
                <img
                  className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                  alt=""
                  src="/iconbusinessv3-9-a-3@2x.png"
                />
                <div className="absolute top-[8.063rem] left-[2.25rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
              </div>
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[0.187rem]">
                <div className="flex-1 flex flex-col items-end justify-start">
                  <img
                    className="w-[8.813rem] h-[8.813rem] relative object-cover z-[1]"
                    alt=""
                    src="/2-weba-5@2x.png"
                  />
                  <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[2.187rem] pr-[1.937rem]">
                    <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[2]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent5;

import { FunctionComponent, memo } from "react";
import TableHeaderRow from "./TableHeaderRow";
import GroupComponent16 from "./GroupComponent16";

export type CyberVPSHostingPricingTableType = {
  className?: string;
};

const CyberVPSHostingPricingTable: FunctionComponent<CyberVPSHostingPricingTableType> =
  memo(({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full text-left text-[1.75rem] text-indigo-100 font-poppins ${className}`}
      >
        <div className="w-[66.813rem] flex flex-col items-end justify-start gap-[2.062rem] max-w-full mq750:gap-[1rem]">
          <div className="self-stretch rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[2.875rem] box-border gap-[1.937rem] max-w-full mq450:pb-[1.875rem] mq450:box-border mq750:gap-[0.938rem]">
            <div className="self-stretch h-[26.75rem] relative rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] hidden" />
            <TableHeaderRow />
            <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.937rem] pr-[0.75rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
                <GroupComponent16
                  cPX11="CCX12"
                  aMD="2 Intel"
                  gB="8 GB"
                  gB1="80 GB"
                  mo="$30/mo"
                />
                <GroupComponent16
                  cPX11="CCX22"
                  aMD="4 AMD"
                  gB="16 GB"
                  gB1="160 GB"
                  mo="$50/mo"
                />
                <GroupComponent16
                  cPX11="CCX32"
                  aMD="8 AMD"
                  gB="32 GB"
                  gB1="240 GB"
                  mo="$100/mo"
                />
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[4.125rem] pr-[3.937rem] box-border max-w-full lg:pl-[2.063rem] lg:pr-[1.938rem] lg:box-border">
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.856rem] max-w-full">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.437rem]">
                <img
                  className="h-[7.688rem] w-[7.688rem] relative overflow-hidden shrink-0"
                  loading="lazy"
                  alt=""
                  src="/cyberpanel2-1-1.svg"
                />
              </div>
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.437rem]">
                <div className="h-[0.375rem] w-[4.688rem] relative [filter:blur(4px)] rounded-[50%] bg-gray-1600" />
              </div>
              <div className="relative leading-[3rem] font-semibold mq450:text-[1.375rem] mq450:leading-[2.313rem]">
                <span>17 Reasons</span>
                <span className="text-[1.5rem] text-darkslategray-100">
                  {" "}
                  Why Hostlasting’s CyberPanel VPS Hosting Is the
                </span>
                <span>
                  <span className="text-[1.625rem]">{` `}</span>
                  <span>Best for You?</span>
                </span>
              </div>
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.437rem] box-border max-w-full text-center text-[0.875rem] text-slategray-100">
                <div className="w-[45.063rem] relative leading-[1.25rem] flex items-center shrink-0 max-w-full">
                  <span className="w-full">
                    <p className="m-0">
                      Hostlasting’s CyberPanel VPS hosting platform offers the
                      best-in-class performance, speed, and
                    </p>
                    <p className="m-0">
                      security features aimed at a remarkable hosting experience
                      and addresses all of your needs in
                    </p>
                    <p className="m-0">a budget-friendly manner.</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  });

export default CyberVPSHostingPricingTable;

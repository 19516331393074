import { FunctionComponent, memo } from "react";

export type FrameComponent13Type = {
  className?: string;
};

const FrameComponent13: FunctionComponent<FrameComponent13Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[3rem] pl-[1.562rem] pr-[1.25rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins mq1275:pb-[1.25rem] mq1275:box-border ${className}`}
      >
        <div className="w-[66.875rem] flex flex-col items-start justify-start gap-[6.625rem] max-w-full mq750:gap-[1.625rem] mq1100:gap-[3.313rem]">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.562rem] box-border max-w-full">
            <div className="w-[51.688rem] flex flex-col items-start justify-start gap-[0.437rem] max-w-full">
              <div className="flex flex-row items-start justify-start py-[0rem] pl-[3.5rem] pr-[3.437rem] mq1100:pl-[1.75rem] mq1100:pr-[1.688rem] mq1100:box-border">
                <h2 className="relative leading-[2.875rem] font-medium mq450:text-[1.188rem] mq450:leading-[2.313rem]">
                  Know more about the cheapest VPS server hosting services
                </h2>
              </div>
              <div className="self-stretch relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
                If you are looking for a budget-friendly VPS server hosting,
                your search ends with Hostlasting. We offer the best plans that
                would address your business requirements at affordable prices.
                In addition, we have a proven track record of the optimum
                uptime, excellent after-sales support, and providing services
                with the best VPS hosting Europe Tier III datacenter.
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-center gap-[4.562rem] max-w-full text-[1.25rem] text-indigo-100 mq750:gap-[1.125rem] mq1100:flex-wrap mq1100:gap-[2.25rem]">
            <div className="flex-1 flex flex-col items-start justify-start pt-[1.125rem] px-[0rem] pb-[0rem] box-border min-w-[21.313rem] max-w-full mq450:min-w-full">
              <div className="self-stretch flex flex-col items-start justify-start gap-[2.312rem] max-w-full mq750:gap-[1.125rem]">
                <div className="w-[29.188rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[1rem] box-border max-w-full">
                  <div className="w-[8.188rem] flex flex-col items-start justify-start gap-[0.75rem]">
                    <img
                      className="w-[8.188rem] h-[8.188rem] relative object-cover"
                      alt=""
                      src="/7c01d9e1ffc44ba6901c5b177e560b3fa-2@2x.png"
                    />
                    <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[1.75rem]">
                      <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600" />
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1.312rem] box-border gap-[2.312rem] max-w-full mq750:gap-[1.125rem]">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                    <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.125rem] pr-[0rem]">
                      <h3 className="relative leading-[1.688rem] font-semibold mq450:text-[1rem] mq450:leading-[1.375rem]">
                        PRIMARY IPs WITH FLEXIBILITY IN NETWORK OPTIONS :
                      </h3>
                    </div>
                    <div className="w-[29.25rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center max-w-full">
                      Establish a connection to the internet by assigning
                      Primary IPs to your server or create a private network
                      server without any Primary IPs. You have the flexibility
                      to change the network option at any time.
                    </div>
                  </div>
                  <div className="w-[29.188rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                    <div className="h-[11.813rem] w-[11.813rem] relative bg-[url('/public/081157cc8f97471294894136f5e6cedba-1@2x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                        alt=""
                        src="/081157cc8f97471294894136f5e6cedba-1@2x.png"
                      />
                      <div className="absolute top-[11.438rem] left-[3.563rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                    </div>
                  </div>
                </div>
                <div className="w-[29.25rem] flex flex-col items-start justify-start gap-[2.25rem] max-w-full mq750:gap-[1.125rem]">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem]">
                    <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                      <h3 className="relative leading-[1.688rem] font-semibold mq450:text-[1rem] mq450:leading-[1.375rem]">
                        FIREWALLS FOR SECURITY:
                      </h3>
                    </div>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100">
                      Secure your infrastructure with state-of-the-art firewalls
                      that come at no additional cost. Create inbound and
                      outbound rules to prevent any unwanted traffic. Once
                      configured, you can assign them to multiple cloud servers.
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.312rem]">
                    <div className="w-[11.813rem] flex flex-col items-start justify-start gap-[0.437rem]">
                      <img
                        className="w-[11.813rem] h-[11.813rem] relative object-cover"
                        alt=""
                        src="/cloud-storage-7-1@2x.png"
                      />
                      <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[3.562rem]">
                        <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[29.5rem] flex flex-row items-start justify-start pt-[0rem] px-[0.125rem] pb-[1.187rem] box-border max-w-full">
                  <div className="flex-1 flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                    <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                      <h3 className="relative leading-[1.688rem] font-semibold mq450:text-[1rem] mq450:leading-[1.375rem]">
                        EXCELLENT PERFORMANCE:
                      </h3>
                    </div>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100">
                      Our cloud servers used for providing SSD cloud VPS hosting
                      services feature high-performance hardware with AMD EPYC
                      2nd Gen and Intel® Xeon® Gold processors, speedy NVMe SSDs
                      in a local RAID10, and a redundant 10 Gbit network
                      connection for optimal performance.
                    </div>
                  </div>
                </div>
                <div className="w-[29.25rem] flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1.812rem] box-border gap-[1.875rem] max-w-full">
                  <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.312rem]">
                    <div className="flex flex-col items-start justify-start gap-[0.437rem]">
                      <img
                        className="w-[9.438rem] h-[9.438rem] relative object-cover"
                        alt=""
                        src="/travelv306-1@2x.png"
                      />
                      <div className="w-[7.438rem] flex flex-row items-start justify-start py-[0rem] px-[1.375rem] box-border">
                        <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600" />
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                    <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] box-border max-w-full">
                      <h3 className="relative leading-[1.688rem] font-semibold mq450:text-[1rem] mq450:leading-[1.375rem]">
                        BACKUPS FOR YOUR PEACE OF MIND:
                      </h3>
                    </div>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100">
                      Hostlasting automatically creates backups of your server
                      to keep your data safe. With the ability to store up to
                      seven backups, you can have peace of mind knowing your
                      data is always protected.
                    </div>
                  </div>
                </div>
                <div className="w-[29.5rem] flex flex-row items-start justify-start pt-[0rem] px-[0.125rem] pb-[1.312rem] box-border max-w-full">
                  <div className="flex-1 flex flex-col items-start justify-start gap-[2.062rem] max-w-full mq750:gap-[1rem]">
                    <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
                      <div className="h-[9.625rem] w-[9.625rem] relative bg-[url('/public/fp1222-databasea-2@2x.png')] bg-cover bg-no-repeat bg-[top]">
                        <img
                          className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                          alt=""
                          src="/fp1222-databasea-2@2x.png"
                        />
                        <div className="absolute top-[9.25rem] left-[2.313rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                      <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] box-border max-w-full">
                        <h3 className="relative leading-[1.688rem] font-semibold mq450:text-[1rem] mq450:leading-[1.375rem]">
                          IMAGES WITH CURRENT RELEASE:
                        </h3>
                      </div>
                      <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100">
                        Hostlasting offers a wide variety of operating systems
                        to choose from, including Ubuntu, Debian, Fedora, and
                        more. Our cloud servers always feature the most current
                        release for optimal performance and flexibility.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[29.25rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[0.75rem] box-border max-w-full">
                  <div className="h-[10.5rem] w-[10.5rem] relative bg-[url('/public/cd7fa9812188440c910f6078db66773e-1@2x.png')] bg-cover bg-no-repeat bg-[top]">
                    <img
                      className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                      alt=""
                      src="/cd7fa9812188440c910f6078db66773e-1@2x.png"
                    />
                    <div className="absolute top-[10.125rem] left-[2.875rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                  </div>
                </div>
                <div className="w-[29.5rem] flex flex-row items-start justify-start pt-[0rem] px-[0.125rem] pb-[1.812rem] box-border max-w-full">
                  <div className="flex-1 flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                    <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] box-border max-w-full">
                      <h3 className="relative leading-[1.688rem] font-semibold mq450:text-[1rem] mq450:leading-[1.375rem]">
                        APP OFFERINGS FOR EASY ACCESS:
                      </h3>
                    </div>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100">
                      Our app offerings include Docker, WordPress, Nextcloud,
                      and many more, providing you with easy access to
                      frequently used software. While creating your server, you
                      can simply select the desired app and get started
                      immediately with preinstalled software.
                    </div>
                  </div>
                </div>
                <div className="w-[28.188rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[1.937rem] box-border max-w-full">
                  <div className="flex flex-col items-end justify-start">
                    <img
                      className="w-[10.938rem] h-[10.938rem] relative object-cover"
                      alt=""
                      src="/location-1@2x.png"
                    />
                    <div className="w-[9.938rem] flex flex-row items-start justify-end py-[0rem] px-[2.625rem] box-border">
                      <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                    </div>
                  </div>
                </div>
                <div className="w-[29.5rem] flex flex-row items-start justify-start pt-[0rem] px-[0.125rem] pb-[2.187rem] box-border max-w-full">
                  <div className="flex-1 flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                    <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] box-border max-w-full">
                      <h3 className="relative leading-[1.688rem] font-semibold mq450:text-[1rem] mq450:leading-[1.375rem]">
                        DDoS PROTECTION AT FREE OF COST:
                      </h3>
                    </div>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100">
                      Hostlasting Online provides advanced security technologies
                      and implements the latest hardware equipment to protect
                      your cloud servers from large-scale DDoS attacks. You can
                      avail this protection free of cost.
                    </div>
                  </div>
                </div>
                <div className="w-[29.5rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                  <div className="h-[11.875rem] w-[11.875rem] relative bg-[url('/public/ebe24492895e499ab1d02eeb589abda4a-3@2x.png')] bg-cover bg-no-repeat bg-[top]">
                    <img
                      className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                      alt=""
                      src="/ebe24492895e499ab1d02eeb589abda4a-3@2x.png"
                    />
                    <div className="absolute top-[11.5rem] left-[3.438rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[29.563rem] flex flex-col items-start justify-start gap-[2.375rem] min-w-[29.563rem] max-w-full mq750:gap-[1.188rem] mq750:min-w-full mq1100:flex-1">
              <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.812rem] box-border gap-[1.125rem] max-w-full">
                <h3 className="relative leading-[1.688rem] font-semibold inline-block max-w-full mq450:text-[1rem] mq450:leading-[1.375rem]">
                  LOAD BALANCER TO HANDLE IT ALL :
                </h3>
                <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100">
                  Quickly scale your applications with load balancers that
                  automatically distribute traffic across your infrastructure.
                  It has the capability to manage common use cases such as TLS
                  termination. Furthermore, there is a scope for the development
                  of an internet-facing entry point for routing the total
                  traffic through the Hostlasting’s cloud networks.
                </div>
              </div>
              <div className="self-stretch flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[0.875rem] box-border gap-[1.375rem] max-w-full">
                <div className="w-[22.188rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                  <img
                    className="h-[8.188rem] w-[8.188rem] relative object-cover"
                    alt=""
                    src="/48db2003e1fb477aaef6f9ab9c940e9ca-1@2x.png"
                  />
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                  <div className="w-[21.563rem] flex flex-col items-start justify-start gap-[1.375rem] max-w-full">
                    <div className="self-stretch flex flex-row items-start justify-end py-[0rem] px-[0.875rem]">
                      <div className="h-[0.375rem] w-[4.688rem] relative [filter:blur(4px)] rounded-[50%] bg-gray-1600" />
                    </div>
                    <h3 className="relative leading-[1.688rem] font-semibold mq450:text-[1rem] mq450:leading-[1.375rem]">
                      NETWORKS WITH PRIVATE ACCESS:
                    </h3>
                  </div>
                  <div className="self-stretch h-[7.125rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0">
                    Communicate with your servers through a private network and
                    set up the complex network topologies for Kubernetes
                    clusters or secure database servers. This is ideal for
                    projects that should not be accessible to the public.
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[8rem] pr-[1.25rem] mq450:pl-[1.25rem] mq450:box-border">
                <div className="h-[11.813rem] w-[11.813rem] relative bg-[url('/public/ea517ef49c60437aa33ddefedb941680-1@2x.png')] bg-cover bg-no-repeat bg-[top]">
                  <img
                    className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                    alt=""
                    src="/ea517ef49c60437aa33ddefedb941680-1@2x.png"
                  />
                  <div className="absolute top-[11.438rem] left-[3.75rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.875rem] gap-[1.125rem]">
                <h3 className="relative leading-[1.688rem] font-semibold mq450:text-[1rem] mq450:leading-[1.375rem]">
                  HIGH STORAGE CAPACITIES:
                </h3>
                <div className="self-stretch h-[6.125rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0">
                  Get reliable and highly-available SSD storage capability for
                  your cloud servers. You have the option to expand the storage
                  capacity of each server up to 10TB. You can connect them to
                  the Hostlasting’s cloud servers.
                </div>
              </div>
              <div className="self-stretch flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[1.312rem] box-border gap-[1.312rem] max-w-full">
                <div className="w-[22.188rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[0.75rem] box-border max-w-full">
                  <div className="w-[11.813rem] flex flex-col items-end justify-start">
                    <img
                      className="w-[11.813rem] h-[11.813rem] relative object-cover"
                      alt=""
                      src="/iconbusinessv3-1--2@2x.png"
                    />
                    <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[3.75rem] pr-[3.375rem]">
                      <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem]">
                  <h3 className="relative leading-[1.688rem] font-semibold mq450:text-[1rem] mq450:leading-[1.375rem]">
                    SNAPSHOTS FEATURE:
                  </h3>
                  <div className="self-stretch h-[6.125rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0">
                    With Hostlasting's Snapshot feature, you can create manual
                    backups of your servers, restore servers to the saved
                    images, create new cloud servers from saved images, or
                    transfer images during a project. .
                  </div>
                </div>
                <div className="w-[22.125rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                  <div className="w-[11.125rem] flex flex-col items-end justify-start">
                    <img
                      className="w-[11.125rem] h-[11.125rem] relative object-cover"
                      alt=""
                      src="/86e2e2fcf66c41a4a2f792b940aabe0ca-5@2x.png"
                    />
                    <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[3.375rem] pr-[3.062rem]">
                      <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[1.062rem] pl-[0.312rem] pr-[0rem] box-border max-w-full">
                <div className="flex-1 flex flex-col items-end justify-start gap-[2.562rem] max-w-full mq750:gap-[1.25rem]">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                    <h3 className="relative leading-[1.688rem] font-semibold inline-block max-w-full mq450:text-[1rem] mq450:leading-[1.375rem]">
                      FLOATING IPs FOR FULFILLING REQUIREMENTS:
                    </h3>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100">
                      Customize your cloud servers with floating IPs as per your
                      specific requirements. You can use them on a redundant
                      server. Else, they can be used for server clusters.
                    </div>
                  </div>
                  <div className="w-[22.813rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                    <div className="w-[9.563rem] flex flex-col items-end justify-start gap-[1.312rem]">
                      <img
                        className="w-[9.563rem] h-[9.563rem] relative object-cover"
                        alt=""
                        src="/web-designv2-7-1@2x.png"
                      />
                      <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[2.625rem] pr-[2.25rem]">
                        <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600" />
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                    <h3 className="relative leading-[1.688rem] font-semibold inline-block max-w-full mq450:text-[1rem] mq450:leading-[1.375rem]">
                      LARGE BANDWIDTH TO HANDLE TRAFFIC:
                    </h3>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100">
                      You can avail a large bandwidth for your projects, with 20
                      TB of included traffic. No matter which Hostlasting cloud
                      package you choose, if there is a need for more bandwidth,
                      you can add it for only €1.19 per TB per month.
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[1.625rem] pl-[0.312rem] pr-[0rem] box-border max-w-full">
                <div className="flex-1 flex flex-col items-end justify-start gap-[2.5rem] max-w-full mq750:gap-[1.25rem]">
                  <div className="w-[23.438rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                    <div className="flex flex-col items-end justify-start">
                      <img
                        className="w-[10.938rem] h-[10.938rem] relative object-cover"
                        alt=""
                        src="/2-weba-4@2x.png"
                      />
                      <div className="w-[9.938rem] flex flex-row items-start justify-end py-[0rem] px-[2.625rem] box-border">
                        <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                    <h3 className="relative leading-[1.688rem] font-semibold inline-block max-w-full mq450:text-[1rem] mq450:leading-[1.375rem]">
                      IDEAL LOCATIONS FOR GREAT CONNECTION:
                    </h3>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100">
                      Our cloud instances are hosted in our state-of-the-art
                      data centers located in Nuremberg and Falkenstein
                      (Germany), Helsinki (Finland), Ashburn, Virginia, and
                      Hillsboro in Oregon (the U.S.). We provide you with the
                      latest AMD-based cloud servers and features. All data
                      centers are fully compliant with the strict European
                      General Data Protection Regulation (GDPR).
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[1.625rem] pl-[6.812rem] pr-[1.25rem] mq450:pl-[1.25rem] mq450:box-border">
                <div className="flex flex-col items-end justify-start gap-[1.312rem]">
                  <img
                    className="w-[8.375rem] h-[8.375rem] relative object-cover"
                    alt=""
                    src="/safety-1@2x.png"
                  />
                  <div className="w-[6.813rem] flex flex-row items-start justify-end py-[0rem] px-[1.062rem] box-border">
                    <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600" />
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.312rem] pr-[0rem] box-border max-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                  <h3 className="relative leading-[1.688rem] font-semibold inline-block max-w-full mq450:text-[1rem] mq450:leading-[1.375rem]">
                    DATA PROTECTION WITH GDPR COMPLIANCE:
                  </h3>
                  <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100">
                    We ensure GDPR compliance for our SSD cloud VPS hosting
                    services. You can easily create a Data Processing Agreement
                    (DPA) online that meets the requirements of Article 28 of
                    the GDPR, including choosing the appropriate European
                    location for your DPA.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent13;

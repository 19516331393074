import { FunctionComponent, memo } from "react";

export type ImportantFeatureOnDedicatedServerType = {
  className?: string;
};

const ImportantFeatureOnDedicatedServer: FunctionComponent<ImportantFeatureOnDedicatedServerType> =
  memo(({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start relative max-w-full text-left text-[1.25rem] text-indigo-100 font-poppins ${className}`}
      >
        <div className="h-[48.188rem] w-[39.563rem] absolute !m-[0] bottom-[-42.187rem] left-[0rem]">
          <img
            className="absolute h-full top-[0rem] bottom-[0rem] left-[-8.625rem] rounded-31xl max-h-full w-[48.188rem] object-cover"
            alt=""
            src="/letter-a-backgroundconverted-1@2x.png"
          />
          <img
            className="absolute top-[29.25rem] left-[11.438rem] w-[1.5rem] h-[1.5rem] overflow-hidden z-[1]"
            loading="lazy"
            alt=""
            src="/jammessagealt.svg"
          />
        </div>
        <div className="flex-1 flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[9.75rem] box-border max-w-full mq450:pb-[1.75rem] mq450:box-border mq1150:pb-[2.688rem] mq1150:box-border mq1350:pb-[4.125rem] mq1350:box-border">
          <div className="flex-1 bg-ghostwhite-100 flex flex-col items-center justify-start pt-[3.187rem] px-[1.25rem] pb-[5.562rem] box-border gap-[9.937rem] max-w-full mq450:gap-[1.25rem] mq450:pb-[1.25rem] mq450:box-border mq800:gap-[2.5rem] mq1150:gap-[4.938rem] mq1150:pt-[1.25rem] mq1150:pb-[1.563rem] mq1150:box-border mq1350:pt-[1.313rem] mq1350:pb-[2.375rem] mq1350:box-border">
            <div className="w-[89.438rem] h-[205.375rem] relative bg-ghostwhite-100 hidden max-w-full" />
            <div className="w-[66.563rem] flex flex-row flex-wrap items-start justify-start gap-[7.937rem] max-w-full mq450:gap-[1rem] mq800:gap-[2rem] mq1150:gap-[3.938rem]">
              <div className="flex-1 flex flex-col items-start justify-start gap-[3.062rem] min-w-[19.063rem] max-w-full mq800:gap-[1.5rem]">
                <div className="w-[26.125rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[2.437rem] box-border max-w-full">
                  <div className="w-[11.75rem] flex flex-col items-start justify-start gap-[0.562rem]">
                    <img
                      className="w-[11.75rem] h-[11.75rem] relative object-cover z-[1]"
                      loading="lazy"
                      alt=""
                      src="/1f040ac6a0f147c5b64276671e902797a-3@2x.png"
                    />
                    <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.5rem] pr-[3.562rem]">
                      <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[2.625rem] gap-[0.5rem]">
                  <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                    <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Multiple Data Center Solutions
                    </h3>
                  </div>
                  <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                    We provide an unlimited Linux based dedicated Tier III and
                    Tier IV servers at different location at affordable prices.
                    You can purchase secured cloud server infrastructure and
                    avail separate resources for power and cooling.
                  </div>
                </div>
                <div className="w-[26.063rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[2.75rem] box-border max-w-full">
                  <div className="w-[11.563rem] flex flex-col items-start justify-start gap-[0.562rem]">
                    <img
                      className="w-[11.563rem] h-[11.563rem] relative object-cover z-[1]"
                      loading="lazy"
                      alt=""
                      src="/save-security-server-1@2x.png"
                    />
                    <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[3.437rem]">
                      <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                  <div className="relative leading-[1.688rem] font-semibold inline-block max-w-full z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                    <h3 className="m-0">{`Features of High Performance and `}</h3>
                    <h3 className="m-0">Dedicated server in India</h3>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.125rem] pr-[0rem] box-border max-w-full text-[0.938rem] text-slategray-100">
                    <div className="flex-1 relative tracking-[0.04em] leading-[1.25rem] inline-block max-w-full z-[1]">
                      Cost-efficient Linux Dedicated server in India plans
                      provide a leading Tier III DC, 24/7 earlier committed
                      hosting service (anytime and anywhere), 99.9% uptime
                      policy, powerful security and redundancy, and
                      multi-software service providers.
                    </div>
                  </div>
                </div>
                <div className="w-[26.125rem] h-[14.5rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[2.687rem] box-border max-w-full">
                  <div className="self-stretch w-[12.625rem] flex flex-col items-start justify-start gap-[1.312rem]">
                    <img
                      className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover z-[1]"
                      loading="lazy"
                      alt=""
                      src="/web-dev-v2-10-08-22-2@2x.png"
                    />
                    <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.937rem] pr-[4rem]">
                      <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[4.75rem] box-border gap-[1.125rem] max-w-full">
                  <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] box-border max-w-full">
                    <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Specifications for High-End Server
                    </h3>
                  </div>
                  <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                    If you require high-end servers, we are ready to provide
                    them along with the replacement warranty for hardware
                    equipment.
                  </div>
                </div>
                <div className="w-[26.375rem] h-[13.813rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[3.875rem] box-border max-w-full">
                  <div className="self-stretch w-[12.375rem] flex flex-col items-start justify-start gap-[1.312rem]">
                    <img
                      className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover z-[1]"
                      loading="lazy"
                      alt=""
                      src="/onlinedatastoragebusinessnetworkconceptuploadingdocumentfiledatainformationcloudcomputingtechnologywithcomputerserverracks3drendering-1-11@2x.png"
                    />
                    <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.687rem] pr-[4rem]">
                      <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[4.375rem] gap-[1.125rem]">
                  <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                    <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Rapid and Quick Setup
                    </h3>
                  </div>
                  <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                    Once you order the dedicated server in India, you will gain
                    the approval for your purchase in minutes and the dedicated
                    server will be in your possession in a couple of days.
                  </div>
                </div>
                <div className="w-[26.125rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                  <div className="w-[11.5rem] flex flex-col items-start justify-start">
                    <img
                      className="w-[11.5rem] h-[11.5rem] relative object-cover z-[1]"
                      loading="lazy"
                      alt=""
                      src="/security-tokena-2@2x.png"
                    />
                    <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.375rem] pr-[3.437rem]">
                      <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[2]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start pt-[0.687rem] px-[0rem] pb-[0rem] box-border min-w-[19rem] max-w-full">
                <div className="self-stretch flex flex-col items-start justify-start gap-[4.437rem] max-w-full mq800:gap-[2.188rem]">
                  <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[3.5rem] gap-[1.125rem]">
                    <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Scalable and Secure Platform
                    </h3>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                      Room for expansion together with our cheapest dedicated
                      server and highly elastic infrastructure. Now concentrate
                      on client excellency and our specialized staff to manage
                      the infrastructure in addition to scalability, which
                      allows constructing premium low cost/price support for
                      your industry.
                    </div>
                  </div>
                  <div className="w-[18.875rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[1.125rem] box-border">
                    <div className="w-[6.875rem] flex flex-col items-start justify-start gap-[1.75rem]">
                      <img
                        className="w-[6.875rem] h-[6.875rem] relative object-cover z-[1]"
                        loading="lazy"
                        alt=""
                        src="/cloud-computing-server-3d-illustrationa-21@2x.png"
                      />
                      <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.062rem] pr-[1.125rem]">
                        <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[5.437rem] gap-[1.125rem]">
                    <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      High-Level Security for Servers
                    </h3>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                      We manage the security of servers through application of
                      strict security Protocol including smtp for protecting the
                      servers from different viruses, malware attacks, and
                      unauthorized invaders. We also deploy a data redundancy
                      technology for ensuring data security.
                    </div>
                  </div>
                  <div className="w-[18.813rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border">
                    <div className="w-[7.063rem] flex flex-col items-start justify-start gap-[1.875rem]">
                      <img
                        className="w-[7.063rem] h-[7.063rem] relative object-cover z-[1]"
                        loading="lazy"
                        alt=""
                        src="/web-designv2-10a-5@2x.png"
                      />
                      <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[1.187rem]">
                        <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[3.187rem] gap-[1.125rem]">
                    <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Very Useful Service Add-ons
                    </h3>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                      We offer one dedicated IP address along with our services.
                      You can also update your server with cPanel, Plesk, MySQL,
                      and added IP address add-ons.
                    </div>
                  </div>
                  <div className="w-[18.813rem] flex flex-row items-start justify-start pt-[0rem] px-[4.562rem] pb-[1.125rem] box-border mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                    <div className="flex-1 flex flex-col items-start justify-start gap-[1.187rem]">
                      <img
                        className="w-[9.688rem] h-[9.688rem] relative object-cover z-[1]"
                        loading="lazy"
                        alt=""
                        src="/web-designv1-9a-3@2x.png"
                      />
                      <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[2.5rem]">
                        <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[4.187rem] gap-[1.125rem]">
                    <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Among the Top 10 Servers
                    </h3>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                      We are equipped with Tier III infrastructure in our data
                      centers in India. Our facilities contain
                      precision-controlled air systems that offer integrated
                      redundancy.
                    </div>
                  </div>
                  <div className="flex flex-row items-start justify-start pt-[0rem] px-[4.812rem] pb-[2.5rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                    <div className="flex flex-col items-end justify-start gap-[1.187rem]">
                      <img
                        className="w-[9.438rem] h-[9.438rem] relative object-cover z-[1]"
                        loading="lazy"
                        alt=""
                        src="/fp0124-rocketa-3@2x.png"
                      />
                      <div className="w-[5.063rem] flex flex-row items-start justify-end py-[0rem] px-[0.187rem] box-border">
                        <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
                    <h3 className="relative leading-[1.688rem] font-semibold inline-block max-w-full z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Techniques for Failure Prevention
                    </h3>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                      All of our top 10 servers in India have been setup on the
                      fail-safe processes and we have carried out the platinum
                      vendor management hardware for prevention from the
                      failure.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[66.438rem] flex flex-col items-start justify-start gap-[0.937rem] max-w-full">
              <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Ease in Management Service
                </h3>
              </div>
              <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[7.937rem] max-w-full text-[0.938rem] text-slategray-100 mq450:gap-[1rem] mq800:gap-[2rem] mq1150:gap-[3.938rem]">
                <div className="flex-1 flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem] box-border min-w-[19rem] max-w-full">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[5.625rem] max-w-full mq800:gap-[2.813rem]">
                    <div className="self-stretch relative tracking-[0.04em] leading-[1.25rem] z-[1]">
                      <p className="m-0">
                        The dedicated server in India provides top 10 robust
                        Services including high-security cloud surroundings,
                        entire root access resources, continuous uptime,
                        powerful support, excellent web development software,
                        high backup solution, and others.
                      </p>
                      <p className="m-0">
                        The uninterruptable service plans are aimed at
                        constructing a company environment and employing a
                        scalable design.
                      </p>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[1rem] pl-[1.25rem] pr-[1.625rem]">
                      <div className="w-[8.5rem] flex flex-col items-end justify-start gap-[0.562rem]">
                        <img
                          className="w-[8.5rem] h-[8.5rem] relative object-cover z-[1]"
                          loading="lazy"
                          alt=""
                          src="/b2c7251d59c74cb0ae42601e6885f55da-2@2x.png"
                        />
                        <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[1.937rem] pr-[1.875rem]">
                          <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem] max-w-full text-[1.25rem] text-indigo-100">
                      <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] box-border max-w-full">
                        <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                          24x Faster Dedicated server Hosting India
                        </h3>
                      </div>
                      <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                        You will get here sensible and funding oriented web
                        servers. Now construct your website on servers that are
                        dedicated. Deal with zero Downtime policy and fatal-free
                        environments. Build a company on quicker lightning rate
                        server.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[4.937rem] min-w-[19rem] max-w-full text-[1.25rem] text-indigo-100 mq800:gap-[2.438rem]">
                  <div className="w-[19.188rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[1.187rem] box-border">
                    <div className="flex flex-col items-end justify-start gap-[1.187rem]">
                      <img
                        className="w-[7.688rem] h-[7.688rem] relative object-cover z-[1]"
                        loading="lazy"
                        alt=""
                        src="/11028375137d4410b75bbcabe44afcd5-11@2x.png"
                      />
                      <div className="w-[4.938rem] flex flex-row items-start justify-end py-[0rem] px-[0.125rem] box-border">
                        <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem]">
                    <h3 className="relative leading-[1.688rem] font-semibold z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      High-Rise Infrastructure
                    </h3>
                    <div className="self-stretch h-[6.813rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0 z-[1]">
                      Purchase server with 99.9% uptime guarantee and also
                      exceptional customer care with dedicated servers in India.
                      Our dedicated service team will stand beneath the sun to
                      address your issues at anytime. The staff is exceptionally
                      trained and professional.
                    </div>
                  </div>
                  <div className="w-[19.125rem] flex flex-row items-start justify-start py-[0rem] px-[2.937rem] box-border mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                    <div className="h-[13.25rem] flex-1 relative bg-[url('/public/b2a-2@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
                      <img
                        className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                        alt=""
                        src="/b2a-2@2x.png"
                      />
                      <div className="absolute top-[12.813rem] left-[4.313rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  });

export default ImportantFeatureOnDedicatedServer;

import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type ReviewOneType = {
  className?: string;
  reviews?: string;
  greatHostingServiceEasyTo?: string;

  /** Style props */
  propPadding?: CSSProperties["padding"];
  propGap?: CSSProperties["gap"];
};

const ReviewOne: FunctionComponent<ReviewOneType> = memo(
  ({
    className = "",
    propPadding,
    propGap,
    reviews,
    greatHostingServiceEasyTo,
  }) => {
    const reviewOneStyle: CSSProperties = useMemo(() => {
      return {
        padding: propPadding,
        gap: propGap,
      };
    }, [propPadding, propGap]);

    return (
      <div
        className={`flex-1 rounded-3xs bg-ghostwhite-200 flex flex-col items-start justify-start pt-[2rem] pb-[3.062rem] pl-[0.812rem] pr-[0.75rem] box-border gap-[0.625rem] min-w-[12rem] text-center text-[0.75rem] text-darkslategray-100 font-dm-sans ${className}`}
        style={reviewOneStyle}
      >
        <div className="w-[15.938rem] h-[13.063rem] relative rounded-3xs bg-ghostwhite-200 hidden" />
        <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
          <img
            className="h-[3.125rem] w-[3.125rem] relative rounded-[50%] object-cover z-[1]"
            loading="lazy"
            alt=""
            src={reviews}
          />
        </div>
        <blockquote className="m-0 self-stretch relative leading-[1.063rem] z-[1]">
          {greatHostingServiceEasyTo}
        </blockquote>
      </div>
    );
  }
);

export default ReviewOne;

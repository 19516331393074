import { FunctionComponent, memo } from "react";

export type FooterType = {
  className?: string;
};

const Footer: FunctionComponent<FooterType> = memo(({ className = "" }) => {
  return (
    <section
      className={`absolute top-[648.188rem] left-[0rem] w-full max-w-full text-left text-[0.938rem] text-white font-poppins mq725:gap-[1.375rem] ${className}`}
    >
      <div className="w-full absolute top-[0rem] left-[0rem] bg-indigo-100 h-[43.5rem]" />
      <img
        className="absolute top-[5.75rem] left-[11.375rem] w-[9.938rem] h-[2.438rem] object-contain"
        loading="lazy"
        alt=""
        src="/hostlasting-logo-1@2x.png"
      />
      <div className="absolute top-[12.188rem] left-[22.375rem] text-[1.25rem] leading-[2.875rem] font-semibold inline-block min-w-[5.625rem] mq450:text-[1rem] mq450:leading-[2.313rem]">
        HOSTING
      </div>
      <a className="[text-decoration:none] absolute top-[15.625rem] left-[22.375rem] leading-[2.875rem] text-whitesmoke-200">
        WordPress Hosting
      </a>
      <div className="absolute top-[15.625rem] left-[41.438rem] leading-[2.875rem]">
        Register Domains
      </div>
      <a className="[text-decoration:none] absolute top-[15.625rem] left-[58.813rem] leading-[2.875rem] text-[inherit] inline-block min-w-[4.188rem]">
        About Us
      </a>
      <a className="[text-decoration:none] absolute top-[18.5rem] left-[58.813rem] leading-[2.875rem] text-[inherit] inline-block min-w-[3.75rem]">
        Support
      </a>
      <a className="[text-decoration:none] absolute top-[21.375rem] left-[58.875rem] leading-[2.875rem] text-[inherit] inline-block min-w-[2.063rem]">
        Blog
      </a>
      <a className="[text-decoration:none] absolute top-[24.25rem] left-[58.875rem] leading-[2.875rem] text-[inherit] inline-block min-w-[5.188rem]">
        Contact Us
      </a>
      <div className="absolute top-[27.125rem] left-[58.875rem] leading-[2.875rem]">
        Terms of Services
      </div>
      <a className="[text-decoration:none] absolute top-[30rem] left-[58.875rem] leading-[2.875rem] text-[inherit] inline-block min-w-[6.375rem]">
        Privacy Policy
      </a>
      <a className="[text-decoration:none] absolute top-[32.875rem] left-[58.875rem] leading-[2.875rem] text-[inherit] inline-block min-w-[3.938rem]">
        Sitemap
      </a>
      <div className="absolute top-[18.5rem] left-[41.5rem] leading-[2.875rem]">
        Transfer Domains
      </div>
      <div className="absolute top-[21.375rem] left-[41.5rem] leading-[2.875rem]">
        Manage Domains
      </div>
      <div className="absolute top-[24.25rem] left-[41.438rem] leading-[2.875rem] inline-block min-w-[7.188rem]">
        SSL Certificates
      </div>
      <a className="[text-decoration:none] absolute top-[18.5rem] left-[22.375rem] leading-[2.875rem] text-whitesmoke-200">
        Cheap SSD Hosting
      </a>
      <a className="[text-decoration:none] absolute top-[21.375rem] left-[22.375rem] leading-[2.875rem] text-whitesmoke-200 inline-block min-w-[5.563rem]">
        VPS Hosting
      </a>
      <a className="[text-decoration:none] absolute top-[24.25rem] left-[22.375rem] leading-[2.875rem] text-whitesmoke-200">
        Dedicated Hosting
      </a>
      <div className="absolute top-[27.125rem] left-[22.375rem] leading-[2.875rem] text-whitesmoke-200 inline-block min-w-[7.438rem]">
        Reseller Hosting
      </div>
      <div className="absolute top-[30rem] left-[22.438rem] leading-[2.875rem] text-whitesmoke-200">
        LiteSpedd Hosting
      </div>
      <a className="[text-decoration:none] absolute top-[32.875rem] left-[22.375rem] leading-[2.875rem] text-whitesmoke-200 inline-block min-w-[2.813rem]">
        Offers
      </a>
      <div className="absolute top-[12.188rem] left-[41.438rem] text-[1.25rem] leading-[2.875rem] font-semibold mq450:text-[1rem] mq450:leading-[2.313rem]">{`DOMAINS & SSL`}</div>
      <div className="absolute top-[12.188rem] left-[58.813rem] text-[1.25rem] leading-[2.875rem] font-semibold inline-block min-w-[6.438rem] mq450:text-[1rem] mq450:leading-[2.313rem]">
        COMPANY
      </div>
      <img
        className="absolute top-[38.5rem] left-[73.188rem] w-[1.5rem] h-[1.5rem] min-h-[1.5rem]"
        loading="lazy"
        alt=""
        src="/akariconsxfill.svg"
      />
      <img
        className="absolute top-[38.5rem] left-[76.5rem] w-[1.5rem] h-[1.5rem] min-h-[1.5rem]"
        loading="lazy"
        alt=""
        src="/iconoirinstagram.svg"
      />
      <img
        className="absolute top-[38.5rem] left-[69.875rem] w-[1.5rem] h-[1.5rem] min-h-[1.5rem]"
        loading="lazy"
        alt=""
        src="/rifacebookfill.svg"
      />
      <div className="h-full absolute top-[41.938rem] left-[0rem] bg-white w-[89.438rem] max-w-full" />
      <div className="absolute top-[41.938rem] left-[calc(50%_-_242.5px)] text-[0.75rem] leading-[1.563rem] font-medium text-black flex items-center w-[30.313rem] max-w-full">{`Copyright © Hostlasting.com , Designed & Developed by Cloud19 Technologies`}</div>
    </section>
  );
});

export default Footer;

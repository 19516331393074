import { FunctionComponent, memo } from "react";

export type SSLDetailsListOptionsType = {
  className?: string;
};

const SSLDetailsListOptions: FunctionComponent<SSLDetailsListOptionsType> =
  memo(({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[0.375rem] box-border max-w-full text-center text-[0.813rem] text-slategray-100 font-poppins ${className}`}
      >
        <div className="w-[76.188rem] flex flex-col items-start justify-start gap-[4.75rem] max-w-full mq750:gap-[2.375rem] mq450:gap-[1.188rem]">
          <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[4.812rem] box-border max-w-full lg:pl-[2.375rem] lg:pr-[2.375rem] lg:box-border">
            <div className="flex-1 relative leading-[1.25rem] font-medium inline-block max-w-full">
              <p className="m-0">
                It is possible to secure your website through 256-bit encryption
                by taking the help of Geotrust QuickSSL, RapidSSL, or Verisign
                SSL. SSL is essential to maintain the integrity and safety level
                of the website. Therefore, by providing certification measures,
                you can maintain the security and system conduct. It is quite
                essential to carry out e-commerce transactions in a safe and
                secure environment over the internet. Furthermore, it is
                necessary to secure information of the customer. The safety and
                security of the transaction can be maintained through efficient
                operation of the organization's resources and therefore ensuring
                the proper utilization of resources.
              </p>
              <p className="m-0">
                An SSL Certificate is an important constituent of our working
                operations as it helps in the creation of an encrypted link
                between the functioning server and our website. This ensures
                that there is further cognizance of actions and therefore the
                consumer's details will be secures and thus the safety of the
                user can be maintained.
              </p>
              <p className="m-0">
                The experts in the field ensure that the consumers must do
                business with a firm that is engaged in online transactions and
                e-commerce business where there should be the presence of SSL
                Certificates. Since, the monetary transactions are exchanged
                over the internet in the online business environment, the
                payment gateway should always be secure, ensuring sound and safe
                transactions and make the consumers feel very stable and secure.
              </p>
            </div>
          </div>
          <div className="self-stretch flex flex-row flex-wrap items-end justify-center gap-[6.5rem] max-w-full text-left text-[1.5rem] text-darkslategray-100 mq750:gap-[3.25rem] mq450:gap-[1.625rem]">
            <div className="flex-1 flex flex-row items-start justify-center min-w-[42.188rem] max-w-full [row-gap:20px] mq750:min-w-full mq1050:flex-wrap">
              <div className="h-[4.25rem] w-[4.813rem] flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem] box-border">
                <img
                  className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                  loading="lazy"
                  alt=""
                  src="/11684121-4391-4@2x.png"
                />
              </div>
              <div className="flex-1 flex flex-col items-end justify-start gap-[2.75rem] min-w-[39.063rem] max-w-full mq750:gap-[1.375rem] mq750:min-w-full">
                <div className="w-[53.563rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[1.25rem] box-border max-w-full text-center text-[0.813rem] text-white">
                  <div className="rounded-11xl bg-indigo-100 flex flex-row items-start justify-start py-[1rem] pl-[3.625rem] pr-[3.562rem] box-border whitespace-nowrap max-w-full mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                    <div className="h-[3.25rem] w-[21.563rem] relative rounded-11xl bg-indigo-100 hidden max-w-full" />
                    <div className="relative tracking-[0.03em] leading-[1.25rem] font-medium z-[1]">{`Explore Our Affordable Plans Now `}</div>
                  </div>
                </div>
                <div className="w-[53.5rem] h-[0.063rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                  <div className="self-stretch w-[39.063rem] relative border-indigo-100 border-t-[1px] border-solid box-border max-w-full" />
                </div>
                <div className="self-stretch flex flex-col items-start justify-start max-w-full">
                  <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit inline-block max-w-full mq450:text-[1.188rem] mq450:leading-[2.313rem]">
                    The reason behind the use of SSL
                  </h2>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[0.562rem] text-[0.938rem] text-slategray-100">
                    <div className="self-stretch relative leading-[1.25rem] z-[1]">
                      <p className="m-0">
                        SSL Certificates are kept to ensure that safety and
                        communication measures are integrated and therefore the
                        consumers can take advantage of integrated solutions.
                        SSL Certificates also play a very important role in the
                        potent function of payment, thereby ensuring that the
                        payment structure is cleared off instantly without any
                        hassles.
                      </p>
                      <p className="m-0">
                        SSL Certificates also play an important role in
                        maintaining the software integrity of the business
                        functioning module, ensuring that the business
                        environment is kept safe and secure and the consumers
                        feel confident about their business dealings with the
                        firm whenever money is exchanged from one source to
                        another. You can buy cheap SSL Certificates with our
                        help.
                      </p>
                    </div>
                    <div className="self-stretch relative leading-[1.25rem]">
                      The experts in the field ensure that the consumers must do
                      business with a firm that is engaged in online
                      transactions and e-commerce business where there should be
                      the presence of SSL Certificates. Since, the monetary
                      transactions are exchanged over the internet in the online
                      business environment, the payment gateway should always be
                      secure, ensuring sound and safe transactions and make the
                      consumers feel very stable and secure.
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start">
                  <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
                    Prominent Features of SSL
                  </h2>
                  <div className="self-stretch relative text-[0.938rem] leading-[1.25rem] text-slategray-100 z-[1]">
                    <p className="m-0">
                      SSL certificates specific to your business needs help in
                      maintaining the payment environment and keep the customer
                      data private and secure along with preventing all kinds of
                      malware and software attacks.
                    </p>
                    <p className="m-0">
                      Secures online transactions create a safe payment gateway.
                      Through this, you can adequately ensure that your
                      reputation and trustworthiness of your website visitors
                      are intact and they feel confident in striking business
                      deals with you. You do not need to spend a lot of money
                      for obtaining such certificates. Cheap SSL Certificates
                      are available as per your needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[11.75rem] w-[4.813rem] flex flex-col items-start justify-start">
              <img
                className="self-stretch h-[4.063rem] relative max-w-full overflow-hidden shrink-0 object-cover"
                loading="lazy"
                alt=""
                src="/11684121-4391-4@2x.png"
              />
            </div>
          </div>
        </div>
      </section>
    );
  });

export default SSLDetailsListOptions;

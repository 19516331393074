import { FunctionComponent, memo } from "react";

export type FrameComponent31Type = {
  className?: string;
};

const FrameComponent31: FunctionComponent<FrameComponent31Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`w-[84.75rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[5.187rem] box-border max-w-full text-left text-[1.25rem] text-indigo-100 font-poppins mq750:pb-[3.375rem] mq750:box-border ${className}`}
      >
        <div className="w-[61.875rem] flex flex-col items-start justify-start gap-[1.25rem] max-w-full">
          <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
            <h3 className="relative leading-[2.875rem] font-semibold mq450:text-[1rem] mq450:leading-[2.313rem]">
              THE United States:
            </h3>
          </div>
          <div className="self-stretch flex flex-row items-end justify-between max-w-full gap-[1.25rem] text-[0.875rem] text-slategray-100 mq1100:flex-wrap">
            <div className="w-[33.875rem] flex flex-col items-start justify-start gap-[1.406rem] min-w-[33.875rem] max-w-full mq750:min-w-full mq1100:flex-1">
              <div className="self-stretch relative leading-[1.25rem]">
                Hostlasting’s cloud products in Virginia, Ashburn, and Hillsboro
                operate on servers located in the data center parks of third
                parties throughout the U.S. It takes only 45 minutes to reach
                Ashburn by car from the U.S. capital Washington, D.C. However,
                it takes only 30 minutes to reach Hillsboro by a car from
                Portland, which is the largest city in Oregon.
              </div>
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.125rem] pr-[0rem] box-border max-w-full">
                <div className="flex-1 relative leading-[1.25rem] inline-block max-w-full">
                  We give the topmost priority to data security. This is why we
                  have taken all the necessary measures to protect the servers
                  in the data centers in Hillsboro and Ashburn. We offer
                  AMD-based cloud servers and features for our SSD cloud VPS
                  hosting customers at these locations.
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.125rem] pr-[0rem] box-border max-w-full">
                <div className="flex-1 relative leading-[1.25rem] inline-block max-w-full">
                  The server location in Ashburn is in a region, which is also
                  known as “Data Center Alley.” This region is among the topmost
                  traffic region in the North American continent. The server
                  location in Hillsboro is in an area known as “Silicon Forest.”
                  It is well-renowned for providing extraordinary network
                  capabilities. These factors make our location ideal for
                  providing superfast, uninterrupted, and efficient hosting.
                </div>
              </div>
            </div>
            <div className="h-[17.438rem] w-[18.375rem] flex flex-col items-start justify-start min-w-[18.375rem] mq1100:flex-1">
              <div className="self-stretch h-[14.125rem] flex flex-col items-start justify-start gap-[1.062rem]">
                <img
                  className="self-stretch flex-1 relative rounded-31xl max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/flagunitedstatesamerica-1@2x.png"
                />
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[2.5rem]">
                  <div className="h-[0.813rem] flex-1 relative [filter:blur(14.5px)] rounded-[50%] bg-gray-1000" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent31;

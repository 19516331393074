import { FunctionComponent, memo } from "react";
import FrameComponent25 from "./FrameComponent25";

export type ContactForDedicationServerType = {
  className?: string;
};

const ContactForDedicationServer: FunctionComponent<ContactForDedicationServerType> =
  memo(({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[10.312rem] pl-[1.25rem] pr-[1.375rem] box-border max-w-full text-left text-[2.25rem] text-darkslategray-100 font-poppins mq800:pb-[6.688rem] mq800:box-border ${className}`}
      >
        <div className="w-[66.438rem] flex flex-row items-end justify-start gap-[5rem] max-w-full mq800:gap-[1.25rem] mq1150:flex-wrap mq1150:gap-[2.5rem]">
          <div className="flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[9.75rem] box-border min-w-[27.688rem] min-h-[22.125rem] max-w-full mq450:pb-[6.313rem] mq450:box-border mq800:min-w-full mq1150:flex-1">
            <div className="flex flex-col items-start justify-start gap-[1.031rem] max-w-full">
              <h2 className="relative text-[1.25rem] tracking-[0.11em] leading-[1.5rem] font-semibold inline-block min-w-[6.625rem] z-[1] mq450:text-[1rem] mq450:leading-[1.188rem]">
                Talk to a
              </h2>
              <h2 className="m-0 relative text-inherit tracking-[0.11em] leading-[1.5rem] font-semibold font-inherit z-[1] mq450:text-[1.375rem] mq450:leading-[0.875rem] mq800:text-[1.813rem] mq800:leading-[1.188rem]">
                DEDICATED
              </h2>
              <h2 className="m-0 relative text-inherit tracking-[0.11em] leading-[1.5rem] font-semibold font-inherit inline-block max-w-full z-[1] mq450:text-[1.375rem] mq450:leading-[0.875rem] mq800:text-[1.813rem] mq800:leading-[1.188rem]">
                SERVER SPECIALIST
              </h2>
              <div className="relative text-[0.938rem] leading-[1.25rem] text-slategray-100 z-[1]">
                <p className="m-0">{`Submit your information to make a purchase of our secure `}</p>
                <p className="m-0">dedicated services.</p>
              </div>
              <div className="flex flex-row items-start justify-start py-[0rem] px-[2.187rem] text-[0.938rem] text-indigo-100">
                <div className="relative [text-decoration:underline] leading-[1.25rem] font-semibold z-[1]">
                  CONTACT INFORMATION
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 rounded-3xl [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] flex flex-col items-start justify-start py-[2.312rem] px-[3.312rem] box-border gap-[0.5rem] min-w-[21.938rem] max-w-full text-[0.875rem] text-white mq450:min-w-full mq800:py-[1.5rem] mq800:px-[1.625rem] mq800:box-border">
            <div className="w-[33.75rem] h-[34.313rem] relative rounded-3xl [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] hidden max-w-full" />
            <div className="relative leading-[1.5rem] font-medium inline-block min-w-[2.938rem] z-[1]">
              Name:
            </div>
            <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.75rem] box-border max-w-full text-[0.75rem] text-gray-700">
              <div className="flex-1 rounded-8xs bg-white border-slategray-300 border-[1px] border-solid box-border flex flex-row items-start justify-start py-[0.5rem] px-[2.437rem] whitespace-nowrap max-w-full z-[1]">
                <div className="h-[2.75rem] w-[27.125rem] relative rounded-8xs bg-white border-slategray-300 border-[1px] border-solid box-border hidden max-w-full" />
                <div className="relative leading-[1.5rem] font-medium inline-block min-w-[6.25rem] z-[2]">
                  Enter your name
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.75rem] box-border gap-[1.25rem] max-w-full">
              <FrameComponent25
                eMailID="E-mail ID:"
                exExampleemailcom="Ex. example@email.com"
              />
              <FrameComponent25
                eMailID="Message:"
                exExampleemailcom="Type your message"
              />
            </div>
            <div className="self-stretch flex flex-row items-start justify-center text-center text-indigo-100">
              <div className="rounded-11xl bg-white flex flex-row items-start justify-start py-[0.75rem] px-[2.25rem] whitespace-nowrap z-[1]">
                <div className="h-[2.75rem] w-[12.25rem] relative rounded-11xl bg-white hidden" />
                <a className="[text-decoration:none] relative tracking-[0.03em] leading-[1.25rem] font-medium text-[inherit] inline-block min-w-[7.75rem] z-[1]">
                  SEND A MESSAGE
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  });

export default ContactForDedicationServer;

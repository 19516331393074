import { FunctionComponent, memo } from "react";

export type Pricing1Type = {
  className?: string;
};

const Pricing1: FunctionComponent<Pricing1Type> = memo(({ className = "" }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[5rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins mq750:pb-[3.25rem] mq750:box-border ${className}`}
    >
      <div className="w-[66.813rem] flex flex-col items-end justify-start gap-[2.875rem] max-w-full mq750:gap-[1.438rem]">
        <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem]">
          <div className="flex flex-col items-start justify-start gap-[0.187rem]">
            <div className="flex flex-row items-start justify-start py-[0rem] pl-[1.812rem] pr-[1.875rem]">
              <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">{`Plans & Pricing`}</h2>
            </div>
            <div className="relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
              Choose from our affordable plans.
            </div>
          </div>
        </div>
        <div className="self-stretch rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[3.375rem] box-border relative gap-[1.937rem] max-w-full text-center text-[0.875rem] text-white mq450:pb-[2.188rem] mq450:box-border mq750:gap-[0.938rem]">
          <div className="self-stretch h-[27.25rem] relative rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] hidden z-[0]" />
          <div className="self-stretch rounded-t-11xl rounded-b-none bg-indigo-300 overflow-x-auto flex flex-row items-start justify-start pt-[1.937rem] px-[4.625rem] pb-[1.562rem] gap-[4.125rem] z-[1] mq450:gap-[1rem] mq750:gap-[2.063rem] mq750:pl-[2.313rem] mq750:pr-[2.313rem] mq750:box-border">
            <div className="h-[4.75rem] w-[66.813rem] relative rounded-t-11xl rounded-b-none bg-indigo-300 shrink-0 hidden" />
            <div className="w-[5.563rem] shrink-0 flex flex-col items-start justify-start">
              <div className="relative leading-[1.25rem] font-medium inline-block min-w-[2.188rem] z-[2]">
                PLAN
              </div>
            </div>
            <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.437rem]">
              <div className="relative leading-[1.25rem] font-medium inline-block min-w-[1.813rem] z-[2]">
                CPU
              </div>
            </div>
            <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[0.625rem]">
              <div className="relative leading-[1.25rem] font-medium inline-block min-w-[1.938rem] z-[2]">
                RAM
              </div>
            </div>
            <div className="relative leading-[1.25rem] font-medium inline-block min-w-[4.063rem] z-[2]">
              STORAGE
            </div>
            <div className="relative leading-[1.25rem] font-medium inline-block min-w-[5.25rem] z-[2]">
              BANDWIDTH
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.937rem] pr-[0.75rem] box-border max-w-full text-[0.813rem] text-slategray-100">
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
              <div className="self-stretch shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white overflow-x-auto flex flex-row items-end justify-start py-[1.187rem] px-[1.125rem] gap-[2.812rem] z-[1] mq750:gap-[1.375rem]">
                <div className="h-[5.313rem] w-[65.125rem] relative shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white shrink-0 hidden" />
                <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.312rem]">
                  <div className="flex flex-col items-start justify-start">
                    <div className="flex flex-row items-start justify-start py-[0rem] pl-[2.187rem] pr-[2.25rem]">
                      <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.938rem] shrink-0 z-[1]">
                        VPS-L2
                      </div>
                    </div>
                    <div className="h-[1rem] flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0rem] box-border text-[0.5rem]">
                      <div className="mt-[-0.25rem] relative leading-[1.25rem] font-semibold inline-block min-w-[7.375rem] shrink-0 z-[1]">
                        (cPanel VPS Hosting License)
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[13.938rem] shrink-0 flex flex-row items-end justify-between py-[0rem] pl-[0rem] pr-[2.687rem] box-border gap-[1.25rem]">
                  <div className="relative leading-[2.938rem] font-semibold inline-block min-w-[5.938rem] z-[1]">
                    1 x 2.7 GHz CPU
                  </div>
                  <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.812rem]">
                    <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[1.813rem] z-[1]">
                      2 GB
                    </div>
                  </div>
                </div>
                <div className="w-[6.5rem] shrink-0 flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.812rem] box-border">
                  <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.375rem] z-[1]">
                    20 GB
                  </div>
                </div>
                <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.375rem]">
                  <div className="flex flex-row items-end justify-center gap-[1.812rem] mq750:flex-wrap">
                    <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.5rem] pl-[0rem] pr-[0.75rem]">
                      <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[1.688rem] z-[1]">
                        2 TB
                      </div>
                    </div>
                    <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[0.812rem] text-[0.563rem]">
                      <div className="flex flex-row items-start justify-start py-[0rem] px-[0.312rem]">
                        <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[6.313rem] shrink-0 z-[1]">
                          Firewall Configuration
                        </div>
                      </div>
                      <div className="h-[1rem] flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0rem] box-border">
                        <div className="mt-[-0.25rem] relative leading-[1.25rem] font-semibold inline-block min-w-[6.938rem] shrink-0 z-[1]">
                          Malware Protection Tool
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.437rem] text-[0.938rem]">
                      <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[3.438rem] z-[1]">
                        $360/y
                      </div>
                    </div>
                    <div className="rounded-xl bg-goldenrod flex flex-row items-start justify-start pt-[0.5rem] px-[0.812rem] pb-[0.437rem] whitespace-nowrap z-[1] text-[0.875rem] text-white">
                      <div className="h-[2.188rem] w-[6.875rem] relative rounded-xl bg-goldenrod hidden" />
                      <a href="#" className="[text-decoration:none] relative leading-[1.25rem] text-white font-medium inline-block min-w-[5.25rem] z-[1]">
                        Deploy Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white overflow-x-auto flex flex-row items-end justify-start pt-[1.187rem] px-[1.125rem] pb-[1.062rem] gap-[3.5rem] z-[1] mq750:gap-[1.75rem]">
                <div className="h-[5.313rem] w-[65.125rem] relative shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white shrink-0 hidden" />
                <div className="w-[16.125rem] shrink-0 flex flex-row items-end justify-between gap-[1.25rem]">
                  <div className="flex flex-col items-start justify-start">
                    <div className="flex flex-row items-start justify-start py-[0rem] px-[2.187rem]">
                      <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[3rem] shrink-0 z-[1]">
                        VPS-L4
                      </div>
                    </div>
                    <div className="h-[0.938rem] flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0rem] box-border text-[0.5rem]">
                      <div className="mt-[-0.313rem] relative leading-[1.25rem] font-semibold inline-block min-w-[7.375rem] shrink-0 z-[2]">
                        (cPanel VPS Hosting License)
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.125rem]">
                    <div className="relative leading-[2.938rem] font-semibold inline-block min-w-[5.938rem] z-[1]">
                      1 x 2.7 GHz CPU
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.937rem] pl-[0rem] pr-[1.875rem]">
                  <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[1.938rem] z-[1]">
                    4 GB
                  </div>
                </div>
                <div className="w-[5.813rem] shrink-0 flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.937rem] box-border">
                  <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.438rem] z-[1]">
                    40 GB
                  </div>
                </div>
                <div className="w-[25.938rem] shrink-0 flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.125rem] box-border">
                  <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[1.812rem]">
                    <div className="flex flex-col items-start justify-start pt-[0.5rem] pb-[0rem] pl-[0rem] pr-[1.125rem]">
                      <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[1.75rem] z-[1]">
                        4 TB
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col items-start justify-start pt-[0.312rem] px-[0rem] pb-[0rem] box-border min-w-[8.188rem] text-[0.5rem]">
                      <div className="self-stretch flex flex-row items-start justify-between gap-[1.25rem]">
                        <div className="flex flex-col items-start justify-start">
                          <div className="flex flex-row items-start justify-start py-[0rem] px-[0.25rem]">
                            <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[5.625rem] shrink-0 z-[2]">
                              Firewall Configuration
                            </div>
                          </div>
                          <div className="h-[1rem] flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0rem] box-border">
                            <div className="mt-[-0.25rem] relative leading-[1.25rem] font-semibold inline-block min-w-[6.125rem] shrink-0 z-[3]">
                              Malware Protection Tool
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem] text-[0.938rem]">
                          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[3.438rem] z-[1]">
                            $396/y
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="rounded-xl bg-deepskyblue flex flex-row items-start justify-start pt-[0.5rem] px-[0.812rem] pb-[0.437rem] whitespace-nowrap z-[1] text-[0.875rem] text-white">
                      <div className="h-[2.188rem] w-[6.875rem] relative rounded-xl bg-deepskyblue hidden" />
                      <a href="#" className="[text-decoration:none] relative leading-[1.25rem] text-white font-medium inline-block min-w-[5.25rem] z-[1]">
                        Deploy Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white overflow-x-auto flex flex-row items-end justify-between pt-[1.187rem] pb-[1.062rem] pl-[1.125rem] pr-[1.812rem] box-border max-w-full gap-[1.25rem] z-[1]">
                <div className="h-[5.313rem] w-[65.125rem] relative shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white shrink-0 hidden" />
                <div className="w-[21.5rem] shrink-0 flex flex-col items-start justify-start">
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[2.187rem] pr-[0rem]">
                    <div className="flex-1 flex flex-row items-end justify-between gap-[1.25rem] mq450:flex-wrap">
                      <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.812rem] pl-[0rem] pr-[1.687rem]">
                        <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.938rem] z-[1]">
                          VPS-L8
                        </div>
                      </div>
                      <div className="relative leading-[2.938rem] font-semibold inline-block min-w-[6.188rem] z-[1]">
                        2 x 2.8 GHz CPU
                      </div>
                      <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.812rem]">
                        <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[1.875rem] z-[1]">
                          8 GB
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="relative text-[0.5rem] leading-[1.25rem] font-semibold inline-block min-w-[7.375rem] z-[2] mt-[-1.125rem]">
                    (cPanel VPS Hosting License)
                  </div>
                </div>
                <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.937rem] pl-[0rem] pr-[1.312rem]">
                  <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.438rem] z-[1]">
                    80 GB
                  </div>
                </div>
                <div className="w-[25.938rem] shrink-0 flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.125rem] box-border max-w-[calc(100%_-_444px)]">
                  <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[2.937rem] max-w-full mq450:gap-[1.438rem]">
                    <div className="flex flex-col items-start justify-start pt-[0.5rem] px-[0rem] pb-[0rem]">
                      <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[1.75rem] z-[1]">
                        8 TB
                      </div>
                    </div>
                    <div className="flex-1 flex flex-row items-start justify-center gap-[1.687rem] min-w-[13.813rem] max-w-full text-[0.5rem] mq450:flex-wrap">
                      <div className="flex-1 flex flex-col items-start justify-start pt-[0.312rem] px-[0rem] pb-[0rem] box-border min-w-[8.25rem]">
                        <div className="self-stretch flex flex-row items-start justify-between gap-[1.25rem]">
                          <div className="flex flex-col items-start justify-start">
                            <div className="flex flex-row items-start justify-start py-[0rem] px-[0.25rem]">
                              <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[5.625rem] shrink-0 z-[2]">
                                Firewall Configuration
                              </div>
                            </div>
                            <div className="h-[1rem] flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0rem] box-border">
                              <div className="mt-[-0.25rem] relative leading-[1.25rem] font-semibold inline-block min-w-[6.125rem] shrink-0 z-[3]">
                                Malware Protection Tool
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem] text-[0.938rem]">
                            <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[3.438rem] z-[1]">
                              $528/y
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="rounded-xl bg-deeppink flex flex-row items-start justify-start pt-[0.5rem] px-[0.812rem] pb-[0.437rem] whitespace-nowrap z-[1] text-[0.875rem] text-white">
                        <div className="h-[2.188rem] w-[6.875rem] relative rounded-xl bg-deeppink hidden" />
                        <a href="#" className="[text-decoration:none] relative leading-[1.25rem] text-white font-medium inline-block min-w-[5.25rem] z-[1]">
                          Deploy Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            className="w-[10.063rem] h-[9.125rem] absolute !m-[0] bottom-[-8.312rem] left-[calc(50%_-_80.5px)] object-contain z-[1]"
            loading="lazy"
            alt=""
            src="/3d-illustration-server-security-and-unlocka-31@2x.png"
          />
        </div>
      </div>
    </section>
  );
});

export default Pricing1;

import { FunctionComponent, memo } from "react";

export type WebHostingContentType = {
  className?: string;
};

const WebHostingContent: FunctionComponent<WebHostingContentType> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`w-[88.313rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full text-left text-[1.5rem] text-indigo-100 font-poppins ${className}`}
      >
        <div className="h-[25.65rem] w-[70.563rem] relative max-w-full">
          <img
            className="absolute top-[0rem] left-[62.313rem] w-[8.25rem] h-[7rem] object-cover"
            loading="lazy"
            alt=""
            src="/11684121-4391-1@2x.png"
          />
          <div className="absolute h-full top-[0rem] bottom-[0rem] left-[0rem] w-[67.938rem] max-w-full">
            <img
              className="absolute top-[11.5rem] left-[0rem] w-[8.263rem] h-[7rem] object-cover"
              loading="lazy"
              alt=""
              src="/11684121-4391-2@2x.png"
            />
            <div className="absolute top-[0rem] left-[1.563rem] w-[66.375rem] flex flex-col items-start justify-start gap-[0.562rem] max-w-full">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
                <img
                  className="h-[15.625rem] w-[15.625rem] relative object-cover"
                  loading="lazy"
                  alt=""
                  src="/3d00244a-1@2x.png"
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[1.031rem]">
                <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
                  <div className="w-[11.625rem] flex flex-col items-start justify-start gap-[1.756rem]">
                    <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.375rem] pr-[0.687rem]">
                      <div className="h-[0.625rem] flex-1 relative [filter:blur(11.2px)] rounded-[50%] bg-darkslategray-200" />
                    </div>
                    <h1 className="m-0 relative text-inherit leading-[2.969rem] font-semibold font-inherit z-[1] mq450:text-[1.188rem] mq450:leading-[2.313rem]">
                      $1 Web Hosting
                    </h1>
                  </div>
                </div>
                <h1 className="m-0 self-stretch relative text-[2.5rem] tracking-[0.04em] leading-[3.081rem] font-semibold font-inherit text-gray-200 text-center mq450:text-[1.5rem] mq450:leading-[1.75rem] mq800:text-[2rem] mq800:leading-[2.375rem]">
                  “WHY CHOOSE Hostlasting.com?”
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default WebHostingContent;

import { FunctionComponent } from "react";
import HeaderHerocPanelSection from "../components/HeaderHerocPanelSection";
import Pricing1 from "../components/Pricing1";
import CPanelCheapestGrid from "../components/CPanelCheapestGrid";
import FrameComponent20 from "../components/FrameComponent20";
import Security from "../components/Security";
import AdditionalFeatures from "../components/AdditionalFeatures";
import FAQ from "../components/FAQ";
import GroupComponent9 from "../components/GroupComponent9";

const Cpanel: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[3.312rem] leading-[normal] tracking-[normal] mq750:gap-[1.625rem]">
      <HeaderHerocPanelSection />
      <div className="w-[0.188rem] h-[9.938rem] relative border-white border-r-[3px] border-solid box-border hidden" />
      <img
        className="w-[15.313rem] h-[15.313rem] relative object-cover hidden"
        alt=""
        src="/1531135-4091-12@2x.png"
      />
      <Pricing1 />
      <section className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[1.625rem] pl-[1.312rem] pr-[1.25rem] box-border max-w-full text-left text-[1rem] text-darkslategray-100 font-poppins">
        <div className="w-[56.375rem] flex flex-col items-start justify-start gap-[1.062rem] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <div className="w-[28.375rem] flex flex-col items-start justify-start gap-[1.25rem] max-w-full">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.312rem]">
                <div className="h-[0.375rem] w-[4.688rem] relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[2]" />
              </div>
              <h2 className="relative leading-[1.813rem] font-semibold">
                Know more about our cheapest VPS hosting with cPanel
              </h2>
            </div>
          </div>
          <div className="self-stretch relative text-[0.875rem] leading-[1.25rem] text-slategray-100 text-center">
            Avail benefits of VPS hosting with CPanel/WHM. We offer SSD-based
            hosting services. In the fiercely competitive marketplace, many
            companies are shouting from their lunges about how they provide the
            best services in cheap prices. However, only few of them live up to
            their promises. We are few of them. We strive to improve the users’
            experience on your website with our best-in-class, reliable, and
            seamless services. Following are some of the benefits of our
            services.
          </div>
        </div>
      </section>
      <CPanelCheapestGrid />
      <section className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins">
        <div className="w-[66.063rem] flex flex-col items-start justify-start gap-[2.562rem] max-w-full mq750:gap-[1.25rem]">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem]">
            <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
              One click install OS Dockers
            </h3>
          </div>
          <img
            className="self-stretch h-[23.5rem] relative max-w-full overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/group-368.svg"
          />
        </div>
      </section>
      <section className="self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[3rem] box-border max-w-full text-left text-[0.875rem] text-darkslategray-100 font-poppins lg:pb-[1.25rem] lg:box-border">
        <div className="flex-1 bg-ghostwhite-100 flex flex-col items-start justify-start pt-[3.312rem] px-[0rem] pb-[3.5rem] box-border gap-[2.75rem] max-w-full lg:pt-[1.375rem] lg:pb-[1.438rem] lg:box-border mq750:gap-[1.375rem] mq750:pt-[1.25rem] mq750:pb-[1.25rem] mq750:box-border">
          <div className="self-stretch h-[150.563rem] relative bg-ghostwhite-100 hidden" />
          <FrameComponent20 />
          <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[3rem] box-border max-w-full">
            <div className="w-[66.688rem] flex flex-col items-start justify-start max-w-full">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem]">
                <h2 className="relative leading-[2.875rem] font-semibold z-[1]">
                  HostLasting’s cPanel VPS Hosting Solutions at your disposal:
                </h2>
              </div>
              <div className="self-stretch relative leading-[1.25rem] text-slategray-100 text-center z-[2]">
                HostLasting offers different flexible plans with various
                configurations. These plans are designed to suit your
                requirements and budgetary constraints. All of these plans
                include following vital features:
              </div>
            </div>
          </div>
          <div className="w-[63.563rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[2.312rem] box-border max-w-full text-center">
            <div className="flex flex-row items-start justify-start gap-[1.125rem] max-w-full">
              <div className="flex flex-col items-start justify-start pt-[0.312rem] px-[0rem] pb-[0rem]">
                <div className="flex flex-col items-start justify-start gap-[1.656rem]">
                  <div className="w-[0.563rem] h-[0.563rem] relative rounded-[50%] bg-indigo-100 z-[1]" />
                  <div className="w-[0.563rem] h-[0.563rem] relative rounded-[50%] bg-indigo-100 z-[1]" />
                  <div className="w-[0.563rem] h-[0.563rem] relative rounded-[50%] bg-indigo-100 z-[1]" />
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-[0.937rem] max-w-[calc(100%_-_27px)]">
                <div className="relative leading-[1.25rem] font-semibold z-[1]">
                  Affordable cPanel VPS Hosting License.
                </div>
                <div className="relative leading-[1.25rem] font-semibold z-[1]">
                  Firewall Configuration based on SSD.
                </div>
                <div className="relative leading-[1.25rem] font-semibold z-[1]">
                  Malware Protection Tools to eliminate the threats posed by
                  cyber-attacks.
                </div>
              </div>
            </div>
          </div>
          <Security />
          <AdditionalFeatures />
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <div className="w-[66.688rem] flex flex-col items-start justify-start max-w-full">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem]">
                <h2 className="relative leading-[2.875rem] font-semibold z-[1]">
                  cPanel VPS Hosting at a glance:
                </h2>
              </div>
              <div className="self-stretch relative leading-[1.25rem] text-slategray-100 text-center z-[2]">
                <p className="m-0">
                  cPanel VPS Hosting enables you to manage virtual private
                  servers through a control panel dashboard. Websites and web
                  applications run on these servers. So, this implies that you
                  can manage the website and web application functionality,
                  speed, and overall operations.
                </p>
                <p className="m-0">
                  cPanel VPS hosting overcomes the limitations of the shared
                  hosting, allowing you to possess the server space with private
                  servers. This service is gaining prominence as it offers more
                  control over the operations as compared to other types of
                  hosting.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FAQ />
      <GroupComponent9 />
    </div>
  );
};

export default Cpanel;

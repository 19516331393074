import { FunctionComponent, memo } from "react";

export type FrameComponent6Type = {
  className?: string;
};

const FrameComponent6: FunctionComponent<FrameComponent6Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins ${className}`}
      >
        <div className="w-[51.438rem] flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem]">
            <div className="h-[7.625rem] w-[7.625rem] relative bg-[url('/public/cloud-storage-5a-2@2x.png')] bg-cover bg-no-repeat bg-[top]">
              <img
                className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                alt=""
                src="/cloud-storage-5a-2@2x.png"
              />
              <div className="absolute top-[6.313rem] left-[1.563rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
            </div>
          </div>
          <h2 className="h-[2.438rem] relative leading-[2.875rem] font-semibold flex items-center mq450:text-[1.188rem] mq450:leading-[2.313rem]">
            SSD CLOUD VPS HOSTING SERVICES AT THE MOST AFFORDABLE PRICES
          </h2>
          <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.75rem] pr-[1.687rem] box-border max-w-full text-center text-[0.875rem] text-slategray-100">
            <div className="flex-1 relative leading-[1.25rem] inline-block max-w-full z-[1]">
              There is no requirement for a minimum contract period. If you need
              SSD Cloud VPS hosting services for less than a month, you can
              avail them. You can pay for the services on an hourly basis. We
              charge our services based on usage only. If you are not using our
              services and do not need a server, you can delete it and avoid
              being charged excessively.
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent6;

import { FunctionComponent, memo } from "react";
import HeaderPanal from "./HeaderPanal";

export type HeaderHeroCyberpanelVPSHostingType = {
  className?: string;
};

const HeaderHeroCyberpanelVPSHosting: FunctionComponent<HeaderHeroCyberpanelVPSHostingType> =
  memo(({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[4.062rem] box-border max-w-full text-left text-[0.875rem] text-white font-poppins mq450:pb-[1.688rem] mq450:box-border mq1050:pb-[2.625rem] mq1050:box-border ${className}`}
      >
        <div className="flex-1 [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[1rem] box-border gap-[0.187rem] max-w-full">
          <HeaderPanal />
          <div className="w-[81.5rem] flex flex-row items-start justify-end py-[0rem] px-[3.312rem] box-border max-w-full lg:pl-[1.625rem] lg:pr-[1.625rem] lg:box-border">
            <div className="flex-1 flex flex-col items-start justify-start gap-[3.187rem] max-w-full mq750:gap-[1.563rem]">
              <div className="self-stretch h-[35rem] relative">
                <div className="absolute top-[12.188rem] left-[0.063rem] tracking-[0.04em] leading-[2.938rem] font-semibold text-center">
                  Carry Out VPS Management with Ease Using CyberPanel
                </div>
                <div className="absolute top-[19.063rem] left-[0.063rem] text-[0.813rem] leading-[1.25rem] font-medium text-lightgray-100 flex items-center w-[38.438rem]">
                  <span className="w-full">
                    <p className="m-0">
                      Unleash the benefits of VPS hosting with ease in overall
                      management and 10 times higher
                    </p>
                    <p className="m-0">
                      speed. Avail CyberPanel, the next-generation control panel
                      designed and developed by
                    </p>
                    <p className="m-0">OpenLiteSpeed.</p>
                  </span>
                </div>
                <div className="absolute h-full top-[0rem] bottom-[0rem] left-[39.875rem] w-[35rem]">
                  <div className="absolute top-[25.5rem] left-[18.688rem] [filter:blur(26.2px)] rounded-[50%] bg-gray-800 w-[11.625rem] h-[0.813rem]" />
                  <img
                    className="absolute top-[4.063rem] left-[20.625rem] w-[10.625rem] h-[10.625rem] overflow-hidden"
                    loading="lazy"
                    alt=""
                    src="/cyberpanel2-1.svg"
                  />
                  <img
                    className="absolute top-[0rem] left-[0rem] w-full h-full object-cover z-[1]"
                    alt=""
                    src="/6663bcc18d0b4f41a07e5b091e66916a-1a-1@2x.png"
                  />
                </div>
                <h1 className="m-0 absolute top-[14.625rem] left-[0.063rem] text-[3rem] tracking-[0.11em] leading-[1.5rem] font-semibold font-inherit flex items-center w-[44.188rem] h-[1.5rem] z-[2] mq450:text-[1.813rem] mq450:leading-[0.875rem] mq1050:text-[2.375rem] mq1050:leading-[1.188rem]">
                  Cyberpanel VPS Hosting
                </h1>
                <div className="absolute top-[25.25rem] left-[0rem] rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none [background:linear-gradient(89.99deg,_#784997,_#fff)] w-[31rem] h-[0.438rem] z-[2]" />
              </div>
              <div className="flex flex-row items-start justify-start py-[0rem] px-[0.062rem] text-center text-[1rem]">
                <div className="flex flex-row items-start justify-start gap-[0.543rem]">
                  <div className="flex flex-col items-start justify-start pt-[1rem] px-[0rem] pb-[0rem]">
                    <img
                      className="w-[1.5rem] h-[1.5rem] relative overflow-hidden shrink-0 z-[2]"
                      alt=""
                      src="/icroundhome.svg"
                    />
                  </div>
                  <a className="[text-decoration:none] relative leading-[3.5rem] font-medium text-plum inline-block min-w-[3rem] z-[2]">
                    Home
                  </a>
                  <h3 className="m-0 relative text-[1.25rem] leading-[3.5rem] font-medium font-inherit inline-block min-w-[0.688rem] z-[2] mq450:text-[1rem] mq450:leading-[2.813rem]">
                    /
                  </h3>
                  <div className="relative leading-[3.5rem] font-medium z-[2]">
                    CyberPanel VPS Hosting
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  });

export default HeaderHeroCyberpanelVPSHosting;

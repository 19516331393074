import { FunctionComponent, memo } from "react";
import LocationItem from "./LocationItem";
import SharedHostingCPU from "./SharedHostingCPU";
import SharedHostingPlanHeader from "./SharedHostingPlanHeader";
import GroupComponent22 from "./GroupComponent22";
import GroupComponent12 from "./GroupComponent12";

export type CloudVPSHostingType = {
  className?: string;
};

const CloudVPSHosting: FunctionComponent<CloudVPSHostingType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.187rem] box-border max-w-full text-center text-[3rem] text-darkslategray-100 font-poppins ${className}`}
      >
        <div className="flex-1 bg-ghostwhite-100 flex flex-col items-end justify-start pt-[11.312rem] px-[11.187rem] pb-[14.875rem] box-border gap-[8.187rem] max-w-full mq800:gap-[4.063rem] mq800:pt-[3.125rem] mq800:px-[5.563rem] mq800:pb-[4.125rem] mq800:box-border mq450:gap-[2.063rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq1350:pt-[4.813rem] mq1350:pb-[6.313rem] mq1350:box-border">
          <div className="w-[89.438rem] h-[145.125rem] relative bg-ghostwhite-100 hidden max-w-full" />
          <div className="flex flex-row items-start justify-end py-[0rem] px-[0.375rem] box-border max-w-full">
            <div className="flex flex-col items-start justify-start gap-[1.375rem] max-w-full">
              <div className="flex flex-row items-start justify-start relative">
                <div className="h-[22.875rem] w-[25.313rem] !m-[0] absolute bottom-[-12.812rem] left-[-24.375rem] flex flex-row items-start justify-start z-[1]">
                  <div className="h-full w-full absolute !m-[0] top-[0rem] right-[0rem] bottom-[0rem] left-[0rem]">
                    <img
                      className="absolute top-[3.875rem] left-[0rem] w-[25.313rem] h-[19rem] object-cover"
                      alt=""
                      src="/hostlastingslide2-12@2x.png"
                    />
                    <img
                      className="absolute top-[0rem] left-[0.938rem] w-[20.188rem] h-[20.188rem] object-contain z-[1]"
                      loading="lazy"
                      alt=""
                      src="/domain-1@2x.png"
                    />
                  </div>
                </div>
                <h2 className="m-0 relative text-inherit leading-[1.25rem] font-semibold font-inherit z-[2] mq800:text-[2.375rem] mq800:leading-[1rem] mq450:text-[1.813rem] mq450:leading-[0.75rem]">
                  Check out
                </h2>
              </div>
              <h2 className="m-0 relative text-[2rem] leading-[1.25rem] font-medium font-inherit inline-block min-w-[3.375rem] z-[2] mq800:text-[1.625rem] mq800:leading-[1rem] mq450:text-[1.188rem] mq450:leading-[0.75rem]">
                our
              </h2>
              <h2 className="m-0 relative text-inherit leading-[1.25rem] font-semibold font-inherit text-indigo-100 z-[2] mq800:text-[2.375rem] mq800:leading-[1rem] mq450:text-[1.813rem] mq450:leading-[0.75rem]">
                CLOUD VPS HOSTING
              </h2>
              <h2 className="m-0 relative text-[2rem] leading-[1.25rem] font-medium font-inherit inline-block min-w-[5.5rem] z-[2] mq800:text-[1.625rem] mq800:leading-[1rem] mq450:text-[1.188rem] mq450:leading-[0.75rem]">
                Plans
              </h2>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[0.812rem] pl-[0.062rem] pr-[0rem] box-border max-w-full text-[0.75rem] text-slategray-100">
            <div className="w-[33.25rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
              <LocationItem uS1="/us-1@2x.png" unitedStates="United States" />
              <LocationItem uS1="/finland-1@2x.png" unitedStates="Finland" />
              <LocationItem uS1="/germany-1@2x.png" unitedStates="Germany" />
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[4.687rem] max-w-full text-left text-[1.5rem] text-white mq800:gap-[1.188rem] mq1150:gap-[2.313rem]">
            <div className="self-stretch flex flex-col items-end justify-start gap-[0.812rem] max-w-full">
              <div className="self-stretch flex flex-col items-start justify-start gap-[4rem] max-w-full mq800:gap-[1rem] mq1150:gap-[2rem]">
                <SharedHostingCPU sharedVCPUx86="Shared vCPU(x86)" />
                <div className="self-stretch rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[3.5rem] box-border gap-[1.937rem] max-w-full z-[1] text-center text-[0.875rem] mq800:gap-[0.938rem] mq800:pb-[2.25rem] mq800:box-border">
                  <div className="self-stretch h-[39.25rem] relative rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] hidden" />
                  <SharedHostingPlanHeader />
                  <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.937rem] pr-[0.75rem] box-border max-w-full text-[0.813rem] text-slategray-100">
                    <div className="flex-1 flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
                      <GroupComponent22
                        cX11="CX11"
                        intel="1 Intel"
                        gB="2 GB"
                        gB1="20 GB"
                        iPv4v6="IPv4/v6"
                        mo="$5/mo"
                      />
                      <GroupComponent12
                        cPX11="CPX11"
                        aMD="2 AMD"
                        gB="2 GB"
                        gB1="40 GB"
                        mo="$6/mo"
                      />
                      <GroupComponent22
                        propOverflowX="auto"
                        cX11="CX21"
                        intel="2 Intel"
                        gB="4 GB"
                        gB1="40 GB"
                        iPv4v6="IPv4/v6"
                        mo="$7/mo"
                      />
                      <GroupComponent12
                        propPadding="1.562rem 0.875rem 1.562rem 2.187rem"
                        cPX11="CPX21"
                        aMD="3 AMD"
                        gB="4 GB"
                        gB1="80 GB"
                        mo="$10/mo"
                      />
                      <GroupComponent22
                        propOverflowX="auto"
                        cX11="CX31"
                        intel="2 Intel"
                        gB="8 GB"
                        gB1="80 GB"
                        iPv4v6="Ipv4"
                        mo="$13/mo"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <SharedHostingCPU
                propAlignSelf="unset"
                propPadding="0rem 1.25rem"
                propWidth="64.75rem"
                sharedVCPUx86="Dedicated vCPU"
              />
            </div>
            <div className="self-stretch rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[2.875rem] box-border gap-[1.937rem] max-w-full z-[1] mq800:gap-[0.938rem] mq450:pb-[1.875rem] mq450:box-border">
              <div className="self-stretch h-[26.75rem] relative rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] hidden" />
              <SharedHostingPlanHeader />
              <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.937rem] pr-[0.75rem] box-border max-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
                  <GroupComponent12
                    propPadding="1.562rem 0.875rem 1.562rem 1.687rem"
                    cPX11="CCX12"
                    aMD="2 Intel"
                    gB="8 GB"
                    gB1="80 GB"
                    mo="$30/mo"
                  />
                  <GroupComponent12
                    propPadding="1.562rem 0.875rem 1.562rem 1.875rem"
                    cPX11="CCX22"
                    aMD="4 AMD"
                    gB="16 GB"
                    gB1="160 GB"
                    mo="$50/mo"
                  />
                  <GroupComponent12
                    propPadding="1.562rem 0.875rem 1.562rem 1.75rem"
                    cPX11="CCX32"
                    aMD="8 AMD"
                    gB="32 GB"
                    gB1="240 GB"
                    mo="$100/mo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default CloudVPSHosting;

import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type FeatureElementsType = {
  className?: string;
  carbonmanageProtection?: string;
  fullRootAccess?: string;

  /** Style props */
  propPadding?: CSSProperties["padding"];
  propGap?: CSSProperties["gap"];
};

const FeatureElements: FunctionComponent<FeatureElementsType> = memo(
  ({
    className = "",
    propPadding,
    propGap,
    carbonmanageProtection,
    fullRootAccess,
  }) => {
    const featureElementsStyle: CSSProperties = useMemo(() => {
      return {
        padding: propPadding,
        gap: propGap,
      };
    }, [propPadding, propGap]);

    return (
      <div
        className={`flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[0.312rem] gap-[0.437rem] text-center text-[0.813rem] text-lightgray-100 font-poppins ${className}`}
        style={featureElementsStyle}
      >
        <div className="flex flex-row items-start justify-start py-[0rem] pl-[2.125rem] pr-[2.187rem]">
          <img
            className="h-[2.25rem] w-[2.25rem] relative overflow-hidden shrink-0 z-[1]"
            loading="lazy"
            alt=""
            src={carbonmanageProtection}
          />
        </div>
        <div className="relative leading-[1.25rem] font-medium inline-block min-w-[6.563rem] z-[1]">
          {fullRootAccess}
        </div>
      </div>
    );
  }
);

export default FeatureElements;

import { FunctionComponent, memo } from "react";
import FrameComponent9 from "./FrameComponent9";
import GroupComponent21 from "./GroupComponent21";
import GroupComponent11 from "./GroupComponent11";
import FrameComponent10 from "./FrameComponent10";

export type FrameComponent8Type = {
  className?: string;
};

const FrameComponent8: FunctionComponent<FrameComponent8Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[2.237rem] pl-[1.5rem] pr-[1.25rem] box-border max-w-full text-center text-[0.875rem] text-white font-poppins ${className}`}
      >
        <div className="w-[66.813rem] flex flex-col items-end justify-start gap-[0.812rem] max-w-full">
          <div className="self-stretch rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[3.5rem] box-border gap-[1.937rem] max-w-full mq750:gap-[0.938rem] mq750:pb-[2.25rem] mq750:box-border">
            <div className="self-stretch h-[39.25rem] relative rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] hidden" />
            <FrameComponent9 />
            <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.937rem] pr-[0.75rem] box-border max-w-full text-[0.813rem] text-slategray-100">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
                <GroupComponent21
                  cX11="CX11"
                  intel="1 Intel"
                  gB="2 GB"
                  gB1="20 GB"
                  iPv4v6="IPv4/v6"
                  mo="$5/mo"
                />
                <GroupComponent11
                  cPX11="CPX11"
                  aMD="2 AMD"
                  gB="2 GB"
                  gB1="40 GB"
                  mo="$6/mo"
                />
                <GroupComponent21
                  cX11="CX21"
                  intel="2 Intel"
                  gB="4 GB"
                  gB1="40 GB"
                  iPv4v6="IPv4/v6"
                  mo="$7/mo"
                />
                <GroupComponent11
                  propPadding="1.562rem 0.875rem 1.562rem 2.187rem"
                  cPX11="CPX21"
                  aMD="3 AMD"
                  gB="4 GB"
                  gB1="80 GB"
                  mo="$10/mo"
                />
                <GroupComponent21
                  cX11="CX31"
                  intel="2 Intel"
                  gB="8 GB"
                  gB1="80 GB"
                  iPv4v6="Ipv4"
                  mo="$13/mo"
                />
              </div>
            </div>
          </div>
          <FrameComponent10
            propAlignSelf="unset"
            propPadding="0rem 1.25rem"
            propWidth="64.75rem"
            sharedVCPUx86="Dedicated vCPU"
          />
        </div>
      </div>
    );
  }
);

export default FrameComponent8;

import { FunctionComponent, memo } from "react";

export type FeatureCheapJoomlaType = {
  className?: string;
};

const FeatureCheapJoomla: FunctionComponent<FeatureCheapJoomlaType> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.562rem] pr-[1.25rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins ${className}`}
      >
        <div className="w-[66.25rem] flex flex-col items-start justify-start gap-[1.937rem] max-w-full mq750:gap-[0.938rem]">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.562rem] box-border max-w-full">
            <div className="w-[26.313rem] flex flex-col items-start justify-start gap-[1.062rem] max-w-full">
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
                <img
                  className="h-[5.625rem] w-[5.563rem] relative overflow-hidden shrink-0"
                  loading="lazy"
                  alt=""
                  src="/logosjoomla.svg"
                />
              </div>
              <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.25rem] pr-[1.5rem]">
                <div className="h-[0.375rem] w-[4.688rem] relative [filter:blur(4px)] rounded-[50%] bg-gray-1600" />
              </div>
              <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
                Features of Cheap Joomla Hosting
              </h2>
            </div>
          </div>
          <div className="self-stretch flex flex-row flex-wrap items-end justify-center gap-[7.75rem] max-w-full text-[1.25rem] text-indigo-100 mq450:gap-[0.938rem] mq750:gap-[1.938rem] mq1050:gap-[3.875rem]">
            <div className="flex-1 flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.625rem] box-border min-w-[19rem] max-w-full">
              <div className="self-stretch flex flex-col items-start justify-start gap-[3.468rem] max-w-full mq750:gap-[1.75rem]">
                <div className="self-stretch flex flex-col items-start justify-start gap-[2.175rem] max-w-full mq750:gap-[1.063rem]">
                  <div className="w-[26.313rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                    <div className="h-[13.063rem] w-[13.063rem] relative bg-[url('/public/11cc0e2406a74382b4dd3111fb2cfb8aa-1@2x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                        alt=""
                        src="/11cc0e2406a74382b4dd3111fb2cfb8aa-1@2x.png"
                      />
                      <div className="absolute top-[11.875rem] left-[4.188rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[0.331rem]">
                    <h3 className="relative tracking-[0.04em] leading-[3.369rem] font-semibold mq450:text-[1rem] mq450:leading-[2.625rem]">
                      Security Measures 
                    </h3>
                    <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                      Cost-effective Joomla hosting additional security features
                      are applicable to ensure that you can keep your system
                      protected from all kinds of virus and malware and
                      therefore filter the requirements. Firstly, through the
                      mail integrity maintenance system, you can eliminate junk
                      mails from the system and keep it up and running with the
                      modern upgrades that are made in the Microsoft Outlook
                      mechanism. Apart from that, SSL Certificates ensure the
                      integrity of your website application.
                    </div>
                  </div>
                </div>
                <div className="w-[26.313rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                  <div className="h-[13.063rem] w-[13.063rem] relative bg-[url('/public/cloud-storage-5a-1@2x.png')] bg-cover bg-no-repeat bg-[top]">
                    <img
                      className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                      alt=""
                      src="/cloud-storage-5a-1@2x.png"
                    />
                    <div className="absolute top-[11.813rem] left-[4.188rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col items-end justify-start gap-[3.5rem] min-w-[19rem] max-w-full mq750:gap-[1.75rem]">
              <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.812rem] box-border gap-[1.125rem] max-w-full">
                <h3 className="relative leading-[1.688rem] font-semibold inline-block max-w-full mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Existence of cPanel Control panel
                </h3>
                <div className="self-stretch h-[8.438rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0">{`Enhancing the visibility of the business is one of the essential factors to establish the business prowess of an enterprise. Through the enhanced web-server mechanisms, internet technology, and digitization & software optimization techniques, we are providing efficient and qualitative services at considerably cheap rates.`}</div>
              </div>
              <div className="w-[26.313rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                <div className="h-[13.063rem] w-[13.063rem] relative bg-[url('/public/f9fba6be92ca4399a5da5ef4f8d37e7da-1@2x.png')] bg-cover bg-no-repeat bg-[top]">
                  <img
                    className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                    alt=""
                    src="/f9fba6be92ca4399a5da5ef4f8d37e7da-1@2x.png"
                  />
                  <div className="absolute top-[11.25rem] left-[4.188rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem]">
                <h3 className="relative leading-[1.688rem] font-semibold mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Free Cloudflare System
                </h3>
                <div className="self-stretch h-[11.5rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0">
                  The system possesses a mechanism to import and transfer
                  important resources and information that can be put to
                  continuous usage in the production and transferring system of
                  the website. The Cloudflare System is fast and reliable,
                  enabling the users of the system to transfer resources and
                  prevent the system by maintaining its safety levels. It
                  provides a shield mechanism to prevent software attacks and
                  disorientation.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FeatureCheapJoomla;

import { FunctionComponent, memo } from "react";

export type HeaderKVMType = {
  className?: string;
};

const HeaderKVM: FunctionComponent<HeaderKVMType> = memo(
  ({ className = "" }) => {
    return (
      <header
        className={`absolute top-[0rem] left-[0rem] bg-indigo-100 w-full h-[7.5rem] overflow-hidden z-[1] text-left text-[0.875rem] text-white font-poppins ${className}`}
      >
        <img
          className="absolute top-[2.469rem] left-[3.75rem] w-[8.5rem] h-[2.125rem] object-contain z-[2]"
          loading="lazy"
          alt=""
          src="/hostlasting-logo@2x.png"
        />
        <div className="absolute top-[2.406rem] left-[13.5rem] rounded-xl border-white border-[1px] border-solid box-border w-[5.688rem] h-[2.313rem] z-[2]">
          <div className="absolute top-[0rem] left-[0rem] rounded-xl border-white border-[1px] border-solid box-border w-full h-full hidden" />
          <img
            className="absolute top-[0.375rem] left-[1.25rem] w-[1.625rem] h-[1.563rem] object-cover z-[1]"
            alt=""
            src="/us-2@2x.png"
          />
          <img
            className="absolute top-[0.688rem] left-[3.5rem] w-[0.875rem] h-[0.875rem] overflow-hidden z-[1]"
            alt=""
            src="/heroiconssolidchevrondown.svg"
          />
        </div>
        <a className="[text-decoration:none] absolute top-[2.813rem] left-[25.438rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[3.438rem] z-[2]">
          SHARED
        </a>
        <a className="[text-decoration:none] absolute top-[2.813rem] left-[31.5rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[3.813rem] z-[2]">
          RESELLER
        </a>
        <div className="absolute top-[2.813rem] left-[37.938rem] leading-[1.5rem] font-medium flex items-center min-w-[1.688rem] z-[2]">
          VPS
        </div>
        <a className="[text-decoration:none] absolute top-[2.813rem] left-[42.25rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[4.25rem] z-[2]">
          ADD-ONS
        </a>
        <a className="[text-decoration:none] absolute top-[2.813rem] left-[49.125rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[4.813rem] z-[2]">
          DEDICATED
        </a>
        <a className="[text-decoration:none] absolute top-[2.813rem] left-[56.563rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[4rem] whitespace-nowrap z-[2]">
          About Us
        </a>
        <a className="[text-decoration:none] absolute top-[2.813rem] left-[63.188rem] leading-[1.5rem] font-medium text-[inherit] flex items-center min-w-[3.625rem] z-[2]">
          Contact
        </a>
        <img
          className="absolute top-[2.313rem] left-[81.438rem] rounded-3xs w-[2.438rem] h-[2.438rem] object-cover z-[2]"
          loading="lazy"
          alt=""
          src="/24hourssupport-1@2x.png"
        />
        <div className="absolute top-[2.25rem] left-[73.063rem] rounded-3xs bg-white w-[6.75rem] h-[2.625rem] z-[2] text-[0.938rem] text-black">
          <div className="absolute top-[0rem] left-[0rem] rounded-3xs bg-white w-full h-full hidden" />
          <a className="[text-decoration:none] absolute top-[0.563rem] left-[2rem] leading-[1.5rem] font-medium text-[inherit] inline-block min-w-[2.75rem] whitespace-nowrap z-[1]">
            Log In
          </a>
        </div>
      </header>
    );
  }
);

export default HeaderKVM;

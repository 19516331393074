import { FunctionComponent, memo } from "react";

export type OptionItemsType = {
  className?: string;
  lIVE?: string;
};

const OptionItems: FunctionComponent<OptionItemsType> = memo(
  ({ className = "", lIVE }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0.125rem] pb-[0.625rem] text-center text-[2.25rem] text-white font-poppins ${className}`}
      >
        <div className="flex-1 rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] flex flex-row items-start justify-center py-[2.625rem] pl-[1.375rem] pr-[1.25rem] z-[1]">
          <div className="h-[8.188rem] w-[15.75rem] relative rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] hidden" />
          <div className="relative tracking-[0.1em] leading-[2.938rem] font-medium inline-block min-w-[5.25rem] z-[2] mq450:text-[1.375rem] mq450:leading-[1.75rem] mq1050:text-[1.813rem] mq1050:leading-[2.375rem]">
            {lIVE}
          </div>
        </div>
      </div>
    );
  }
);

export default OptionItems;

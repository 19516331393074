import { FunctionComponent, memo } from "react";
import OptionItems from "./OptionItems";
import Offer from "./Offer";
import Plan from "./Plan";
import PlanDetails from "./PlanDetails";
import PlanPriceComponent from "./PlanPriceComponent";
import PlanPriceEnjoy from "./PlanPriceEnjoy";

export type PlanDescriptionSSDType = {
  className?: string;
};

const PlanDescriptionSSD: FunctionComponent<PlanDescriptionSSDType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[0.875rem] box-border max-w-full text-left text-[0.688rem] text-slategray-100 font-poppins ${className}`}
      >
        <div className="w-[76.188rem] flex flex-col items-start justify-start gap-[3rem] max-w-full mq750:gap-[1.5rem]">
          <div className="self-stretch flex flex-row items-start justify-between max-w-full gap-[1.25rem] mq750:flex-wrap">
            <div className="h-[5.125rem] w-[4.813rem] flex flex-col items-start justify-start pt-[1.062rem] px-[0rem] pb-[0rem] box-border">
              <img
                className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                loading="lazy"
                alt=""
                src="/11684121-4391-4@2x.png"
              />
            </div>
            <div className="w-[28.938rem] flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.687rem] pr-[0.812rem] box-border max-w-full">
                <div className="flex-1 flex flex-col items-start justify-start max-w-full">
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[2.937rem] pr-[2.875rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                    <div className="relative leading-[1.25rem] font-medium">
                      Not sure about which plan to choose? We are here to help
                      you.
                    </div>
                  </div>
                  <div className="relative text-[0.813rem] leading-[1.25rem] font-medium">{`Choose from our variety of plans that best suites your requirement. `}</div>
                </div>
              </div>
              <h2 className="m-0 relative text-[2.25rem] tracking-[0.04em] leading-[2.938rem] font-semibold font-inherit text-indigo-100 text-center mq450:text-[1.375rem] mq450:leading-[1.75rem] mq1050:text-[1.813rem] mq1050:leading-[2.375rem]">
                SSD Linux Hosting Plans
              </h2>
            </div>
            <div className="h-[4.75rem] w-[4.813rem] flex flex-col items-start justify-start pt-[0.687rem] px-[0rem] pb-[0rem] box-border">
              <img
                className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                loading="lazy"
                alt=""
                src="/11684121-4391-4@2x.png"
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[1.062rem] box-border max-w-full text-center text-[2.25rem] text-black">
            <div className="flex-1 flex flex-row items-start justify-center gap-[1.437rem] max-w-full mq1050:flex-wrap">
              <div className="flex-1 flex flex-col items-start justify-start pt-[0.125rem] px-[0rem] pb-[0rem] box-border min-w-[16.063rem] max-w-[17.438rem]">
                <div className="self-stretch rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid flex flex-col items-start justify-start pt-[0.75rem] pb-[1.562rem] pl-[0.687rem] pr-[0.625rem] gap-[0.312rem]">
                  <div className="w-[17.438rem] h-[44.813rem] relative rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                  <OptionItems lIVE="LIVE" />
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.25rem] pr-[3.375rem] text-[1.5rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                    <Offer />
                  </div>
                  <Plan emailID="10 Email ID" y252070mo="3Y ₹2520 - 70/mo" />
                </div>
              </div>
              <div className="flex-[0.9176] rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border flex flex-col items-start justify-start pt-[0.75rem] pb-[1.562rem] pl-[0.687rem] pr-[0.625rem] gap-[0.312rem] min-w-[16.063rem] max-w-[17.438rem] text-[1.5rem] mq450:flex-1">
                <div className="w-[17.438rem] h-[44.813rem] relative rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                <OptionItems lIVE="LAUGH" />
                <PlanDetails />
                <Plan
                  emailID="Unlimited Email ID"
                  y252070mo="3Y ₹5040 - 140/mo"
                />
              </div>
              <div className="flex-[0.9176] rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border flex flex-col items-start justify-start pt-[0.75rem] pb-[1.562rem] pl-[0.687rem] pr-[0.625rem] gap-[0.937rem] min-w-[16.063rem] max-w-[17.438rem] text-white mq450:flex-1">
                <div className="w-[17.438rem] h-[44.813rem] relative rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                  <div className="flex-1 rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] flex flex-row items-start justify-start py-[2.625rem] pl-[4.75rem] pr-[4.562rem] z-[1] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                    <div className="h-[8.188rem] w-[15.75rem] relative rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] hidden" />
                    <h1 className="m-0 relative text-inherit tracking-[0.1em] leading-[2.938rem] font-medium font-inherit inline-block min-w-[6.438rem] z-[2] mq450:text-[1.375rem] mq450:leading-[1.75rem] mq1050:text-[1.813rem] mq1050:leading-[2.375rem]">
                      LOVE
                    </h1>
                  </div>
                </div>
                <PlanPriceComponent />
              </div>
              <div className="flex-1 flex flex-col items-start justify-start pt-[0.125rem] px-[0rem] pb-[0rem] box-border min-w-[16.063rem] max-w-[17.438rem] text-white">
                <div className="self-stretch rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid flex flex-col items-start justify-start pt-[0.75rem] pb-[1.562rem] pl-[0.687rem] pr-[0.625rem] gap-[0.937rem]">
                  <div className="w-[17.438rem] h-[44.813rem] relative rounded-mid bg-white border-whitesmoke-500 border-[1px] border-solid box-border hidden" />
                  <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                    <div className="flex-1 rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] flex flex-row items-start justify-start py-[2.625rem] pl-[3.75rem] pr-[3.625rem] z-[1] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border">
                      <div className="h-[8.188rem] w-[15.75rem] relative rounded-lgi [background:linear-gradient(102.6deg,_#fff,_#4b0082)] hidden" />
                      <h1 className="m-0 relative text-inherit tracking-[0.1em] leading-[2.938rem] font-medium font-inherit z-[2] mq450:text-[1.375rem] mq450:leading-[1.75rem] mq1050:text-[1.813rem] mq1050:leading-[2.375rem]">
                        ENJOY
                      </h1>
                    </div>
                  </div>
                  <PlanPriceEnjoy
                    mo3="₹210/mo ($3)"
                    host3Website="Host 3 Website"
                    y7560210mo="3Y ₹7560 - 210/mo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default PlanDescriptionSSD;

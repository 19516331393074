import { FunctionComponent, memo } from "react";
import FrameComponent9 from "./FrameComponent9";
import GroupComponent11 from "./GroupComponent11";

export type FrameComponent71Type = {
  className?: string;
};

const FrameComponent71: FunctionComponent<FrameComponent71Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[1.487rem] pl-[1.5rem] pr-[1.25rem] box-border max-w-full ${className}`}
      >
        <div className="w-[66.813rem] rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[2.875rem] box-border gap-[1.937rem] max-w-full mq450:pb-[1.875rem] mq450:box-border mq750:gap-[0.938rem]">
          <div className="self-stretch h-[26.75rem] relative rounded-11xl [background:linear-gradient(180deg,_rgba(213,_223,_255,_0.4),_rgba(213,_223,_255,_0.04))] hidden" />
          <FrameComponent9 />
          <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.937rem] pr-[0.75rem] box-border max-w-full">
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.625rem] max-w-full">
              <GroupComponent11
                propPadding="1.562rem 0.875rem 1.562rem 1.687rem"
                cPX11="CCX12"
                aMD="2 Intel"
                gB="8 GB"
                gB1="80 GB"
                mo="$30/mo"
              />
              <GroupComponent11
                propPadding="1.562rem 0.875rem 1.562rem 1.875rem"
                cPX11="CCX22"
                aMD="4 AMD"
                gB="16 GB"
                gB1="160 GB"
                mo="$50/mo"
              />
              <GroupComponent11
                propPadding="1.562rem 0.875rem 1.562rem 1.75rem"
                cPX11="CCX32"
                aMD="8 AMD"
                gB="32 GB"
                gB1="240 GB"
                mo="$100/mo"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent71;

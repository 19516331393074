import { FunctionComponent, memo } from "react";

export type AboutUsContainerType = {
  className?: string;
};

const AboutUsContainer: FunctionComponent<AboutUsContainerType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[3.937rem] box-border max-w-full text-left text-[2.5rem] text-indigo-100 font-poppins mq800:pb-[2.563rem] mq800:box-border ${className}`}
      >
        <div className="flex-1 flex flex-row items-start justify-start relative max-w-full">
          <img
            className="h-[24.75rem] w-[33rem] absolute !m-[0] right-[-1.125rem] bottom-[2.5rem] object-contain"
            loading="lazy"
            alt=""
            src="/hostlastingslide2-1@2x.png"
          />
          <div className="flex-1 bg-ghostwhite-100 flex flex-col items-start justify-start pt-[3.812rem] px-[11.437rem] pb-[4.312rem] box-border gap-[0.937rem] max-w-full z-[1] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq800:pt-[2.5rem] mq800:px-[2.813rem] mq800:pb-[2.813rem] mq800:box-border mq1125:pl-[5.688rem] mq1125:pr-[5.688rem] mq1125:box-border">
            <div className="w-[89.438rem] h-[33.625rem] relative bg-ghostwhite-100 hidden max-w-full" />
            <h1 className="m-0 relative text-inherit tracking-[0.04em] leading-[3.313rem] font-bold font-inherit z-[2] mq450:text-[1.5rem] mq450:leading-[2rem] mq800:text-[2rem] mq800:leading-[2.625rem]">
              About Us
            </h1>
            <div className="w-[42.375rem] flex flex-row items-start justify-start relative max-w-full text-[0.938rem] text-slategray-100">
              <div className="flex-1 relative tracking-[0.04em] leading-[1.25rem] inline-block max-w-full z-[2]">
                <span>
                  Hostlasting is one of the best 20x LiteSpeed and affordable
                  web hosting companies that provides cheap web hosting plans,
                  unlimited SSD disk space, unlimited bandwidth using the cPanel
                  control panel, and free website backup (SAID 5 Data
                  redundancy). We provide the U.S., Canada, the U.K., Germany,
                  Singapore, France, and India with servers, 99.9% uptime, and
                  24x7 support to our valuable customers. We provide Domain
                  Names Services, SSD Linux Shared Hosting, Cheap Reseller
                  Hosting, Cheap VPS Hosting, Storage VPS Hosting, Windows VPS
                  Hosting, Dedicated Server Hosting, SSL Certificates, and all
                  the latest features that are beneficial for you to start a
                  business on the go-live to connect people from all over the
                  world by shifting it into global web. "Hostlasting" is a
                  legally registered brand underneath "
                </span>
                <a
                  className="text-blueviolet-200"
                  href="https://www.cloud19.in/"
                  target="_blank"
                >
                  <span className="[text-decoration:underline]">
                    Cloud19 Technologies
                  </span>
                </a>
                <span>
                  " and we’re leaders in the web hosting business. "Hostlasting"
                  aims to become the planet's Best Web Hosting Company with
                  assurance to provide 99.9% uptime with 20x LiteSpeed
                  technology, all-time accessible customer service through
                  chats, email, and call. Our web hosting technicians provide
                  you the assistance to choose the optimal hosting plan that's
                  acceptable for your industry. You can use more than 400 free
                  PHP scripts that empower you to install everything in
                  one-click like Drupal, Wordpress, phpBB Forum, Mambo, and
                  others.
                </span>
              </div>
              <div className="h-[27.5rem] w-[37.313rem] absolute !m-[0] top-[-7.312rem] right-[-35.125rem]">
                <img
                  className="absolute top-[7rem] left-[3.875rem] w-[27.375rem] h-[20.5rem] object-contain z-[2]"
                  alt=""
                  src="/hostlastingslide2-11@2x.png"
                />
                <img
                  className="absolute top-[0rem] left-[0rem] w-[37.313rem] h-[24.875rem] object-cover z-[3]"
                  alt=""
                  src="/vps-1@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default AboutUsContainer;

import { FunctionComponent, memo } from "react";
import CpanelNecessity from "./CpanelNecessity";
import QuestionList from "./QuestionList";

export type FAQType = {
  className?: string;
};

const FAQ: FunctionComponent<FAQType> = memo(({ className = "" }) => {
  return (
    <section
      className={`w-[88.125rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[2.875rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins lg:pb-[1.875rem] lg:box-border mq1050:pb-[1.25rem] mq1050:box-border ${className}`}
    >
      <div className="w-[67rem] flex flex-col items-start justify-start gap-[2.312rem] max-w-full mq750:gap-[1.125rem]">
        <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[3.187rem] pr-[1.25rem] mq450:pl-[1.25rem] mq450:box-border">
          <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
            FREQUENTLY ASKED QUESTIONS
          </h2>
        </div>
        <div className="self-stretch flex flex-col items-end justify-start gap-[0.125rem] max-w-full text-[0.875rem] text-slategray-100">
          <CpanelNecessity
            isItNecessaryToAddCPanelW="1.Is it necessary to add cPanel with the VPS Hosting?"
            secondQuestionMark="/vector-335.svg"
          />
          <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.562rem] box-border max-w-full">
            <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[1.437rem] pb-[1.312rem] pl-[4.687rem] pr-[4.562rem] box-border max-w-full mq1050:pl-[2.313rem] mq1050:pr-[2.25rem] mq1050:box-border">
              <div className="h-[5.938rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
              <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                Yes, it is. It allows you to have a more control over your
                websites and web applications along with ease in management
                through an easy and uncomplicated interface. Some of the
                providers will promise you to offer cheap VPS hosting with free
                cPanel, but you need to make the decision based on your needs
                and conscience.
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.562rem] box-border gap-[0.125rem] max-w-full">
            <CpanelNecessity
              isItNecessaryToAddCPanelW="2.How cPanel addition on a VPS server will be beneficial?"
              secondQuestionMark="/vector-335.svg"
            />
            <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[3.437rem] px-[4.312rem] box-border min-h-[29.313rem] max-w-full mq450:pt-[2.25rem] mq450:pb-[2.25rem] mq450:box-border mq1050:pl-[2.125rem] mq1050:pr-[2.125rem] mq1050:box-border">
              <div className="h-[29.313rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
              <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                <p className="m-0">
                  Apart from performing administrative tasks with ease and
                  gaining control over the entire fleet of websites and web
                  applications, following are the ways in which cPanel addition
                  will be beneficial:
                </p>
                <p className="m-0">Ease in management of resources:</p>
                <p className="m-0">
                  Effective allocation and management of resources will be an
                  influential factor in seamless operation of website and web
                  applications. With cPanel, you can manage the memory space and
                  reduce downtime. This, in turn, will result in smooth
                  functioning.
                </p>
                <p className="m-0">Backups in place:</p>
                <p className="m-0">
                  Taking the importance of data safety into consideration, the
                  backup services are essential. In case of server crashes and
                  other unanticipated situations, the regular backup would help
                  in restoring the files and functionality.
                </p>
                <p className="m-0">Excellent security:</p>
                <p className="m-0">
                  With the SSL certificate and multi-layer protection offered by
                  cPanel VPS hosting solutions, the data will be secure. The
                  encryption will keep the sophisticated threats by cyber
                  criminals away and ensure data security. Hostlasting employs
                  technologically advanced solutions to ensure security of the
                  data and websites.
                </p>
                <p className="m-0">Access to the root:</p>
                <p className="m-0">
                  As you again the access to the root, you can implement many
                  processes faster than before. The need to wait for hosting
                  provider to respond will be eliminated. You can add new email
                  accounts, domains, sub-domains, and others along with updating
                  the software. You will have an entire control and operate
                  independently.
                </p>
                <p className="m-0">Affordable hosting:</p>
                <p className="m-0">
                  Your pockets will not be burdened more than the nominal
                  charges of three to four pizzas. Some of the VPS servers
                  include the cost of cPanel in the monthly package of the VPS.
                  However, you can avail the cPanel separately.
                </p>
              </div>
            </div>
          </div>
          <QuestionList
            doesHostlastingOfferAffor="3.Does Hostlasting offer affordable cPanel VPS hosting?"
            absolutelyOurFlexiblePlan="Absolutely. Our flexible plans are the proof. We understand your budgetary constraints and devised our plans accordingly. "
            ourPlansEnsureThatWeOffer="Our plans ensure that we offer the best possible services at the cheapest possible prices. So, "
            ourHostingSolutionsAreThe="our hosting solutions are the best fit for you."
          />
          <div className="self-stretch flex flex-row items-start justify-end pt-[0rem] pb-[0.562rem] pl-[0.062rem] pr-[0.312rem] box-border max-w-full">
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
              <CpanelNecessity
                isItNecessaryToAddCPanelW="4.Why should I avail cPanel VPS hosting services from Hostlasting?"
                secondQuestionMark="/vector-337.svg"
              />
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
                <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-end py-[1.875rem] px-[0.062rem] box-border min-h-[21.125rem] max-w-full">
                  <div className="h-[21.125rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="w-[61.875rem] relative tracking-[0.04em] leading-[1.063rem] flex items-center shrink-0 max-w-full z-[1]">
                    <span className="w-full">
                      <p className="m-0">
                        Hostlasting is instrumental in providing reliable,
                        robust, and best cPanel VPS hosting services since its
                        foundation.  Our team of skilled professionals and
                        experts always strive to provide the highest possible
                        service to customers with  competence, sincerity, and
                        dedication. Besides these factors, we provide
                        easy-to-use user interface, advanced SSD drives for fast
                         processing, and other technical benefits to ensure
                        efficient operation of servers, websites and web
                        applications. With our tools  and automation solutions,
                        you can manage the entire fleet of resources
                        efficiently. Along with these vital features, we also
                        offer: 
                      </p>
                      <ul className="m-0 font-inherit text-inherit pl-[1.357rem]">
                        <li className="my-[undefined] mx-[undefined]">
                          Free SSL Security for your server
                        </li>
                        <li className="my-[undefined] mx-[undefined]">
                          Affordable cPanel license
                        </li>
                        <li className="my-[undefined] mx-[undefined]">
                          Instant installation of cPanel on your server
                        </li>
                        <li className="my-[undefined] mx-[undefined]">
                          Unlimited storage capabilties
                        </li>
                        <li className="my-[undefined] mx-[undefined]">
                          Around the clock support
                        </li>
                        <li className="my-[undefined] mx-[undefined]">
                          Assurance of 99.99% uptime of your server
                        </li>
                        <li>Lightning fast loading speed</li>
                      </ul>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <QuestionList
            propPadding="0rem 0.25rem 0.562rem 0.125rem"
            doesHostlastingOfferAffor="5.What is the cost of cPanel VPS license?"
            absolutelyOurFlexiblePlan="Hostlasting provides three types of plans along with technical configuration. These plans include VPS-L2, VPS-L4 and VPS-L8. "
            ourPlansEnsureThatWeOffer="All of these plans include cPanel VPS Hosting License, firewall, and malware protection. The charges of the license are same"
            ourHostingSolutionsAreThe="for all the plans. However, the cost of each plan varies with the other configuration parameters."
          />
          <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0rem] pr-[0.375rem] box-border max-w-full">
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
              <CpanelNecessity
                isItNecessaryToAddCPanelW="6.What differentiates the managed VPS from the unmanaged VPS"
                secondQuestionMark="/vector-337.svg"
              />
              <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full">
                <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-end pt-[1.875rem] px-[0.625rem] pb-[1.812rem] box-border max-w-full">
                  <div className="h-[7.938rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="relative tracking-[0.04em] leading-[1.063rem] z-[1]">
                    <p className="m-0">{`With the managed VPS hosting, the provider will carry out server configuration, software update, operating system installation, `}</p>
                    <p className="m-0">{`and other technical tasks. You do not need to possess the technical knowledge. So, you can focus more on the core tasks of your `}</p>
                    <p className="m-0">{`business rather than focusing on server management. With the unmanaged VPS hosting, you need to avail technical knowledge of `}</p>
                    <p className="m-0">
                      the operating system, software, and other aspects to
                      manage the server by yourself.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default FAQ;

import { FunctionComponent, memo } from "react";

export type GroupComponent15Type = {
  className?: string;
  sERVERS?: string;
  coreCPU?: string;
  gB?: string;
  gBSSDSpace?: string;
  gB1?: string;
  mo?: string;
};

const GroupComponent15: FunctionComponent<GroupComponent15Type> = memo(
  ({ className = "", sERVERS, coreCPU, gB, gBSSDSpace, gB1, mo }) => {
    return (
      <div
        className={`self-stretch shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white overflow-x-auto flex flex-row items-end justify-between py-[1.187rem] pl-[1.562rem] pr-[1.812rem] gap-[1.25rem] z-[1] text-center text-[0.813rem] text-slategray-100 font-poppins ${className}`}
      >
        <div className="h-[5.313rem] w-[65.125rem] relative shadow-[0px_4px_8.6px_rgba(0,_0,_0,_0.05)] rounded-xl bg-white shrink-0 hidden" />
        <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.25rem] pl-[0rem] pr-[0.687rem]">
          <div className="flex flex-col items-start justify-start">
            <div className="flex flex-row items-start justify-start py-[0rem] px-[1.437rem]">
              <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[3.688rem] shrink-0 z-[1]">
                {sERVERS}
              </div>
            </div>
            <div className="h-[1.188rem] flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0rem] box-border">
              <div className="mt-[-0.063rem] relative leading-[1.25rem] font-medium inline-block min-w-[6.563rem] shrink-0 z-[1]">
                Full Root Access
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.437rem]">
          <div className="relative leading-[2.938rem] font-semibold inline-block min-w-[4.625rem] z-[1]">
            {coreCPU}
          </div>
        </div>
        <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.812rem] pl-[0rem] pr-[0.937rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[1.875rem] z-[1]">
            {gB}
          </div>
        </div>
        <div className="flex flex-col items-start justify-end pt-[0rem] pb-[0.812rem] pl-[0rem] pr-[0.562rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[7.438rem] z-[1]">
            {gBSSDSpace}
          </div>
        </div>
        <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.875rem]">
          <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[2.938rem] z-[1]">
            {gB1}
          </div>
        </div>
        <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.375rem] text-[0.938rem]">
          <div className="flex flex-row items-end justify-start gap-[1.812rem]">
            <div className="flex flex-col items-start justify-end pt-[0rem] px-[0rem] pb-[0.437rem]">
              <div className="relative leading-[1.25rem] font-semibold inline-block min-w-[5.25rem] z-[1]">
                {mo}
              </div>
            </div>
            <div className="rounded-xl bg-goldenrod flex flex-row items-start justify-start pt-[0.5rem] px-[1.937rem] pb-[0.437rem] z-[1] text-[0.875rem] text-white">
              <div className="h-[2.188rem] w-[6.375rem] relative rounded-xl bg-goldenrod hidden" />
              <a href="#" className="[text-decoration:none] relative leading-[1.25rem] font-medium text-white inline-block min-w-[2.5rem] z-[1]">
                Order
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default GroupComponent15;

import { FunctionComponent, memo } from "react";
import HeroSection3 from "./HeroSection3";
import Body from "./Body";

export type HeaderHerocPanelSectionType = {
  className?: string;
};

const HeaderHerocPanelSection: FunctionComponent<HeaderHerocPanelSectionType> =
  memo(({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.25rem] box-border max-w-full text-left text-[1rem] text-white font-poppins ${className}`}
      >
        <div className="flex-1 [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1rem] box-border gap-[5.875rem] max-w-full mq450:gap-[1.438rem] mq750:gap-[2.938rem]">
          <HeroSection3 />
          <Body />
        </div>
      </section>
    );
  });

export default HeaderHerocPanelSection;

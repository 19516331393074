import { FunctionComponent, memo } from "react";
import FrameComponent42 from "./FrameComponent42";
import FrameComponent32 from "./FrameComponent32";
import FrameComponent23 from "./FrameComponent23";

export type FrameComponent18Type = {
  className?: string;
};

const FrameComponent18: FunctionComponent<FrameComponent18Type> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[3rem] pl-[1.375rem] pr-[1.25rem] box-border max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins mq1150:pb-[1.25rem] mq1150:box-border mq1350:pb-[1.938rem] mq1350:box-border ${className}`}
      >
        <div className="w-[66.688rem] flex flex-col items-start justify-start gap-[2.562rem] max-w-full mq800:gap-[1.25rem]">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[2.375rem] pr-[1.25rem]">
            <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit mq450:text-[1.188rem] mq450:leading-[2.313rem]">
              FREQUENTLY ASKED QUESTIONS
            </h2>
          </div>
          <div className="self-stretch flex flex-col items-end justify-start gap-[0.125rem] max-w-full text-[0.875rem] text-black">
            <FrameComponent42
              whatIsTheUsualUsageLandsc="1.What is the usual usage landscape for storage VPS?"
              vector335="/vector-335.svg"
            />
            <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.562rem] box-border max-w-full text-slategray-100">
              <div className="flex-1 rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start py-[1.5rem] pl-[4.875rem] pr-[4.375rem] box-border min-h-[8.375rem] max-w-full mq1150:pl-[2.438rem] mq1150:pr-[2.188rem] mq1150:box-border">
                <div className="h-[8.375rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                  Our affordable and best-in-class cloud storage VPS servers
                  have an ability to store huge amount of data generated through
                  your websites and web applications. Moreover, these storage
                  servers will help you in ensuring backup of your data and
                  archives. Our storage VPS hosting servers are compatible with
                  different applications, operating systems, and development
                  scenarios. You can avail root privileges along with different
                  features without any additional cost.
                </div>
              </div>
            </div>
            <FrameComponent32
              whatKindOfHypervisorIsUse="2.What kind of hypervisor is used in storage VPS?"
              hostlastingUtilizesARelia="Hostlasting utilizes a reliable, safe, and advanced OpenVZ virtualization server. The OpenVZ virtualization is a hypervisor technology that is utilized at the operating system level. This hypervisor offers ease in scalability and high performance. Moreover, it is reliable and compatible with leading operating systems."
            />
            <FrameComponent32
              whatKindOfHypervisorIsUse="3.What are the use case scenarios in which storage VPS is utilized?"
              hostlastingUtilizesARelia="Storage VPS hosting is used when the required for storage of website data is high and the most reliable performance is required day in and day out. With implementation of OpenVZ virtualization server, Hostlasting provides cheap storage VPS hosting services."
            />
            <FrameComponent23
              whatAreTheVersionsOfLinux="4.What are the versions of Linux Kernel available at Hostlasting?"
              vector342="/vector-335.svg"
              linuxKernelIsTheMostVital="Linux Kernel is the most vital component of the Linux operating system. It plays a crucial role in management, operation, and security of resources. Our storage VPS operates on the most reliable and advanced 2.6.32.x Linux Kernel version. If you need more advanced version of the Linux Kernel, please feel free to ask. We will help you in upgrading the existing version."
            />
            <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.562rem] box-border gap-[0.125rem] max-w-full text-slategray-100">
              <FrameComponent42
                propPadding="0.5rem 3.75rem 0.562rem 4.687rem"
                whatIsTheUsualUsageLandsc="5. Which operating systems (OS) are provided by Hostlasting with storage VPS hosting services?"
                vector335="/vector-337.svg"
              />
              <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-col items-start justify-start pt-[2.125rem] pb-[6.375rem] pl-[4.687rem] pr-[4.437rem] box-border gap-[0.562rem] max-w-full mq1150:pl-[2.313rem] mq1150:pr-[2.188rem] mq1150:box-border">
                <div className="w-[66.563rem] h-[20.75rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                <div className="self-stretch relative tracking-[0.04em] leading-[1.063rem] z-[1]">
                  Hostlasting provides four operating systems such as CentOS,
                  Fedora, Ubuntu, and Debian with storage VPS hosting services
                  up to 2TB.
                </div>
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[0.125rem] pr-[0rem] box-border max-w-full">
                  <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                    <p className="m-0">
                      CentOS – CentOS is a short-form of Community Enterprise
                      Operating System. This is a Linux distribution system that
                      is created by Red Hat Enterprise Linux (RHEL). This
                      manageable and stable operating system is utilized majorly
                      by enterprises, and there is a huge community base to for
                      supporting the system. 
                    </p>
                    <p className="m-0">
                      Fedora – This operating system is developed by the
                      community called as Fedora Project. This Linux
                      distribution system is sponsored by Red Hat. It is
                      open-source, free, and majorly used for learning and
                      studying. 
                    </p>
                    <p className="m-0">
                      Ubuntu – Ubuntu is another free, open-source, and popular
                      Linux distribution system. This OS is supported by
                      enterprises and professionals. It proves to be one of the
                      best options for the cloud-based environment. 
                    </p>
                    <p className="m-0">
                      Debian – Debian is a UNIX-based operating system and
                      popular among personal computers users and network
                      servers. Moreover, basic utilities and programs help the
                      operating system run on the laptops and computers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-end pt-[0rem] pb-[0.562rem] pl-[0rem] pr-[0.125rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.125rem] max-w-full">
                <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-between py-[0.875rem] pl-[4.687rem] pr-[3.812rem] box-border max-w-full gap-[1.25rem] mq1150:pl-[2.313rem] mq1150:pr-[1.875rem] mq1150:box-border">
                  <div className="h-[4rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="relative tracking-[0.04em] leading-[1.125rem] font-medium z-[1]">
                    <p className="m-0">{`6.Does Hostlasting offer support for IPv4 addresses? If yes, then how many addresses can be added? `}</p>
                    <p className="m-0">Do you also provide IPv6 support?</p>
                  </div>
                  <div className="flex flex-col items-start justify-start pt-[0.875rem] px-[0rem] pb-[0rem]">
                    <img
                      className="w-[0.75rem] h-[0.375rem] relative z-[1]"
                      loading="lazy"
                      alt=""
                      src="/vector-337.svg"
                    />
                  </div>
                </div>
                <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[2.75rem] px-[4.625rem] pb-[2.812rem] box-border max-w-full text-slategray-100 mq1150:pl-[2.313rem] mq1150:pr-[2.313rem] mq1150:box-border">
                  <div className="h-[9.813rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                  <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                    Yes, we provide support for IPv4 addresses. Through our
                    virtual machines, our clients can avail a single IPv4
                    address. In case, you need more IPv4 addresses, you can
                    avail them through add-ons after the server deployment. In
                    addition, maximum of 31 addresses can be added in a single
                    instance. We also offer support for a single IPv6 address.
                    Moreover, you can add more addresses if the business arises.
                  </div>
                </div>
              </div>
            </div>
            <FrameComponent23
              whatAreTheVersionsOfLinux="7.Where are Hostlasting’s storage VPS hosting servers located?"
              vector342="/vector-337.svg"
              linuxKernelIsTheMostVital="Our high-quality and cost-efficient storage VPS hosting servers are located in Europe. We chose Tier III datacenter in Lithuania for locating the servers. The location is selected by taking uptime and high performance considerations into account. The datacenter also contains a cooling infrastructure along with stringent security measures for ensuring 99.99% uptime and data safety. Our servers would offer the best services for any locations around the world including India, the U.S., Netherlands, Australia, the U.K., Romania, and others."
            />
            <div className="self-stretch flex flex-col items-start justify-start gap-[0.125rem] max-w-full text-slategray-100">
              <FrameComponent42
                propPadding="1.437rem 3.812rem 1.437rem 4.687rem"
                whatIsTheUsualUsageLandsc="8.What if I run out of bandwidth with my current plan?"
                vector335="/vector-337.svg"
              />
              <div className="self-stretch rounded-sm bg-whitesmoke-300 flex flex-row items-start justify-start pt-[2.75rem] px-[4.625rem] pb-[2.812rem] box-border max-w-full mq1150:pl-[2.313rem] mq1150:pr-[2.313rem] mq1150:box-border">
                <div className="h-[9.813rem] w-[66.563rem] relative rounded-sm bg-whitesmoke-300 hidden max-w-full" />
                <div className="flex-1 relative tracking-[0.04em] leading-[1.063rem] inline-block max-w-full z-[1]">
                  <p className="m-0">
                    Our VPS 1TB storage servers utilize the allocated bandwidth
                    until for end of the month. In case the bandwidth you
                    availed as the current plan is over, we will lower down the
                    server port speed by nearly 10 times until the next month
                    cycle begins.
                  </p>
                  <p className="m-0">
                    You don’t need to worry about the extra charges. We will not
                    charge you any extra fees or suspend your services. You can
                    always avail the upgraded plan and gain VPS 2TB storage
                    capacity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default FrameComponent18;

import { FunctionComponent, memo } from "react";
import HeaderKVM from "./HeaderKVM";

export type HeaderHeroBannerSectionType = {
  className?: string;
};

const HeaderHeroBannerSection: FunctionComponent<HeaderHeroBannerSectionType> =
  memo(({ className = "" }) => {
    return (
      <div className={`self-stretch h-[37.5rem] relative ${className}`}>
        <div className="absolute top-[32.25rem] left-[32.563rem] [filter:blur(26.2px)] rounded-[50%] bg-gray-800 w-[14.25rem] h-[1rem]" />
        <img
          className="absolute top-[7.125rem] left-[24.5rem] w-[40.5rem] h-[30.375rem] object-contain z-[1]"
          loading="lazy"
          alt=""
          src="/3donemessageemailhasopenedgraphicillustrationiconpinkyellowbluea-1@2x.png"
        />
        <HeaderKVM />
      </div>
    );
  });

export default HeaderHeroBannerSection;

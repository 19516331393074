import { FunctionComponent, memo } from "react";
import FrameComponent110 from "./FrameComponent110";

export type DedicatedServerSpecificType = {
  className?: string;
};

const DedicatedServerSpecific: FunctionComponent<DedicatedServerSpecificType> =
  memo(({ className = "" }) => {
    return (
      <section
        className={`self-stretch bg-ghostwhite-100 flex flex-col items-start justify-start pt-[3.312rem] px-[11.437rem] pb-[6.687rem] box-border gap-[3.437rem] max-w-full text-left text-[1.5rem] text-darkslategray-100 font-poppins mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq800:gap-[1.688rem] mq800:pt-[2.125rem] mq800:px-[5.688rem] mq800:pb-[4.375rem] mq800:box-border ${className}`}
      >
        <div className="w-[89.438rem] h-[39.063rem] relative bg-ghostwhite-100 hidden max-w-full" />
        <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[0.062rem] pr-[0rem]">
          <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit z-[1] mq450:text-[1.188rem] mq450:leading-[2.313rem]">
            DEDICATED SERVER TECHNICAL SPECIFICATIONS
          </h2>
        </div>
        <div className="w-[62.438rem] flex flex-col items-end justify-start gap-[2.875rem] max-w-full text-[1rem] text-indigo-100 mq800:gap-[1.438rem]">
          <div className="self-stretch flex flex-col items-end justify-start gap-[2.562rem] max-w-full mq800:gap-[1.25rem]">
            <div className="self-stretch flex flex-row items-start justify-between gap-[1.25rem] mq1350:flex-wrap">
              <FrameComponent110
                dEDICATEDFULLROOTACCESS="DEDICATED FULL ROOT ACCESS:"
                betterHighControlResource="Better High Control Resources"
                increasedEfficiency="Increased Efficiency"
                getMaximumFlexibility="Get Maximum Flexibility"
              />
              <FrameComponent110
                propMinWidth="unset"
                dEDICATEDFULLROOTACCESS="PRIOR RAPID SUPPORT AND SERVICES:"
                betterHighControlResource="Allow to Manage 24/7"
                increasedEfficiency="99.9% Uptime Guarantee"
                getMaximumFlexibility="Cost Effective Approach"
              />
              <FrameComponent110
                propMinWidth="unset"
                dEDICATEDFULLROOTACCESS="BEST DEVELOPMENT SOFTWARES:"
                betterHighControlResource="On Demand – cPanel, Plesk, PHP etc"
                increasedEfficiency="Easily Deployment Services"
                getMaximumFlexibility="Highly Scalable Operations"
              />
            </div>
            <div className="w-[58.25rem] h-[0.063rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
              <div className="self-stretch w-[39.063rem] relative border-indigo-100 border-t-[1px] border-solid box-border max-w-full z-[1]" />
            </div>
          </div>
          <div className="w-[58.188rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full text-[0.75rem] text-slategray-100">
            <div className="w-[37.313rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq800:flex-wrap">
              <div className="flex flex-col items-start justify-start gap-[0.687rem] min-w-[14.813rem] mq800:flex-1">
                <h3 className="relative text-[1rem] leading-[2.875rem] font-semibold text-indigo-100 z-[1]">
                  STRONG SECURITY PLATFORM:
                </h3>
                <div className="flex flex-col items-start justify-start gap-[0.625rem] text-center">
                  <div className="flex flex-row items-start justify-start gap-[0.625rem]">
                    <div className="flex flex-col items-start justify-start pt-[0.312rem] px-[0rem] pb-[0rem]">
                      <img
                        className="w-[0.875rem] h-[0.625rem] relative z-[1]"
                        alt=""
                        src="/vector1.svg"
                      />
                    </div>
                    <div className="relative leading-[1.25rem] z-[1]">
                      Robust and Safe Infrastructure
                    </div>
                  </div>
                  <div className="flex flex-row items-start justify-start gap-[0.625rem]">
                    <div className="flex flex-col items-start justify-start pt-[0.312rem] px-[0rem] pb-[0rem]">
                      <img
                        className="w-[0.875rem] h-[0.625rem] relative z-[1]"
                        alt=""
                        src="/vector1.svg"
                      />
                    </div>
                    <div className="relative leading-[1.25rem] z-[1]">
                      Firewall and data security system
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-start justify-start gap-[0.75rem]">
                  <div className="flex flex-col items-start justify-start pt-[0.312rem] px-[0rem] pb-[0rem]">
                    <img
                      className="w-[0.875rem] h-[0.625rem] relative z-[1]"
                      alt=""
                      src="/vector1.svg"
                    />
                  </div>
                  <div className="relative leading-[1.25rem] z-[1]">
                    Secure from malware attacks
                  </div>
                </div>
              </div>
              <FrameComponent110
                propMinWidth="15.625rem"
                dEDICATEDFULLROOTACCESS="DISASTER RECOVERY SOLUTION:"
                betterHighControlResource="Immediate BackUp Data Review"
                increasedEfficiency="Less time and Quick Recovery"
                getMaximumFlexibility="Safe Procedure and management"
              />
            </div>
          </div>
        </div>
      </section>
    );
  });

export default DedicatedServerSpecific;

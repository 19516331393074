import { FunctionComponent } from "react";
import HeaderHeroCheapSSL from "../components/HeaderHeroCheapSSL";
import SSLItems from "../components/SSLItems";
import SSLDetailsListOptions from "../components/SSLDetailsListOptions";
import SSLImageItemsGrid from "../components/SSLImageItemsGrid";
import FaqSSL from "../components/FaqSSL";
import FooterComponent from "../components/FooterComponent";

const SSLCertificate: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-end justify-start gap-[2.5rem] leading-[normal] tracking-[normal] text-left text-[1.25rem] text-white font-poppins mq750:gap-[1.25rem]">
      <HeaderHeroCheapSSL />
      <div className="w-[0.188rem] h-[9.938rem] relative border-white border-r-[3px] border-solid box-border hidden" />
      <SSLItems />
      <SSLDetailsListOptions />
      <SSLImageItemsGrid />
      <FaqSSL />
      <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[5.5rem]">
        <div className="rounded-3xs bg-indigo-100 flex flex-row items-start justify-start py-[0.25rem] px-[3.437rem] gap-[0.5rem]">
          <div className="h-[3.375rem] w-[14.438rem] relative rounded-3xs bg-indigo-100 hidden" />
          <div className="flex flex-col items-start justify-start pt-[0.437rem] px-[0rem] pb-[0rem]">
            <img
              className="w-[2rem] h-[2rem] relative overflow-hidden shrink-0 z-[1]"
              alt=""
              src="/fluentpersonsupport16filled.svg"
            />
          </div>
          <div className="relative leading-[2.875rem] font-medium inline-block min-w-[5.063rem] z-[1] mq450:text-[1rem] mq450:leading-[2.313rem]">
            Support
          </div>
        </div>
      </div>
      <FooterComponent
        hOSTINGMargin="0"
        dOMAINSSSLMargin="0"
        cOMPANYMargin="0"
        wordPressHostingTextDecoration="unset"
        aboutUsTextDecoration="unset"
        cheapSSDHostingTextDecoration="unset"
        supportTextDecoration="unset"
        vPSHostingTextDecoration="unset"
        blogTextDecoration="unset"
        dedicatedHostingTextDecoration="unset"
        offersTextDecoration="unset"
        contactUsTextDecoration="unset"
        privacyPolicyTextDecoration="unset"
        sitemapTextDecoration="unset"
      />
    </div>
  );
};

export default SSLCertificate;

import { FunctionComponent, memo } from "react";

export type BenefitsType = {
  className?: string;
};

const Benefits: FunctionComponent<BenefitsType> = memo(({ className = "" }) => {
  return (
    <div
      className={`flex-1 flex flex-col items-start justify-start gap-[4.937rem] min-w-[19.063rem] max-w-full text-left text-[1.25rem] text-indigo-100 font-poppins mq750:gap-[2.438rem] ${className}`}
    >
      <div className="w-[27.063rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[1.062rem] box-border max-w-full">
        <div className="h-[9.188rem] w-[9.188rem] relative bg-[url('/public/iconbusinessv3-9-a-3@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
          <img
            className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden z-[1]"
            alt=""
            src="/iconbusinessv3-9-a-3@2x.png"
          />
          <div className="absolute top-[8.188rem] left-[2.25rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[2]" />
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1.437rem] gap-[1.125rem]">
        <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
          <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
            Around the clock support:
          </h3>
        </div>
        <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
          Our skilled and competent team of professionals is available to
          address your concerns around the clock. The customer-first approach is
          at the core of our business and we make sure your issues are solved as
          soon as possible.
        </div>
      </div>
      <div className="w-[26.688rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[3rem] box-border max-w-full">
        <div className="w-[8.813rem] flex flex-col items-start justify-start gap-[0.875rem]">
          <img
            className="w-[8.813rem] h-[8.813rem] relative object-cover z-[1]"
            loading="lazy"
            alt=""
            src="/2-weba-5@2x.png"
          />
          <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[2.062rem]">
            <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[0.5rem] pl-[0.125rem] pr-[0rem] box-border max-w-full">
        <div className="flex-1 flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
          <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
            <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
              Multi-layered protection:
            </h3>
          </div>
          <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
            We understand how valuable the data security is. With cyber-threats
            getting more sophisticated and advanced than before, we employ
            multi-layered security through a combination of firewall
            configuration and malware protection.
          </div>
        </div>
      </div>
      <div className="w-[27.125rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
        <div className="w-[12rem] flex flex-col items-start justify-start">
          <img
            className="w-[12rem] h-[12rem] relative object-cover z-[1]"
            loading="lazy"
            alt=""
            src="/11028375137d4410b75bbcabe44afcd5-1@2x.png"
          />
          <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.437rem] pr-[3.875rem]">
            <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[2]" />
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[2.375rem] gap-[1.125rem]">
        <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
          <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
            Licensed cPanel/WHM:
          </h3>
        </div>
        <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
          With availability of licensed cPanel/WHM with all of our plans, you
          will avail an entire access to the control dashboard to manage
          functionality and features over the server. In addition, users will be
          able to perform tasks faster than before.
        </div>
      </div>
      <div className="w-[27.063rem] h-[10.313rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[0.625rem] box-border max-w-full">
        <div className="self-stretch w-[11.438rem] flex flex-col items-start justify-start gap-[1.687rem]">
          <img
            className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover z-[1]"
            loading="lazy"
            alt=""
            src="/9594680-1@2x.png"
          />
          <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[3.187rem] pr-[3.562rem]">
            <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem] max-w-full">
        <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] box-border max-w-full">
          <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
            Regular backups to ensure data safety:
          </h3>
        </div>
        <div className="self-stretch h-[5.688rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0 z-[1]">
          With thoughtful and extensive configuration, we ensure there will be
          regular updates and backup of your data on the servers. Our plans
          include backup services without any extra charges.
        </div>
      </div>
    </div>
  );
});

export default Benefits;

import { FunctionComponent, memo } from "react";
import HeaderKVM from "./HeaderKVM";

export type HeaderHeroKvmWindowType = {
  className?: string;
};

const HeaderHeroKvmWindow: FunctionComponent<HeaderHeroKvmWindowType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch [background:linear-gradient(107.3deg,_#b79fc7,_#43006e_83.56%)] overflow-hidden flex flex-col items-start justify-start pt-[15.375rem] px-[11.312rem] pb-[1rem] box-border relative gap-[6.375rem] max-w-full text-center text-[1rem] text-white font-poppins mq450:gap-[1.563rem] mq450:pl-[1.25rem] mq450:pt-[6.5rem] mq450:pr-[1.25rem] mq450:box-border mq750:gap-[3.188rem] mq750:pl-[5.625rem] mq750:pr-[5.625rem] mq750:box-border mq1050:pt-[10rem] mq1050:pb-[1.25rem] mq1050:box-border ${className}`}
      >
        <div className="w-[1.5rem] h-[1.5rem] relative overflow-hidden shrink-0 hidden z-[0]" />
        <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[4.562rem] pl-[1.812rem] pr-[1.75rem] box-border max-w-full text-left">
          <div className="flex-1 flex flex-col items-start justify-start gap-[1.937rem] max-w-full mq750:gap-[0.938rem]">
            <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
              <div className="w-[40.5rem] flex flex-col items-start justify-start max-w-full">
                <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[1.312rem] pr-[1.25rem]">
                  <div className="relative tracking-[0.11em] leading-[3.125rem] font-semibold z-[1]">
                    Unmanaged Windows VPS Hosting
                  </div>
                </div>
                <h1 className="m-0 relative text-[3rem] leading-[3.125rem] font-semibold font-inherit z-[1] mq450:text-[1.813rem] mq450:leading-[1.875rem] mq1050:text-[2.375rem] mq1050:leading-[2.5rem]">
                  KVM Windows VPS Hosting
                </h1>
              </div>
            </div>
            <div className="relative text-[0.875rem] leading-[1.25rem] font-medium text-lightgray-100 text-center z-[1]">
              <p className="m-0">{`Windows-based VPS hosting with KVM virtualization is a powerful solution that offer flexibility, reliability, and control for your business. `}</p>
              <p className="m-0">
                Hostlasting provides a set of hosting solutions for enabling you
                with required convenience and speed for running your websites
                and businesses.
              </p>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[0.062rem] pr-[0rem] box-border max-w-full text-[0.813rem] text-lightgray-100">
          <div className="w-[36rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq750:flex-wrap">
            <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[0.812rem]">
              <div className="relative leading-[1.25rem] font-medium inline-block min-w-[5.188rem] z-[1]">
                Dedicated IP
              </div>
            </div>
            <div className="w-[8.563rem] flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.312rem] box-border">
              <div className="self-stretch relative leading-[1.25rem] font-medium z-[1]">
                <p className="m-0">{`No cost Windows `}</p>
                <p className="m-0">Server License</p>
              </div>
            </div>
            <div className="relative leading-[1.25rem] font-medium inline-block min-w-[6.188rem] z-[1]">
              SSD Disk Drives
            </div>
            <div className="relative leading-[1.25rem] font-medium inline-block min-w-[6.438rem] z-[1]">
              4 GBPS Network
            </div>
          </div>
        </div>
        <div className="w-full h-[45.5rem] absolute !m-[0] top-[0rem] right-[0rem] left-[0rem]">
          <img
            className="absolute top-[21.375rem] left-[3.813rem] w-[24.125rem] h-[24.125rem] object-contain"
            loading="lazy"
            alt=""
            src="/1f040ac6a0f147c5b64276671e902797a-1@2x.png"
          />
          <img
            className="absolute top-[5.563rem] left-[64.063rem] w-[24.125rem] h-[24.125rem] object-contain"
            loading="lazy"
            alt=""
            src="/1f040ac6a0f147c5b64276671e902797a-1@2x.png"
          />
          <HeaderKVM />
          <img
            className="absolute top-[33.813rem] left-[37.938rem] w-[2.25rem] h-[2.25rem] z-[1]"
            loading="lazy"
            alt=""
            src="/vector.svg"
          />
          <img
            className="absolute top-[33.813rem] left-[28.188rem] w-[2.25rem] h-[2.25rem] overflow-hidden z-[1]"
            loading="lazy"
            alt=""
            src="/mdiipoutline.svg"
          />
          <img
            className="absolute top-[33.813rem] left-[49.125rem] w-[2.25rem] h-[2.25rem] overflow-hidden z-[1]"
            loading="lazy"
            alt=""
            src="/riusersharedline.svg"
          />
          <img
            className="absolute top-[33.813rem] left-[58.438rem] w-[2.25rem] h-[2.25rem] overflow-hidden z-[1]"
            loading="lazy"
            alt=""
            src="/eosiconsip.svg"
          />
          <div className="absolute top-[28.125rem] left-[29.25rem] rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none [background:linear-gradient(89.99deg,_#6e3b8f,_#fff)] w-[31rem] h-[0.438rem] z-[1]" />
        </div>
        <div className="flex flex-row items-start justify-start gap-[0.543rem]">
          <div className="flex flex-col items-start justify-start pt-[1rem] px-[0rem] pb-[0rem]">
            <img
              className="w-[1.5rem] h-[1.5rem] relative overflow-hidden shrink-0 z-[1]"
              alt=""
              src="/icroundhome.svg"
            />
          </div>
          <a className="[text-decoration:none] relative leading-[3.5rem] font-medium text-plum inline-block min-w-[3rem] z-[1]">
            Home
          </a>
          <h3 className="m-0 relative text-[1.25rem] leading-[3.5rem] font-medium font-inherit inline-block min-w-[0.688rem] z-[1] mq450:text-[1rem] mq450:leading-[2.813rem]">
            /
          </h3>
          <div className="relative leading-[3.5rem] font-medium z-[1]">
            Windows VPS Hosting
          </div>
        </div>
      </section>
    );
  }
);

export default HeaderHeroKvmWindow;

import { FunctionComponent, memo } from "react";

export type BreadcrumbType = {
  className?: string;
};

const Breadcrumb: FunctionComponent<BreadcrumbType> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`absolute top-[45.875rem] left-[11.313rem] w-[23.625rem] h-[3.5rem] text-center text-[1rem] text-white font-poppins ${className}`}
      >
        <div className="absolute top-[0rem] left-[6.813rem] leading-[3.5rem] font-medium">
          Cheap Unlimited Reseller Hosting
        </div>
        <div className="absolute top-[0rem] left-[0rem] w-[5rem] h-[3.5rem] text-plum">
          <a className="[text-decoration:none] absolute top-[0rem] left-[2rem] leading-[3.5rem] font-medium text-[inherit] inline-block min-w-[3rem]">
            Home
          </a>
          <img
            className="absolute top-[1rem] left-[0rem] w-[1.5rem] h-[1.5rem] overflow-hidden"
            alt=""
            src="/icroundhome.svg"
          />
        </div>
        <div className="absolute top-[0rem] left-[5.563rem] text-[1.25rem] leading-[3.5rem] font-medium inline-block min-w-[0.688rem] mq450:text-[1rem] mq450:leading-[2.813rem] mq450:w-full mq450:h-[0.688rem]">
          /
        </div>
      </div>
    );
  }
);

export default Breadcrumb;

import { FunctionComponent, memo } from "react";

export type GroupComponent5Type = {
  className?: string;
};

const GroupComponent5: FunctionComponent<GroupComponent5Type> = memo(
  ({ className = "" }) => {
    return (
      <footer
        className={`self-stretch bg-indigo-100 flex flex-col items-start justify-start pt-[5.75rem] px-[0rem] pb-[0rem] box-border gap-[2.75rem] max-w-full text-left text-[1.25rem] text-white font-poppins mq800:gap-[1.375rem] mq800:pt-[3.75rem] mq800:box-border ${className}`}
      >
        <div className="self-stretch h-[43.5rem] relative bg-indigo-100 hidden" />
        <div className="w-[32.688rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[1.25rem] box-border max-w-full">
          <img
            className="h-[2.438rem] w-[9.938rem] relative object-contain z-[1]"
            loading="lazy"
            alt=""
            src="/hostlasting-logo-1@2x.png"
          />
        </div>
        <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
          <div className="w-[44.688rem] flex flex-col items-start justify-start gap-[0.562rem] max-w-full">
            <div className="w-[42.875rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq800:flex-wrap">
              <div className="w-[11.313rem] flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.25rem] box-border">
                <h3 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit inline-block min-w-[5.625rem] z-[1] mq450:text-[1rem] mq450:leading-[2.313rem]">
                  HOSTING
                </h3>
              </div>
              <h3 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[2.313rem]">{`DOMAINS & SSL`}</h3>
              <h3 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit inline-block min-w-[6.438rem] z-[1] mq450:text-[1rem] mq450:leading-[2.313rem]">
                COMPANY
              </h3>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start max-w-full text-[0.938rem]">
              <div className="w-[40.625rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
                <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.187rem] text-whitesmoke-200">
                  <a className="[text-decoration:none] relative leading-[2.875rem] text-[inherit] z-[1]">
                    WordPress Hosting
                  </a>
                </div>
                <div className="relative leading-[2.875rem] z-[1]">
                  Register Domains
                </div>
                <a className="[text-decoration:none] relative leading-[2.875rem] text-[inherit] inline-block min-w-[4.188rem] z-[1]">
                  About Us
                </a>
              </div>
              <div className="w-[40.188rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
                <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.125rem] text-whitesmoke-200">
                  <a className="[text-decoration:none] relative leading-[2.875rem] text-[inherit] z-[2]">
                    Cheap SSD Hosting
                  </a>
                </div>
                <div className="relative leading-[2.875rem] z-[2]">
                  Transfer Domains
                </div>
                <a className="[text-decoration:none] relative leading-[2.875rem] text-[inherit] inline-block min-w-[3.75rem] z-[1]">
                  Support
                </a>
              </div>
              <div className="w-[38.563rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
                <div className="w-[10.125rem] flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.25rem] box-border text-whitesmoke-200">
                  <a className="[text-decoration:none] relative leading-[2.875rem] text-[inherit] inline-block min-w-[5.563rem] z-[3]">
                    VPS Hosting
                  </a>
                </div>
                <div className="relative leading-[2.875rem] z-[3]">
                  Manage Domains
                </div>
                <a className="[text-decoration:none] relative leading-[2.875rem] text-[inherit] inline-block min-w-[2.063rem] z-[1]">
                  Blog
                </a>
              </div>
              <div className="self-stretch flex flex-row items-start justify-between gap-[1.25rem] mq450:flex-wrap">
                <div className="flex flex-col items-start justify-start text-whitesmoke-200">
                  <a className="[text-decoration:none] relative leading-[2.875rem] text-[inherit] z-[4]">
                    Dedicated Hosting
                  </a>
                  <div className="relative leading-[2.875rem] inline-block min-w-[7.438rem] z-[5]">
                    Reseller Hosting
                  </div>
                  <div className="flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0.187rem]">
                    <div className="relative leading-[2.875rem] z-[6]">
                      LiteSpedd Hosting
                    </div>
                  </div>
                  <a className="[text-decoration:none] relative leading-[2.875rem] text-[inherit] inline-block min-w-[2.813rem] z-[7]">
                    Offers
                  </a>
                </div>
                <div className="relative leading-[2.875rem] inline-block min-w-[7.188rem] z-[4]">
                  SSL Certificates
                </div>
                <div className="flex flex-col items-start justify-start">
                  <a className="[text-decoration:none] relative leading-[2.875rem] text-[inherit] inline-block min-w-[5.188rem] z-[1]">
                    Contact Us
                  </a>
                  <div className="relative leading-[2.875rem] z-[1]">
                    Terms of Services
                  </div>
                  <a className="[text-decoration:none] relative leading-[2.875rem] text-[inherit] inline-block min-w-[6.375rem] z-[1]">
                    Privacy Policy
                  </a>
                  <a className="[text-decoration:none] relative leading-[2.875rem] text-[inherit] inline-block min-w-[3.938rem] z-[2]">
                    Sitemap
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-end justify-start gap-[1.937rem] max-w-full text-[0.75rem] text-black mq800:gap-[0.938rem]">
          <div className="w-[31rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <div className="flex flex-row items-start justify-start gap-[1.812rem]">
              <img
                className="h-[1.5rem] w-[1.5rem] relative min-h-[1.5rem] z-[1]"
                loading="lazy"
                alt=""
                src="/rifacebookfill.svg"
              />
              <img
                className="h-[1.5rem] w-[1.5rem] relative min-h-[1.5rem] z-[1]"
                loading="lazy"
                alt=""
                src="/akariconsxfill.svg"
              />
              <img
                className="h-[1.5rem] w-[1.5rem] relative min-h-[1.5rem] z-[1]"
                loading="lazy"
                alt=""
                src="/iconoirinstagram.svg"
              />
            </div>
          </div>
          <div className="self-stretch bg-white flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full z-[1]">
            <div className="self-stretch w-[89.438rem] relative bg-white hidden max-w-full" />
            <div className="w-[30.313rem] relative leading-[1.563rem] font-medium flex items-center shrink-0 max-w-full z-[2]">{`Copyright © Hostlasting.com , Designed & Developed by Cloud19 Technologies`}</div>
          </div>
        </div>
      </footer>
    );
  }
);

export default GroupComponent5;

import { FunctionComponent, memo } from "react";

export type PlanPricingTableHeadType = {
  className?: string;
};

const PlanPricingTableHead: FunctionComponent<PlanPricingTableHeadType> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`self-stretch rounded-t-11xl rounded-b-none bg-indigo-300 overflow-x-auto flex flex-row items-start justify-start pt-[1.937rem] px-[4.625rem] pb-[1.562rem] gap-[4.687rem] z-[1] text-center text-[0.875rem] text-white font-poppins mq450:gap-[1.188rem] mq1150:gap-[2.313rem] mq1150:pl-[2.313rem] mq1150:pr-[2.313rem] mq1150:box-border ${className}`}
      >
        <div className="h-[4.75rem] w-[66.813rem] relative rounded-t-11xl rounded-b-none bg-indigo-300 shrink-0 hidden" />
        <div className="w-[8.688rem] shrink-0 flex flex-col items-start justify-start">
          <div className="relative leading-[1.25rem] font-medium inline-block min-w-[2.188rem] z-[2]">
            PLAN
          </div>
        </div>
        <div className="w-[4.563rem] shrink-0 flex flex-col items-start justify-start">
          <div className="relative leading-[1.25rem] font-medium inline-block min-w-[1.813rem] z-[2]">
            CPU
          </div>
        </div>
        <div className="relative leading-[1.25rem] font-medium inline-block min-w-[1.938rem] z-[2]">
          RAM
        </div>
        <div className="relative leading-[1.25rem] font-medium inline-block min-w-[4.063rem] z-[2]">
          STORAGE
        </div>
        <div className="relative leading-[1.25rem] font-medium inline-block min-w-[5.25rem] z-[2]">
          BANDWIDTH
        </div>
      </div>
    );
  }
);

export default PlanPricingTableHead;

import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type PlanDetailsType = {
  className?: string;

  /** Style props */
  propMargin?: CSSProperties["margin"];
};

const PlanDetails: FunctionComponent<PlanDetailsType> = memo(
  ({ className = "", propMargin }) => {
    const freeLetsEncrypt1Style: CSSProperties = useMemo(() => {
      return {
        margin: propMargin,
      };
    }, [propMargin]);

    return (
      <div
        className={`self-stretch flex flex-row items-start justify-start py-[0rem] pl-[2.687rem] pr-[2.937rem] text-center text-[1.5rem] text-black font-poppins mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border ${className}`}
      >
        <div className="flex-1 flex flex-col items-end justify-start gap-[0.343rem]">
          <div className="relative leading-[2.938rem] font-semibold z-[1] mq450:text-[1.188rem] mq450:leading-[2.375rem]">
            ₹140/mo ($2)
          </div>
          <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0.562rem] pr-[0.437rem] text-[0.688rem]">
            <div className="flex-1 flex flex-col items-end justify-start">
              <div className="self-stretch flex flex-row items-start justify-end py-[0rem] pl-[0rem] pr-[0.125rem]">
                <div className="h-[2.938rem] flex-1 relative shrink-0">
                  <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem] z-[1]" />
                  <div className="absolute top-[0rem] left-[2.125rem] leading-[2.938rem] font-medium inline-block min-w-[5rem] z-[2]">
                    Host 1 Website
                  </div>
                </div>
              </div>
              <div className="self-stretch h-[2.938rem] relative shrink-0 mt-[-0.438rem]">
                <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem] z-[1]" />
                <div className="absolute top-[0rem] left-[0.75rem] leading-[2.938rem] font-medium inline-block min-w-[7.75rem] z-[3]">
                  Unlimited SSD Storage
                </div>
              </div>
              <div className="self-stretch h-[2.938rem] relative shrink-0 mt-[-0.438rem]">
                <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem] z-[1]" />
                <div className="absolute top-[0rem] left-[0.938rem] leading-[2.938rem] font-medium inline-block min-w-[7.313rem] z-[2]">
                  Unlimited bandwidth
                </div>
              </div>
              <div className="self-stretch h-[2.938rem] relative shrink-0 mt-[-0.438rem]">
                <div className="absolute top-[0.625rem] left-[0rem] rounded-11xl bg-royalblue-300 w-[9.25rem] h-[1.75rem] z-[1]" />
                <div className="absolute top-[0rem] left-[1rem] leading-[2.938rem] font-medium inline-block min-w-[7.313rem] z-[2]">
                  Unlimited FTP, MySQL
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-end py-[0rem] pl-[1.125rem] pr-[1.062rem] text-[0.938rem] text-lightgray-200">
            <div
              className="relative leading-[2.938rem] font-semibold z-[1]"
              style={freeLetsEncrypt1Style}
            >
              Free Let's Encrypt
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PlanDetails;

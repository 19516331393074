import { FunctionComponent } from "react";
import HeaderHeroSSDLiteSpeedHost from "../components/HeaderHeroSSDLiteSpeedHost";
import SSDLightPlansAndDetails from "../components/SSDLightPlansAndDetails";
import SSDLightCompetitionDetails from "../components/SSDLightCompetitionDetails";

const SSDLiteSpeedHosting: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[4.062rem] leading-[normal] tracking-[normal] mq450:gap-[1rem] mq750:gap-[2rem]">
      <div className="w-[0.188rem] h-[9.938rem] relative border-white border-r-[3px] border-solid box-border hidden" />
      <section className="self-stretch flex flex-row items-start justify-start relative max-w-full text-center text-[1rem] text-white font-poppins">
        <img
          className="h-[40.813rem] w-[66.375rem] absolute !m-[0] right-[11.5rem] bottom-[-4.25rem] object-cover"
          loading="lazy"
          alt=""
          src="/features-1@2x.png"
        />
        <div className="flex-1 flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[40.125rem] box-border gap-[4.375rem] max-w-full lg:pb-[16.938rem] lg:box-border mq450:gap-[1.063rem] mq450:pb-[7.125rem] mq450:box-border mq750:gap-[2.188rem] mq1050:pb-[11rem] mq1050:box-border">
          <HeaderHeroSSDLiteSpeedHost />
          <SSDLightPlansAndDetails />
        </div>
        <h3 className="!m-[0] absolute top-[45.875rem] left-[18.125rem] text-inherit leading-[3.5rem] font-medium font-inherit z-[1]">
          SSD LiteSpeed Hosting
        </h3>
        <a className="[text-decoration:none] absolute !m-[0] top-[45.875rem] left-[13.313rem] leading-[3.5rem] font-medium text-plum inline-block min-w-[3rem] z-[1]">
          Home
        </a>
        <img
          className="h-[1.5rem] w-[1.5rem] absolute !m-[0] top-[46.875rem] left-[11.313rem] overflow-hidden shrink-0 z-[1]"
          loading="lazy"
          alt=""
          src="/icroundhome.svg"
        />
        <h2 className="!m-[0] absolute top-[45.875rem] left-[16.875rem] text-[1.25rem] leading-[3.5rem] font-medium font-inherit inline-block min-w-[0.688rem] z-[1] mq450:text-[1rem] mq450:leading-[2.813rem]">
          /
        </h2>
      </section>
      <SSDLightCompetitionDetails />
      <footer className="self-stretch bg-indigo-100 flex flex-col items-start justify-start pt-[5.75rem] px-[0rem] pb-[0rem] box-border gap-[2.75rem] max-w-full text-left text-[1.25rem] text-white font-poppins mq750:gap-[1.375rem] mq750:pt-[3.75rem] mq750:box-border">
        <div className="self-stretch h-[43.5rem] relative bg-indigo-100 hidden" />
        <div className="w-[32.688rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[1.25rem] box-border max-w-full">
          <img
            className="h-[2.438rem] w-[9.938rem] relative object-contain z-[1]"
            loading="lazy"
            alt=""
            src="/hostlasting-logo-1@2x.png"
          />
        </div>
        <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
          <div className="w-[44.688rem] flex flex-col items-start justify-start gap-[0.562rem] max-w-full">
            <div className="w-[42.875rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq750:flex-wrap">
              <div className="w-[11.313rem] flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.25rem] box-border">
                <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit inline-block min-w-[5.625rem] z-[1] mq450:text-[1rem] mq450:leading-[2.313rem]">
                  HOSTING
                </h2>
              </div>
              <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[2.313rem]">{`DOMAINS & SSL`}</h2>
              <h2 className="m-0 relative text-inherit leading-[2.875rem] font-semibold font-inherit inline-block min-w-[6.438rem] z-[1] mq450:text-[1rem] mq450:leading-[2.313rem]">
                COMPANY
              </h2>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start max-w-full text-[0.938rem]">
              <div className="w-[40.625rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
                <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.187rem] text-whitesmoke-200">
                  <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit z-[1]">
                    WordPress Hosting
                  </h3>
                </div>
                <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit z-[1]">
                  Register Domains
                </h3>
                <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit inline-block min-w-[4.188rem] z-[1]">
                  About Us
                </h3>
              </div>
              <div className="w-[40.188rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
                <div className="flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.125rem] text-whitesmoke-200">
                  <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit z-[2]">
                    Cheap SSD Hosting
                  </h3>
                </div>
                <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit z-[2]">
                  Transfer Domains
                </h3>
                <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit inline-block min-w-[3.75rem] z-[1]">
                  Support
                </h3>
              </div>
              <div className="w-[38.563rem] flex flex-row items-start justify-between gap-[1.25rem] max-w-full mq450:flex-wrap">
                <div className="w-[10.125rem] flex flex-col items-start justify-start py-[0rem] pl-[0rem] pr-[1.25rem] box-border text-whitesmoke-200">
                  <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit inline-block min-w-[5.563rem] z-[3]">
                    VPS Hosting
                  </h3>
                </div>
                <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit z-[3]">
                  Manage Domains
                </h3>
                <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit inline-block min-w-[2.063rem] z-[1]">
                  Blog
                </h3>
              </div>
              <div className="self-stretch flex flex-row items-start justify-between gap-[1.25rem] mq450:flex-wrap">
                <div className="flex flex-col items-start justify-start text-whitesmoke-200">
                  <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit z-[4]">
                    Dedicated Hosting
                  </h3>
                  <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit inline-block min-w-[7.438rem] z-[5]">
                    Reseller Hosting
                  </h3>
                  <div className="flex flex-row items-start justify-start py-[0rem] pl-[0.062rem] pr-[0.187rem]">
                    <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit z-[6]">
                      LiteSpedd Hosting
                    </h3>
                  </div>
                  <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit inline-block min-w-[2.813rem] z-[7]">
                    Offers
                  </h3>
                </div>
                <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit inline-block min-w-[7.188rem] z-[4]">
                  SSL Certificates
                </h3>
                <div className="flex flex-col items-start justify-start">
                  <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit inline-block min-w-[5.188rem] z-[1]">
                    Contact Us
                  </h3>
                  <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit z-[1]">
                    Terms of Services
                  </h3>
                  <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit inline-block min-w-[6.375rem] z-[1]">
                    Privacy Policy
                  </h3>
                  <h3 className="m-0 relative text-inherit leading-[2.875rem] font-normal font-inherit inline-block min-w-[3.938rem] z-[2]">
                    Sitemap
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-end justify-start gap-[1.937rem] max-w-full text-[0.75rem] text-black mq750:gap-[0.938rem]">
          <div className="w-[31rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
            <div className="flex flex-row items-start justify-start gap-[1.812rem]">
              <img
                className="h-[1.5rem] w-[1.5rem] relative min-h-[1.5rem] z-[1]"
                loading="lazy"
                alt=""
                src="/rifacebookfill.svg"
              />
              <img
                className="h-[1.5rem] w-[1.5rem] relative min-h-[1.5rem] z-[1]"
                loading="lazy"
                alt=""
                src="/akariconsxfill.svg"
              />
              <img
                className="h-[1.5rem] w-[1.5rem] relative min-h-[1.5rem] z-[1]"
                loading="lazy"
                alt=""
                src="/iconoirinstagram.svg"
              />
            </div>
          </div>
          <div className="self-stretch bg-white flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full z-[1]">
            <div className="self-stretch w-[89.438rem] relative bg-white hidden max-w-full" />
            <div className="w-[30.313rem] relative leading-[1.563rem] font-medium flex items-center shrink-0 max-w-full z-[2]">{`Copyright © Hostlasting.com , Designed & Developed by Cloud19 Technologies`}</div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SSDLiteSpeedHosting;

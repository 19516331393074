import { FunctionComponent, memo, useMemo, type CSSProperties } from "react";

export type FrameComponent110Type = {
  className?: string;
  dEDICATEDFULLROOTACCESS?: string;
  betterHighControlResource?: string;
  increasedEfficiency?: string;
  getMaximumFlexibility?: string;

  /** Style props */
  propMinWidth?: CSSProperties["minWidth"];
};

const FrameComponent110: FunctionComponent<FrameComponent110Type> = memo(
  ({
    className = "",
    propMinWidth,
    dEDICATEDFULLROOTACCESS,
    betterHighControlResource,
    increasedEfficiency,
    getMaximumFlexibility,
  }) => {
    const frameDiv8Style: CSSProperties = useMemo(() => {
      return {
        minWidth: propMinWidth,
      };
    }, [propMinWidth]);

    return (
      <div
        className={`flex flex-col items-start justify-start gap-[0.75rem] text-left text-[1rem] text-indigo-100 font-poppins ${className}`}
        style={frameDiv8Style}
      >
        <div className="relative leading-[2.875rem] font-semibold z-[1]">
          {dEDICATEDFULLROOTACCESS}
        </div>
        <div className="flex flex-row items-start justify-start gap-[0.75rem] text-center text-[0.75rem] text-slategray-100">
          <div className="flex flex-col items-start justify-start pt-[0.312rem] px-[0rem] pb-[0rem]">
            <div className="flex flex-col items-start justify-start gap-[1.25rem]">
              <img
                className="w-[0.875rem] h-[0.625rem] relative z-[1]"
                loading="lazy"
                alt=""
                src="/vector1.svg"
              />
              <img
                className="w-[0.875rem] h-[0.625rem] relative z-[1]"
                alt=""
                src="/vector1.svg"
              />
              <img
                className="w-[0.875rem] h-[0.625rem] relative z-[1]"
                alt=""
                src="/vector1.svg"
              />
            </div>
          </div>
          <div className="flex flex-col items-start justify-start gap-[0.625rem]">
            <div className="relative leading-[1.25rem] z-[1]">
              {betterHighControlResource}
            </div>
            <div className="relative leading-[1.25rem] inline-block min-w-[7.5rem] z-[1]">
              {increasedEfficiency}
            </div>
            <div className="relative leading-[1.25rem] text-left z-[1]">
              {getMaximumFlexibility}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FrameComponent110;

import { FunctionComponent, memo } from "react";

export type KnowKVMGridSectionsType = {
  className?: string;
};

const KnowKVMGridSections: FunctionComponent<KnowKVMGridSectionsType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[11.812rem] box-border max-w-full text-left text-[1.25rem] text-indigo-100 font-poppins lg:pb-[5rem] lg:box-border mq750:pb-[3.25rem] mq750:box-border ${className}`}
      >
        <div className="flex-1 bg-ghostwhite-100 flex flex-row flex-wrap items-start justify-center pt-[3.375rem] px-[11.437rem] pb-[8.187rem] box-border gap-[7.937rem] max-w-full lg:gap-[3.938rem] lg:pt-[1.438rem] lg:px-[5.688rem] lg:pb-[3.438rem] lg:box-border mq450:gap-[1rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq750:gap-[2rem] mq750:pt-[1.25rem] mq750:px-[2.813rem] mq750:pb-[2.25rem] mq750:box-border">
          <div className="h-[137.25rem] w-[89.438rem] relative bg-ghostwhite-100 hidden max-w-full" />
          <div className="flex-1 flex flex-col items-start justify-start gap-[5.25rem] min-w-[19.063rem] max-w-full mq750:gap-[2.625rem]">
            <div className="self-stretch flex flex-row items-start justify-center py-[0rem] pl-[5.25rem] pr-[1.25rem] mq450:pl-[1.25rem] mq450:box-border">
              <div className="h-[11.875rem] w-[11.875rem] relative bg-[url('/public/frame-3@3x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
                <img
                  className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden z-[1]"
                  alt=""
                  src="/ebe24492895e499ab1d02eeb589abda4a-3@2x.png"
                />
                <div className="absolute top-[11.5rem] left-[3.563rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[2]" />
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem]">
              <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  KVM-based virtualization:
                </h3>
              </div>
              <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                Kernel-based Virtual Machine (KVM) virtualization provides one
                of the best and reliable Windows VPS hosting services. With
                independently dedicated resources and suitable configuration,
                our suitable and budget-friendly plans will cater to your
                business needs.
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[1.562rem] pl-[6.25rem] pr-[1.25rem] mq450:pl-[1.25rem] mq450:box-border">
              <div className="h-[12.875rem] w-[12.875rem] relative bg-[url('/public/www-domain-name-1@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
                <img
                  className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                  alt=""
                  src="/www-domain-name-1@2x.png"
                />
                <div className="absolute top-[11.75rem] left-[3.563rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start pt-[0rem] pb-[1.75rem] pl-[0.125rem] pr-[0rem] box-border max-w-full">
              <div className="flex-1 flex flex-col items-end justify-start gap-[3.625rem] max-w-full mq750:gap-[1.813rem]">
                <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem]">
                  <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                    <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                      Cost-saving:
                    </h3>
                  </div>
                  <div className="self-stretch h-[5.438rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0 z-[1]">
                    Hostlasting’s plans are designed in such a way that clients
                    will avail the required services at minimal cost. You can
                    avail excellent performance and quality service through our
                    customized plans at as low cost as possible.
                  </div>
                </div>
                <div className="w-[24.375rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full">
                  <div className="h-[12.875rem] w-[12.875rem] relative bg-[url('/public/cloud-storage-5a-2@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
                    <img
                      className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                      alt=""
                      src="/cloud-storage-5a-2@2x.png"
                    />
                    <div className="absolute top-[11.813rem] left-[3.875rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[0.812rem] box-border gap-[1.125rem] max-w-full">
              <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem] box-border max-w-full">
                <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Avail different Windows OS versions:
                </h3>
              </div>
              <div className="self-stretch h-[5.563rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0 z-[1]">
                Clients can avail licensed versions of Windows 2008, 2012, and
                2016 operating systems with KVM Windows VPS hosting services.
                You can gain benefits of fully-licensed Windows VPS with remote
                desktop.
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[1.706rem] pl-[5.187rem] pr-[1.25rem]">
              <div className="w-[7.688rem] flex flex-col items-start justify-start gap-[1.381rem]">
                <img
                  className="w-[7.688rem] h-[7.688rem] relative object-cover z-[1]"
                  loading="lazy"
                  alt=""
                  src="/complain-to-customer-servicea-11@2x.png"
                />
                <div className="self-stretch flex flex-row items-start justify-start py-[0rem] pl-[1.375rem] pr-[1.437rem]">
                  <div className="h-[0.538rem] flex-1 relative [filter:blur(11.4px)] rounded-[50%] bg-gray-1300 z-[1]" />
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[1.125rem]">
              <div className="flex flex-row items-start justify-start py-[0rem] px-[0.125rem]">
                <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  On-demand assistance:
                </h3>
              </div>
              <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                Along with solving your problems, we offer full assistance
                related to control panels, data transfer, scalability, and other
                tasks. We do not charge an extra penny for ensuring agility to
                your business, and offer assistance to ensure your success.
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start pt-[1.375rem] px-[0rem] pb-[0rem] box-border min-w-[19rem] max-w-full">
            <div className="self-stretch flex flex-col items-start justify-start gap-[3.062rem] mq750:gap-[1.5rem]">
              <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[1.75rem] gap-[1.125rem]">
                <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Security for storage:
                </h3>
                <div className="self-stretch h-[6.375rem] relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 flex items-center shrink-0 z-[1]">
                  High-quality resources, features, and solutions are available
                  at affordable prices. Our flexible plans will not burden your
                  pockets, but definitely ensure seamless operation of your
                  website.
                </div>
              </div>
              <div className="w-[16.188rem] flex flex-row items-start justify-start pt-[0rem] px-[1.75rem] pb-[2.312rem] box-border">
                <div className="h-[12.688rem] flex-1 relative bg-[url('/public/metaverse-1@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
                  <img
                    className="absolute top-[0rem] left-[0rem] w-full h-full object-cover hidden"
                    alt=""
                    src="/metaverse-1@2x.png"
                  />
                  <div className="absolute top-[12.313rem] left-[4.563rem] [filter:blur(4px)] rounded-[50%] bg-gray-1600 w-[4.688rem] h-[0.375rem] z-[1]" />
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[4.125rem] gap-[1.125rem]">
                <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  European server infrastructure:
                </h3>
                <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                  Location of our KVM VPS servers is Lithuania, Europe. The
                  location has been chosen by taking continuity and reliability
                  needs into consideration. Our servers are placed and
                  maintained at Tier III datacenters. This avoids interruption
                  of services and failure of components.
                </div>
              </div>
              <div className="flex flex-row items-start justify-start py-[0rem] px-[2.562rem]">
                <div className="flex flex-col items-end justify-start gap-[0.687rem]">
                  <img
                    className="w-[11.063rem] h-[11.063rem] relative object-cover z-[1]"
                    loading="lazy"
                    alt=""
                    src="/decentralized-financea-3@2x.png"
                  />
                  <div className="w-[9.938rem] flex flex-row items-start justify-end py-[0rem] px-[2.625rem] box-border">
                    <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[5.562rem] gap-[1.125rem]">
                <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Top-notch hardware:
                </h3>
                <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                  Our servers utilize high-performance and dedicated processors.
                  They are equipped with multi-core (20 cores/40 threads) Intel
                  Xeon E5-2640 v4 processors. Moreover, each node contains 256GB
                  RAM with dedicated network connection of 4 GBPS.
                </div>
              </div>
              <div className="flex flex-row items-start justify-start pt-[0rem] px-[3.375rem] pb-[1.937rem]">
                <div className="flex flex-col items-end justify-start gap-[0.687rem]">
                  <img
                    className="w-[8.813rem] h-[8.813rem] relative object-cover z-[1]"
                    loading="lazy"
                    alt=""
                    src="/2-weba-5@2x.png"
                  />
                  <div className="w-[7.063rem] flex flex-row items-start justify-end py-[0rem] px-[1.187rem] box-border">
                    <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start pt-[0rem] px-[0rem] pb-[3.687rem] gap-[1.125rem]">
                <h3 className="m-0 relative text-inherit leading-[1.688rem] font-semibold font-inherit z-[1] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  24*7 guidance and support:
                </h3>
                <div className="self-stretch relative text-[0.938rem] tracking-[0.04em] leading-[1.25rem] text-slategray-100 z-[1]">
                  Our team of highly-skilled professionals and experts are
                  available to solve your problems and help you with all of your
                  issues once you avail our services. We are instrumental in
                  being available for our clients 24*7 and solve their queries
                  as soon as possible.
                </div>
              </div>
              <div className="flex flex-row items-start justify-start py-[0rem] px-[1.812rem]">
                <div className="flex flex-col items-end justify-start gap-[1.75rem]">
                  <img
                    className="w-[9.938rem] h-[9.938rem] relative object-cover z-[1]"
                    loading="lazy"
                    alt=""
                    src="/fp0124-spacewalk-1@2x.png"
                  />
                  <div className="w-[7.938rem] flex flex-row items-start justify-end py-[0rem] px-[1.625rem] box-border">
                    <div className="h-[0.375rem] flex-1 relative [filter:blur(4px)] rounded-[50%] bg-gray-1600 z-[1]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default KnowKVMGridSections;

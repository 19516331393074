import { FunctionComponent, memo } from "react";

export type GroupComponent2Type = {
  className?: string;
};

const GroupComponent2: FunctionComponent<GroupComponent2Type> = memo(
  ({ className = "" }) => {
    return (
      <div
        className={`flex-1 rounded-3xs bg-ghostwhite-200 flex flex-col items-start justify-start pt-[1.687rem] px-[1.437rem] pb-[1.75rem] box-border gap-[0.75rem] min-w-[11.875rem] max-w-[13.5rem] text-left text-[0.625rem] text-indigo-100 font-poppins ${className}`}
      >
        <div className="w-[13.5rem] h-[7.375rem] relative rounded-3xs bg-ghostwhite-200 hidden" />
        <img
          className="self-stretch h-[2.125rem] relative max-w-full overflow-hidden shrink-0 object-contain z-[1]"
          loading="lazy"
          alt=""
          src="/logofox-1@2x.png"
        />
        <div className="self-stretch flex flex-row items-start justify-start py-[0rem] px-[1.437rem]">
          <div className="flex-1 flex flex-row items-end justify-start gap-[0.75rem]">
            <div className="flex-1 flex flex-row items-start justify-start gap-[0.312rem]">
              <img
                className="h-[0.938rem] w-[0.938rem] relative min-h-[0.938rem] z-[1]"
                alt=""
                src="/rating-stars.svg"
              />
              <img
                className="h-[0.938rem] w-[0.938rem] relative min-h-[0.938rem] z-[1]"
                alt=""
                src="/rating-stars.svg"
              />
              <img
                className="h-[0.938rem] w-[0.938rem] relative min-h-[0.938rem] z-[1]"
                alt=""
                src="/rating-stars.svg"
              />
              <img
                className="h-[0.938rem] w-[0.938rem] relative min-h-[0.938rem] z-[1]"
                alt=""
                src="/rating-stars.svg"
              />
              <img
                className="h-[0.938rem] w-[0.938rem] relative min-h-[0.938rem] z-[1]"
                alt=""
                src="/rating-stars1.svg"
              />
            </div>
            <div className="relative tracking-[0.04em] leading-[1.063rem] font-semibold inline-block min-w-[1.063rem] z-[1]">
              4.8
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default GroupComponent2;

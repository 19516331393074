import { FunctionComponent, memo } from "react";
import Benefits from "./Benefits";
import HostingFeatures1 from "./HostingFeatures1";

export type CPanelCheapestGridType = {
  className?: string;
};

const CPanelCheapestGrid: FunctionComponent<CPanelCheapestGridType> = memo(
  ({ className = "" }) => {
    return (
      <section
        className={`self-stretch flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.937rem] box-border max-w-full text-left text-[1.25rem] text-indigo-100 font-poppins ${className}`}
      >
        <div className="flex-1 bg-ghostwhite-100 flex flex-row flex-wrap items-start justify-center py-[5.187rem] px-[11.437rem] box-border gap-[7.937rem] min-h-[137.25rem] max-w-full lg:gap-[3.938rem] lg:py-[3.375rem] lg:px-[5.688rem] lg:box-border mq450:gap-[1rem] mq450:pl-[1.25rem] mq450:pr-[1.25rem] mq450:box-border mq750:gap-[2rem] mq750:py-[1.438rem] mq750:px-[2.813rem] mq750:box-border mq1050:pt-[2.188rem] mq1050:pb-[2.188rem] mq1050:box-border">
          <div className="h-[137.25rem] w-[89.438rem] relative bg-ghostwhite-100 hidden max-w-full" />
          <Benefits />
          <HostingFeatures1 />
        </div>
      </section>
    );
  }
);

export default CPanelCheapestGrid;
